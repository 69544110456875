import { Accounts } from '../listSlice'
import {
    Form,
    Input,
    Grid,
    Space,
    Typography,
    Image,
    Divider,
    Card,
    Row,
    Col
} from "antd"
import dayjs from "dayjs"
import { useAppSelector } from '../../../app/hooks'
import { setAccountsType } from '../../book/const'
import IconFont from '../../../components/IconFont'

const { useBreakpoint } = Grid
const { Title } = Typography

type Props = {
    accounts?: Accounts
    exceedReason?: string
    staffUserName?: string[]
}

const TravelViewer = ({ accounts: t, exceedReason, staffUserName }: Props): JSX.Element => {
    const screens = useBreakpoint()
    const orderInfo = useAppSelector(state => state.reimburseFormViewer.orderInfo)
    const { Item } = Form

    return (
        <Form style={{ width: screens.lg ? "90%" : "100%" }}>
            {t?.accountsType === 16 &&
                <>
                    <Item label="发票类型">
                        <Input value={t.invoiceType} className="readonlyInput" disabled />
                    </Item>
                    <Item label="发票税率">
                        <Input value={Math.round(t?.taxRate * 100) + '%'} className="readonlyInput" disabled />
                    </Item>

                    <Item label="酒店名称">
                        <Input value={t.accountsDescription} className="readonlyInput" disabled />
                    </Item>
                    <Item label="所在城市">
                        <Input value={t.city} className="readonlyInput" disabled />
                    </Item>
                </>
            }

            {t && (t.accountsType === 18 || (t.accountsType >= 1800 && t.accountsType <= 1900)) &&
                <Item label="补助名称">
                    <Input value={t.accountsDescription} className="readonlyInput" disabled />
                </Item>
            }
            {t && (t.accountsType !== 18 && !(t.accountsType >= 1800 && t.accountsType <= 1900)) &&
                <>
                    <Item label="出发地">
                        <Input value={t.accountsStartData} className="readonlyInput" disabled />
                    </Item>
                    <Item label="目的地">
                        <Input value={t.accountsEndData} className="readonlyInput" disabled />
                    </Item>
                </>
            }
            {
                t && (t.accountsType === 13 || t.accountsType === 18 || (t.accountsType >= 1800 && t.accountsType <= 1900)) &&
                <>
                    <Item label="出差城市">
                        <Input value={t.city} className="readonlyInput" disabled />
                    </Item>
                    <Item label={t.accountsType === 13 ? "出行方式" : "补助类型"}>
                        <Input value={t.auditInfo} className="readonlyInput" disabled />
                    </Item>
                </>
            }
            {
                t && (t.accountsType === 16 || t.accountsType === 18 || (t.accountsType >= 1800 && t.accountsType <= 1900)) &&
                <>
                    <Item label="起始时间">
                        <Input value={dayjs(parseInt(t.accountsStartData)).format("YYYY-MM-DD")} className="readonlyInput" disabled />
                    </Item>
                    <Item label="结束时间">
                        <Input value={dayjs(parseInt(t.accountsEndData)).format("YYYY-MM-DD")} className="readonlyInput" disabled />
                    </Item>
                </>
            }
            {
                t && (t.accountsType === 9 || t.accountsType === 10 || t.accountsType === 11 || t.accountsType === 14) &&
                <Item label="票号">
                    <Input value={t.ticket} className="readonlyInput" disabled />
                </Item>
            }
            {
                t && (t.accountsType === 10 || t.accountsType === 11 || t.accountsType === 13 || t.accountsType === 14) &&
                <Item label='发票类型'>
                    <Input value={t.invoiceType} className="readonlyInput" disabled />
                </Item>
            }
            {t && (t.accountsType === 19 || t.accountsType === 20 || t.accountsType === 21 || t.accountsType === 22) &&
                <Item label="车牌号">
                    <Input value={t.auditInfo} className="readonlyInput" disabled />
                </Item>
            }
            {/* 补助缺 本人承诺对方单位未提供用餐 */}
            {/* 轮船缺一个是否实名制 */}
            {
                t && (t.accountsType === 9 || t.accountsType === 10 || t.accountsType === 11) &&
                <Item label='席位'>
                    <Input value={t.auditInfo} className="readonlyInput" disabled />
                </Item>
            }
            {
                t && (t.accountsType === 10 || t.accountsType === 11) &&
                <Item label={t.accountsType === 10 ? '航班号' : '车次'}>
                    <Input value={t.trafficNo} className="readonlyInput" disabled />
                </Item>
            }
            {t && (t.accountsType !== 16 && t.accountsType !== 18 && !(t.accountsType >= 1800 && t.accountsType <= 1900)) &&
                <Item label="时间">
                    <Input value={t.accountDate} className="readonlyInput" disabled />
                </Item>
            }
            {/* 本人承诺 */}
            <Item label="归属人">
                <Input value={staffUserName} className="readonlyInput" disabled />
            </Item>
            {t?.accountsRemarks && <Item label='备注'>
                <Input value={t.accountsRemarks} className="readonlyInput" disabled />
            </Item>}
            <Item label="支付方式">
                <Input value={t?.corpAccounts ? '公司' : '个人支付'} className="readonlyInput" disabled />
            </Item>
            {exceedReason && <Item label='超标原因'>
                <Input value={exceedReason} className="readonlyInput" disabled />
            </Item>}
            <Divider />
            <Title level={5}>多币种信息</Title>
            <Form.Item
                label='费用金额'
                name='accountsSumMoney'
                initialValue={t?.accountsSumMoney}
            >
                <Input className="readonlyInput" prefix="￥" addonAfter={t?.currency} disabled />
            </Form.Item>
            <Form.Item
                label='标准金额'
                name='accountsSumMoney2'
                initialValue={t?.accountsSumMoney2}
            >
                <Input className="readonlyInput" prefix="￥" addonAfter={t?.currency2} disabled />
            </Form.Item>
            <Form.Item
                label='结算金额'
                name='accountsSumMoney3'
                initialValue={t?.accountsSumMoney3}
            >
                <Input className="readonlyInput" prefix="￥" addonAfter={t?.currency3} disabled />
            </Form.Item>
            {orderInfo && <><Title level={5}>关联订单</Title>
                <Form.Item label='订单编号' name='customId' initialValue={orderInfo.customId}><Input className="readonlyInput" disabled /></Form.Item>
                <Form.Item label='发车时间' name='depatureTime' initialValue={orderInfo.depatureTime}><Input className="readonlyInput" disabled /></Form.Item>
                <Form.Item label='预定时间' name='orderTime' initialValue={orderInfo.orderTime}><Input className="readonlyInput" disabled /></Form.Item>
                <Form.Item label='订单总额' name='total' initialValue={orderInfo.total}><Input className="readonlyInput" disabled /></Form.Item>
                <Card>
                    <Row gutter={[24, 24]} justify='center' align='middle'>
                        <Col flex="1" style={{ textAlign: "center" }}><div> {orderInfo.fromPlace}{orderInfo.roomType}</div></Col>
                        <Col flex='none'><IconFont type={setAccountsType.find(item => item.value === Number(t?.accountsType))?.icon ?? ''} style={{ fontSize: '30px' }} /></Col>
                        <Col flex='1' style={{ textAlign: "center" }}><div>{orderInfo.toPlace}{orderInfo.night && orderInfo.night + '夜间'}</div></Col>
                    </Row>
                    <Row gutter={[24, 24]} justify='center' align='middle'>
                        <Col flex="1" style={{ textAlign: "center" }}>
                            <div>{orderInfo.number}{orderInfo.hotelName}</div>
                        </Col>
                        <Col flex='none'></Col>
                        <Col flex="1" style={{ textAlign: "center" }}>
                            {orderInfo.userName}{orderInfo.seat}
                        </Col>
                    </Row>
                </Card>
                <Form.Item label='其他信息' name='remark' initialValue={orderInfo.remark}><Input className="readonlyInput" disabled /></Form.Item>
            </>
            }
            <Divider />
            {t?.fileRepertories && <Card
                styles={{ body: { padding: 0 }, header: { padding: 0 } }} title='附件' bordered={false}>
                <Space size='large' wrap>
                    {
                        t?.fileRepertories?.map((it, idx) => {
                            return <Image key={idx} src={it.url} width={140} height={140} />
                        })
                    }
                </Space>
            </Card>
            }
        </Form>
    )
}

export default TravelViewer
