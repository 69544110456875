import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Dayjs } from 'dayjs'
import { RootState } from "../../../app/store"
import { accessTokenSelector, } from "../../user/loginSlice"
import { currentCompanyIdSelector, currentStaffSelector } from "../../user/userSlice"
import { bookingApplyForSelector } from "../bookSlice"
import { fetchHotelBookingApi, fetchHotelDetailApi, fetchHotelKeywordApi, fetchHotelListApi, fetchHotelRefundApi, Hotel, HotelBookingQuery, HotelDetail, HotelDetailQuery, HotelKeyword, HotelKeywordQuery, HotelListQuery, HotelRefundQuery } from "./hotelAPI"
import { HotelListQueryArgs } from "./list/TopSearch"
import { HotelSelected } from "./pay/SelectPerson"
import { CommonLegacyResult } from "../../models/misc"
interface State {
    keywordList?: HotelKeyword[],
    keywordLoading: boolean,
    hotelLoading: boolean,
    hotelListQuery?: HotelListQuery,
    hotelList?: Hotel[],
    hotelListHasMore: boolean
    hotelDetail?: HotelDetail,
    hotelDetailError: boolean,
    bookingError?: string | number,
    refundError?: string | number,
    bookingPriceError?: CommonLegacyResult<string>
}

export interface HotelBookingFormValues {
    productId: string,
    supplierId: string,
    checkInDate: Dayjs,
    checkOutDate: Dayjs,
    roomCount: number,
    selectedTraveller: HotelSelected[],
    contact: string,
    arrivalEarlyTime: string,
    amount: number,
}

const initialState: State = {
    keywordLoading: false,
    hotelLoading: false,
    hotelListHasMore: false,
    hotelDetailError: false,
}

// 酒店关键字查询
export const hotelKeywordSearch = createAsyncThunk<HotelKeyword[], HotelKeywordQuery>('book/hotel/keyword', async (q: HotelKeywordQuery, { getState, rejectWithValue }) => {
    const token = accessTokenSelector(getState() as RootState)
    if (!token) return rejectWithValue(undefined)
    if (q.keyword === '') return rejectWithValue(undefined)
    try {
        return await fetchHotelKeywordApi(token, q)
    } catch {
        return rejectWithValue(undefined)
    }

})

const makeHotelListQuery = (args: HotelListQueryArgs, cid: string): HotelListQuery => {
    const query: HotelListQuery = {
        pageNo: 1,
        depthPath: args.city.depthPath,
        checkInDate: args.dateRange?.[0]?.format("YYYY-MM-DD") || "",
        checkOutDate: args.dateRange?.[1]?.format("YYYY-MM-DD") || "",
        companyId: cid,
        roomPriceStart: args.priceRange?.minPrice,
        roomPriceEnd: args.priceRange?.maxPrice,
        stars: args.stars,
    }
    if (args.keyword) {
        switch (args.keyword.type) {
            case "1":
                query.hotelName = args.keyword.name
                break
            case "3":
                query.latitude = `${args.keyword.lat}`
                query.longitude = `${args.keyword.lon}`
                break
        }
    }
    return query
}
// 酒店列表查询
export const hotelList = createAsyncThunk<[HotelListQuery, Array<Hotel>], HotelListQueryArgs | undefined>('book/hotel/list/query',
 async (args: HotelListQueryArgs | undefined, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as RootState
    const token = accessTokenSelector(state)
    const cid = currentCompanyIdSelector(state)
    const oldQuery = hotelListQuerySelector(state)
    if (!token || !cid) return rejectWithValue(undefined)
    if (args) {
        dispatch(cleanHotelList())
    }
    const query = args ? makeHotelListQuery(args, cid) : oldQuery && {
        ...oldQuery,
        pageNo: oldQuery.pageNo + 1
    }

    if (!query) return rejectWithValue(undefined)
    try {
        return [query, await fetchHotelListApi(token, query)]
    } catch {
        return rejectWithValue(undefined)
    }
})

const hotelListQuerySelector = (state: RootState) => state.bookHotel.hotelListQuery
//酒店详情
export const hotelDetail = createAsyncThunk<HotelDetail, string>('hotel/detail/query', async (id: string, { getState, rejectWithValue }) => {
    const state = getState() as RootState
    const token = accessTokenSelector(state)
    const hlq = hotelListQuerySelector(state)
    if (!token || !hlq) return rejectWithValue(undefined)
    const q: HotelDetailQuery = {
        ...hlq,
        hotelId: id,
    }
    try {
        return await fetchHotelDetailApi(token, q)
    } catch {
        return rejectWithValue(undefined)
    }
})
// 酒店预定下单
export const hotelBooking = createAsyncThunk('hotel/booking', async (values: HotelBookingFormValues, { getState, rejectWithValue }) => {
    const state = getState() as RootState
    const token = accessTokenSelector(state)
    const hlq = hotelListQuerySelector(state)
    const applyform = bookingApplyForSelector(state)
    const staff = currentStaffSelector(state)

    if (!token || !hlq || !applyform || !staff) return rejectWithValue(undefined)
    const q: HotelBookingQuery = {
        amount: values.amount,
        companyId: hlq.companyId,
        supplierId: values.supplierId,
        productId: values.productId,
        roomCount: values.roomCount,
        applyForId: applyform.applyId,
        bookerNum: staff.staffId,
        bookerName: staff.staffUserName,
        bookerMobile: values.contact,
        contactName: staff.staffUserName,
        contactPhone: values.contact,
        checkinDate: values.checkInDate.format("YYYY-MM-DD"),
        checkoutDate: values.checkOutDate.format("YYYY-MM-DD"),
        arrivalEarlyTime: values.arrivalEarlyTime,
        arrivalLateTime: values.arrivalEarlyTime,
        //根据选中的List获取身份信息及手机号
        checkiners: values.selectedTraveller.map(stid => {
            //获取人员信息
            const traveller = applyform.travellers?.find(t => t.travellerId === stid.travellerId)
            //获取选中的idlist   
            const trId = traveller?.idList?.[stid.selectedIdIdx]
            return {
                checkinerName: trId?.name || "",
                checkinerIdCardNum: trId?.idNumber || "",
                checkinerPhone: traveller?.phone || "",
            }
        })
    }
    try {
        return await fetchHotelBookingApi(token, q)
    } catch (err) {
        return rejectWithValue(err)
    }
})

//酒店订单退订
export const hotelRefund = createAsyncThunk('hotel/refund', async (q: HotelRefundQuery, { getState, rejectWithValue }) => {
    const token = accessTokenSelector(getState() as RootState)
    if (!token) return rejectWithValue(undefined)
    try {
        return await fetchHotelRefundApi(token, q)
    } catch (msg) {
        return rejectWithValue(msg)
    }

})

const slice = createSlice({
    name: "book/hotel",
    initialState,
    reducers: {
        cleanHotelList: state => {
            delete state.hotelList
            state.hotelListHasMore = false
        },
        cleanHotelState: state => {
            state.hotelLoading = false
            delete state.bookingError
            delete state.hotelDetail
        },
        resetHotelKeyword: state => ({
            ...state, keywordList: undefined, keywordLoading: false
        }),
        findHotelDetails: (state, { payload }: PayloadAction<{ checkInDate: string, checkOutDate: string, companyId: string, cityId: string }>) => {
            if (!state.hotelListQuery) {
                state.hotelListQuery = {
                    pageNo: 1,
                    depthPath: payload.cityId,
                    checkInDate: payload.checkInDate,
                    checkOutDate: payload.checkOutDate,
                    companyId: payload.companyId,
                    latitude: "",
                    longitude: "",
                    roomPriceStart: 1,
                    roomPriceEnd: 1,
                    stars: "",
                    hotelName: "",
                }
            } else {
                state.hotelListQuery.checkInDate = payload.checkInDate
                state.hotelListQuery.checkOutDate = payload.checkOutDate
                state.hotelListQuery.companyId = payload.companyId
                state.hotelListQuery.depthPath = payload.cityId
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(hotelKeywordSearch.pending, state => { delete state.keywordList; state.keywordLoading = true })
            .addCase(hotelKeywordSearch.fulfilled, (state, { payload }) => { state.keywordList = payload; state.keywordLoading = false })
            .addCase(hotelKeywordSearch.rejected, (state) => { delete state.keywordList; state.keywordLoading = false })

            .addCase(hotelList.pending, state => { state.hotelLoading = true })
            .addCase(hotelList.fulfilled, (state, { payload }) => {
                state.hotelLoading = false
                const [q, l] = payload
                state.hotelListHasMore = true
                state.hotelListQuery = q
                state.hotelList = state.hotelList ? [...state.hotelList, ...l] : l
            })
            .addCase(hotelList.rejected, state => {
                state.hotelLoading = false
                state.hotelListHasMore = false
            })

            .addCase(hotelDetail.pending, (state) => {
                state.hotelLoading = true
                state.hotelDetailError = false
            })
            .addCase(hotelDetail.fulfilled, (state, { payload }) => {
                state.hotelLoading = false
                state.hotelDetail = payload
            })
            .addCase(hotelDetail.rejected, (state) => { state.hotelLoading = false; state.hotelDetailError = true })

            .addCase(hotelBooking.pending, (state) => { state.hotelLoading = true })
            .addCase(hotelBooking.fulfilled, (state) => { state.hotelLoading = false })
            .addCase(hotelBooking.rejected, (state, { payload }) => {
                state.hotelLoading = false
                if (typeof payload === "object") {
                    state.bookingPriceError = payload as unknown as CommonLegacyResult<string>
                } else if (payload && (typeof payload === "string" || typeof payload === "number")) {
                    state.bookingError = payload
                } else {
                    state.bookingError = "非常抱歉，发生了未知错误，请您稍后再试。"
                }
            })

            .addCase(hotelRefund.pending, (state) => { state.hotelLoading = true; delete state.refundError })
            .addCase(hotelRefund.fulfilled, (state) => { state.hotelLoading = false })
            .addCase(hotelRefund.rejected, (state, { payload }) => {
                state.hotelLoading = false
                if (payload && (typeof payload === "string" || typeof payload === "number")) state.refundError = payload
            })
    }
})

export const { cleanHotelState, cleanHotelList, resetHotelKeyword, findHotelDetails } = slice.actions
export default slice.reducer
