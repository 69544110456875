import React, { useEffect, useState } from 'react'
import {
    useAppDispatch, useAppSelector,
} from '../../../app/hooks'
import { Accounts, findByReimburseList, findStaffId } from '../listSlice'
import { Button, Tooltip, Typography, List, Space, Skeleton, Row, Col, Checkbox } from 'antd'
import { TitleAccountsType, listAccountsType } from '../../book/const'
import dayjs from 'dayjs'
import { Viewer } from '../../account/Viewer'
import IconFont from '../../../components/IconFont'
import AccountsDeleteButton from '../../account/AccountsDeleteButton'
import { DataPage } from '../../models'
import { Mode } from '../../applyForm/Editor'
import { fetchPayAccount } from '../editorSlice'



type Props = {
    accountsOnClick?: (value: boolean) => void
    onChange?: (value: Accounts[]) => void
    mode?: string
    value?: Accounts[]
}

const { Text } = Typography

export default function ReimburseSelector({ mode, accountsOnClick, onChange, value }: Props) {
    const modes = useAppSelector(state => (state.reimburesEditor.reimburesForm ? "edit" : "create") as Mode)
    const dispatch = useAppDispatch()
    const defaultPageSize = 10
    const [selectedRowData, setSelectedRowData] = useState<Accounts>() // 存储选中行的数据
    const [isDrawerVisible, setIsDrawerVisible] = useState(false) // 控制 Drawer 的显示
    // 公司配置
    const currentCompany = useAppSelector(state => state.user.currentCompany)
    const types = useAppSelector(state => state.user.currentCompany?.companyBasicConfig.enabledAccountTypes.REIMBURSE_TRAVEL ?? [])
    const companyId = useAppSelector(state => state.user.currentCompany?.companyId)
    const accountsLoading = useAppSelector(state => state.reimburseList.accountsLoading)//关联账目
    const accountsData = useAppSelector(state => state.reimburseList.accountsData) //关联账目数据
    const accounts = useAppSelector(state => state.reimburesEditor.reimburesForm?.extraInfo.accounts) // 详情数据
    const [accountsList, setAccountsList] = useState<DataPage<Accounts>>()
    // 查询申请单关联账目数据
    const ApplyFormAccount = useAppSelector(state => state.applyFormEditor.ApplyFormAccount)
    const [checkbox, setCheckbox] = useState<Accounts[]>(value ?? [])
    const [allSelected, setAllSelected] = useState(false)
    // 公司支付单位
    const PayAccountLoading = useAppSelector(state => state.reimburesEditor.PayAccountLoading)

    useEffect(() => {
        //初始化加载 关联账目接口
        if (accountsLoading !== 'fulfilled' && !accountsData) {
            dispatch(findByReimburseList({ page: 0, size: 10 }))
        }
        // 公司支付单位
        if (PayAccountLoading !== 'fulfilled') {
            dispatch(fetchPayAccount())
        }
    }, [PayAccountLoading, accountsData, accountsLoading, dispatch])

    useEffect(() => {
        if (modes === 'edit') {
            if (currentCompany) {
                const accountsLists = accountsData?.content?.filter(it => {
                    if (it.companyId && companyId !== it.companyId) {
                        return false
                    }
                    if (types.length > 0) {
                        return types.includes(Number(it.accountsType))
                    }
                    return true
                })
                if (accountsData) {
                    const value = {
                        ...accountsData,
                        content: accountsData?.page === 0 ? accounts?.concat(accountsLists ?? []) : accountsLists
                    }
                    setAccountsList(value)
                }
            }
        } else {
            if (accountsData) {
                // if (Array.isArray(ApplyFormAccount) && ApplyFormAccount.length > 0) {
                //     const value = {
                //         ...accountsData,
                //         content: accountsData?.page === 0 ? [...ApplyFormAccount, ...(accountsData.content || [])] : accountsData.content
                //     }
                //     console.log(value, 'value')
                //     setAccountsList(value)
                // } else {
                //     setAccountsList(accountsData)
                // }
                setAccountsList(accountsData)
            }
        }
    }, [accounts, accountsData, companyId, currentCompany, modes, ApplyFormAccount, types])

    // 全部选中取消按钮 
    const handleSelectAll = () => {
        if (allSelected) {
            setCheckbox(ApplyFormAccount ?? [])
        } else {
            const AccountsList = accountsData?.content?.concat(ApplyFormAccount ?? [])
            setCheckbox(AccountsList?.filter((account, index, array) =>
                array.findIndex(t => t.accountsId === account.accountsId) === index
            ) ?? [])
        }
        setAllSelected(!allSelected)
    }

    // 点击每一条
    const handlClick = (item: Accounts) => {
        console.log("🚀 ~ handlClick ~ item:", item)
        if (!mode) {
            setSelectedRowData(item) // 设置选中行的数据
            setIsDrawerVisible(true) // 打开 Drawer
            // 调用归属人接口
            if (item.customerList) {
                dispatch(findStaffId(item.customerList.map(it => it.staffId)))
            } else {
                dispatch(findStaffId([item.staffId]))
            }
        } else {
            // 检查 checkbox 数组中是否已经存在该 item
            if (checkbox.includes(item)) {
                // 如果存在，则移除该 item
                setCheckbox(checkbox.filter(selectedItems => selectedItems.accountsId !== item.accountsId))
            } else {
                // 如果不存在，则添加该 item
                setCheckbox([...checkbox, item])
            }
        }
    }

    const accountsClick = () => {
        // 调用归属人接口
        const staffIds = new Set(checkbox?.map(it => {
            if (it.customerList) {
                return it.customerList.map(i => i.staffId)
            } else {
                return [it.staffId]
            }
        }).flat())
        dispatch(findStaffId(Array.from(staffIds)))
        onChange?.(checkbox)
        accountsOnClick?.(false)
    }

    return (
        <>
            {
                // 详情
                selectedRowData && <Viewer item={selectedRowData || ""} isDrawerVisible={isDrawerVisible} setIsDrawerVisible={setIsDrawerVisible}
                    onFinished={() => dispatch(findByReimburseList({ page: 0, size: defaultPageSize }))} />
            }
            <List
                style={{ cursor: "pointer" }}
                header={
                    mode && <Row justify='space-between'>
                        <Button type="primary" onClick={accountsClick}>确定添加</Button>
                        <Button type='dashed' onClick={handleSelectAll}>{allSelected ? '取消选中' : '选中全部'}</Button>
                    </Row>
                }
                itemLayout="horizontal"
                dataSource={accountsList?.content}
                pagination={accountsList && {
                    total: accountsList.totalElements,
                    current: accountsList.page + 1,
                    pageSize: accountsList.size,
                    onChange: (page, size) => {
                        dispatch(findByReimburseList({
                            page: page - 1,
                            size: size || defaultPageSize,
                        }))
                    }
                }}
                renderItem={item => {
                    return <List.Item key={item.accountsId} onClick={() => ApplyFormAccount && ApplyFormAccount?.find(it => it.oid === item.oid) !== undefined ? null : handlClick(item)}>
                        <Skeleton avatar title={false} loading={accountsLoading === 'pending'} active>
                            <Row wrap={true} align='middle' style={{ width: '100%' }}>
                                {mode && <Col flex='0 40px'>
                                    <Checkbox key={item.accountsId} checked={checkbox.find(it => it.accountsId === item.accountsId) !== undefined} />
                                </Col>
                                }
                                <Col flex="0 40px">
                                    <Tooltip placement="top" title={TitleAccountsType(item.accountsType)}>
                                        <IconFont type={listAccountsType(item.accountsType)} style={{ fontSize: '30px' }} alt='' />
                                    </Tooltip>
                                </Col>
                                <Col flex='1'>
                                    <Row wrap={true} align="middle" style={{ width: '100%' }}>
                                        <Col flex="2 180px" style={{ textAlign: 'center', alignItems: 'center' }}>
                                            <Space direction='horizontal'>
                                                {
                                                    (/^\d+$/.test(item?.accountsStartData)) ? <Text>{dayjs(Number(item?.accountsStartData)).format("YYYY-MM-DD")}</Text> : <Text style={{ width: 80 }} ellipsis={{ tooltip: item?.accountsStartData }}>{item.accountsStartData}</Text>
                                                }
                                                <IconFont type='icon-jiantou' />
                                                {
                                                    (/^\d+$/.test(item?.accountsEndData)) ? <Text>{dayjs(Number(item?.accountsEndData)).format("YYYY-MM-DD")}</Text> : <Text style={{ width: 80 }} ellipsis={{ tooltip: item.accountsEndData }}>{item.accountsEndData}</Text>
                                                }
                                            </Space>
                                        </Col>
                                        <Col flex="1 180px" style={{ textAlign: 'center' }}>
                                            {item.auditInfo && <Text style={{ width: 180 }} ellipsis={{ tooltip: item.auditInfo ? item.auditInfo : item.accountsRemarks }}>
                                                {item.auditInfo ? item.auditInfo : item.accountsRemarks}
                                            </Text>}
                                            {
                                                (!item.auditInfo) &&
                                                <Text>{dayjs(item.accountsDate).format("YYYY-MM-DD")}</Text>
                                            }
                                        </Col>
                                        <Col flex="2 240px">
                                            <Row justify='center'>
                                                <Col flex='1' style={{ textAlign: 'center' }}>
                                                    <Text>￥{item.accountsSumMoney3.toFixed(2)}</Text>
                                                </Col>
                                                <Col flex='1' style={{ textAlign: 'center' }}>
                                                    <Text>{item.corpAccounts ? '对公' : '个人'}</Text>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                {
                                    !mode && <Col flex='0 40px' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <AccountsDeleteButton id={item.accountsId || ''} onFinished={() =>
                                            dispatch(findByReimburseList({ page: 0, size: defaultPageSize }))} />
                                    </Col>
                                }
                            </Row>
                        </Skeleton >
                    </List.Item>
                }}
            />
        </>
    )
}
