import { Layout, Col, Row } from "antd"
import { ReactElement, useState } from "react"
import NoteMsg from "../list/components/NoteMsg"
import SiderContent from "./components/SiderContent"
import { AirplanePayConfirm } from "../payConfirm/AirplanePayConfirm"
import { useAppSelector } from "../../../../app/hooks"

export default function AirplaneAddPassenger(): ReactElement {
    const [seatCount, setSeatCount] = useState(0)
    const orderFunctionPage = useAppSelector(
        state => state.location.tipMessage?.orderFunctionPage?.split("：")
    )
    const inlandOFNotice = useAppSelector(
        state => state.location.tipMessage?.inlandOFNotice?.split("：")
    )
    const airplaneNoteList = [
        {
            title: orderFunctionPage?.[0] + ":",
            msg: orderFunctionPage?.[1],
            level: "01",
        },
        {
            title: inlandOFNotice?.[0] + ":",
            msg: inlandOFNotice?.[1],
            level: "02",
        },
    ]

    return (
        <Layout>
            <Row>
                <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                    <AirplanePayConfirm
                        handlePassenger={num => setSeatCount(num)}
                    />
                </Col>
                <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <div className="container">
                        <SiderContent passengerCount={seatCount} />
                        <NoteMsg noteList={airplaneNoteList} />
                    </div>
                </Col>
            </Row>
        </Layout>
    )
}
