import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { AsyncActionState, BusinessPurposes, Company, DataPage, PageRequest, PreCheckError, Staff, DocumentType, CustomField } from "../models"
import { accessTokenSelector } from "../user/loginSlice"
import { InvoiceTypeApi, PayAccount, fetchCustomerListApi, fetchPayAccountApi, findReimburseIdApi, getFlightSeats, getTrainSeats, patchAccounts, patchReimburse, postAccounts, postAnnexes, postReimburesForm, updatePreCheckApi } from "./api"
import { Dayjs } from "dayjs"
import { Accounts, BankCardList, FileRepertories } from "./listSlice"
import { currentAuthLockedSelector, currentCompanyIdSelector, currentStaffIdSelector } from "../user/userSlice"
import { findByCompanyIdApi } from "../org/orgApi"
import { findByStaffIdApi, getStaffByIds } from "../staff/staffAPI"
import { setAvailable } from "../applyForm/editorSlice"
import { ReimburesForm, ReviseReimburseForm } from "../models/reimburesForm"
import { findCustomFieldByCompanyIdApi } from "../applyForm/api"

export interface InvoiceTypeList {
    id: string
    dictTypeId: string
    parentId: string
    parentIds: string
    sort: number
    dictName: string
    dictValue: string
    createDate: string
    updateDate: string
}

export interface StaffUserList {
    authSubsidy230?: boolean
    authSubsidy230EndDate?: Dayjs
    authSubsidy230StartDate?: Dayjs
    bankCardList?: BankCardList[]
    companyId: string
    gender: number
    reimbursePolicyId: string   //报销政策id
    staffId: string
    staffType: number
    staffUserDepartment: string
    staffUserName: string
    staffUserPhone: number
    userId: number
    workPlace: string

}

// 保存附件
export interface Attachments {
    files: FileRepertories[]
    reimburseId: string
}

interface State {
    purposes?: BusinessPurposes[]//出差目的
    loading: AsyncActionState
    InvoiceTypeLoading: AsyncActionState
    PayAccountLoading: AsyncActionState
    InvoiceTypeData?: InvoiceTypeList[]
    trainSeats?: string[]
    flightSeats?: string[]
    staffUserList?: DataPage<StaffUserList>
    reimburesForm?: ReviseReimburseForm
    mode?: string
    payAccount?: PayAccount[]
    reimburseId?: string
    preChecked: AsyncActionState
    updatePreCheckError?: PreCheckErrors[]
    updateReimburseError?: PreCheckError
    updated?: boolean
    batchUpdateAccountsLoading: AsyncActionState
    customFieldsPersonal?: CustomField[] //因私出行自定义字段
}

const initialState: State = {
    InvoiceTypeLoading: 'idle',
    loading: 'idle',
    PayAccountLoading: 'idle',
    preChecked: 'idle',
    batchUpdateAccountsLoading: 'idle'
}

export interface PreCheckErrors {
    refId: string
    type: string
    ruleName: string
    failLevel: string
}

//加载一些必要的初始化信息
export const reimburesinitEditor = createAsyncThunk('reimburesInitEditor/init',
    async (id: string | undefined, { getState, rejectWithValue, dispatch }) => {
        const state = getState() as RootState
        const token = accessTokenSelector(state)
        const companyId = currentCompanyIdSelector(state)
        const staffId = currentStaffIdSelector(state)
        if (!token || !companyId || !staffId) throw rejectWithValue(401)

        try {
            //更新公司配置
            const company = await findByCompanyIdApi(token, companyId)
            console.log("🚀 ~ company:", company)
            // 公司支付单位
            const payAccount = await fetchPayAccountApi(token, companyId)
            //更新出差申请编辑器可用性
            const staff = await findByStaffIdApi(token, staffId)
            dispatch(setAvailable(!staff.authLocked))
            const EmployeeCollection = { company: company, staff: staff, payAccount: payAccount }
            dispatch(updateCompanyConfig(EmployeeCollection))
            //获取自定义字段配置
            const cfs = await findCustomFieldByCompanyIdApi(token, companyId)
            dispatch(setCustomFields(cfs))

            //获取单据信息
            if (id) {
                // 报销单详情
                const data = await findReimburseIdApi(token, id)
                // 归属人
                const staffIds = data.extraInfo.accounts?.map(it => it.customerList.map(it => it.staffId)).flat(1) ?? []
                const staffIdList = await getStaffByIds(token, companyId, staffIds)

                if (data) {
                    dispatch(setReimburesForm({ ...data, staffIdList: staffIdList } as ReviseReimburseForm))
                }
                else throw rejectWithValue("未查询到报销单")
            }
        } catch (err) {
            throw rejectWithValue(err)
        }
    })


//发票类型接口 
export const InvoiceType = createAsyncThunk('reimbures/InvoiceType',
    async (orderType: string, { getState, rejectWithValue }) => {
        const token = accessTokenSelector(getState() as RootState)
        if (!token) return rejectWithValue(undefined)
        try {
            return await InvoiceTypeApi(token, orderType)
        } catch (err) {
            throw rejectWithValue(null)
        }
    }
)

// 火车座椅
export const fetchTrainSeats = createAsyncThunk("keyValue/app-dict-train-seats",
    async (_, { rejectWithValue }) => {
        try {
            return await getTrainSeats()
        } catch (err) {
            rejectWithValue(err)
        }
    }
)

// 飞机座椅
export const fetchFlightSeats = createAsyncThunk("keyValue/app-dict-flight-seats",
    async (_, { rejectWithValue }) => {
        try {
            return await getFlightSeats()
        } catch (err) {
            rejectWithValue(err)
        }
    }
)
// 归属人
export const fetchCustomerList = createAsyncThunk('reimburesEditor/v3/staff/search',
    async (page: PageRequest, { getState, rejectWithValue }): Promise<DataPage<StaffUserList>> => {
        const state = getState() as RootState
        const token = accessTokenSelector(state)
        const companyId = currentCompanyIdSelector(state)
        const authLocked = currentAuthLockedSelector(state)
        if (!token) throw new Error()
        try {
            return await fetchCustomerListApi(token, { ...page, companyId, authLocked })

        } catch (error) {
            throw rejectWithValue(error)
        }

    })
// 保存报销单
export const createReimburesForm = createAsyncThunk('ReimburesFormEditor/create',
    async (form: ReimburesForm, { getState, rejectWithValue }) => {
        const appState = getState() as RootState
        const token = accessTokenSelector(appState)
        if (!token) throw rejectWithValue(401)
        try {
            const ret = await postReimburesForm(token, form)
            return ret.reimburseId

        } catch (err) {
            throw rejectWithValue(err)
        }
    })

// 报销单规则校验接口
export const updatePreCheck = createAsyncThunk('ReimburesFormEditor/ReimburesFormPreCheck',
    async (reimburseId: string, { getState, rejectWithValue }) => {
        const appState = getState() as RootState
        const token = accessTokenSelector(appState)
        if (!token) throw rejectWithValue(401)
        try {
            return await updatePreCheckApi(token, reimburseId)
        } catch (err) {
            throw rejectWithValue(err)
        }
    }
)
const reimburesSelector = (state: RootState) => state.reimburesEditor.reimburesForm

// 更新报销单
export const updateReimburse = createAsyncThunk('ReimburesFormEditor/updateReimburse',
    async (reimbures: ReimburesForm, { dispatch, getState, rejectWithValue }) => {
        const appState = getState() as RootState
        const token = accessTokenSelector(appState)
        const reimburesForm = reimburesSelector(appState)
        if (!token) throw rejectWithValue(401)
        if (!reimburesForm?.reimburseId) throw rejectWithValue(400)

        try {
            const res = await patchReimburse(token, reimburesForm.reimburseId, reimbures)
            return res
            // const ret = await patchApplyForm(token, applyForm.applyId, values)
            // dispatch(resetState())
            // if (ret.approvalState === -1 && applyForm.approvalState === 1) {
            //     //之前已审批的单据更新后需要提交审批
            //     //新接口上会重置审批状态
            //     dispatch(setUseSubmit(true))
            // }
        } catch (err) {
            throw rejectWithValue(err)
        }
        // return {}
    })

// 更新账目
export const updateAccounts = createAsyncThunk('ReimburesFormEditor/updateAccounts',
    async (accounts: string[], { getState, rejectWithValue }) => {
        const appState = getState() as RootState
        const token = accessTokenSelector(appState)
        const reimburesForm = reimburesSelector(appState)

        if (!token) throw rejectWithValue(401)
        if (!reimburesForm?.reimburseId) throw rejectWithValue(400)

        try {
            return await patchAccounts(token, reimburesForm.reimburseId, accounts)

        } catch (err) {
            throw rejectWithValue(err)
        }

    })

// 批量更新账目关联的报销单信息
export const BatchUpdateAccounts = createAsyncThunk('ReimburesFormEditor/BatchUpdateAccounts',
    async ({ mode, accounts }: { mode: string, accounts: Accounts[] }, { getState, rejectWithValue }) => {
        const appState = getState() as RootState
        const token = accessTokenSelector(appState)
        if (!token) throw rejectWithValue(401)
        try {
            const ret = await postAccounts(token, mode, accounts)
            return ret
        } catch (err) {
            throw rejectWithValue(err)
        }
    })

// 保存附件
export const createAnnexes = createAsyncThunk('ReimburesFormEditor/createAccounts',
    async (form: Attachments, { getState, rejectWithValue }): Promise<void> => {
        console.log("🚀 ~ form:", form)
        const appState = getState() as RootState

        const token = accessTokenSelector(appState)
        if (!token) throw rejectWithValue(401)
        try {
            const res = await postAnnexes(token, form)
            return res
        } catch (err) {
            throw rejectWithValue(err)
        }
    })

// 公司支付单位 
export const fetchPayAccount = createAsyncThunk('ReimburesFormEditor/fetchPayAccount',
    async (_, { getState, rejectWithValue }): Promise<PayAccount[]> => {
        const state = getState() as RootState
        const appState = getState() as RootState
        const companyId = currentCompanyIdSelector(state)
        const token = accessTokenSelector(appState)
        if (!token || !companyId) throw rejectWithValue(401)
        try {
            return await fetchPayAccountApi(token, companyId)
        } catch (err) {
            throw rejectWithValue(err)
        }
    }
)

const slice = createSlice({
    name: 'reimburesEditor',
    initialState,
    reducers: {
        reset: (_) => {
            return { ...initialState }
        },
        resetInvoiceType: (state) => {
            state.InvoiceTypeLoading = 'idle'
            delete state.InvoiceTypeData
        },
        updateCompanyConfig: (state, { payload }: PayloadAction<{ company: Company, staff: Staff }>) => {
            const { company, } = payload
            const { businessPurposesList } = company
            state.purposes = businessPurposesList

        },
        // 报销单详情
        setReimburesForm: (state, { payload }: PayloadAction<ReviseReimburseForm>) => {
            state.reimburesForm = payload
        },
        setMode: (state, { payload }: PayloadAction<string>) => {
            state.mode = payload
        },
        //获取自定义字段配置
        setCustomFields: (state, { payload }: PayloadAction<CustomField[]>) => {
            state.customFieldsPersonal = payload.filter(cf => cf.documentType === DocumentType.REIMBURSE_TRAVEL)
        }
    },
    extraReducers: builder => {
        builder
            // 初始化
            .addCase(reimburesinitEditor.pending, state => {
                state.loading = 'pending'
            })
            .addCase(reimburesinitEditor.fulfilled, (state) => {
                state.loading = 'fulfilled'
            })
            .addCase(reimburesinitEditor.rejected, (state) => {
                state.loading = 'reject'
            })

            // 保存报销单
            .addCase(createReimburesForm.pending, state => {
                state.loading = 'pending'
            })
            .addCase(createReimburesForm.fulfilled, (state, { payload }) => {
                state.loading = 'fulfilled'
                state.reimburseId = payload
            })
            .addCase(createReimburesForm.rejected, (state) => {
                state.loading = 'reject'
            })
            // 更新报销单
            .addCase(updateReimburse.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(updateReimburse.fulfilled, (state) => {
                state.loading = 'fulfilled'
                state.updated = true
            })
            .addCase(updateReimburse.rejected, (state, { payload }) => {
                state.loading = 'reject'
                state.updateReimburseError = payload as PreCheckError
            })
            // 批量更新账目关联的报销单信息
            .addCase(BatchUpdateAccounts.pending, state => {
                state.batchUpdateAccountsLoading = 'pending'
            })
            .addCase(BatchUpdateAccounts.fulfilled, (state) => {
                state.batchUpdateAccountsLoading = 'fulfilled'
            })
            .addCase(BatchUpdateAccounts.rejected, (state) => {
                state.batchUpdateAccountsLoading = 'reject'
            })

            // 公司支付单位
            .addCase(fetchPayAccount.pending, state => {
                state.PayAccountLoading = 'pending'
                delete state.payAccount
            })
            .addCase(fetchPayAccount.fulfilled, (state, { payload }) => {
                state.PayAccountLoading = 'fulfilled'
                state.payAccount = payload?.filter(item => item.enable && item.selectable)

            })
            .addCase(fetchPayAccount.rejected, (state) => {
                state.PayAccountLoading = 'reject'
            })
            //   预检
            .addCase(updatePreCheck.pending, (state) => {
                delete state.updatePreCheckError
                state.preChecked = 'pending'
            })
            .addCase(updatePreCheck.fulfilled, (state) => {
                state.preChecked = 'fulfilled'
            })
            .addCase(updatePreCheck.rejected, (state, { payload }) => {
                state.updatePreCheckError = payload as PreCheckErrors[]
                state.preChecked = 'reject'
            })
            // 发票类型
            .addCase(InvoiceType.pending, state => {
                state.InvoiceTypeLoading = "pending"
                delete state.InvoiceTypeData
            })
            .addCase(InvoiceType.fulfilled, (state, { payload }) => {
                state.InvoiceTypeLoading = "fulfilled"
                state.InvoiceTypeData = payload
            })
            .addCase(InvoiceType.rejected, (state) => {
                state.InvoiceTypeLoading = "reject"
                delete state.InvoiceTypeData
            })
            // 火车座椅
            .addCase(fetchTrainSeats.pending, state => {
                delete state.trainSeats
            })
            .addCase(fetchTrainSeats.rejected, state => {
                delete state.trainSeats
            })
            .addCase(fetchTrainSeats.fulfilled, (state, { payload }) => {
                state.trainSeats = payload
            })
            // 飞机座椅
            .addCase(fetchFlightSeats.pending, state => {
                delete state.flightSeats

            })
            .addCase(fetchFlightSeats.rejected, state => {
                delete state.flightSeats
            })
            .addCase(fetchFlightSeats.fulfilled, (state, { payload }) => {
                state.flightSeats = payload
            })
            // 归属人
            .addCase(fetchCustomerList.pending, state => {
                delete state.staffUserList
            })
            .addCase(fetchCustomerList.rejected, state => {
                delete state.staffUserList
            })
            .addCase(fetchCustomerList.fulfilled, (state, { payload }) => {
                state.staffUserList = payload
            })
    },
})

export const { reset, resetInvoiceType, setReimburesForm, updateCompanyConfig, setCustomFields } = slice.actions
export default slice.reducer
