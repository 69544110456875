import { Input, Modal } from "antd"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { AppDispatch, RootState } from "../../app/store"
import {
    BatchApplyReqParams,
    dismissApprovalOpDialog,
    fetchBatchApplyOps,
} from "./applyFormApprovalSlice"

const mapStateToProps = (state: RootState) => ({
    visible: state.applyFormApproval.visible,
    loading: state.applyFormApproval.loading,
    errors: state.applyFormApproval.errors,
    type: state.applyFormApproval.type,
    selectedValues: state.applyFormApproval.selectedValues,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    dispatchApply: (arg: BatchApplyReqParams) => {
        dispatch(fetchBatchApplyOps(arg))
    },
    dispatchDismiss: () => {
        dispatch(dismissApprovalOpDialog())
    },
})

export type BatchOpType = "accept" | "reject"

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        onFinished?: (err?: string[]) => void
    }

const _approval = ({
    selectedValues: selected,
    errors,
    visible,
    type,
    loading,
    onFinished,
    dispatchApply,
    dispatchDismiss,
}: Props): JSX.Element => {
    const [content, setContent] = useState<string>()

    const onOk = async () => {
        if (selected) {
            dispatchApply({
                selected,
                content,
                state: type === "accept" ? 1 : 2,
            })
        }
    }

    useEffect(() => {
        switch (loading) {
            case "fulfilled":
            case "reject":
                onFinished?.(errors)
                dispatchDismiss()
                break
        }
    }, [loading, errors, onFinished, dispatchDismiss])

    return (
        <Modal
            destroyOnClose
            title={type === "accept" ? "审批同意确认" : "审批驳回确认"}
            centered
            open={visible}
            closable={false}
            onCancel={dispatchDismiss}
            okType="primary"
            okButtonProps={{ loading: loading === "pending" }}
            cancelButtonProps={{ hidden: loading !== "idle" }}
            onOk={onOk}>
            <Input
                placeholder="审批意见（选填）"
                onChange={e => {
                    setContent(e.target.value)
                }}
            />
        </Modal>
    )
}
export const ApplyFormApprovalOpDialog = connect(
    mapStateToProps,
    mapDispatchToProps
)(_approval)
