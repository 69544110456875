import { ReactElement, useState, useEffect } from "react"
import { Card, Col, Row } from "antd"

import "./TrainSelectSeat.css"

export const TrainSelectSeat = (props: {
    seatCode: string
    seatCount: number
    onChange?: (s: string[]) => void
}): ReactElement => {
    const { seatCode, onChange, seatCount } = props
    // 默认选中座位为空
    const [selected, setSelected] = useState<string[]>([])

    useEffect(() => {
        onChange?.(selected)
    }, [selected, onChange])

    function selectSeat(seat: string) {
        if (selected.find(s => s === seat)) {
            setSelected(selected.filter(s => s !== seat))
        } else {
            if (selected.length < seatCount) {
                setSelected([...selected, seat])
            } else {
                const tmpArr = selected.slice(
                    selected.length - seatCount + 1,
                    selected.length
                )
                setSelected([...tmpArr, seat])
            }
        }
    }
    return (
        <Card
        styles={{ body: {  padding: "0px 8px 0px 8px" } }}
            className="seat_options">
            <Row>
                <Col flex="1 725px">
                    <dl className="seat_options_bd">
                        <dt>窗</dt>
                        <dd
                            className={`seat ${
                                selected.find(s => s === "1A") ? "cur" : ""
                            }`}
                            onClick={() => selectSeat("1A")}>
                            A
                        </dd>
                        <dd
                            className={`seat ${
                                selected.find(s => s === "1B") ? "cur" : ""
                            } ${
                                seatCode === "M" ||
                                seatCode === "9" ||
                                seatCode === "P"
                                    ? "hide"
                                    : ""
                            }`}
                            onClick={() => selectSeat("1B")}>
                            B
                        </dd>
                        <dd
                            className={`seat ${
                                selected.find(s => s === "1C") ? "cur" : ""
                            }`}
                            onClick={() => selectSeat("1C")}>
                            C
                        </dd>
                        <dt style={{ width: 53 }}>过道</dt>
                        <dd
                            className={`seat ${
                                selected.find(s => s === "1D") ? "cur" : ""
                            } ${
                                seatCode === "9" || seatCode === "P"
                                    ? "hide"
                                    : ""
                            }`}
                            onClick={() => selectSeat("1D")}>
                            D
                        </dd>
                        <dd
                            className={`seat ${
                                selected.find(s => s === "1F") ? "cur" : ""
                            }`}
                            onClick={() => selectSeat("1F")}>
                            F
                        </dd>
                        <dt>窗</dt>
                    </dl>
                </Col>
                <Col flex="1 725px">
                    {seatCount > 1 && (
                        <dl className="seat_options_bd">
                            <dt>窗</dt>
                            <dd
                                className={`seat ${
                                    selected.find(s => s === "2A") ? "cur" : ""
                                }`}
                                onClick={() => selectSeat("2A")}>
                                A
                            </dd>
                            <dd
                                className={`seat ${
                                    selected.find(s => s === "2B") ? "cur" : ""
                                } ${
                                    seatCode === "M" ||
                                    seatCode === "9" ||
                                    seatCode === "P"
                                        ? "hide"
                                        : ""
                                }`}
                                onClick={() => selectSeat("2B")}>
                                B
                            </dd>
                            <dd
                                className={`seat ${
                                    selected.find(s => s === "2C") ? "cur" : ""
                                }`}
                                onClick={() => selectSeat("2C")}>
                                C
                            </dd>
                            <dt style={{ width: 52 }}>过道</dt>
                            <dd
                                className={`seat ${
                                    selected.find(s => s === "2D") ? "cur" : ""
                                } ${
                                    seatCode === "9" || seatCode === "P"
                                        ? "hide"
                                        : ""
                                }`}
                                onClick={() => selectSeat("2D")}>
                                D
                            </dd>
                            <dd
                                className={`seat ${
                                    selected.find(s => s === "2F") ? "cur" : ""
                                }`}
                                onClick={() => selectSeat("2F")}>
                                F
                            </dd>
                            <dt>窗</dt>
                        </dl>
                    )}
                </Col>
            </Row>
        </Card>
    )
}
