import { PageHeader } from '@ant-design/pro-layout'
import { Alert, Button, Col, Form, Input, Modal, ResultProps, Row, Typography, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import TravelRequestSelector from './editor/TravelRequestSelector'
import { ApplyForm, CustomFieldInstance } from '../models'
import ReimburseAccount from './editor/ReimburseAccount'
import PaymentDetail from './editor/PaymentDetail'
import Attachments from './editor/Attachments'
import { useNavigate, useParams } from 'react-router-dom'
import { Mode } from '../applyForm/Editor'
import TravelProjectItems from './editor/TravelProjectItems'
import TravelPurposesSelector from './editor/TravelPurposesSelector'
import { BatchUpdateAccounts, createAnnexes, createReimburesForm, reimburesinitEditor, reset as resetEditor, updateAccounts, updateReimburse } from './editorSlice'
import { Accounts, FileRepertories, setApplyForms } from './listSlice'
import { entryReset } from '../account/accountSlice'
import { PaymentList } from '../models/reimburesForm'
import { fetchApplyFormAccount } from '../applyForm/editorSlice'
import ApplyFormViewer from './editor/components/ApplyFormViewer'
import CustomFields from './editor/CustomFields'


interface ReimbursementRequest {
    actionType: string
    businessPurposesId: string
    projectId: string
    projectName: string
    reimburseName: string
    reimburseNum: string
    accounts: Accounts[]
    fileRepertories?: FileRepertories[]
    reimburseMoney: number
    reimburseMoneyCorp: number
    reimburseMoneyPersonal: number
    paymentDetailList: PaymentList[]
    applyId: string
    companyId: string
    staffId: string
    applyForm: ApplyForm
    customFieldInstances?: Array<CustomFieldInstance>
}

export default function ReimburesEditor() {

    const navi = useNavigate()
    const [initialized, setInitialized] = useState(false)
    const [formValues, setFormValues] = useState<ReimbursementRequest>()
    const mode = useAppSelector(state => (state.reimburesEditor.reimburesForm ? "edit" : "create") as Mode)
    const reimburesForm = useAppSelector(state => state.reimburesEditor.reimburesForm)
    console.log("🚀 ~ ReimburesEditor ~ reimburesForm:", reimburesForm)
    const createdId = useAppSelector(state => state.reimburesEditor.reimburseId)
    // 申请单信息
    const applyForm = useAppSelector(state => state.reimburesEditor.reimburesForm?.extraInfo.applyForm)
    // 保存报销单loading
    const loading = useAppSelector(state => state.reimburesEditor.loading)
    // 更新报销单error
    const updateReimburseError = useAppSelector(state => state.reimburesEditor.updateReimburseError)
    const updated = useAppSelector(state => state.reimburesEditor.updated)
    const [fileRepertories, setFileRepertories] = useState<FileRepertories[]>()
    const batchUpdateAccountsLoading = useAppSelector(state => state.reimburesEditor.batchUpdateAccountsLoading)
    const ApplyFormAccount = useAppSelector(state => state.applyFormEditor.ApplyFormAccount)

    const { Title } = Typography
    const { Item } = Form
    const [form] = Form.useForm()
    const dispatch = useAppDispatch()
    const { documentId } = useParams()

    const modifications = (value: ReimbursementRequest) => {
        const applyForm = (Object.keys(value.applyForm) as (keyof ApplyForm)[])
            .filter(key =>
                typeof value.applyForm?.[key] !== "object"
                    ? value.applyForm?.[key] !== reimburesForm?.extraInfo.applyForm?.[key]
                    : JSON.stringify(value.applyForm?.[key]) !==
                    JSON.stringify(reimburesForm?.extraInfo.applyForm?.[key])
            )
            .reduce((prev, next) => {
                prev[next] = value.applyForm?.[next]
                return prev
            }, Object.create({}))

        const actionType = (Object.keys(value.actionType) as (keyof string)[])
            .filter(key =>
                typeof value.actionType?.[key] !== "object"
                    ? value.actionType?.[key] !== reimburesForm?.actionType?.[key]
                    : JSON.stringify(value.actionType?.[key]) !==
                    JSON.stringify(reimburesForm?.actionType?.[key])
            )
            .reduce((prev, next) => {
                prev[next] = value.actionType?.[next]
                return prev
            }, Object.create({}))

        const projectName = (Object.keys(value.projectName) as (keyof string)[])
            .filter(key =>
                typeof value.projectName?.[key] !== "object"
                    ? value.projectName?.[key] !== reimburesForm?.projectName?.[key]
                    : JSON.stringify(value.projectName?.[key]) !==
                    JSON.stringify(reimburesForm?.projectName?.[key])
            )
            .reduce((prev, next) => {
                prev[next] = value.projectName?.[next]
                return prev
            }, Object.create({}))

        const accounts = (Object.keys(value.accounts) as (keyof Accounts[])[])
            .filter(key =>
                typeof value.accounts?.[key] !== "object"
                    ? value.accounts?.[key] !== reimburesForm?.extraInfo.accounts?.[key]
                    : JSON.stringify(value.accounts?.[key]) !==
                    JSON.stringify(reimburesForm?.extraInfo.accounts?.[key])
            )
            .reduce((prev, next) => {
                prev[next] = value.accounts?.[next]
                return prev
            }, Object.create({}))

        const paymentDetailList = (Object.keys(value.paymentDetailList) as (keyof PaymentList[])[])
            .filter(key =>
                typeof value.paymentDetailList?.[key] !== "object"
                    ? value.paymentDetailList?.[key] !== reimburesForm?.paymentDetailList?.[key]
                    : JSON.stringify(value.paymentDetailList?.[key]) !==
                    JSON.stringify(reimburesForm?.paymentDetailList?.[key])
            )
            .reduce((prev, next) => {
                prev[next] = value.paymentDetailList?.[next]
                return prev
            }, Object.create({}))

        return {
            paymentDetailList,
            accounts,
            projectName,
            actionType,
            applyForm
        }
    }
    // 查询申请单关联账目
    useEffect(() => {
        if (ApplyFormAccount) {
            form.setFieldValue('accounts', ApplyFormAccount)
        } else {
            form.setFieldValue('accounts', [])
        }
    }, [ApplyFormAccount, form])

    useEffect(() => {
        if (!initialized && loading !== "pending") {
            // 初始化状态
            dispatch(resetEditor())
            dispatch(entryReset())
            // 初始化数据
            dispatch(reimburesinitEditor(documentId))
            setInitialized(true)
        }
    }, [dispatch, documentId, initialized, loading])

    // 保存报销单附件
    useEffect(() => {
        if (loading === 'fulfilled' && createdId && fileRepertories) {
            const value = {
                reimburseId: createdId,
                files: fileRepertories
            }
            dispatch(createAnnexes(value))
        }
    }, [createdId, dispatch, fileRepertories, loading])

    useEffect(() => {
        //修改模式初始化
        if (mode === "edit" && reimburesForm) {
            form.setFieldsValue({
                accounts: reimburesForm.extraInfo.accounts,
                applyForm: reimburesForm.extraInfo.applyForm,
                fileRepertories: reimburesForm.extraInfo.legacyFiles
            })
            form.setFieldsValue(reimburesForm)
            dispatch(setApplyForms(reimburesForm.extraInfo.applyForm?.travellers))
        } else {
            dispatch(setApplyForms())
            form.resetFields()
        }
    }, [dispatch, form, mode, reimburesForm])

    useEffect(() => {
        if (createdId) {
            //创建完成
            dispatch(resetEditor())
            navi("../result", {
                replace: true,
                state: {
                    status: "success",
                    title: "报销单创建成功！",
                    applyId: createdId,
                } as ResultProps,
            })
        } else if (updated) {
            //更新完成
            Modal.success({
                content: "报销单更新成功！",
                onOk: () => {
                    dispatch(resetEditor())
                    navi("../" + reimburesForm?.reimburseId, {
                        replace: true,
                    })
                },
            })
            // 更新成功调用附件上传接口
            if (loading === 'fulfilled' && reimburesForm?.reimburseId && fileRepertories) {
                const value = {
                    reimburseId: reimburesForm?.reimburseId,
                    files: fileRepertories
                }
                dispatch(createAnnexes(value))
            }
        }
    }, [createdId, dispatch, fileRepertories, loading, navi, reimburesForm?.reimburseId, updated])

    //批量更新账目关联的报销单信息
    useEffect(() => {
        if (batchUpdateAccountsLoading === 'fulfilled') {
            if (!formValues) return
            dispatch(updateAccounts(formValues?.accounts.map(it => it.accountsId ?? '') ?? []))
            dispatch(updateReimburse(formValues))

        } else if (batchUpdateAccountsLoading === 'reject') {
            Modal.warning({ title: '未找到该报销单' })
        }

        if (updateReimburseError?.error === 'INVALID_APPLY_ID') {
            Modal.warning({
                title: '无效的申请单ID',
                content: `${updateReimburseError.message}`,
            })
        } else if (updateReimburseError?.error === 'INVALID_PAYMENT_DETAIL') {
            Modal.warning({
                title: '无效的打卡明细，打卡明细金额总和不能大于报销单实际个人支付总和',
                content: `${updateReimburseError.message}`,
            })
        } else if (updateReimburseError?.error === 'INVALID_ACCOUNT_IDS') {
            Modal.warning({
                title: '无效的账目ID，可能是缺少账目ID或者有部分账目不可用。',
                content: `${updateReimburseError.message}`,
            })
        } else if (updateReimburseError?.error === 'REIMBURSE_IN_PROGRESS') {
            Modal.warning({
                title: '报销单处于审批中的状态，无法更新。',
                content: `${updateReimburseError.message}`,
            })
        }
    }, [batchUpdateAccountsLoading, dispatch, formValues, updateReimburseError?.error, updateReimburseError?.message])


    // create保存
    const onFinish = (value: ReimbursementRequest) => {
        console.log("🚀 ~ onFinish ~ value:", value)

        if (mode === 'create') {
            const values = {
                ...value,
                applyId: value.applyForm.applyId,
                companyId: value.applyForm.companyId,
                staffId: value.applyForm.staffId,
                fileRepertories: [],
                accountIds: value.accounts.map(it => it.accountsId ?? ''),
            }
            // 附件信息
            setFileRepertories(value.fileRepertories?.filter(it => it.originFileObj) ?? [])
            // 保存报销单
            dispatch(createReimburesForm(values))
        } else {
            //这种判断方案有个前提就是所有字段都为必填，否则无法区分未修改和置空的情况
            const res = modifications(value)
            if (Object.keys(res.applyForm).length <= 0 &&
                Object.keys(res.actionType).length <= 0 &&
                Object.keys(res.projectName).length <= 0 &&
                Object.keys(res.accounts).length <= 0 &&
                Object.keys(res.paymentDetailList).length <= 0) {
                message.warning("并未修改任何信息，无需保存。")
            } else {
                if (value.accounts.filter(it => it.accountsId)) { //更新'
                    const values = {
                        ...value,
                        applyId: value.applyForm.applyId,
                        companyId: value.applyForm.companyId,
                        staffId: value.applyForm.staffId,
                        fileRepertories: value.fileRepertories?.filter(it => !it.originFileObj),
                        accountIds: value.accounts.map(it => it.accountsId ?? '')
                    }
                    // 批量更新账目关联的报销单信息
                    dispatch(BatchUpdateAccounts({ mode: 'update', accounts: value.accounts }))
                    // 附件信息
                    setFileRepertories(value.fileRepertories?.filter(it => it.originFileObj) ?? [])
                    setFormValues(values)
                }
            }
        }
    }
    // 字段值更新时触发回调事件
    const handleChanges = (changedValues: ReimbursementRequest, all: ReimbursementRequest) => {
        console.log("🚀 ~ handleChanges ~ all:", all)
        console.log("🚀 ~ handleChanges ~ changedValues:", changedValues)
        if (changedValues.applyForm) {
            // 查询申请单关联账目
            if (changedValues.applyForm.applyId) {
                dispatch(fetchApplyFormAccount(changedValues.applyForm.applyId))
            }

            form.setFieldsValue(
                Object.assign(all, {
                    // 出差目的
                    actionType: changedValues.applyForm.actionType,
                    businessPurposesId: changedValues.applyForm.businessPurposesId,
                    // 出差项目
                    projectId: changedValues.applyForm.projectId,
                    projectName: changedValues.applyForm.projectName,
                } as ReimbursementRequest),
            )
        }

        if (changedValues.accounts) {
            const accountsSumMoney = changedValues.accounts.reduce((total, it) => {
                // 如果corpAccounts为true，则忽略该项
                if (it.corpAccounts) return total
                // 否则，将accountsSumMoney3的值累加到总和中
                return total + it.accountsSumMoney3
            }, 0)

            form.setFieldsValue(
                Object.assign(all, {
                    reimburseMoney: accountsSumMoney,
                    reimburseMoneyCorp: changedValues.accounts.map(it => it.financialTaxMoney ?? 0).reduce((total, amount) => total + amount, 0),
                    reimburseMoneyPersonal: accountsSumMoney,
                } as ReimbursementRequest)
            )
        }

    }

    return (
        <Form form={form} layout="vertical"
            onFinish={onFinish}
            onValuesChange={handleChanges}
        >
            <PageHeader
                ghost={false}
                title={mode === 'create' ? "创建报销申请" : "修改报销单"}
                subTitle={reimburesForm && reimburesForm.reimburseNum}
                extra={[
                    <Button key="submit" type="primary" htmlType="submit" loading={initialized && loading === "pending"}>
                        保存
                    </Button>
                ]}
            />
            <div className="container">
                <Row justify="center">
                    <Col xs={24} sm={24} md={24} lg={17}>
                        {
                            (mode === 'edit' || mode === 'create') &&
                            <Alert message='提示:出差原因选择"室内交通"时,可不关联出差申请' type="warning" />
                        }
                        <div style={{ height: "16px" }}></div>
                        {
                            mode === 'edit' && <ApplyFormViewer applyForm={applyForm}
                                staffList={reimburesForm?.staffIdList}
                                detail={reimburesForm} />
                        }
                        <Title level={4}>基础信息</Title>
                        {/* 关联申请单 */}
                        <TravelRequestSelector />
                        {/* 自定义字段选择 */}
                        <CustomFields />
                        {/* 出差项目 */}
                        <TravelProjectItems />
                        {/* 出差目的 */}
                        <TravelPurposesSelector />
                        {/* 报销单名称 */}
                        <Item
                            name="reimburseName"
                            label="报销单名称"
                            rules={[
                                { required: true },
                            ]}
                            initialValue='差旅费报销单'>
                            <Input disabled />
                        </Item>
                        {/* 账目 */}
                        <ReimburseAccount />
                        {/* 支付列表 */}
                        <PaymentDetail />
                        {/* 报销总额 个人支付  */}
                        <Form.Item hidden label='总金额' name='reimburseMoney'>
                            <Input />
                        </Form.Item>
                        <Form.Item hidden label='企业报销金额' name='reimburseMoneyCorp'>
                            <Input />
                        </Form.Item>
                        <Form.Item hidden label='个人报销金额' name='reimburseMoneyPersonal'>
                            <Input />
                        </Form.Item>
                        {/* 附件 */}
                        <Attachments />
                    </Col>
                </Row>
            </div>
        </Form>
    )
}
