import { useEffect } from "react"
import { Button, Result } from "antd"
import type { ResultProps } from "antd"
import { Link, useLocation, useNavigate } from "react-router-dom"

type Props = ResultProps & {
    reimburseId?: string
}
export default function ReimburesOpResult(): JSX.Element {
    const navi = useNavigate()
    const loc = useLocation()
    console.log("🚀 ~ ReimburesOpResult ~ loc:", loc)
    const props = loc.state as Props

    useEffect(() => {
        if (!props) navi("/404")
    }, [navi, props])

    return (
        <div className="container">
            <Result
                {...props}
                extra={[
                    <Button key="backToHome">
                        <Link to="/" replace>
                            回到首页
                        </Link>
                    </Button>,
                    props?.reimburseId && (
                        <Link
                            key="goToViewer"
                            to={"../" + props?.reimburseId}
                            replace>
                            <Button key="goToViewer" type="primary">
                                查看报销单
                            </Button>
                        </Link>
                    ),
                ]}
            />
        </div>
    )
}
