import { Checkbox, List, Radio, Tooltip, Typography } from "antd"
import { useState, ReactElement, useEffect } from "react"
import { useAppSelector } from "../../../../app/hooks"
import { idNumberMarked } from "../../../applyForm/viewer/TraverllerEditor"
import {
    CardPassportTypeList,
    IdCardType,
    idCardTypeList,
} from "../../../traveller/Editor"
import { TravellerSelected } from "../../airplane/payConfirm/BookSelectPassenger"
import { isPassportDisabled } from "../../../applyForm/viewer/TraverllerIdCard"
import { Traveller } from "../../../models"

const { Title } = Typography

export interface HotelSelected {
    travellerId: string
    selectedIdIdx: number
}

interface Props {
    value?: HotelSelected[]
    onChange?: (ids?: HotelSelected[]) => void
}

export const SelectPerson = ({ value, onChange }: Props): ReactElement => {
    const travellers = useAppSelector(
        state => state.booking.applyForm?.travellers || []
    )
    //默认出行人全部不选中
    const [selected, setSelected] = useState<TravellerSelected[]>(
        travellers.map(i => {
            return {
                travellerId: i.travellerId || "",
                selected: false,
                selectedIdIdx: undefined,
            }
        })
    )

    useEffect(() => {
        const innerValue = selected
            .filter(item => item.selected)
            ?.map(it => ({
                travellerId: it.travellerId,
                selectedIdIdx: it.selectedIdIdx || 0,
            }))
        if (JSON.stringify(value) !== JSON.stringify(innerValue)) {
            onChange?.(innerValue)
        }
    }, [onChange, selected, value])

    // 多选框
    function onTravellerClick(traveller: Traveller) {
        const reviseSelected = selected.map(j => {
            //修改选择的selected
            if (j.travellerId === traveller.travellerId) {
                j.selected = !j.selected
                if (!j.selected) {
                    delete j.selectedIdIdx
                } else {
                    const idlist = traveller.idList ?? []
                    if (isPassportDisabled(idlist[0])) {
                        delete j.selected
                    } else {
                        j.selectedIdIdx = 0
                    }
                }
            }
            return j
        })
        setSelected(reviseSelected)
    }

    // 单选框
    function onIdCardClick(travellerId: string, idx2: number) {
        const reviseSelectedIdx = selected.map(it => {
            if (it.travellerId === travellerId) {
                it.selected = true
                it.selectedIdIdx = idx2
            }
            return it
        })
        setSelected(reviseSelectedIdx)
    }

    return (
        <List
            key="Passenger"
            dataSource={travellers}
            renderItem={traveller => {
                const travellerIdCard = selected.find(
                    it => it.travellerId === traveller.travellerId
                )
                if (!traveller.idList) return
                const idCard = travellerIdCard
                    ? traveller.idList[travellerIdCard?.selectedIdIdx ?? -1]
                    : undefined
                const idName = idCard
                    ? `${idCard.name} - ${idNumberMarked(idCard.idNumber)}`
                    : traveller.name

                return (
                    <List.Item key={traveller.idNumber}>
                        <List.Item.Meta
                            avatar={
                                <Checkbox
                                    checked={travellerIdCard?.selected}
                                    onChange={() => onTravellerClick(traveller)}
                                />
                            }
                            title={
                                <Typography.Text
                                    style={{ padding: 0, margin: 0 }}>
                                    <Title level={5}>{idName}</Title>
                                </Typography.Text>
                            }
                            description={
                                <Radio.Group
                                    optionType="button"
                                    value={
                                        idCard ? idCard.idNumber : undefined
                                    }>
                                    {traveller.idList.map((i, idx) => {
                                        return (
                                            <Tooltip
                                                title={
                                                    isPassportDisabled(i) &&
                                                    "护照已过期"
                                                }
                                                key={idx}>
                                                <Radio.Button
                                                    value={i.idNumber}
                                                    key={i.idType + idx}
                                                    disabled={isPassportDisabled(
                                                        i
                                                    )}
                                                    onClick={() =>
                                                        onIdCardClick(
                                                            String(
                                                                traveller?.travellerId
                                                            ),
                                                            idx
                                                        )
                                                    }>
                                                    {i.idType ===
                                                        IdCardType.PASSPORT &&
                                                        CardPassportTypeList().find(
                                                            it =>
                                                                it.passportType ===
                                                                i.passportType
                                                        )?.displayName}
                                                    {
                                                        idCardTypeList().find(
                                                            it =>
                                                                it.idCardType ===
                                                                i.idType
                                                        )?.displayName
                                                    }
                                                </Radio.Button>
                                            </Tooltip>
                                        )
                                    })}
                                </Radio.Group>
                            }
                        />
                    </List.Item>
                )
            }}></List>
    )
}
