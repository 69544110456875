import { ReactElement, useState, useEffect } from "react"
import { Card, Col, Row, Button, Modal, Layout } from "antd"
import { PageHeader } from "@ant-design/pro-layout"
import OrderDetailTopInfoCpn from "./components/OrderDetailTopInfoCpn"
import PlaneOrderDetailJourneyCpn from "./components/PlaneOrderDetailJourneyCpn"
import PlaneOrderDetailPriceCpn from "./components/PlaneOrderDetailPriceCpn"
import PlaneOrderDetailPassengerCpn from "./components/PlaneOrderDetailPassengerCpn"
import TrainOrderDetailJourneyCpn from "./components/TrainOrderDetailJourneyCpn"
import HotelOrderDetailRoomCpn from "./components/HotelOrderDetailRoomCpn"
import TrainOrderDetailPriceCpn from "./components/TrainOrderDetailPriceCpn"
import TrainOrderDetailPassengerCpn from "./components/TrainOrderDetailPassengerCpn"
import HotelOrderDetailPriceCpn from "./components/HotelOrderDetailPriceCpn"
import TrainOrderDetailRulesCpn from "./components/TrainOrderDetailRulesCpn"
import { PlaneDetailRefundRulesCpn } from "./components/PlaneOrderDetailRulesCpn"
import {
    OrderListModel,
    HotelOrderListModel,
    PlaneOrderListModel,
    TrainOrderListModel,
} from "../service/orderListModel"
import { HotelOrderDetailRules } from "./components/HotelOrderDetailRules"
import { RefundOrderFlightValidateParams } from "../../book/airplane/services/bookAirplaneApi"
import { AppDispatch, RootState } from "../../../app/store"
import {
    deleteChangeRefundValidateResInfo,
    airplaneRefundValidate,
} from "../../book/airplane/services/bookAirplaneSlice"
import { connect } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

const mapStateToProps = (state: RootState) => ({
    res: state.bookAirplane.changeRefundValidateRes,
    loading: state.orderModule.isLoading,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    // 飞机退票校验
    airplaneRefundValidateInfo: (params: RefundOrderFlightValidateParams) => {
        dispatch(airplaneRefundValidate(params))
    },
    // 飞机 删state里的数据，清除状态
    dleteRefundValidate: () => {
        dispatch(deleteChangeRefundValidateResInfo())
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

const _orderDetailComponent = (props: Props): ReactElement => {
    const { dleteRefundValidate, airplaneRefundValidateInfo, res } = props
    // 路由跳转传参
    const navi = useNavigate()
    const location = useLocation()
    //通过useLocation把TrainListCardItem.tsx页面的item接收过来
    const orderModel = location.state as OrderListModel
    // 确认框开关
    const [showRules, setShowRules] = useState(false)

    // 区分不同的操作状态
    function showStateOperate(orderModel: OrderListModel) {
        if (orderModel?.orderType === undefined) return
        if (orderModel.orderType === 1) {
            return (
                <OrderDetailTopInfoCpn
                    orderModel={orderModel}
                    trainChangeClick={() =>
                        _trainChange(orderModel as TrainOrderListModel)
                    }
                    trainRefundClick={() => {
                        _trainRefund(orderModel as TrainOrderListModel)
                    }}
                />
            )
        } else if (orderModel.orderType === 2) {
            return (
                <OrderDetailTopInfoCpn
                    orderModel={orderModel}
                    airplaneChangeClick={() =>
                        _airplaneChange(orderModel as PlaneOrderListModel)
                    }
                    airplaneRefundClick={() =>
                        _airplaneRefund(orderModel as PlaneOrderListModel)
                    }
                />
            )
        } else {
            return (
                <OrderDetailTopInfoCpn
                    orderModel={orderModel}
                    hotelRefundClick={_hotelRefund}
                />
            )
        }
    }

    // 区分不同行程
    function showCpn(orderModel: OrderListModel) {
        if (orderModel.orderType === 1) {
            return <TrainOrderDetailJourneyCpn {...orderModel} />
        } else if (orderModel.orderType === 2) {
            return <PlaneOrderDetailJourneyCpn {...orderModel} />
        } else {
            return <HotelOrderDetailRoomCpn {...orderModel} />
        }
    }

    // 区分不同退改规则
    function showRefundRulesCpn(orderModel: OrderListModel) {
        if (orderModel.orderType === 1) {
            return <TrainOrderDetailRulesCpn />
        } else if (orderModel.orderType === 2) {
            const { flight, cabin, applyId } = orderModel as PlaneOrderListModel
            const params = {
                flightDate: flight?.flightDate,
                supplierId: cabin?.supplierId,
                airline: flight?.airline,
                productType: cabin?.productType,
                initTicketPrice: cabin?.initTicketPrice,
                flightNum: flight?.flightNum,
                cabin: cabin?.cabin,
                takeOffPort: flight?.takeOffPort,
                landingPort: flight?.landingPort,
                applyForId: applyId,
            }
            return <PlaneDetailRefundRulesCpn params={params} />
        } else {
            return (
                <HotelOrderDetailRules
                    {...(orderModel as HotelOrderListModel).hotelProduct}
                />
            )
        }
    }

    // 区分不同价格明细
    function showPriceCpn(orderModel: OrderListModel) {
        if (orderModel.orderType === 1) {
            return <TrainOrderDetailPriceCpn {...orderModel} />
        } else if (orderModel.orderType === 2) {
            return <PlaneOrderDetailPriceCpn {...orderModel} />
        } else {
            return <HotelOrderDetailPriceCpn {...orderModel} />
        }
    }

    const orderTypeStr = (orderType?: number): string => {
        switch (orderType) {
            case 1:
                return "火车"
            case 2:
                return "飞机"
            case 3:
                return "酒店"
            default:
                return ""
        }
    }

    const _airplaneChange = (item: PlaneOrderListModel) => {
        // 飞机改签 通过useNavigate携带参数跳转到RefundTicket.tsx页面
        navi("/order/list/detail/operate/1", { state: item })
    }
    const _airplaneRefund = (item: PlaneOrderListModel) => {
        // 飞机退票
        const params: RefundOrderFlightValidateParams = {
            applyForId: item.applyId || "",
            ticketNum: item.ticketNum || "",
            orderNumber: item.customId || "",
            supplierId: item.cabin?.supplierId || "",
        }
        // 飞机退票验证
        airplaneRefundValidateInfo(params)
    }

    const _trainChange = (item: TrainOrderListModel) => {
        // 火车改签 通过useNavigate携带参数跳转到TrainTicketOperate.tsx页面
        navi("/order/list/train/operate/1", { state: item })
    }
    const _trainRefund = (item: TrainOrderListModel) => {
        // 火车退票 通过useNavigate携带参数跳转到TrainTicketOperate.tsx页面
        navi("/order/list/train/operate/2", { state: item })
    }

    const _hotelRefund = (oid: string) => {
        // 酒店退订 通过useNavigate携带参数跳转到HotelRefund.tsx页面
        navi(`/booking/hotel/refund/${oid}`)
    }

    useEffect(() => {
        if (res && res.rc === 0) {
            // 跳转RefundTicket.tsx
            navi("/order/list/detail/operate/2", { state: orderModel })
        } else if (res && res.rc === 1) {
            Modal.warning({ content: `${res?.msg}` })
        }
        return () => {
            // 卸载 飞机删state里的数据，清除状态
            dleteRefundValidate()
        }
    }, [res, navi, orderModel, dleteRefundValidate])

    return (
        <Layout>
            <PageHeader
                ghost={false}
                title={"订单详情"}
                onBack={() => {
                    navi(-1)
                }}
                subTitle={`${orderTypeStr(orderModel.orderType)}`}
            />
            <Row>
                <Col flex="2 200px">
                    <div className="container">
                        {showStateOperate(orderModel)}
                        <Card
                            title={
                                orderModel.orderType === 3
                                    ? "入住信息"
                                    : "行程信息"
                            }
                            bordered={false}
                            style={{
                                marginBottom: "10px",
                                borderRadius: "10px",
                            }}
                            styles={{
                                body: {
                                    backgroundColor: "rgb(248,248,248)",
                                    borderTopLeftRadius: "10px",
                                    borderTopRightRadius: "10px",
                                }
                            }}
                            extra={
                                orderModel.ticketNum && (
                                    <div style={{ display: "inline-block" }}>
                                        票号：{orderModel.ticketNum}
                                    </div>
                                )
                            }>
                            {showCpn(orderModel)}
                        </Card>

                        {/** 酒店订单不显示退改签规则 */}
                        <Card
                            bordered={false}
                            style={{
                                marginBottom: "10px",
                                borderRadius: "10px",
                            }}
                            styles={{ body: { backgroundColor: "rgb(248,248,248)", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" } }}>
                            <Button
                                type="link"
                                onClick={() => setShowRules(true)}>
                                {" "}
                                {orderModel.orderType === 3
                                    ? "查看退改规则"
                                    : "查看退改签规则"}{" "}
                            </Button>

                            <Modal
                                title="退改规则"
                                width="60vw"
                                open={showRules}
                                closable={true}
                                footer={null}
                                onCancel={() => setShowRules(false)}>
                                {showRefundRulesCpn(orderModel)}
                            </Modal>
                        </Card>

                        {/** 酒店订单不显示乘机人 */}
                        {orderModel.orderType !== 3 && (
                            <Card
                                title="乘机人"
                                bordered={false}
                                style={{
                                    marginBottom: "10px",
                                    borderRadius: "10px",
                                }}
                                styles={{
                                    body: {
                                        backgroundColor: "rgb(248,248,248)",
                                        borderTopLeftRadius: "10px",
                                        borderTopRightRadius: "10px",
                                    }
                                }}>
                                {orderModel.orderType === 1 ? (
                                    <TrainOrderDetailPassengerCpn
                                        {...orderModel}
                                    />
                                ) : (
                                    <PlaneOrderDetailPassengerCpn
                                        {...orderModel}
                                    />
                                )}
                            </Card>
                        )}

                        <Card
                            title="联系人信息"
                            bordered={false}
                            style={{
                                marginBottom: "10px",
                                borderRadius: "10px",
                            }}
                            styles={{
                                body: {
                                    backgroundColor: "rgb(248,248,248)",
                                    borderTopLeftRadius: "10px",
                                    borderTopRightRadius: "10px",
                                }
                            }}>
                            <p>
                                手机号：
                                {orderModel.orderType === 2
                                    ? orderModel.passenger?.passengerMobile
                                    : orderModel.phone}
                            </p>
                            <p>
                                {orderModel.orderType === 2
                                    ? `联系邮箱：${orderModel.passenger?.passengerEmail}`
                                    : `预订人：${orderModel.orderUserName}`}
                            </p>
                        </Card>

                        <Card
                            title="供应商"
                            bordered={false}
                            style={{
                                marginBottom: "10px",
                                borderRadius: "10px",
                            }}
                            styles={{
                                body: {
                                    backgroundColor: "rgb(248,248,248)",
                                    borderTopLeftRadius: "10px",
                                    borderTopRightRadius: "10px",
                                }
                            }}>
                            <p>{orderModel.corpAccountsName}</p>
                        </Card>
                    </div>
                </Col>
                <Col flex="1 100px">
                    <div className="container">{showPriceCpn(orderModel)}</div>
                </Col>
            </Row>
        </Layout>
    )
}

export const OrderDetailComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(_orderDetailComponent)
