import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import dayjs from 'dayjs'
import { RootState } from "../../app/store"
import { AccessToken } from "../models"
import { fetchTokenViaAuthCodeApi, validateTokenApi } from "./userAPI"
import { redirectToLogin, reset as resetUserState } from "./userSlice"


interface LoginState {
    authError?: string
    accessToken?: AccessToken
    redirectUri?: string
}
const initialState: LoginState = {}

export interface FetchTokenReq {
    code: string
    state: string
    uri: string
}

export const accessTokenSelector = (state: RootState): AccessToken | undefined => state.login.accessToken
const redirectUriSelector = (state: RootState) => state.login.redirectUri

const newAuthorizeQuery = () => {
    const t = dayjs().format('YYYYMMDDHHmmss')
    const redirectUri = window.location.protocol + "//" + window.location.host + "/login"
    const uri = `${window.SSCL_LOGIN_API}?response_type=code&client_id=${window.SSCL_CLIENT_ID}&state=${t}&redirect_uri=${encodeURIComponent(redirectUri)}`
    return { uri, redirectUri }
}

const newSSOQuery = (p: URLSearchParams) => {
    const t = dayjs().format('YYYYMMDDHHmmss')
    if (!p.has("ref") || p.get("ref") !== "poly") {
        return {}
    }
    if (!p.has("app")) p.set("app", "sso")
    let redirectUri: string | undefined
    switch (p.get("app")) {
        case "sso":
            redirectUri = window.location.protocol + "//" + window.location.host + "/login?" + p.toString()
            break
        case "travelApplyApproval":
            if (p.has("rel")) {
                redirectUri = window.location.protocol + "//" + window.location.host + "/login?" + p.toString()
            }
            break

    }
    const uri = redirectUri && `${window.SSCL_OPEN_API}/poly/oauth/authorize?response_type=code&client_id=${window.SSCL_CLIENT_ID}&state=${t}&redirect_uri=${encodeURIComponent(redirectUri)}`
    return { uri, redirectUri }
}

export const fetchTokenViaAuthCode = createAsyncThunk<AccessToken, FetchTokenReq>('login/fetchAccessToken',
    async (req: FetchTokenReq, { rejectWithValue, getState }) => {
        const uri = redirectUriSelector(getState() as RootState)
        if (uri) req.uri = uri
        try {
            const token = await fetchTokenViaAuthCodeApi(req)
            return token
        } catch (err) {
            return rejectWithValue(err)
        }
    })

//验证token有效性
export const validateToken = createAsyncThunk('login/tokenValidator',
    async (_: void, { getState, rejectWithValue, dispatch }) => {
        const token = accessTokenSelector(getState() as RootState)
        try {
            if (!token) throw new Error()
            await validateTokenApi(token)
        } catch (err) {
            dispatch(resetUserState())
            dispatch(redirectToLogin())
            throw rejectWithValue(null)
        }
    }
)

const slice = createSlice({
    name: "login",
    initialState,
    reducers: {
        jump: (state) => {
            const { uri, redirectUri } = newAuthorizeQuery()
            state.redirectUri = redirectUri
            window.location.href = uri
        },
        jumpSSO: (state, { payload }: PayloadAction<URLSearchParams>) => {
            const { uri, redirectUri } = newSSOQuery(payload)
            if (!uri) {
                window.location.href = "/login?error=invalid_arguments"
            } else {
                state.redirectUri = redirectUri
                window.location.href = uri
            }
        },
        reset: () => {
            return {}
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchTokenViaAuthCode.pending, (state) => ({ ...state, authError: undefined, accessToken: undefined }))
            .addCase(fetchTokenViaAuthCode.rejected, (state, { payload }) => {
                delete state.redirectUri
                state.authError = (payload as Record<string, string>)['error']
            })
            .addCase(fetchTokenViaAuthCode.fulfilled, (state, { payload }) => {
                delete state.redirectUri
                state.accessToken = payload
            })
            // ---
            .addCase(validateToken.rejected, () => ({}))
    }
})

export const { jump, jumpSSO, reset } = slice.actions
export default slice.reducer
