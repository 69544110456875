import React from 'react'
import { InputNumber } from 'antd'

type Props = {
    onChange?: (v?: number) => void
    value?: number
}

const InvoiceTaxRate = ({ onChange, value }: Props) => {

    return (
        <InputNumber
            value={Math.floor(((value ?? 0) * 100))}
            addonAfter={'%'} min={0} max={100} style={{ width: '100%' }} onChange={(value) => {
                if (typeof value === 'number')
                    onChange?.(value / 100)
            }} />
    )
}

export default InvoiceTaxRate
