import { ReactElement } from "react"
import { List, Typography } from "antd"
import { useAppSelector } from "../../../../app/hooks"

const TrainOrderDetailRulesCpn = (): ReactElement => {
    const nomalTrainNotice = useAppSelector(
        state => state.location.tipMessage?.nomalTrainNotice
    )
    const changeTrainNotice = useAppSelector(
        state => state.location.tipMessage?.changeTrainNotice
    )

    return (
        <>
            <List
                header={<Typography.Text mark>[退票]</Typography.Text>}
                dataSource={nomalTrainNotice}
                renderItem={item => <List.Item>{item}</List.Item>}
            />

            <List
                header={<Typography.Text mark>[改签]</Typography.Text>}
                dataSource={changeTrainNotice}
                renderItem={item => <List.Item>{item}</List.Item>}
            />
        </>
    )
}

export default TrainOrderDetailRulesCpn
