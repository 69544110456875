import { Modal, TableColumnsType } from "antd"
import { Button, Table, Tabs } from "antd"
import { PageHeader } from "@ant-design/pro-layout"
import { useEffect } from "react"
import { connect } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { AppDispatch, RootState } from "../../app/store"
import { PageRequest } from "../models"
import { ApprovalsStateType } from "./api"
import { reset } from "./listSlice"
import { ApplyFormItemVo, findAll, setTabKey } from "./listSlice"

const mapStateToProps = (state: RootState) => ({
    loading: state.applyFormList.loading,
    list: state.applyFormList.list,
    tabKey: state.applyFormList.tabKey,
    error: state.applyFormList.error,
    companyId: state.user.currentCompany?.companyId,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setTabKey: (tabKey: string) => {
        dispatch(setTabKey(tabKey as ApprovalsStateType))
    },
    fetchList: (p: PageRequest) => {
        dispatch(findAll(p))
    },
    reset: () => {
        dispatch(reset())
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

const cols: TableColumnsType<ApplyFormItemVo> = [
    {
        title: "申请单号",
        dataIndex: "documentNum",
    },
    {
        title: "开始日期",
        dataIndex: "startDate",
    },
    {
        title: "结束日期",
        dataIndex: "endDate",
    },
    {
        title: "审批状态",
        dataIndex: "state",
    },
]

const _List = ({
    error,
    loading,
    list,
    tabKey,
    companyId,
    fetchList,
    setTabKey,
    reset,
}: Props) => {
    const navi = useNavigate()
    const defaultPageSize = 10

    useEffect(() => {
        reset()
    }, [companyId, reset])

    useEffect(() => {
        if (error) {
            if (error !== 404)
                Modal.error({
                    content:
                        typeof error === "string"
                            ? error
                            : `请求错误：${error}`,
                })
        } else if (!list && !loading) {
            fetchList({
                page: 0,
                size: defaultPageSize,
            })
        }
    }, [list, loading, error, fetchList])

    const onPageChange = (page: number, size: number) => {
        fetchList({ page: page - 1, size })
    }
    // 定义tabs值
    const items = [
        { label: "草稿", key: "draft" },
        { label: "已提交", key: "submitted" },
    ]
    return (
        <>
            <PageHeader
                title="出差申请"
                ghost={false}
                extra={[
                    <Button key="create" type="primary" ghost>
                        <Link to="../create">新建出差申请</Link>
                    </Button>,
                ]}
            />
            <div className="container">
                <Tabs
                    items={items}
                    activeKey={tabKey}
                    onChange={setTabKey}></Tabs>
                <Table
                    loading={loading}
                    rowKey="documentId"
                    dataSource={list?.content}
                    columns={cols}
                    scroll={{ x: "max-content" }}
                    pagination={
                        list && {
                            total: list.totalElements,
                            current: list.number + 1,
                            pageSize: list.size,
                            defaultPageSize,
                            onChange: onPageChange,
                        }
                    }
                    onRow={(r: ApplyFormItemVo) => {
                        return {
                            className: "clickable",
                            onClick: () => {
                                navi(`../${r.documentId}`)
                            },
                        }
                    }}
                />
            </div>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(_List)
