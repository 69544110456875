import { ReactElement, useState, useEffect } from "react"
import { Result } from "antd"
import { CommonLegacyResult } from "../../../models"
import { useLocation } from "react-router-dom"

interface ResCommonResult<DATA> extends CommonLegacyResult<DATA> {
    mode?: string
}

export const AirplanePaySuccess = (): ReactElement => {
    const location = useLocation()
    const [title, setTitle] = useState("")
    const [subTitle, setSubTitle] = useState("")

    const res = location.state as ResCommonResult<unknown>

    useEffect(() => {
        if (res.mode === "change") {
            const tmpRes = res as ResCommonResult<{
                changeResult: { changeNum: string }
            }>
            setTitle("改签成功")
            setSubTitle(
                `订单号: ${
                    tmpRes.data?.changeResult.changeNum ?? "--"
                } 改签中，请您耐心等待`
            )
        } else {
            const tmpRes = res as ResCommonResult<{
                createResult: { orderID: string; orderAmount: string }
            }>
            setTitle(
                `支付成功! 金额:¥${
                    tmpRes.data?.createResult.orderAmount ?? "--"
                }`
            )
            setSubTitle(
                `订单号: ${
                    tmpRes.data?.createResult.orderID ?? "--"
                } 出票中，请您耐心等待`
            )
        }
    }, [res])

    return <Result status="success" title={title} subTitle={subTitle}></Result>
}
