import { ReactElement } from "react"
import { Card, Col, Row } from "antd"
import {
    CheckCircleTwoTone,
    ClockCircleTwoTone,
    CloseCircleTwoTone,
} from "@ant-design/icons"
import { OrderListModel } from "../../service/orderListModel"
import {
    airplaneOrderStatus,
    airplaneOrderStatusToStr,
    hotelOrderStatusToStr,
    trainOrderStatus,
    trainOrderStatusToStr,
    hotelOrderStatus,
} from "../../../book/const"
import { ShowStateOperate } from "../../common/view/OrderStateOperate"

const OrderStatusStyle = (params: {
    orderType?: number
    orderState?: number
}): ReactElement => {
    let colorStr = "#333"
    const tipTone = (params: { orderType?: number; orderState?: number }) => {
        if (params.orderType === 1) {
            switch (params.orderState) {
                case trainOrderStatus.CLTrainOrderStateSavingSeat:
                case trainOrderStatus.CLTrainOrderStateCreating:
                case trainOrderStatus.CLTrainOrderStateChanging:
                case trainOrderStatus.CLTrainOrderStateReturning:
                case trainOrderStatus.CLTrainOrderStateWaitingConfirmChange:
                    colorStr = "#40a9ff"
                    return <ClockCircleTwoTone />

                case trainOrderStatus.CLTrainOrderStateSavingSeatFail:
                case trainOrderStatus.CLTrainOrderStateCreateFail:
                case trainOrderStatus.CLTrainOrderStateReturnFail:
                    colorStr = "#ff4d4f"
                    return <CloseCircleTwoTone twoToneColor="#ff4d4f" />

                case trainOrderStatus.CLTrainOrderStateReturnSuccess:
                case trainOrderStatus.CLTrainOrderStateChageSuccess:
                    colorStr = "#bfbfbf"
                    return <CloseCircleTwoTone twoToneColor="#bfbfbf" />

                case trainOrderStatus.CLTrainOrderStateCreateSuccess:
                    colorStr = "#73d13d"
                    return <CheckCircleTwoTone twoToneColor="#73d13d" />
                default:
                    break
            }
        } else if (params.orderType === 2) {
            // 飞机
            switch (params.orderState) {
                case airplaneOrderStatus.CLOrderStateChanging:
                case airplaneOrderStatus.CLOrderStateCreating:
                case airplaneOrderStatus.CLOrderStateReturning:
                    colorStr = "#40a9ff"
                    return <ClockCircleTwoTone />

                case airplaneOrderStatus.CLOrderStateChangeFail:
                case airplaneOrderStatus.CLOrderStateCreateFail:
                case airplaneOrderStatus.CLOrderStateReturnFail:
                    colorStr = "#ff4d4f"
                    return <CloseCircleTwoTone twoToneColor="#ff4d4f" />

                case airplaneOrderStatus.CLOrderStateChageSuccess:
                case airplaneOrderStatus.CLOrderStateReturnSuccess:
                    colorStr = "#bfbfbf"
                    return <CloseCircleTwoTone twoToneColor="#bfbfbf" />

                case airplaneOrderStatus.CLOrderStateComplete:
                case airplaneOrderStatus.CLOrderStateCreateSuccess:
                    colorStr = "#73d13d"
                    return <CheckCircleTwoTone twoToneColor="#73d13d" />

                default:
                    break
            }
        } else {
            switch (params.orderState) {
                case hotelOrderStatus.CLHotelOrderStateApplyFail:
                case hotelOrderStatus.CLHotelOrderStateBookingFailure:
                    colorStr = "#ff4d4f"
                    return <CloseCircleTwoTone twoToneColor="#ff4d4f" />

                case hotelOrderStatus.CLHotelOrderStateApplySuccess:
                case hotelOrderStatus.CLHotelOrderStateReserving:
                case hotelOrderStatus.CLHotelOrderStateOrderCanceling:
                case hotelOrderStatus.CLHotelOrderStateRefunding:
                    colorStr = "#40a9ff"
                    return <ClockCircleTwoTone />

                case hotelOrderStatus.CLHotelOrderStateBookingSuccess:
                    colorStr = "#73d13d"
                    return <CheckCircleTwoTone twoToneColor="#73d13d" />

                case hotelOrderStatus.CLHotelOrderStateCheckout:
                case hotelOrderStatus.CLHotelOrderStateOrderCanceled:
                case hotelOrderStatus.CLHotelOrderStateRefunded:
                case hotelOrderStatus.CLHotelOrderStateNotCheckIn:
                    colorStr = "#bfbfbf"
                    return <CloseCircleTwoTone twoToneColor="#bfbfbf" />

                default:
                    break
            }
            return <CloseCircleTwoTone twoToneColor="#bfbfbf" />
        }
    }

    let orderTypeStr = ""
    if (params.orderType === 1) {
        orderTypeStr = trainOrderStatusToStr(params.orderState)
    } else if (params.orderType === 2) {
        // 飞机
        orderTypeStr = airplaneOrderStatusToStr(params.orderState)
    } else {
        orderTypeStr = hotelOrderStatusToStr(params.orderState)
    }

    return (
        <>
            {tipTone(params)}
            <span style={{ paddingLeft: "10px" }}>
                订单状态：
                <span style={{ color: colorStr }}>{orderTypeStr}</span>
            </span>
        </>
    )
}

interface Props {
    orderModel: OrderListModel
    airplaneChangeClick?: () => void
    airplaneRefundClick?: () => void
    trainChangeClick?: () => void
    trainRefundClick?: () => void
    hotelRefundClick?: (oid: string) => void
}
const OrderDetailTopInfoCpn = (props: Props): ReactElement => {
    const {
        orderModel,
        airplaneChangeClick,
        airplaneRefundClick,
        trainChangeClick,
        trainRefundClick,
        hotelRefundClick,
    } = props
    const { orderType, orderState, customId, orderTime } = orderModel
    const _airplaneChange = () => {
        // 飞机改签
        airplaneChangeClick?.()
    }
    const _airplaneRefund = () => {
        // 飞机退票
        airplaneRefundClick?.()
    }

    const _trainChange = () => {
        // 火车改签
        trainChangeClick?.()
    }
    const _trainRefund = () => {
        // 火车退票
        trainRefundClick?.()
    }

    const _hotelRefund = () => {
        // 酒店退订
        hotelRefundClick?.(orderModel.oid || "")
    }

    return (
        <div>
            <Card
                title={
                    <OrderStatusStyle
                        orderType={orderType}
                        orderState={orderState}
                    />
                }
                bordered={false}
                style={{ marginBottom: "10px", borderRadius: "10px" }}
                styles={{
                    header: {
                        fontSize: "20px",
                        backgroundColor: "rgb(248,248,248)",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                    }
                }}>
                <Row>
                    <Col flex="auto">
                        <div>
                            (对公)订单号：<span>{customId}</span>
                        </div>
                    </Col>
                    <Col flex="auto">
                        <div>预订时间：{orderTime}</div>
                    </Col>
                </Row>

                <ShowStateOperate
                    orderModel={orderModel}
                    airplaneChangeClick={_airplaneChange}
                    airplaneRefundClick={_airplaneRefund}
                    trainChangeClick={_trainChange}
                    trainRefundClick={_trainRefund}
                    hotelRefundClick={_hotelRefund}
                />
            </Card>
        </div>
    )
}
export default OrderDetailTopInfoCpn
