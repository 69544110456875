import { ReactElement, useEffect } from "react"
import { Tooltip, Typography, Space, Divider, Row, Col, message } from "antd"
import ClockCircleOutlined from "@ant-design/icons/lib/icons/ClockCircleOutlined"
import SwapRightOutlined from "@ant-design/icons/lib/icons/SwapRightOutlined"
import arrowImage from "./../image/arrow-box.png"
import dayjs from "dayjs"
import { orange } from "@ant-design/colors"
import { useAppSelector } from "../../../../../app/hooks"
import { useNavigate } from "react-router-dom"

export default function SiderContent(props: {
    passengerCount: number
}): ReactElement {
    const flight = useAppSelector(
        state => state.bookAirplane.airplaneParameters?.flight
    )
    const cabin = useAppSelector(
        state => state.bookAirplane.airplaneParameters?.cabin
    )

    const { Text, Paragraph } = Typography
    const { passengerCount } = props
    const takeoffMoment = dayjs(flight?.takeOffTime, "YYYY-MM-DD")
    const landingMoment = dayjs(flight?.landingTime, "YYYY-MM-DD")
    const day = landingMoment.diff(takeoffMoment, "days")
    // 跳转传参
    const navi = useNavigate()

    useEffect(() => {
        if (!flight && !cabin) {
            message.warning("缺少参数")
            navi(-1)
        }
    }, [cabin, flight, navi])

    return (
        <div style={{ textAlign: "center" }}>
            <Space direction="vertical" size={5}>
                <Space
                    align="center"
                    size={19}
                    style={{ fontSize: 16 }}
                    direction="horizontal">
                    <Text strong>{flight?.flightDate?.slice(5, 10)}</Text>
                    <Text strong>{flight?.takeOffCityName}</Text>&nbsp;
                    <Text strong>
                        <SwapRightOutlined />
                    </Text>
                    &nbsp;
                    <Text strong>{flight?.toCityName}</Text>
                </Space>
                <Space direction="horizontal">
                    <Text type="secondary">
                        <img
                            alt=""
                            height="16"
                            width="16"
                            src="//pic.c_ctrip.com/flight_intl/airline_logo/32/ho.png"
                        />{" "}
                        {flight?.airlineName} {flight?.flightNum}
                    </Text>
                    <Text type="secondary">
                        {flight?.planeType}
                        {flight?.planeSize}
                    </Text>
                    <Text type="secondary">{cabin?.cabinGrade}</Text>
                    <Text type="secondary">
                        {flight?.meal && flight?.meal === "true"
                            ? "有餐饮"
                            : "无餐饮"}
                    </Text>
                    <Text type="secondary" style={{ color: orange[5] }}>
                        {flight?.shareFlag === "Y" ? "共享" : ""}
                    </Text>
                </Space>
                <Space direction="horizontal">
                    <Space direction="vertical">
                        <Text strong style={{ fontSize: 24 }}>
                            {flight?.takeOffTime?.slice(11, 16)}
                        </Text>
                        <Paragraph
                            ellipsis={{
                                rows: 2,
                                tooltip: flight?.takeOffPortname,
                            }}
                            style={{ maxWidth: 100, marginBottom: "0" }}>
                            {flight?.takeOffPortname}
                            {flight?.takeOffTerminal}
                        </Paragraph>
                    </Space>
                    <Space
                        direction="vertical"
                        style={{ maxWidth: "90px", padding: "0 12px" }}>
                        <Text type="secondary">
                            <ClockCircleOutlined />
                            {flight?.flightTime}
                        </Text>
                        <Text>
                            <img
                                style={{ width: "100%", marginTop: "8px" }}
                                src={arrowImage}
                                alt=""
                            />
                        </Text>
                    </Space>
                    <Space direction="vertical">
                        <Text strong style={{ fontSize: 24 }}>
                            {flight?.landingTime?.slice(11, 16)}
                            {day > 0 && (
                                <Tooltip
                                    title={`到达时间为第${
                                        day + 1
                                    } 天，${flight?.landingTime} `}
                                    color="gold"
                                    placement="topRight">
                                    <Text
                                        type="secondary"
                                        underline
                                        style={{ cursor: "help" }}>
                                        <small>+{day}天</small>
                                    </Text>
                                </Tooltip>
                            )}
                        </Text>
                        <Paragraph
                            ellipsis={{
                                rows: 2,
                                tooltip: flight?.landingPortName,
                            }}
                            style={{ maxWidth: 100, marginBottom: "0" }}>
                            {flight?.landingPortName}
                            {flight?.toTerminal}
                        </Paragraph>
                    </Space>
                </Space>
            </Space>
            <Divider />
            <Row gutter={[16, 5]} style={{ padding: "0 30px" }}>
                <Col span={24}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                        <Space size={5}>
                            <Text>成人套餐</Text>
                            <Text
                                style={{ cursor: "help" }}
                                underline
                                type="secondary">
                                退改
                                <Text italic type="secondary">
                                    ¥
                                </Text>
                                24起
                            </Text>
                            <Text
                                style={{ cursor: "help" }}
                                underline
                                type="secondary">
                                行李额{" "}
                            </Text>
                        </Space>
                        <div>
                            <Text>
                                <Text italic>¥</Text>
                                {parseInt(cabin?.finallyPrice || "") +
                                    parseInt(cabin?.serviceAmount || "")}
                            </Text>
                            <Text type="secondary">x {passengerCount}</Text>
                        </div>
                    </div>
                </Col>
                <Col span={24}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                        <Text type="secondary">成人 </Text>
                        <div>
                            <Text>
                                <Text italic>¥</Text>
                                {cabin?.finallyPrice || ""}
                            </Text>
                            <Text type="secondary">x {passengerCount}</Text>
                        </div>
                    </div>
                </Col>
                <Col span={24}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                        <Text type="secondary">服务费</Text>
                        <div>
                            <Text>
                                <Text italic>¥</Text>
                                {cabin?.serviceAmount}
                            </Text>
                            <Text type="secondary">x{passengerCount}</Text>
                        </div>
                    </div>
                </Col>
                <Col span={24}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                        <Text>成人机建费</Text>
                        <div>
                            <Text>
                                <Text italic>¥</Text>
                                {flight?.adultAirportPrice}
                            </Text>
                            <Text type="secondary">x {passengerCount}</Text>
                        </div>
                    </div>
                </Col>
                <Col span={24}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                        <Text>成人燃油税</Text>
                        <div>
                            <Text>
                                <Text italic>¥</Text>
                                {flight?.adultFuelPrice}
                            </Text>
                            <Text type="secondary">x {passengerCount}</Text>
                            {/* <div style={{ position: 'absolute', right: '0', top: '0', textAlign: 'right' }} className="SiderContent_corner">免费</div> */}
                        </div>
                    </div>
                </Col>
                <Col span={24}>
                    <Text
                        strong
                        style={{
                            display: "block",
                            color: orange[5],
                            fontSize: 30,
                            textAlign: "right",
                        }}>
                        <Text italic style={{ color: orange[5], fontSize: 20 }}>
                            ¥
                        </Text>
                        {parseInt(cabin?.finallyPrice || "") *
                            (passengerCount || 0) +
                            parseInt(cabin?.serviceAmount || "") *
                                (passengerCount || 0) +
                            parseInt(flight?.adultAirportPrice || "") *
                                (passengerCount || 0) +
                            parseInt(flight?.adultFuelPrice || "") *
                                (passengerCount || 0)}
                    </Text>
                </Col>
            </Row>
        </div>
    )
}
