import { DeleteOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Grid } from 'antd'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { deleteAccount } from './accountSlice'

type Props = {
    id: string, // 添加接收 id 的属性
    onFinished?: () => void
}

export default function AccountsDeleteButton({ id, onFinished }: Props) {
    const { md } = Grid.useBreakpoint()
    const deleteAccountLoading = useAppSelector(state => state.account.deleteAccountLoading)
    const dispatch = useAppDispatch()
    const [visible, setVisible] = useState(false)

    const handleConfirm = async (e?: React.MouseEvent) => {
        e?.stopPropagation()
        // 删除账目接口
        await dispatch(deleteAccount(id))
        setVisible(false)
        onFinished?.()
    }

    return (
        <Popconfirm
            title="要删除这条账目信息吗？"
            open={visible}
            onConfirm={handleConfirm}
            onCancel={(e) => {
                e?.stopPropagation()
                setVisible(false)
            }}
            okButtonProps={{ loading: deleteAccountLoading }}
            okType="danger"
            okText="删除"
            cancelText="取消">
            <Button
                type="link"
                size="small"
                danger
                onClick={(e) => {
                    e.stopPropagation()
                    setVisible(true)
                }}>
                <DeleteOutlined style={{ fontSize: md ? '25px' : '20px' }} />
            </Button>
        </Popconfirm >

    )
}
