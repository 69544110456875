import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import { persistedStore, store } from "./app/store"
import { Provider } from "react-redux"
import * as serviceWorker from "./serviceWorker"
import { PersistGate } from "redux-persist/integration/react"
import * as Sentry from "@sentry/browser"

if (window.SSCL_CLOUD)
    Sentry.init({
        dsn: "https://8b75d8b0309f48ac848fefe55a289e42@o4504687995191296.ingest.sentry.io/4504688041459712",
        integrations: [new Sentry.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        environment: window.SSCL_CLOUD,
    })

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate persistor={persistedStore}>
                <App />
            </PersistGate>
        </Provider>
    </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
