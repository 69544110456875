import { ReactElement, useEffect, useState } from "react"
import { connect } from "react-redux"
import { Select } from "antd"

import { AppDispatch, RootState } from "../../../app/store"
import { airplaneChangeReason } from "../service/orderModuleSlice"
import {
    FlightChangeReasonVModel,
    OrderListModel,
} from "../service/orderListModel"
const { Option } = Select

const mapStateToProps = (state: RootState) => ({
    reasonList: state.orderModule.reasonList,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    // 查询飞机改签退票原因
    flightChangeReason: (params: string) => {
        dispatch(airplaneChangeReason(params))
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        orderModel: OrderListModel
    } & {
        type: string // '1'改签   ’2‘退票
    } & {
        onChange?: (v: string) => void
    }

function reasonDataToOptions(
    data?: Array<FlightChangeReasonVModel>
): Array<ReactElement> {
    return data
        ? data.map(reason => (
              <Option key={reason.id} value={reason.dictValue}>
                  {reason.dictValue + "-" + reason.dictName}
              </Option>
          ))
        : []
}
const _reasonList = (props: Props): ReactElement => {
    const { type, flightChangeReason, reasonList, onChange } = props
    const [options, setOptions] = useState<Array<ReactElement>>()

    useEffect(() => {
        if (!reasonList) {
            flightChangeReason(type)
        }
    }, [flightChangeReason, type, reasonList])

    useEffect(() => {
        setOptions(reasonDataToOptions(reasonList))
    }, [reasonList])
    return (
        <div>
            <Select
                onChange={onChange}
                size="large"
                defaultValue={`请选择${type === "1" ? "改签" : "退票"}原因`}>
                {options}
            </Select>
        </div>
    )
}

export const ChangeRefundReasonList = connect(
    mapStateToProps,
    mapDispatchToProps
)(_reasonList)
