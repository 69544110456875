import { AccessToken, CommonLegacyResult, KeyValue } from "../models"
import { FetchTokenReq } from "./loginSlice"



export interface BusinessPurposesInfo {
    businessPurposesId: string
    purposes: string
    enable: boolean
}

export const fetchTokenViaAuthCodeApi = async (req: FetchTokenReq): Promise<AccessToken> => {
    const params = new URLSearchParams()
    params.set("grant_type", "authorization_code")
    params.set("code", req.code)
    params.set("redirect_uri", req.uri)
    const res = await fetch(`${window.SSCL_AUTH_API}/token`, {
        method: 'POST',
        headers: {
            'Authorization': `Basic ${window.btoa(window.SSCL_CLIENT_ID + ':' + window.SSCL_CLIENT_KEY)}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: params.toString()
    })
    const json = await res.json()
    if (!res.ok) return Promise.reject(json)
    return json as AccessToken
}

export const getKeyValueByKey = async (key: string): Promise<KeyValue> => {
    const res = await fetch(`${window.SSCL_API}/rest/keyValue/${key}`, { mode: "cors" })
    if (!res.ok) return Promise.reject(res.status)
    const ret = (await res.json()) as CommonLegacyResult<KeyValue>
    return ret.data ?? Promise.reject("empty data")
}

export const validateTokenApi = async ({ access_token }: AccessToken): Promise<void> => {
    const res = await fetch(`${window.SSCL_API}/user/token`, {
        method: 'get',
        mode: 'cors',
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    })
    if (!res.ok) {
        return Promise.reject(401)
    }
}
