import { Col, Image, Row } from "antd"
import { useState } from "react"

export default function HotelImages({ imgs }: { imgs: string[] }): JSX.Element {
    const [visible, setVisible] = useState(false)
    const [current, setCurrent] = useState(0)
    const handleClick = (i: number) => {
        setVisible(true)
        setCurrent(i)
    }

    return (
        <>
            <Row gutter={[8, 8]}>
                <Col xs={0} sm={0} md={12}>
                    <Image
                        className="common-img"
                        width="100%"
                        height={248}
                        placeholder={true}
                        src={imgs[0]}
                        preview={{ visible: false }}
                        onClick={() => handleClick(0)}
                    />
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Row gutter={[8, 8]}>
                        <Col span="8">
                            <Image
                                className="common-img"
                                placeholder={true}
                                src={imgs[1]}
                                width="100%"
                                height={120}
                                preview={{ visible: false }}
                                onClick={() => handleClick(1)}
                            />
                        </Col>
                        <Col span="8">
                            <Image
                                className="common-img"
                                placeholder={true}
                                src={imgs[2]}
                                width="100%"
                                height={120}
                                preview={{ visible: false }}
                                onClick={() => handleClick(2)}
                            />
                        </Col>
                        <Col span="8">
                            <Image
                                className="common-img"
                                placeholder={true}
                                src={imgs[3]}
                                width="100%"
                                height={120}
                                preview={{ visible: false }}
                                onClick={() => handleClick(3)}
                            />
                        </Col>
                        <Col span="8">
                            <Image
                                className="common-img"
                                placeholder={true}
                                src={imgs[4]}
                                width="100%"
                                height={120}
                                preview={{ visible: false }}
                                onClick={() => handleClick(4)}
                            />
                        </Col>
                        <Col span="8">
                            <Image
                                className="common-img"
                                placeholder={true}
                                src={imgs[5]}
                                width="100%"
                                height={120}
                                preview={{ visible: false }}
                                onClick={() => handleClick(5)}
                            />
                        </Col>
                        <Col span="8">
                            <Image
                                className="common-img"
                                placeholder={true}
                                src={imgs[6]}
                                width="100%"
                                height={120}
                                preview={{ visible: false }}
                                onClick={() => handleClick(6)}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div style={{ display: "none" }}>
                <Image.PreviewGroup
                    preview={{
                        visible,
                        current,
                        onVisibleChange: vis => {
                            setVisible(vis)
                            setCurrent(0)
                        },
                    }}>
                    {imgs.map((img, i) => (
                        <Image key={i} src={img} />
                    ))}
                </Image.PreviewGroup>
            </div>
        </>
    )
}
