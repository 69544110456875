import { blue } from "@ant-design/colors"
import {
    Button,
    Col,
    Divider,
    Grid,
    Image,
    List,
    Row,
    Space,
    Typography,
} from "antd"
import { PageHeader } from "@ant-design/pro-layout"
import { ReactElement, useEffect } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { Navigate, useLocation } from "react-router-dom"
import { AppDispatch, RootState } from "../../../../app/store"
import { cleanHotelState, hotelList } from "../hotelSlice"
import TopSearch, { HotelListQueryArgs } from "./TopSearch"

const { Title, Text } = Typography

const mapStateToProps = (state: RootState) => ({
    loading: state.bookHotel.hotelLoading,
    hasMore: state.bookHotel.hotelListHasMore,
    list: state.bookHotel.hotelList,
    dirty: !!state.booking.hotelCity,
    noSelectApplyfor: !state.booking.applyForm,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    // 酒店列表查询
    findHotelList: (args?: unknown) => {
        dispatch(hotelList(args ? (args as HotelListQueryArgs) : undefined))
    },
    // 清除酒店状态
    deleteHotelState: () => {
        dispatch(cleanHotelState())
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

function _list({
    dirty,
    list,
    loading,
    hasMore,
    noSelectApplyfor,
    findHotelList,
    deleteHotelState,
}: Props): ReactElement {
    const location = useLocation()
    // 个性化布局
    const { md } = Grid.useBreakpoint()

    useEffect(() => {
        if (dirty) {
            // 清除酒店状态
            deleteHotelState()
        }
    }, [dirty, deleteHotelState])

    const handleLoadMore = () => {
        // 酒店列表查询
        findHotelList()
    }

    const stars = (str?: string): string => {
        if (!str) return "无评级"
        switch (str) {
            case "1":
                return "一星级"
            case "2":
                return "二星级"
            case "3":
                return "三星级"
            case "4":
                return "四星级"
            case "5":
                return "五星级"
            default:
                return "无评级"
        }
    }

    if (noSelectApplyfor) {
        return (
            <Navigate
                to="/booking/setup"
                state={{ referrer: location.pathname }}
            />
        )
    }

    return (
        <>
            <PageHeader ghost={false}>
                <TopSearch onSearch={findHotelList} loading={loading} />
            </PageHeader>
            <div className="container">
                <List
                    loadMore={
                        hasMore && (
                            <Button
                                onClick={handleLoadMore}
                                loading={loading}
                                block={true}
                                children="加载更多"
                            />
                        )
                    }
                    loading={loading}
                    dataSource={list}
                    split={true}
                    renderItem={item => {
                        return (
                            <List.Item>
                                <Row style={{ width: "100%" }} gutter={16}>
                                    <Col flex="none 150px">
                                        <Link
                                            to={
                                                "/booking/hotel/detail/" +
                                                item.id
                                            }>
                                            <Image
                                                className="common-img"
                                                width={150}
                                                height={150}
                                                src={
                                                    item.picture ||
                                                    item.bigPicture
                                                }
                                                placeholder={true}
                                                fallback="/default-pic.svg"
                                                preview={false}
                                            />
                                        </Link>
                                    </Col>
                                    <Col flex="1 300px">
                                        <Title level={5}>
                                            {item.hotelName}
                                        </Title>
                                        <Space direction="vertical">
                                            <Text type="secondary">
                                                {stars(item.stars)}
                                            </Text>
                                            <Text>{item.address}</Text>
                                        </Space>
                                    </Col>
                                    <Col hidden={!md} flex="none">
                                        <Divider
                                            style={{ height: "100%" }}
                                            type="vertical"
                                        />
                                    </Col>
                                    <Col flex="none 200px">
                                        <Space direction="vertical">
                                            {item.lowestPrice > 0 && (
                                                <Text>
                                                    ￥
                                                    <Text
                                                        style={{
                                                            fontSize: 20,
                                                            color: blue[5],
                                                        }}
                                                        strong>
                                                        {item.lowestPrice}
                                                    </Text>
                                                    &nbsp;起
                                                </Text>
                                            )}
                                            <Button type="primary" ghost>
                                                <Link
                                                    to={
                                                        "/booking/hotel/detail/" +
                                                        item.id
                                                    }>
                                                    查看详情
                                                </Link>
                                            </Button>
                                        </Space>
                                    </Col>
                                </Row>
                            </List.Item>
                        )
                    }}
                />
            </div>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(_list)
