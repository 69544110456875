import { ReactElement } from "react"
import { Card, Typography, Badge } from "antd"
import { CheckCircleTwoTone } from "@ant-design/icons"
const { Title } = Typography

interface Props {
    name: string
}
export const ShowPassenger = (props: Props): ReactElement => {
    const { name } = props
    return (
        <Card style={{ display: "flex" }}
        styles={{ body: { padding: 0 ,margin: 0  } }}>
            <Card.Grid style={{ flex: "1 0 fit-content" }}>
                <Badge offset={[10, 12]} count={<CheckCircleTwoTone />}>
                    <Typography.Text style={{ padding: 0 }}>
                        <Title level={5}>{name}</Title>
                    </Typography.Text>
                </Badge>
            </Card.Grid>
        </Card>
    )
}
