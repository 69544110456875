import { ReactElement } from "react"
import { Card, Row, Col } from "antd"
import { TrainSeatItemVModel } from "../services/bookTrainSlice"
import { red } from "@ant-design/colors"

interface Props {
    seat: TrainSeatItemVModel
    passengerCount: number
    mode: "book" | "change"
}

export const PayConfirmPrice = (props: Props): ReactElement => {
    const { seat, passengerCount, mode } = props
    const tipTitle = mode === "book" ? "预订支付-订单总额" : "改签支付-待付款"
    return (
        <Card
            bordered={false}
            title={
                <span>
                    {tipTitle}:
                    {
                        <span
                            style={{
                                fontSize: 20,
                                color: red[5],
                                marginLeft: 10,
                            }}>
                            <dfn>¥</dfn>
                            {seat.price * passengerCount +
                                seat.serviceAmount * passengerCount || 0}
                        </span>
                    }{" "}
                </span>
            }
            style={{ marginTop: 10 }}>
            <Row>
                <Col span={10}>成人票</Col>
                <Col span={4} style={{ textAlign: "right" }}>{`x${
                    passengerCount || 0
                }`}</Col>
                <Col span={10} style={{ textAlign: "right" }}>
                    <span>
                        <dfn>¥</dfn>
                        {seat.price}
                    </span>
                </Col>
            </Row>
            {seat.serviceAmount > 0 && (
                <Row>
                    <Col span={10}>服务费</Col>
                    <Col
                        span={4}
                        style={{
                            textAlign: "right",
                        }}>{`x${passengerCount}`}</Col>
                    <Col span={10} style={{ textAlign: "right" }}>
                        <span>
                            <dfn>¥</dfn>
                            {seat.serviceAmount}
                        </span>
                    </Col>
                </Row>
            )}
        </Card>
    )
}
