import { Button, Input, Space } from "antd"
import { MLocation } from "../../models"

type DestTagProps = {
    searchResult?: MLocation[]
    search?: (e: string) => void
    destItemOnClick?: (it: MLocation) => () => void
}

const DestinationChildren = ({
    search,
    searchResult,
    destItemOnClick,
}: DestTagProps) => {
    return (
        <>
            <Input
                onChange={e => search?.(e.target.value)}
                placeholder="输入城市名/拼音/缩写搜索"
            />
            <Space
                style={{
                    paddingTop: "10px",
                    maxHeight: "60vh",
                    overflowY: "auto",
                    flexWrap: "wrap",
                }}
                direction="horizontal">
                {searchResult?.map(it => (
                    <Button
                        key={`destSearch${it.depthPath}`}
                        type="text"
                        onClick={destItemOnClick?.(it)}>
                        {it.name}
                    </Button>
                ))}
            </Space>
        </>
    )
}

export default DestinationChildren
