import { ReactElement, useState } from "react"
import { Row, Col, Button, Modal, Space, Typography } from "antd"
import {
    TrainOrderListModel,
    TrainOrderListTrainModel,
} from "../../service/orderListModel"
import { TrainOrderDetailScheduleCpn } from "./TrainOrderDetailScheduleCpn"
import { TrainOrderScheduleParams } from "../../service/orderModuleApi"
import { runTimeStr } from "../../../book/const"
import { blue } from "@ant-design/colors"
export interface TrainJourneyItem {
    from?: string
    time?: string
    date?: string
}
const { Text, Paragraph } = Typography
export const TrainJourneyInfoCpn = (props: TrainJourneyItem): ReactElement => {
    const { from, time, date } = props

    return (
        <Space direction="vertical" align="center">
            <Paragraph
                ellipsis={{ rows: 2, tooltip: from }}
                strong
                style={{ maxWidth: 80, fontSize: "20px", marginBottom: "0" }}>
                {" "}
                {from}
            </Paragraph>
            <Text strong style={{ fontSize: "16px" }}>
                {time}
            </Text>
            <Text>{date}</Text>
        </Space>
    )
}

function TrainJourneyTimeCpn(props: TrainOrderListTrainModel) {
    const { runTimeSpan, fromStation, toStation, trainNo, trainDate } = props
    const scheduleParam: TrainOrderScheduleParams = {
        fromStation: fromStation,
        toStation: toStation,
        trainNo: trainNo,
        trainDate: trainDate,
    }

    const [showStation, setShowStation] = useState(false)

    return (
        <Space direction="vertical">
            <Text style={{ color: blue[5] }}>{trainNo}</Text>
            <Button
                size="small"
                shape="round"
                onClick={() => setShowStation(true)}>
                时刻表
            </Button>
            <Modal
                title="时刻表"
                open={showStation}
                closable={true}
                footer={null}
                onCancel={() => setShowStation(false)}>
                <TrainOrderDetailScheduleCpn params={scheduleParam} />
            </Modal>
            <Text>{runTimeStr(runTimeSpan)}</Text>
        </Space>
    )
}

export default function TrainOrderDetailJourneyCpn(
    props: TrainOrderListModel
): ReactElement {
    const { train } = props

    return (
        <Row style={{ textAlign: "center" }}>
            <Col flex="1">
                {" "}
                {
                    <TrainJourneyInfoCpn
                        from={train?.fromStation}
                        date={train?.departureDate}
                        time={train?.fromTime}
                    />
                }
            </Col>
            <Col flex="auto">
                <TrainJourneyTimeCpn {...train} />
            </Col>
            <Col flex="1">
                {" "}
                {
                    <TrainJourneyInfoCpn
                        from={train?.toStation}
                        date={train?.arriveDate}
                        time={train?.toTime}
                    />
                }
            </Col>
        </Row>
    )
}
