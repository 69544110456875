import { ReactElement, useState, useEffect } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { connect } from "react-redux"
import {
    Layout,
    Card,
    Button,
    Popconfirm,
    message,
    Modal,
    Form,
    Input,
    Typography,
    Descriptions,
    Alert,
} from "antd"
import { PageHeader } from "@ant-design/pro-layout"
import { RootState, AppDispatch } from "../../../app/store"
import { TrainOrderListModel } from "../service/orderListModel"
import TrainOrderDetailRulesCpn from "../detail/components/TrainOrderDetailRulesCpn"
import TrainOrderDetailJourneyCpn from "../detail/components/TrainOrderDetailJourneyCpn"
import TrainOrderDetailPassengerCpn from "../detail/components/TrainOrderDetailPassengerCpn"
import {
    deleteTrainResInfo,
    fetchRefundTrain,
} from "../../book/train/services/bookTrainSlice"
import { orange } from "@ant-design/colors"
const { Text } = Typography
const mapStateToProps = (state: RootState) => ({
    refundLoading: state.bookTrain.refundLoading,
    trainRes: state.bookTrain.trainRes,
    specialTrainNotice: state.location.tipMessage?.specialTrainNotice,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    // 火车退票
    refundTrain: (p: { oid: string; verificationCode?: string }) => {
        dispatch(fetchRefundTrain(p))
    },
    // 删state里的数据，清除状态
    deleteRes: () => {
        dispatch(deleteTrainResInfo())
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

const _ticketOperate = ({
    refundTrain,
    refundLoading,
    trainRes,
    deleteRes,
    specialTrainNotice,
}: Props): ReactElement => {
    //路由跳转传参
    const navi = useNavigate()
    const location = useLocation()
    //通过useLocation把Order.tsx订单列表页面的item接收过来
    const model = location.state as TrainOrderListModel
    // 获取路由携带参数
    const { id } = useParams<{ id: string }>()
    // 改签退票确认框开关
    const [visible, setVisible] = useState<boolean>(false)
    // 对话框开关
    const [mVisible, setMVisible] = useState<boolean>(false)
    const [codeRequire, setCodeRequire] = useState<boolean>(false)
    // 创建 Form 实例，用于管理所有数据状态
    const [form] = Form.useForm()
    // 提示信息开关
    const [tipsvisible, setTipsvisible] = useState(true)
    const handleOk = () => {
        // 关闭改签退票确认框
        setVisible(false)
        if (id === "1") {
            // 改签
            // 通过useNavigate携带参数跳转页面TrainList.tsx
            navi("/booking/train", { state: model })
        } else {
            // 火车退票
            refundTrain({ oid: model.oid || "" })
        }
    }
    const handleModalOk = () => {
        setMVisible(false) // 关闭对话框
        // getFieldValue获取对应字段名的值
        const code = form.getFieldValue("verificationCode")
        //火车退票
        refundTrain({ oid: model.oid || "", verificationCode: code })
    }

    useEffect(() => {
        if (trainRes && trainRes.rc === 0) {
            message.success(trainRes.msg)
        } else if (
            trainRes &&
            trainRes.rc === 1 &&
            trainRes.data &&
            (trainRes.data as { code: number; msg: string }).code === -2
        ) {
            setCodeRequire(true)
            setMVisible(true) //打开对话框
        } else if (trainRes && trainRes.rc === 1) {
            Modal.warning({ content: `${trainRes?.msg}` })
        }
        return () => {
            // 卸载 火车删的是state里的数据，清除状态
            deleteRes()
        }
    }, [deleteRes, trainRes])

    const handleCancel = () => {
        // 关闭改签退票确认框
        setVisible(false)
    }
    const handleModalCancel = () => {
        setMVisible(false)
    }

    return (
        <Layout>
            <PageHeader
                ghost={false}
                title={`我要${id === "1" ? "改签" : "退票"}`}
                subTitle="火车"
                onBack={() => {
                    navi(-1)
                }}></PageHeader>
            {specialTrainNotice
                ? tipsvisible && (
                    <Alert
                        banner
                        message={
                            <Text style={{ color: orange[5] }}>
                                {specialTrainNotice}
                            </Text>
                        }
                        closable
                        afterClose={() => setTipsvisible(false)}
                    />
                )
                : null}
            <div className="container">
                <Descriptions title="行程信息" items={[{
                    contentStyle: { display: 'flow-root' },
                    children: <TrainOrderDetailJourneyCpn {...model} />
                }]} />
                <Descriptions title="退改签规则" items={[{
                    contentStyle: { display: 'flow-root' },
                    children: <TrainOrderDetailRulesCpn />
                }]} />
                <Descriptions title="乘机人" items={[{
                    contentStyle: { display: 'flow-root' },
                    children: <TrainOrderDetailPassengerCpn {...model} />
                }]} />
                <Card bordered={false}>
                    <Popconfirm
                        title={`温馨提示:确定要${id === "1" ? "改签" : "退票"
                            }吗？`}
                        open={visible}
                        onConfirm={handleOk}
                        okButtonProps={{ loading: refundLoading }}
                        onCancel={handleCancel}>
                        <Button
                            type="primary"
                            size="large"
                            block
                            onClick={() => {
                                setVisible(true)
                            }}>
                            {" "}
                            {`${id === "1" ? "下一步,选择改签日期" : "确定退票"
                                }`}{" "}
                        </Button>
                    </Popconfirm>

                    <Form form={form}>
                        {codeRequire && (
                            <Modal
                                title={`温馨提示:退票账户验证`}
                                open={mVisible}
                                onOk={handleModalOk}
                                destroyOnClose
                                confirmLoading={refundLoading}
                                onCancel={handleModalCancel}>
                                {
                                    <div>
                                        <Text>
                                            编辑短信内容666发送到12306,返回的验证码输入下框中
                                        </Text>
                                        <Form.Item
                                            name={"verificationCode"}
                                            label="验证码"
                                            rules={[{ required: true }]}
                                            style={{ width: "100%" }}>
                                            <Input placeholder="编辑短信内容666发送到12306,输入返回的验证码" />
                                        </Form.Item>
                                    </div>
                                }
                            </Modal>
                        )}
                    </Form>
                </Card>
            </div>
        </Layout>
    )
}

export const TicketOperate = connect(
    mapStateToProps,
    mapDispatchToProps
)(_ticketOperate)
