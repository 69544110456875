import { Modal } from "antd"
import { AccessToken, CommonLegacyResult, DataPage, PageRequest } from "../models"
import { Accounts, ReimburseList, ReimburseForm } from "./listSlice"
import { Attachments, InvoiceTypeList, StaffUserList } from "./editorSlice"
import { getKeyValueByKey } from "../user/userAPI"
import { ReimburesForm, ReviseReimburseForm } from "../models/reimburesForm"
import { FileType } from "../account/api"

export interface PayAccount {
    arguments?: {
        corpId?: string
        appKey?: string
        appSecret?: string
        subAccountName1?: string
    }
    paId: number
    packageId: string
    companyId: string
    name: string
    account: string
    token: string
    arg1?: string
    createTime: string
    enable: boolean
    orderTaxRate?: {
        planeOrderTaxRate: number
        hotelOrderTaxRate: number
        trainOrderTaxRate: number
        taxiOrderTaxRate: number
    }
    availableType?: Array<number>
    selectable: boolean
}


export type ApprovalsStateType = 'approved' | "draft" | "submitted"
//报销单列表查询
export const findAllApi = async ({ access_token }: AccessToken, query: { state?: ApprovalsStateType } & PageRequest):
    Promise<DataPage<ReimburseForm>> => {
    const search = new URLSearchParams()
    const { state, page, size } = query
    switch (state) {
        case 'approved': {
            search.append("approvalState", "1")
            break
        }
        case 'submitted': {
            search.append("approvalState", "0")
            search.append("approvalState", "1")
            search.append("approvalState", "2")
            break
        }
        case 'draft': {
            search.set("approvalState", "-1")
            break
        }
    }
    search.set("page", `${page}`)
    search.set("size", `${size}`)

    const res = await fetch(`${window.SSCL_API}/rest/reimburse/findByState?${search.toString()}`, {
        mode: "cors",
        method: "GET",
        headers: {
            'Authorization': `Bearer ${access_token}`,
        },
    })
    if (!res.ok) return Promise.reject(res.status)
    const body = await res.json() as CommonLegacyResult<DataPage<ReimburseForm>>
    if (body.rc !== 0) {
        Modal.warning({ content: `${body.msg}` })
        return Promise.reject(null)
    }
    if (!body.data) return Promise.reject(null)
    return body.data
}

// 查询报销单详情
export const findReimburseApi = async ({ access_token }: AccessToken, id: string): Promise<ReimburseList> => {
    const res = await fetch(`${window.SSCL_API}/rest/reimburse/${id}`, {
        mode: "cors",///跨域请求
        method: "GET",
        headers: {
            'Authorization': `Bearer ${access_token}`,
        },
    })
    if (!res.ok) {
        return Promise.reject(res.status)
    }

    const body = (await res.json()) as CommonLegacyResult<ReimburseList>
    if (!body.data) return Promise.reject()
    return body.data
}

// 公司支付单位
export const fetchPayAccountApi = async ({ access_token }: AccessToken, companyId: string): Promise<PayAccount[]> => {
    const res = await fetch(`${window.SSCL_API}/rest/company/${companyId}/payAccount`, {
        mode: "cors",
        method: 'get',
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    })
    if (!res.ok) {
        return Promise.reject(res.status)
    }
    const json = await res.json()
    if (!json) return Promise.reject("empty body")
    const data = json as CommonLegacyResult<PayAccount[]>
    return data.data ?? Promise.reject("empty data")
}

// 关联账目接口
export const findByReimburseIdApi = async ({ access_token }: AccessToken, query: PageRequest): Promise<DataPage<Accounts>> => {
    const { page, size } = query
    const res = await fetch(`${window.SSCL_API}/rest/account/findByReimburseId?exists=${false}&page=${page}&size=${size}`, {
        method: 'GET',
        mode: 'cors',//跨域请求
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        }
    })
    if (!res.ok) return Promise.reject(res.status)
    const body = await res.json() as CommonLegacyResult<DataPage<Accounts>>
    if (!body.data) return Promise.reject(null)
    return body.data
}

// 发票类型
export const InvoiceTypeApi = async ({ access_token }: AccessToken, orderType: string): Promise<InvoiceTypeList[]> => {
    const res = await fetch(`${window.SSCL_API}/rest/dict/data/invoice_type_${orderType}`, {
        method: 'get',
        mode: 'cors',
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        },
    })
    if (!res.ok) return Promise.reject(401)
    const body = await res.json() as CommonLegacyResult<InvoiceTypeList[]>
    if (!body.data) return Promise.reject(null)
    return body.data
}



// 火车座椅
export const getTrainSeats = async (): Promise<string[]> => {
    const tipMessage = await getKeyValueByKey("app-dict-train-seats")
    const url = JSON.parse(tipMessage.value)
    return url
}


// 飞机座椅
export const getFlightSeats = async (): Promise<string[]> => {
    const tipMessage = await getKeyValueByKey("app-dict-flight-seats")
    const url = JSON.parse(tipMessage.value)
    return url
}


export const fetchCustomerListApi = async ({ access_token }: AccessToken, query: { authLocked?: boolean, companyId?: string } & PageRequest):
    Promise<DataPage<StaffUserList>> => {
    const { page, size, authLocked, companyId, staffUserName } = query
    const search = new URLSearchParams()
    if (page) search.set("page", `${page}`)
    if (size) search.set("size", `${size}`)

    const res = await fetch(`${window.SSCL_API}/v3/staff/search?${search}`, {
        mode: "cors",
        method: "POST",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        },
        body: JSON.stringify({ authLocked, companyId, staffUserName })
    })
    const body = await res.json() as CommonLegacyResult<DataPage<StaffUserList>>
    if (!body.data) return Promise.reject(null)
    return body.data
}

// 保存报销单
export const postReimburesForm = async ({ access_token }: AccessToken, reimbures: ReimburesForm): Promise<ReimburesForm> => {
    const res = await fetch(`${window.SSCL_API}/forms/reimburse`, {
        mode: "cors",
        method: "POST",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(reimbures)
    })
    if (!res.ok) {
        return Promise.reject(res.status)
    }
    return await res.json()
}
// 报销单规则校验接口
export const updatePreCheckApi = async ({ access_token }: AccessToken, reimburseId: string,): Promise<void> => {
    const res = await fetch(`${window.SSCL_API}/forms/reimburse/${reimburseId}/validate`, {
        mode: "cors",
        method: "PUT",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json",
        },
    })
    if (!res.ok) return Promise.reject(await res.json())
}
// 更新报销单 /forms/reimburse/{id}
export const patchReimburse = async ({ access_token }: AccessToken, id: string, patchBody: ReimburesForm): Promise<void> => {
    const res = await fetch(`${window.SSCL_API}/forms/reimburse/${id}`, {
        mode: "cors",
        method: "PUT",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(patchBody)
    })
    if (!res.ok) return Promise.reject(await res.json())
}
// 更新账目
export const patchAccounts = async ({ access_token }: AccessToken, id: string, patchBody: string[]): Promise<void> => {
    const res = await fetch(`${window.SSCL_API}/rest/reimburse/${id}/accounts`, {
        mode: "cors",
        method: "PUT",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(patchBody)
    })
    if (!res.ok) return Promise.reject(await res.json())
}
// 批量更新账目关联的报销单信息
export const postAccounts = async ({ access_token }: AccessToken, mode: string, accounts: Accounts[]): Promise<Accounts[]> => {

    const res = await fetch(`${window.SSCL_API}/rest/account/batch`, {
        mode: "cors",
        method: "POST",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        },
        body: mode === 'update' ? JSON.stringify({ update: accounts }) : ''
    })
    if (!res.ok) {
        return Promise.reject(res.status)
    }
    return await res.json()
}


// 保存附件
export const postAnnexes = async ({ access_token }: AccessToken, t: Attachments): Promise<void> => {
    const formData = new FormData()
    const { reimburseId, files } = t
    files?.forEach((file) => {
        if (file.originFileObj) {
            formData.append('files', file.originFileObj as FileType)
        }
    })
    const res = await fetch(`${window.SSCL_API}/v3/reimburse/${reimburseId}/upload`, {
        method: "POST",
        mode: "cors",
        headers: {
            'Authorization': `Bearer ${access_token}`,
        },
        body: formData
    })

    if (!res.ok) {
        return Promise.reject(res.status)
    }
    return await res.json()
}

// 报销单详情
export const findReimburseIdApi = async ({ access_token }: AccessToken, id: string): Promise<ReviseReimburseForm> => {
    const res = await fetch(`${window.SSCL_API}/forms/reimburse/${id}`, {
        mode: "cors",///跨域请求
        method: "GET",
        headers: {
            'Authorization': `Bearer ${access_token}`,
        },
    })
    if (!res.ok) return Promise.reject(res.status)

    const body = (await res.json()) as ReviseReimburseForm

    return body
}

// 删除报销单
export const deleteReimburesByDocumentId = async ({ access_token }: AccessToken, documentId: string): Promise<void> => {
    const res = await fetch(`${window.SSCL_API}/forms/reimburse/${documentId}`, {
        mode: "cors",
        method: "DELETE",
        headers: {
            'Authorization': `Bearer ${access_token}`,
        },
    })
    if (!res.ok) {
        return Promise.reject(res.status)
    }
}
