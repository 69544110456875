import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit'
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import seesionStorage from 'redux-persist/lib/storage/session'
import layoutReducer from '../features/menu/menuSlice'
import userReducer from '../features/user/userSlice'
import orderModuleReducer from '../features/order/service/orderModuleSlice'
import loginReducer from '../features/user/loginSlice'
import travellerReducer from '../features/traveller/slice'
import staffReducer from '../features/staff/staffSlice'
import applyFormListReducer from '../features/applyForm/listSlice'
import applyFormEditorReducer from '../features/applyForm/editorSlice'
import applyFormViewerReducer from '../features/applyForm/viewerSlice'
import applyFormTodoListReducer from '../features/approval/applyFormTodoListSlice'
import applyFormProjectSelectorReducer from '../features/applyForm/editor/projectSlice'
import bookReducer from '../features/book/bookSlice'
import bookHotelReducer from '../features/book/hotel/hotelSlice'
import bookAirplaneReducer from '../features/book/airplane/services/bookAirplaneSlice'
import applyFormApprovalReducer from '../features/approval/applyFormApprovalSlice'
import bookTrainReducer from '../features/book/train/services/bookTrainSlice'
import locationReducer from '../features/location/slice'
import supplierReducer from '../features/supplier/supplierSlice'
import reimburseListReducer from '../features/reimburseForm/listSlice'
import reimburesEditorReducer from '../features/reimburseForm/editorSlice'
import accountReducer from '../features/account/accountSlice'
import reimburseFormViewerReducer from '../features/reimburseForm/viewerSlice'


const rootReducer = combineReducers({
    layout: layoutReducer,
    login: persistReducer({
        key: 'root',
        version: 1,
        storage,
        whitelist: ["accessToken", "redirectUri"]
    }, loginReducer),
    user: persistReducer({
        key: 'user',
        version: 1,
        storage: seesionStorage,
    }, userReducer),
    location: persistReducer({
        key: 'location',
        version: 1,
        storage: seesionStorage,
        whitelist: ["tipMessage"],
    }, locationReducer),
    traveller: travellerReducer,
    staff: staffReducer,
    applyFormList: applyFormListReducer,
    applyFormViewer: applyFormViewerReducer,
    applyFormProjectSelector: applyFormProjectSelectorReducer,
    applyFormEditor: applyFormEditorReducer,
    applyFormTodoList: applyFormTodoListReducer,
    applyFormApproval: applyFormApprovalReducer,
    orderModule: persistReducer({
        key: "orderModule",
        version: 1,
        storage: seesionStorage,
        whitelist: ["tabKey"],
    }, orderModuleReducer),
    bookAirplane: bookAirplaneReducer,
    bookTrain: bookTrainReducer,
    booking: bookReducer,
    bookHotel: bookHotelReducer,
    supplier: persistReducer({
        key: 'supplier',
        version: 1,
        storage: seesionStorage,
        whitelist: ["supplierIcon"],
    }, supplierReducer),
    reimburseList: reimburseListReducer,
    reimburesEditor: reimburesEditorReducer,
    account: accountReducer,
    reimburseFormViewer:reimburseFormViewerReducer,
})

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
})

export const persistedStore = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof rootReducer>
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
