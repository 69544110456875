import { CommonLegacyResult, CommonPageConent, AccessToken } from "../../models"
import { FlightChangeReasonVModel, OrderListModel } from './orderListModel'
import { OrderTypePageRequest, PlaneRefundRulesVModel } from './orderModuleSlice'

// 飞机详情 飞机退改签规则 入参
export interface AirplaneOrderRefundRulesParams {
    flightDate?: string,
    supplierId?: string,
    airline?: string,
    productType?: string,
    initTicketPrice?: string,
    flightNum?: string,
    cabin?: string,
    takeOffPort?: string,
    landingPort?: string,
    applyForId?: string,
}

// 火车详情 火车时刻表 入参
export interface TrainOrderScheduleParams {
    fromStation?: string,
    toStation?: string,
    trainNo?: string,
    trainDate?: string
}
// 火车详情 火车时刻表
export interface TrainStopStationsVModel {
    arrivalTime?: string     //到达该站点时间
    createDate?: string
    departureTime?: string   //该站点出发时间
    fromStation?: string     //出发站
    id?: string
    station?: string         //站点名称
    stationNo?: string       //车次序号
    stayTimeSpan?: string    //停留时长
    toStation?: string       //到达站
    trainDate?: string       //出发日期
    trainNo?: string         //车次号
    updateDate?: string
}

//---api
export const fetchOrderListApi = async ({ access_token }: AccessToken, param: OrderTypePageRequest): Promise<CommonPageConent<OrderListModel> | undefined> => {
    const res = await fetch(`${window.SSCL_API}/rest/orderInfo/orderType/${param.orderType}/source/0/nonAccount?page=${param.page ? param.page : 0}&size=${param.size ? param.size : 30}`, {
        mode: "cors",
        method: "get",
        headers: {
            'Content-Type': "application/json;charset=UTF-8",
            'Authorization': `Bearer ${access_token}`
        }
    })

    if (!res.ok) return Promise.reject(res)
    const body = await res.json() as CommonLegacyResult<CommonPageConent<OrderListModel>>

    return body.data
}

// 查询飞机详情中的退改签规则
export const fetchPlaneRefundRulesApi = async ({ access_token }: AccessToken, params: AirplaneOrderRefundRulesParams): Promise<PlaneRefundRulesVModel> => {

    const res = await fetch(`${window.SSCL_PLATFORM_API}/platform/open/v1/flight/rules`, {

        method: "POST",
        headers: {
            'Content-Type': "application/json;charset=UTF-8",
            'Authorization': `Bearer ${access_token}`
        },
        body: JSON.stringify(params)
    })


    if (!res.ok) return Promise.reject(res)
    const body = await res.json() as CommonLegacyResult<PlaneRefundRulesVModel>

    return body.data || {}
}

// 查询火车详情中的时刻表
export const fetchOrderTrainScheduleApi = async ({ access_token }: AccessToken, param: TrainOrderScheduleParams): Promise<Array<TrainStopStationsVModel>> => {
    const res = await fetch(`${window.SSCL_PLATFORM_API}/platform/open/v1/train/stopStations`, {
        method: "POST",
        mode: "cors",///跨域请求
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        },
        body: JSON.stringify(param)
    })

    if (!res.ok) return Promise.reject(res)
    const body = await res.json() as CommonLegacyResult<Array<TrainStopStationsVModel>>

    return body.data || []
}

// ---------------------订单操作
// 飞机火车的退改签；酒店的取消预订退订
// 飞机改签原因
export const fetchChangeReasonApi = async ({ access_token }: AccessToken, type: string): Promise<FlightChangeReasonVModel[]> => {
    const urlType = type === '1' ? 'flight_change_type' : 'flight_refund_type'
    const res = await fetch(`${window.SSCL_API}/rest/dict/data/${urlType}`, {
        mode: "cors",
        method: "get",
        headers: {
            'Content-Type': "application/json;charset=UTF-8",
            'Authorization': `Bearer ${access_token}`
        }
    })

    if (!res.ok) return Promise.reject(res)
    const body = await res.json() as CommonLegacyResult<FlightChangeReasonVModel[]>
    return body.data || []
}
