import { CommonLegacyResult, AccessToken } from "../../models"

export interface HotelCity {
    id: string
    cityName: string
    country: string
    pinyinName: string
}

export interface HotelKeyword {
    lat?: number
    lon?: number
    name: string
    type: "1" | "3"
}

export interface HotelKeywordQuery {
    depthPath: string
    keyword: string
}

export interface HotelListQuery {
    pageNo: number
    depthPath: string
    checkInDate: string
    checkOutDate: string
    companyId: string
    latitude?: string
    longitude?: string
    roomPriceStart?: number
    roomPriceEnd?: number
    stars?: string
    hotelName?: string
}

export interface HotelDetailQuery {
    companyId: string
    hotelId: string
    checkInDate: string
    checkOutDate: string
}

export interface Hotel {
    id: string
    hotelName: string
    address: string
    lat: number
    lon: number
    baiduLat: number
    baiduLon: number
    picture: string
    bigPicture: string
    category: string
    hotelCityId: string
    lowestPrice: number
    stars: string
    surroundings: string
    trafficGuide: string
}

interface HotelImageItem {
    smallPicUrl: string
    bigPicUrl: string
}

interface HotelSupplier {
    id: string
    name: string
    supplierIdentify: string
    weight: 3
    accessSecurit: string
    accessToken: string
    extendParam: {
        companyID: string
        pGroupId: string
        secretKey: string
        pId: string
    }
}

export interface HotelProduct {
    id: string
    hotelId: string
    roomId: string
    specialFlag: string
    avgPrice: number
    bedType: string
    windowType: string
    breakfastDesc: string
    broadNetDesc: string
    cancelRule: string
    cancelType: string
    productType: string
    ratePlanName: string
    guaranteeRuleTip: string
    saleStatusCode: string
    productPrices: {
        hotelId: string
        id: string
        price: number
        priceDay: string
        priceType: string
        startTime: Date
        endTime: Date
        breakfastNum: number
        roomId: string
        hotelRoomProductId: string
        supplierId: string
        supplier: HotelSupplier
    }[]
    supplierId: string
    supplier: HotelSupplier
}

export interface HotelRoom {
    hotelId: string
    roomName: string
    roomEname: string
    amenities: string
    area: string
    capacity: string
    floors: string
    bathRoom: string
    drinkFood: string
    others: string
    id: string
    images: HotelImageItem[]
    lowestPrice: number
    products: HotelProduct[]
}

export interface HotelDetail extends Hotel {
    hotelCity?: HotelCity
    distance: number
    hotelGroupName: string
    images?: HotelImageItem[]
    longBrief?: string
    shortBreif?: string
    phone: string
    rooms: HotelRoom[]
}

export interface HotelBookingQuery {
    applyForId: string
    companyId: string
    supplierId: string
    productId: string
    bookerNum: string
    bookerName: string
    bookerMobile: string
    contactName: string
    contactPhone: string
    contactEmail?: string
    checkinDate: string
    checkoutDate: string
    arrivalEarlyTime: string
    arrivalLateTime: string
    roomCount: number
    amount: number
    checkiners: Array<{
        roomNo?: string
        checkinerName: string
        checkinerPhone?: string
        checkinerEmail?: string
        checkinerNum?: string
        checkinerIdCardNum: string
    }>
}

export interface HotelRefundQuery {
    oid: string
    refundDesc?: string
}

// 酒店列表查询
export async function fetchHotelListApi({ access_token }: AccessToken, q: HotelListQuery): Promise<Hotel[]> {
    const res = await fetch(`${window.SSCL_HOTEL_API}/hotel/open/hotel/list?pageNo=${q.pageNo}`, {
        mode: "cors",
        method: "POST",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(q),
    })
    if (!res.ok) return Promise.reject(res.status)
    const body = await res.json() as CommonLegacyResult<Hotel[]>
    const list = body.data
    if (!list || list.length === 0) return Promise.reject()
    return list
}
//酒店详情
export async function fetchHotelDetailApi({ access_token }: AccessToken, q: HotelDetailQuery): Promise<HotelDetail> {
    const res = await fetch(`${window.SSCL_HOTEL_API}/hotel/open/hotelDetail/detail`, {
        mode: "cors",
        method: "POST",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(q),
    })
    if (!res.ok) return Promise.reject(res.status)
    const body = await res.json() as CommonLegacyResult<HotelDetail>
    const d = body.data
    if (!d) return Promise.reject()
    return d
}
// 酒店预定下单
export async function fetchHotelBookingApi({ access_token }: AccessToken, q: HotelBookingQuery): Promise<void> {
    let res = await fetch(`${window.SSCL_HOTEL_API}/hotel/open/hotel/order/apply`, {
        mode: "cors",
        method: "POST",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(q),
    })
    if (!res.ok) return Promise.reject(res.status)
    let body = await res.json() as CommonLegacyResult<string>
    if (body.rc !== 0 || !body.data) return Promise.reject(body.msg)
    const id = body.data
    // 酒店订单通知预定
    res = await fetch(`${window.SSCL_HOTEL_API}/hotel/open/hotel/order/reserve`, {
        mode: "cors",
        method: "POST",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            orderId: id,
            totalAmount: q.amount
        }),
    })
    if (!res.ok) return Promise.reject(res.status)
    body = await res.json() as CommonLegacyResult<string>
    if (body.rc !== 0) return Promise.reject(body)
}
// 酒店关键字查询
export async function fetchHotelKeywordApi({ access_token }: AccessToken, q: HotelKeywordQuery): Promise<HotelKeyword[]> {
    const res = await fetch(`${window.SSCL_HOTEL_API}/hotel/open/hotel/hotKeyword/query`, {
        mode: "cors",
        method: "POST",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(q),
    })
    if (!res.ok) return Promise.reject(res.status)
    const body = await res.json() as CommonLegacyResult<HotelKeyword[]>
    if (!body.data || body.data.length === 0) return Promise.reject()
    return body.data
}
// 订单退订
export const fetchHotelRefundApi = async ({ access_token }: AccessToken, q: HotelRefundQuery): Promise<void> => {
    const res = await fetch(`${window.SSCL_HOTEL_API}/hotel/open/hotel/order/refund`, {
        mode: "cors",
        method: "post",
        headers: {
            'Content-Type': "application/json;charset=UTF-8",
            'Authorization': `Bearer ${access_token}`
        },
        body: JSON.stringify(q)
    })
    if (!res.ok) return Promise.reject(res.status)
    const body = await res.json() as CommonLegacyResult<void>
    if (body.rc !== 0) return Promise.reject(body.msg)
}
