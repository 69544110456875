import { Col, Grid, Row, Space, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import IconFont from '../../components/IconFont'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { findStaffId, resetListState } from '../reimburseForm/listSlice'
import { AccountsType, setAccountsType } from '../book/const'
import { fetchPayAccount } from '../reimburseForm/editorSlice'

const { Text } = Typography

type Props = {
    mode?: string
    setOutValue?: (v: number) => void
    setModalVisiable?: (v: boolean) => void
    setOpen?: (v: boolean) => void
}

export default function Editor({ mode, setOutValue, setModalVisiable, setOpen }: Props) {
    const navi = useNavigate()
    // 公司配置
    const types = useAppSelector(state => state.user.currentCompany?.companyBasicConfig.enabledAccountTypes.REIMBURSE_TRAVEL ?? [])
    const [initialized, setInitialized] = useState(false)
    // // 登录人数据
    const currentStaff = useAppSelector((state) => state.user.currentStaff)
    const PayAccountLoading = useAppSelector(state => state.reimburesEditor.PayAccountLoading)

    const dispatch = useAppDispatch()
    // 初始化状态 清空数据
    useEffect(() => {
        if (!initialized && mode !== 'reimbures') {
            dispatch(resetListState())
            setInitialized(true)
            dispatch(findStaffId([currentStaff?.staffId ?? '']))
            // 公司支付单位
            if (PayAccountLoading !== 'fulfilled') {
                dispatch(fetchPayAccount())
            }
        } else {
            dispatch(findStaffId([currentStaff?.staffId ?? '']))
        }
    }, [PayAccountLoading, currentStaff?.staffId, dispatch, initialized, mode])

    //  个性化布局
    const { lg } = Grid.useBreakpoint()

    const handleClick = (it: AccountsType) => {
        if (mode === 'reimbures') {
            setOutValue?.(it.value)
            setModalVisiable?.(false)
            setOpen?.(true)
        } else {
            navi(`/accountsForm/${it.value}/edit`)
        }

    }

    return (
        <div className={mode ? "" : "container"}>
            <Row justify="center" gutter={[12, 12]}>
                <Row style={{ width: lg ? "75%" : "100%" }} gutter={[12, 12]}>
                    {setAccountsType.filter(element => types.includes(element.value)).map((it, idx) => {
                        return <Col key={idx} span={8} style={{ textAlign: 'center' }} onClick={() => handleClick(it)}>
                            <Space direction='vertical' align='center' >
                                <IconFont type={it.icon} style={{ fontSize: '65px' }} />
                                <Text style={{ width: 100 }} ellipsis={{ tooltip: it.label }}>{it.label}</Text>
                            </Space>
                        </Col>
                    })}
                </Row>
            </Row>
        </div >
    )
}
