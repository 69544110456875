import { ReactElement, useState, useEffect } from "react"
import { connect } from "react-redux"
import { Form, Button, DatePicker, Row, Col, Grid, Affix, Drawer } from "antd"
import { FilterOutlined, SearchOutlined } from "@ant-design/icons"
import dayjs, { Dayjs } from "dayjs"
import "dayjs/locale/zh-cn"
import locale from "antd/es/date-picker/locale/zh_CN"
import { TrainCitySelector } from "./TrainCitySelector"
import { RootState } from "../../../../../app/store"
import TrainListFilters from "../components/TrainListFilters"
import { TrainCity } from "../../../../models"
import { SearchTrainListParams } from "../../services/bookTrainApi"

interface Props {
    onSearch?: (values: SearchTrainListParams) => void
    onStartSearch?: (value: number) => void
    onDelayedSearch?: (value: number) => void
    mode?: "book" | "change"
    city?: { from?: string; to?: string }
    loading?: boolean
}
export interface SearchTrainFormValues {
    fromStation: TrainCity
    toStation: TrainCity
    trainDate: Dayjs
}

const mapStateToProps = (state: RootState) => ({
    applyFor: state.booking.applyForm,
    trainList: state.bookTrain.trainList,
    fliterBtnAvailable: state.bookTrain.trainListByQueried.length > 0,
})
type StateProps = ReturnType<typeof mapStateToProps> & Props

const _topSearch = ({
    onSearch,
    fliterBtnAvailable,
    mode,
    city,
    loading,
    applyFor,
}: StateProps): ReactElement => {
    const [form] = Form.useForm()
    const [disabled, setDisabled] = useState(false)
    // 抽屉开关
    const [open, setOpen] = useState(false)
    const config = {
        rules: [
            {
                type: "object" as const,
                required: true,
                message: "请选择出发时间！",
            },
        ],
    }
    const dateFormat = "YYYY-MM-DD"

    useEffect(() => {
        setDisabled(mode === "book")
    }, [mode])

    const onFinish = (values: SearchTrainFormValues) => {
        //通过子传父传递values到TrainList.tsx页面
        const args: SearchTrainListParams = {
            fromStation: values.fromStation?.name,
            toStation: values.toStation?.name,
            trainDate: values.trainDate.format("YYYY-MM-DD"),
        }
        onSearch?.(args)
    }

    function disabledDate(current: Dayjs) {
        // 预订日期在出差申请日期之间【“今天”在出差申请范围之前；“今天”在出差申请范围之间】
        if (mode === "book") {
            const start = dayjs()
                .startOf("day")
                .isAfter(dayjs(applyFor?.applyStartDate))
                ? dayjs().startOf("day")
                : dayjs(applyFor?.applyStartDate)
            return (
                current &&
                (current < start || current > dayjs(applyFor?.applyEndDate))
            )
        } else {
            return current && current < dayjs().startOf("day")
        }
    }
    const { lg } = Grid.useBreakpoint()

    return (
        <>
            <Form form={form} layout="inline" onFinish={onFinish}>
                <Row gutter={[0, 16]} style={{ width: lg ? "75%" : "100%" }}>
                    <Col flex="1 200px">
                        <Form.Item
                            name="fromStation"
                            normalize={value => JSON.parse(value)}
                            rules={[
                                {
                                    required: disabled,
                                    message: "请选择出发地！",
                                },
                            ]}>
                            <TrainCitySelector
                                isStart={true}
                                isCity={true}
                                mode={mode}
                                cityName={city?.from}
                            />
                        </Form.Item>
                    </Col>
                    <Col flex="1 200px">
                        <Form.Item
                            name="toStation"
                            normalize={value => JSON.parse(value)}
                            rules={[
                                {
                                    required: disabled,
                                    message: "请选择目的地！",
                                },
                            ]}>
                            <TrainCitySelector
                                isStart={false}
                                mode={mode}
                                isCity={true}
                                cityName={city?.to}
                            />
                        </Form.Item>
                    </Col>
                    <Col flex="1 200px">
                        <Form.Item
                            name="trainDate"
                            initialValue={
                                dayjs()
                                    .startOf("day")
                                    .isAfter(dayjs(applyFor?.applyStartDate))
                                    ? dayjs()
                                    : dayjs(applyFor?.applyStartDate)
                            }
                            {...config}>
                            <DatePicker
                                locale={locale}
                                format={dateFormat}
                                disabledDate={disabledDate}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                    <Col flex="none">
                        <Form.Item shouldUpdate>
                            {() => (
                                <Button
                                    type="primary"
                                    block={true}
                                    icon={<SearchOutlined />}
                                    loading={loading}
                                    htmlType="submit">
                                    搜索
                                </Button>
                            )}
                        </Form.Item>
                    </Col>
                    {fliterBtnAvailable && (
                        <Col flex="none">
                            <Affix offsetTop={10}>
                                <Button
                                    icon={<FilterOutlined />}
                                    type="default"
                                    onClick={() => setOpen(true)}>
                                    筛选
                                </Button>
                            </Affix>
                            <Drawer
                                title="筛选条件"
                                placement="right"
                                onClose={() => setOpen(false)}
                                open={open}
                                width="300px">
                                <TrainListFilters />
                            </Drawer>
                        </Col>
                    )}
                </Row>
            </Form>
        </>
    )
}

export default connect(mapStateToProps)(_topSearch)
