import { Checkbox, message, Modal, Spin, Steps } from "antd"
import { useCallback, useEffect, useState } from "react"
import { connect } from "react-redux"
import { AppDispatch, RootState } from "../../../app/store"
import {
    resetOpsModal,
    fetchApplyFormAuditor,
    fetchFlowByDocumentId,
    submitToApproval,
    setOperationError,
} from "../viewerSlice"

const mapStateToProps = (state: RootState) => ({
    detail: state.applyFormViewer.detail,
    flow: state.applyFormViewer.flow,
    state: state.applyFormViewer.operationState,
    auditors: state.applyFormViewer.auditors,
    error: state.applyFormViewer.operationError,
    isSuccessed: state.applyFormViewer.isPostApprovalSuccess,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    dispatchFetchFlow: (id: string) => {
        dispatch(fetchFlowByDocumentId(id))
    },
    dispatchFetchAuditor: () => {
        dispatch(fetchApplyFormAuditor())
    },
    dispatchReset: () => {
        dispatch(resetOpsModal())
    },
    dispatchPostApproval: (id: string, targets?: string[]) => {
        dispatch(submitToApproval({ id, targets }))
    },
    dispatchOperationError: (error: string) => {
        dispatch(setOperationError(error))
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        visiable: boolean
        onCancel?: () => void
        onFinished?: () => void
    }

const _CreateFlowInst = ({
    visiable,
    detail,
    dispatchFetchFlow,
    flow,
    state,
    onCancel,
    auditors,
    dispatchFetchAuditor,
    error,
    dispatchOperationError,
    dispatchReset,
    dispatchPostApproval,
    onFinished,
    isSuccessed,
}: Props) => {
    const [current, setCurrent] = useState(0)
    const [okText, setOkText] = useState<string>()
    const [targetList, setTargetList] = useState<string[]>()

    useEffect(() => {
        //处理流程
        if (!visiable) return
        if (current === 0) {
            if (
                !detail?.travellers?.every(
                    it => it.idList && it.idList.length > 0
                )
            ) {
                dispatchOperationError("您使用的是旧版出行人，请您更新证件信息")
            } else if (flow) {
                setCurrent(current + 1)
            } else if (!flow) {
                dispatchFetchFlow(detail?.applyId ?? "404")
            }
        } else if (current === 1) {
            //线性审批跳过获取审批人
            if (flow?.flowMode === "LINE") setCurrent(current + 1)
            else if (!auditors) {
                dispatchFetchAuditor()
            } else {
                setOkText("下一步")
            }
        } else if (current === 2) {
            setOkText("提交")
        }
    }, [
        visiable,
        current,
        dispatchFetchFlow,
        flow,
        detail,
        auditors,
        dispatchFetchAuditor,
        dispatchOperationError,
    ])

    //重置modal信息
    const resetModal = useCallback(() => {
        setCurrent(0)
        setTargetList(undefined)
        dispatchReset()
    }, [setCurrent, dispatchReset])

    const _onCancel = useCallback(() => {
        resetModal()
        onCancel?.()
    }, [resetModal, onCancel])

    useEffect(() => {
        //提交完结
        if (isSuccessed) {
            message.success("提交审批流程成功！")
            resetModal()
            onFinished?.()
        }
    }, [isSuccessed, resetModal, onFinished])

    useEffect(() => {
        //错误处理
        if (!visiable || !error || state !== "reject") return
        Modal.error({
            title: "提交审批错误",
            centered: true,
            content: typeof error === "number" ? `请求错误：${error}` : error,
        })
        _onCancel()
    }, [error, visiable, _onCancel, state])

    const onOk = () => {
        if (current === 1) {
            //选择审批人
            if (!targetList || targetList.length === 0) {
                message.warning("必须至少选择一位审批人")
            } else {
                setCurrent(current + 1)
            }
        } else if (current === 2) {
            //提交
            dispatchPostApproval(detail?.applyId ?? "404", targetList)
        }
    }
    const items = [
        { title: "预检" },
        { title: "选择审批人" },
        { title: "提交" },
    ]
    return (
        <Modal
            title="申请单提交流程"
            onCancel={_onCancel}
            onOk={onOk}
            okText={okText}
            okButtonProps={{ hidden: !okText, loading: state === "pending" }}
            open={visiable}
            closable={state !== "pending"}
            centered>
            <Steps items={items} current={current} size="small" progressDot />
            <Spin spinning={state === "pending"}>
                <div className="container">
                    <div hidden={current !== 1}>
                        <Checkbox.Group
                            options={auditors?.map(a => ({
                                label: a.staffUserName,
                                value: a.staffId,
                            }))}
                            value={targetList}
                            onChange={v =>
                                setTargetList(v.map(v => v as string))
                            }
                        />
                    </div>
                    <div style={{ textAlign: "center" }} hidden={current !== 2}>
                        点击提交按钮进入审批流程
                    </div>
                </div>
            </Spin>
        </Modal>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(_CreateFlowInst)
