import { Col, Row, Spin } from "antd"
import { useEffect } from "react"
import { connect } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { AppDispatch } from "../../app/store"
import { reset as resetLoginState, jumpSSO } from "./loginSlice"
import { reset as resetRedirect } from "./userSlice"

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    redirect: (p: URLSearchParams) => dispatch(jumpSSO(p)),
    reset: () => {
        dispatch(resetLoginState())
        dispatch(resetRedirect())
    },
})

type Props = ReturnType<typeof mapDispatchToProps>

const _sso = ({ redirect, reset }: Props): JSX.Element => {
    const [params] = useSearchParams()
    useEffect(() => {
        reset()
        redirect(params)
    }, [params, reset, redirect])
    return (
        <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
            <Col>
                <Spin size="large" tip="正在跳转" />
            </Col>
        </Row>
    )
}

export default connect(null, mapDispatchToProps)(_sso)
