import { Button, Form, List, message, Modal } from "antd"
import { DeleteRowOutlined } from "@ant-design/icons"
import { useState } from "react"
import { connect } from "react-redux"
import { Traveller } from "../../models"
import { TravellerList } from "../../traveller/List"
import TravellerInfo from "../viewer/TravellerInfo"
import { useAppSelector } from "../../../app/hooks"

const TraverllerDetailOps = ({
    traveller: t,
    onClick,
}: {
    traveller: Traveller
    onClick: (t: Traveller) => void
}) => {
    return (
        <div>
            <TravellerInfo traveller={t} clickable={true} />
            <Button
                type="text"
                onClick={() => {
                    onClick(t)
                }}
                icon={<DeleteRowOutlined />}
                size="small">
                移除
            </Button>
        </div>
    )
}

type TravellerProps = {
    value?: Traveller[]
    onChange?: (v?: Traveller[]) => void
}

const _TravellerSelector = ({ value, onChange }: TravellerProps) => {
    const [modalVisiable, setModalVisiable] = useState(false)

    const onDeleteClick = (t: Traveller) => {
        const _v = value?.filter(it => it.travellerId !== t.travellerId)
        onChange?.((_v?.length ?? 0) > 0 ? _v : undefined)
    }

    const onSelect = (t: Traveller) => {
        if (!value?.find(it => it.travellerId === t.travellerId) ?? true) {
            onChange?.(value ? [...value, t] : [t])
        } else {
            message.warning("此出行人已关联")
        }
    }

    return (
        <>
            {(value?.length ?? 0) > 0 && (
                <List
                    grid={{ column: 4, xs: 1, sm: 1, md: 3 }}
                    dataSource={value}
                    renderItem={it => (
                        <List.Item>
                            <TraverllerDetailOps
                                traveller={it}
                                onClick={onDeleteClick}
                            />
                        </List.Item>
                    )}
                />
            )}
            <Modal
                title="选择出行人"
                open={modalVisiable}
                closable={true}
                centered
                footer={null}
                onCancel={() => setModalVisiable(false)}
                destroyOnClose={true}>
                <TravellerList
                    mode="selector"
                    onSelect={tm => {
                        onSelect({
                            ...tm,
                            sourceStr: undefined,
                            idTypeStr: undefined,
                        } as Traveller)
                        setModalVisiable(false)
                    }}
                />
            </Modal>
            <Button onClick={() => setModalVisiable(true)} type="dashed">
                关联出行人
            </Button>
        </>
    )
}

const TravellerSelector = connect()(_TravellerSelector)

export default function TravellerItem() {
    // Form hooks
    const { useFormInstance, useWatch } = Form
    const visiable = useAppSelector(
        state => state.applyFormEditor.applyForm === undefined
    )
    // 获取国际国内出差按钮
    const form = useFormInstance()
    // 获取 form 中字段对应的值
    const international = useWatch<boolean | undefined>("international", form)
    if (!visiable) return <></>
    return (
        <Form.Item
            name="travellers"
            label="出行人"
            rules={[
                { required: true, message: "请添加出行人" },
                {
                    validator: async (_, value: Traveller[]) => {
                        if (international) {
                            //是否国际
                            value?.forEach(it => {
                                if (
                                    !it.idList?.some(
                                        item => item.idType === "PASSPORT"
                                    )
                                )
                                    throw new Error(
                                        `国际出差，${
                                            it?.idList && it?.idList[0].name
                                        }出行人证件类型应包含护照`
                                    )
                            })
                        }
                    },
                },
            ]}>
            <TravellerSelector />
        </Form.Item>
    )
}
