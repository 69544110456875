import { Grid, Input, Space } from "antd"
import { useState } from "react"
import { useUpdateEffect } from "react-use"

export type PriceRange = {
    minPrice?: number
    maxPrice?: number
}

type Props = {
    onChange?: (value?: PriceRange) => void
}

const HotelInputGroup = ({ onChange }: Props) => {
    //  个性化布局
    const { lg } = Grid.useBreakpoint()
    const [min, setMin] = useState<string>("")
    const [max, setMax] = useState<string>("")

    useUpdateEffect(() => {
        onChange?.({
            minPrice: min !== "" ? Number(min) : undefined,
            maxPrice: max !== "" ? Number(max) : undefined,
        })
    }, [min, max])

    return (
        <Space.Compact block>
            <Input
                onChange={e => setMin(e.target.value)}
                value={min}
                style={{ width: lg ? "47.5%" : "43%", borderRightWidth: 0 }}
                type="number"
                placeholder="最低价格"
            />
            <Input
                style={{
                    width: lg ? "5%" : "14%",
                    textAlign: "center",
                    borderLeft: 0,
                    borderRight: 0,
                    pointerEvents: "none",
                    background: "#fff",
                }}
                placeholder="~"
                disabled
            />
            <Input
                onChange={e => setMax(e.target.value)}
                value={max}
                style={{ width: lg ? "47.5%" : "43%", borderLeftWidth: 0 }}
                type="number"
                placeholder="最高价格"
            />
        </Space.Compact>
    )
}

export default HotelInputGroup
