import { ReactElement } from "react"
import { Row, Col, Timeline, Layout, Typography } from "antd"
import { PlaneOrderListModel } from "../../service/orderListModel"
import { ClockCircleOutlined } from "@ant-design/icons"
import { blue } from "@ant-design/colors"
const { Header, Content } = Layout
const { Text } = Typography

function JourneyTimeItem(props: {
    cityName?: string
    portName?: string
    flightImg?: string
}) {
    const { cityName, portName, flightImg } = props
    return (
        <div>
            {flightImg && (
                <img referrerPolicy="no-referrer" src={flightImg} alt="" />
            )}
            <Text strong style={{ fontSize: 20 }}>
                {cityName}
            </Text>
            <span>--</span>
            <Text type="secondary" style={{ fontSize: 16 }}>
                {portName}
            </Text>
        </div>
    )
}

const PlaneOrderDetailJourneyCpn = (
    props: PlaneOrderListModel
): ReactElement => {
    const { flight, shippingClassName, discount } = props

    const items = [
        {
            label: `${flight?.takeOffTime?.substring(16, 5)}`,
            children: (
                <JourneyTimeItem
                    cityName={flight?.takeOffCityName}
                    portName={flight?.takeOffPortname}
                    flightImg={flight?.flightImg}
                />
            ),
        },
        {
            dot: <ClockCircleOutlined />,
            color: "red",
            children: <div>{flight?.flightTime}</div>,
        },
        {
            label: `${flight?.landingTime?.substring(16, 5)}`,
            children: (
                <JourneyTimeItem
                    cityName={flight?.toCityName}
                    portName={flight?.landingPortName}
                    flightImg={flight?.flightImg}
                />
            ),
        },
    ]
    return (
        <div>
            <Layout>
                <Header style={{ backgroundColor: "white", padding: "0" }}>
                    <p style={{ fontSize: 14 }}>
                        起飞日期：{flight?.flightDate}
                    </p>
                </Header>
                <Content style={{ backgroundColor: "white" }}>
                    <Row>
                        <Col flex="500px">
                            <Timeline
                                mode="alternate"
                                style={{ marginLeft: "-80px" }}
                                items={items}
                            />
                        </Col>
                        <Col
                            flex="1 100px"
                            style={{ textAlign: "center", marginTop: "-3px" }}>
                            <p>
                                {flight?.airlineName}-{flight?.flightNum}{" "}
                            </p>
                            <p>{flight?.planeType}</p>
                            <span>{shippingClassName}</span>
                            <span> | </span>
                            <span style={{ color: blue[6] }}>
                                {discount && discount * 10}折{" "}
                            </span>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </div>
    )
}

export default PlaneOrderDetailJourneyCpn
