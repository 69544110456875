import { Input, message, Modal } from "antd"
import type { LegacyButtonType } from "antd/es/button/button"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { AppDispatch, RootState } from "../../../app/store"
import { fetchPutApproval, resetOpsModal } from "../viewerSlice"

const mapStateToProps = (state: RootState) => ({
    state: state.applyFormViewer.operationState,
    error: state.applyFormViewer.operationError,
    id: state.applyFormViewer.approvalId,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    dispatchReset: () => {
        dispatch(resetOpsModal())
    },
    dispatchPutApproval: (id: string, state: 1 | 2, content?: string) => {
        dispatch(fetchPutApproval({ id, body: { state, content } }))
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        visiable: boolean
        onCancel?: () => void
        onFinished?: () => void
        mode?: string
    }

const _PutApproval = ({
    visiable,
    onCancel,
    onFinished,
    mode,
    state,
    id,
    dispatchPutApproval,
    dispatchReset,
}: Props) => {
    console.log("🚀 ~ mode:", mode)
    console.log("🚀 ~ id:", id)
    const [okText, setOkText] = useState<string>()
    const [title, setTitle] = useState<string>()
    const [okType, setOkType] = useState<LegacyButtonType>()
    const [value, setValue] = useState<string>()

    useEffect(() => {
        //更新标题和按钮文本
        switch (mode) {
            case "accept":
                setTitle("审批同意确认")
                setOkText("同意")
                setOkType("primary")
                break
            case "reject":
                setTitle("审批驳回确认")
                setOkText("驳回")
                setOkType("danger")
                break
        }
        setValue(undefined)
    }, [mode])

    const _onCancel = () => {
        dispatchReset()
        onCancel?.()
    }

    const onOk = () => {
        if (!id) return
        switch (mode) {
            case "accept":
                dispatchPutApproval(id, 1, value)
                break
            case "reject":
                dispatchPutApproval(id, 2, value)
                break
        }
    }

    useEffect(() => {
        if (visiable && state === "fulfilled") {
            message.success("审批操作完成！")
            dispatchReset()
            onFinished?.()
        }
    })

    return (
        <Modal
            open={visiable}
            onCancel={_onCancel}
            centered
            okText={okText}
            title={title}
            okType={okType}
            onOk={onOk}
            okButtonProps={{ loading: state === "pending" }}
            closable={state !== "pending"}>
            <Input.TextArea
                placeholder="[选填] 审批意见"
                allowClear
                value={value}
                onChange={e => setValue(e.target.value)}
            />
        </Modal>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(_PutApproval)
