import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const slice = createSlice({
    name: 'menu',
    initialState: { isMobileMenuVisible: false },
    reducers: {
        changeMobileMenuVisible: (state, { payload }: PayloadAction<boolean>) => {
            state.isMobileMenuVisible = payload
        }
    }
})

export const { changeMobileMenuVisible } = slice.actions
export default slice.reducer
