import { AccessToken, CommonLegacyResult, Project } from "../models"

interface SearchProjectSearch {
    companyId: string
    key: string
    onlyEnabled?: boolean
}

export async function getRecommendProject({ access_token }: AccessToken, companyId: string): Promise<Project> {
    const res = await fetch(`${window.SSCL_API}/v3/user/${companyId}/default/project`, {
        mode: "cors",
        method: "get",
        headers: {
            'Authorization': `Bearer ${access_token}`,
        },
    })

    if (!res.ok) return Promise.reject(res.status)
    const ret = (await res.json()) as CommonLegacyResult<Project>
    return ret.data ?? Promise.reject("empty data")
}


export async function searchProjectByKey({ access_token }: AccessToken, { companyId, key, onlyEnabled }: SearchProjectSearch): Promise<Array<Project>> {
    const p = new URLSearchParams()
    p.set("key", key)
    if (onlyEnabled !== undefined)
        p.set("onlyEnabled", onlyEnabled + "")
    const res = await fetch(`${window.SSCL_API}/rest/company/${companyId}/project/search?${p.toString()}`, {
        mode: "cors",
        method: "get",
        headers: {
            'Authorization': `Bearer ${access_token}`,
        },
    })

    if (!res.ok) return Promise.reject(res.status)
    const ret = (await res.json()) as CommonLegacyResult<Array<Project>>
    return ret.data ?? Promise.reject("empty data")
}
