import { Button, Form, Modal, Typography } from "antd"
import { DeleteRowOutlined } from "@ant-design/icons"
import { useState } from "react"
import { connect } from "react-redux"
import { ApplyForm } from "../../models"
import ApplyFormSelector from "../../book/ApplyFormSelector"
import { useAppDispatch } from "../../../app/hooks"
import { setApplyForms } from "../listSlice"


const { Text } = Typography

export interface ApplyFormList {
    applyId: string
    businessPurposesId: string
    companyId: string
    projectId: string
    reimburseNum: string
    actionType: string
    projectName: string
    projectNum: string
    staffId: string
    applyForm: ApplyForm
}

type TravellerProps = {
    value?: ApplyForm
    onChange?: (v?: ApplyForm) => void
}

const TravelRequestSelector = ({ value, onChange }: TravellerProps) => {
    const [modalVisiable, setModalVisiable] = useState(false)
    const form = Form.useFormInstance()
    const dispatch = useAppDispatch()
    const projectName = Form.useWatch<string | undefined>("projectName", form)  // 出差项目

    const onSelect = (t: ApplyForm) => {
        if (!projectName) {
            onChange?.(t)
            // 出行人
            dispatch(setApplyForms(t.travellers ?? []))
        } else if (t?.projectName === projectName) {
            onChange?.(t)
            dispatch(setApplyForms(t.travellers ?? []))
        } else if (t?.projectName !== projectName) {
            Modal.confirm({
                title: '项目不一致',
                cancelText: "取消",
                okText: "确定",
                content:
                    "选择的出差申请和报销单不在同一个项目中,是否把报销单的项目改为出差申请的项目?",
                onOk: () => {
                    onChange?.(t)
                    dispatch(setApplyForms(t.travellers ?? []))
                }
            })
        }
    }

    return (
        <>
            {(value) && (
                <div>
                    <Text>{value.txm}</Text>
                    <Button
                        type="text"
                        onClick={() => {
                            dispatch(setApplyForms(undefined))
                            onChange?.(undefined)
                        }}
                        icon={<DeleteRowOutlined />}
                        size="small">
                        移除
                    </Button>
                </div>

            )}
            <Modal
                width='90%'
                title="选择申请单"
                open={modalVisiable}
                closable={true}
                centered
                footer={null}
                onCancel={() => setModalVisiable(false)}
                destroyOnClose={true}>
                <ApplyFormSelector
                    mode="selector"
                    onSelect={tm => {
                        onSelect?.(tm)
                        setModalVisiable(false)
                    }}
                />
            </Modal>
            <Button onClick={() => setModalVisiable(true)} type="dashed">
                关联申请单
            </Button>
        </>
    )
}

const TravellerSelector = connect()(TravelRequestSelector)

export default function TravellerItem() {

    return (
        <>
            <Form.Item
                name="applyForm"
                label="关联出差申请"
                rules={[
                    { required: true, message: "关联出差申请" },
                ]}>
                <TravellerSelector />
            </Form.Item>
        </>
    )
}
