import { Button, Col, Form, Input, Result, Row } from "antd"
import { PageHeader } from "@ant-design/pro-layout"
import { useState } from "react"
import { connect } from "react-redux"
import { useParams } from "react-router-dom"
import { AppDispatch, RootState } from "../../../app/store"
import { hotelRefund } from "./hotelSlice"

const mapStateToProps = (state: RootState) => ({
    loading: state.bookHotel.hotelLoading,
    error: state.bookHotel.refundError,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    //酒店订单退订
    hotelRefund: (id: string, txt?: string) =>
        dispatch(hotelRefund({ oid: id, refundDesc: txt })),
})
type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

const _refund = ({ loading, error, hotelRefund }: Props) => {
    const { id } = useParams<{ id: string }>() //获取路由携带参数
    const [submitted, setSubmitted] = useState(false) //酒店房间退订开关
    //提交回调函数
    const handleSubmit = (v: { txt?: string }) => {
        setSubmitted(true)
        // 酒店订单退订
        hotelRefund(id || "", v.txt)
    }

    if (submitted && !loading) {
        if (!error)
            return (
                <Result
                    status="success"
                    title="退订成功！"
                    subTitle="您的酒店订单已经成功发起退订，退订费用以实际规则为准。"
                />
            )
        if (typeof error === "string")
            return <Result status="error" title="退订失败" subTitle={error} />
        else if (error === 500)
            return <Result status="500" title="退订失败" subTitle={error} />
        else return <Result status="error" title="退订失败" subTitle={error} />
    }
    return (
        <>
            <PageHeader style={{ backgroundColor: "white" }} title="退订酒店" />
            <Row justify="center" align="middle" style={{ height: "80%" }}>
                <Col span="10">
                    <Form onFinish={handleSubmit}>
                        <Form.Item name="txt">
                            <Input placeholder="请输入退订原因" />
                        </Form.Item>
                        <Button
                            loading={loading}
                            htmlType="submit"
                            block
                            type="primary"
                            danger>
                            提交
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(_refund)
