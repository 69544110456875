import React, { useEffect, useState } from 'react'
import { Checkbox, Form, Input, Select, Typography } from 'antd'
import { useAppSelector } from '../../../app/hooks'
import { blue, orange } from '@ant-design/colors'
import dayjs, { Dayjs } from 'dayjs'
import { CustomerList } from '../../reimburseForm/listSlice'
import { AccountsCity } from '../../models'

const { Text } = Typography

export interface AccountPolicyItem {
    accountsPolicyId: string,
    accountsType: number,
    currency?: string,
    accountsSumMoney: number
    displayName: string
    unitType?: string
}

type Props = {
    onChange?: (value?: AccountPolicyItem) => void
    value?: AccountPolicyItem
}
export const CommitmentInfo = (accountsType: number, auditInfo: string) => {
    switch (accountsType) {
        //国际伙食补助  1800 无需 填写补助承诺
        case 1801://判断住宿补助政策的名字包括包干补助
            if (auditInfo.includes("包干")) {
                return '本人承诺对方单位未提供住宿和用餐'
            } else {
                return '本人承诺对方单位未提供用餐'
            }
            break
        case 1802://交通补助
            return '本人承诺对方单位未提供车辆'
            break
        default:
            break
    }
}

function AccountPolicySelector({ onChange, value, }: Props) {

    // 补助类型数据
    const subsidyPolicy = useAppSelector(state => state.account.subsidyPolicy)
    const subsidyPolicyLoading = useAppSelector(state => state.account.subsidyPolicyLoading)

    const form = Form.useFormInstance()
    const city = Form.useWatch<AccountsCity | undefined>("city", form)
    const customerList = Form.useWatch<CustomerList[] | undefined>("customerList", form)
    useEffect(() => {
        if (city || customerList) {
            onChange?.()
            form.setFieldValue("accountPolicyId", undefined)
            form.setFieldValue("accountsType", undefined)
            form.setFieldValue("auditInfo", undefined)
        }
    }, [city, customerList, form, onChange])


    const onSelect = (v: string) => {
        const value = JSON.parse(v)
        const _value = {
            accountsPolicyId: value.accountsPolicyId,
            accountsType: value.accountsType,
            currency: value.currency,
            accountsSumMoney: value.value,
            displayName: value.displayName,
            unitType: value.unitType
        }
        onChange?.(_value)
    }

    return (<>
        <Select
            value={value?.displayName ?? ''}
            disabled={subsidyPolicyLoading !== 'fulfilled'}
            placeholder="差旅补助" onSelect={onSelect} style={{ width: '100%' }} showSearch>
            {subsidyPolicy?.map((it, idx) => {
                return <Select.Option key={idx} value={JSON.stringify(it)} >
                    <Text style={{ color: it.unitType === 'PER_PERSON_PER_DAY' ? blue[5] : orange[5], fontSize: 14 }}>
                        {it.currency} {it.value} {it.unitType === 'PER_PERSON_PER_DAY' ? '每天' : "一次性"}</Text>
                    <Text>{it.displayName}</Text>
                </Select.Option>
            })}
        </Select>
    </>
    )
}

type AccountPolicyProps = {
    isEditor?: string
}

export default function AccountPolicy({ isEditor }: AccountPolicyProps) {
    const form = Form.useFormInstance()
    const reimburseSubsidyPromise = useAppSelector(state => state.account.reimburseSubsidyPromise)
    const accountsStartData = Form.useWatch<Dayjs>("accountsStartData", form)
    const accountsEndData = Form.useWatch<Dayjs>("accountsEndData", form)

    const accountPolicyId = Form.useWatch<string>("accountPolicyId", form)
    const accountsType = Form.useWatch<number>("accountsType", form)
    const auditInfo = Form.useWatch<string>('auditInfo', form)
    const accountsSumMoney = Form.useWatch<number>("accountsSumMoney", form)
    const currency = Form.useWatch<string>("currency", form)

    const [project, setProject] = useState<AccountPolicyItem>()


    useEffect(() => {
        // 获取时间
        const date1 = dayjs(accountsStartData)
        const date2 = dayjs(accountsEndData)
        const daysCount = dayjs(date2).diff(dayjs(date1), 'day') + 1
        if (project) {
            form.setFieldValue("accountPolicyId", project?.accountsPolicyId)
            form.setFieldValue("accountsType", project?.accountsType)
            form.setFieldValue("auditInfo", project?.displayName)
            //     // 如果是每天的花就用天数算金额  PER_PERSON_PER_DAY是每天
            if (project.unitType === 'PER_PERSON_PER_DAY') {
                const totalAmount = daysCount * project.accountsSumMoney
                // 计算总金额
                if (project.currency === 'CNY') {
                    form.setFieldValue("accountsSumMoney", totalAmount)
                    form.setFieldValue("accountsSumMoney2", totalAmount)
                    form.setFieldValue("accountsSumMoney3", totalAmount)
                    form.setFieldValue("currency", project.currency)
                    form.setFieldValue("currency2", project.currency)
                    form.setFieldValue("currency3", project.currency)
                } else {
                    form.setFieldValue("accountsSumMoney", totalAmount)
                    form.setFieldValue("accountsSumMoney2", totalAmount)
                    // 得使用汇率接口
                    form.setFieldValue("accountsSumMoney3", totalAmount * 7)
                    form.setFieldValue("currency", project.currency)
                    form.setFieldValue("currency2", project.currency)
                }
            } else {//一次性补助
                form.setFieldValue("accountsSumMoney", project.accountsSumMoney)
                form.setFieldValue("accountsSumMoney2", project.accountsSumMoney)
                form.setFieldValue("accountsSumMoney3", project.accountsSumMoney)
                form.setFieldValue("currency", project.currency)
                form.setFieldValue("currency2", project.currency)
                form.setFieldValue("currency3", project.currency)
            }
        }
        else {
            if (accountPolicyId && accountsType && currency && accountsSumMoney && auditInfo) {
                setProject({
                    accountsPolicyId: accountPolicyId,
                    accountsType: accountsType,
                    currency: currency,
                    accountsSumMoney: accountsSumMoney,
                    displayName: auditInfo,
                    unitType: undefined,
                })
            }
        }
    }, [accountPolicyId, accountsEndData, accountsStartData, accountsSumMoney, accountsType, auditInfo, currency, form, project])



    useEffect(() => {
        if (isEditor) {
            form.setFieldValue("agreement", !reimburseSubsidyPromise)
            if (project) {
                CommitmentInfo(project?.accountsType, project?.displayName)
            }
        } else {
            form.setFieldValue("agreement", reimburseSubsidyPromise)
            if (project) {
                CommitmentInfo(project?.accountsType, project?.displayName)
            }

        }
    }, [form, isEditor, project, reimburseSubsidyPromise])

    return (
        <>
            <Form.Item required
                label="补助类型"
                rules={[{ required: true }]}>
                <AccountPolicySelector onChange={setProject} value={project} />
            </Form.Item>
            <Form.Item hidden name="accountPolicyId"><Input /></Form.Item>
            <Form.Item hidden name="accountsType"><Input /></Form.Item>
            <Form.Item hidden name="auditInfo"><Input /></Form.Item>
            {(CommitmentInfo(accountsType, auditInfo) && project) && <Form.Item
                label="  " colon={false} name="agreement" valuePropName="checked" required
                rules={[
                    { required: true }, {
                        validator: (_, value) =>
                            value ? Promise.resolve() : Promise.reject(new Error('请勾选复选框')),
                    },
                ]}>
                <Checkbox>{CommitmentInfo(project?.accountsType, project?.displayName)}</Checkbox>
            </Form.Item >}
        </>
    )
}
