import { ReactElement } from "react"
import { HotelProductModel } from "../../service/orderListModel"

export const HotelOrderDetailRules = (
    props: HotelProductModel
): ReactElement => {
    const { cancelType, cancelRule } = props
    return (
        <div>
            <p>{cancelType}</p>
            <p>{cancelRule}</p>
        </div>
    )
}
