import React, { useEffect } from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Modal, Table, TableColumnsType, Tabs } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
    ReimburseItemVo, findreimburseAll,
    resetListState, setTabKey
} from './listSlice'
import { ApprovalsStateType } from './api'


const cols: TableColumnsType<ReimburseItemVo> = [
    {
        title: "报销编号",
        dataIndex: "reimburseNum",
    },
    {
        title: "报销名称",
        dataIndex: "reimburseName",
    },
    {
        title: "报销项目",
        dataIndex: "projectName",
    },
    {
        title: "报销金额",
        dataIndex: "reimburseMoney",
    },
    {
        title: "审批状态",
        dataIndex: "state",
    },
]

export default function ReimburseFormList() {
    const dispatch = useAppDispatch()
    const reimburseList = useAppSelector(state => state.reimburseList.list)
    const error = useAppSelector(state => state.reimburseList.error)
    const loading = useAppSelector(state => state.reimburseList.loading)
    const tabKey = useAppSelector(state => state.reimburseList.tabKey)
    const companyId = useAppSelector(state => state.user.currentCompany?.companyId)

    const navi = useNavigate()
    const defaultPageSize = 10

    useEffect(() => {
        dispatch(resetListState())
    }, [dispatch, companyId])

    useEffect(() => {
        if (error) {
            if (error !== 404)
                Modal.error({
                    content:
                        typeof error === "string"
                            ? error
                            : `请求错误：${error}`,
                })
        } else if (!reimburseList && !loading) {
            dispatch(findreimburseAll({
                page: 0,
                size: defaultPageSize,
            }))
        }
    }, [loading, error, reimburseList, dispatch])

    // 定义tabs值
    const items = [
        { label: "草稿", key: "draft" },
        { label: "已提交", key: "submitted" },
    ]
    const onPageChange = (page: number, size: number) => {
        dispatch(findreimburseAll({ page: page - 1, size }))
    }

    return (
        <>
            <PageHeader
                title="报销单"
                ghost={false}
                extra={[
                    <Button key="create" type="primary" ghost>
                        <Link to="../create">创建报销单</Link>
                    </Button>,
                ]}
            />
            <div className="container">
                <Tabs
                    items={items}
                    activeKey={tabKey}
                    onChange={(value) => dispatch(setTabKey(value as ApprovalsStateType))}
                />
                <Table
                    style={{ cursor: "pointer" }}
                    loading={loading}
                    rowKey={record => record.reimburseNum}
                    dataSource={reimburseList?.content}
                    columns={cols}
                    scroll={{ x: "max-content" }}
                    pagination={
                        reimburseList && {
                            total: reimburseList.totalElements,
                            current: reimburseList.page + 1,
                            pageSize: reimburseList.size,
                            defaultPageSize,
                            onChange: onPageChange,
                        }
                    }
                    onRow={(r: ReimburseItemVo) => {
                        return {
                            className: "clickableName",
                            onClick: () => {
                                navi(`../${r.reimburseId}`,)
                            },
                        }
                    }}
                />
            </div>
        </>
    )
}
