import { ReactElement } from "react"
import { Card, Row, Col } from "antd"
import { HotelOrderListModel } from "../../service/orderListModel"
import { red } from "@ant-design/colors"
export default function HotelOrderDetailPriceCpn(
    props: HotelOrderListModel
): ReactElement {
    const { total, roomCount, night } = props
    return (
        <div>
            <Card
                title="酒店费用明细"
                bordered={false}
                style={{ marginBottom: "10px", borderRadius: "10px" }}
                styles={{
                    body: {
                        backgroundColor: "rgb(248,248,248)",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                    }
                }}>
                <Row align="middle">
                    <Col span={7}>订单总额：</Col>
                    <Col span={7} style={{ color: red[5], fontSize: "20px" }}>
                        ¥{total}
                    </Col>
                    <Col span={10} style={{ textAlign: "right" }}>
                        共{roomCount}间,{night}晚
                    </Col>
                </Row>
            </Card>
        </div>
    )
}
