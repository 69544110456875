import { ReactElement, useState } from "react"
import { Row, Col, Button, Modal, Space, Typography } from "antd"
import { SwapRightOutlined } from "@ant-design/icons"
import {
    TrainItemVModel,
    TrainSeatItemVModel,
} from "../../services/bookTrainSlice"
import { runTimeStr } from "../../../const"
import { TrainOrderScheduleParams } from "../../../../order/service/orderModuleApi"
import { TrainOrderDetailScheduleCpn } from "../../../../order/detail/components/TrainOrderDetailScheduleCpn"
import { TrainOrderListModel } from "../../../../order/service/orderListModel"
import { useNavigate } from "react-router-dom"
import { blue } from "@ant-design/colors"
const { Text, Paragraph } = Typography
const TrainListSchedule = (props: TrainItemVModel): ReactElement => {
    const {
        fromTime,
        toTime,
        fromStation,
        toStation,
        runTimeSpan,
        trainNo,
        trainDate,
    } = props
    // 对话框开关
    const [showStation, setShowStation] = useState(false)
    const scheduleParam: TrainOrderScheduleParams = {
        fromStation: fromStation,
        toStation: toStation,
        trainNo: trainNo,
        trainDate: trainDate,
    }
    return (
        <Row align="top">
            <Col flex="1 115px">
                <Space direction="horizontal">
                    <Text strong type="secondary" style={{ fontSize: 20 }}>
                        {trainNo}
                    </Text>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => setShowStation(true)}>
                        经停站
                    </Button>
                    <Modal
                        title="时刻表"
                        width="60vw"
                        open={showStation}
                        closable={true}
                        footer={null}
                        onCancel={() => setShowStation(false)}>
                        <TrainOrderDetailScheduleCpn params={scheduleParam} />
                    </Modal>
                </Space>
            </Col>
            <Col flex="auto">
                <Space direction="horizontal" size={20}>
                    <Space direction="vertical" size={4}>
                        <Text strong style={{ fontSize: 20 }}>
                            {fromTime}
                        </Text>
                        <Paragraph
                            ellipsis={{ rows: 2, tooltip: fromStation }}
                            style={{
                                maxWidth: 80,
                                fontSize: 16,
                                marginBottom: 0,
                            }}>
                            {fromStation}
                        </Paragraph>
                    </Space>
                    <Space direction="vertical" size={4} align="center">
                        <Text type="secondary">{runTimeStr(runTimeSpan)}</Text>
                        <Text>
                            <SwapRightOutlined style={{ fontSize: 24 }} />
                        </Text>
                    </Space>
                    <Space direction="vertical" size={4}>
                        <Text strong style={{ fontSize: 20 }}>
                            {toTime}
                        </Text>
                        <Paragraph
                            ellipsis={{ rows: 2, tooltip: toStation }}
                            style={{
                                maxWidth: 80,
                                fontSize: 16,
                                marginBottom: 0,
                            }}>
                            {toStation}
                        </Paragraph>
                    </Space>
                </Space>
            </Col>
        </Row>
    )
}

interface Props {
    seat: TrainSeatItemVModel
    train: TrainItemVModel
    trainOrder?: TrainOrderListModel
}

const TrainListSeatItem = (porps: Props): ReactElement => {
    const { seat, trainOrder } = porps
    const { seatName, seatNum, price } = seat
    const navi = useNavigate()
    const _bookClick = () => {
        // 通过useNavigate携带参数跳转到TrainPayConfirm.tsx页面
        navi("/booking/train/confirm", { state: porps })
    }

    const showSeatNum = () => {
        const seatnum = parseInt(seatNum)
        if (seatnum === 99) {
            return <Text>余票充足</Text>
        } else if (seatnum < 99) {
            return (
                <Text>
                    余票&nbsp;
                    <Text style={{ color: blue[5] }}>{seatNum}&nbsp;</Text>张
                </Text>
            )
        } else {
            return <Text>{seatNum}</Text>
        }
    }
    return (
        <Row align="top">
            <Col flex="auto">{seatName}</Col>
            <Col flex="auto">{showSeatNum()}</Col>
            <Col
                flex="auto"
                style={{ fontSize: 18, fontWeight: 700, color: blue[5] }}>
                <span>
                    <dfn>¥</dfn>
                    {price}
                </span>
            </Col>
            <Col flex="auto" style={{ textAlign: "right" }}>
                <Button
                    type="primary"
                    disabled={
                        parseInt(seatNum) === 0 || seatNum === "未开放预售"
                    }
                    onClick={_bookClick}>
                    {trainOrder ? "改签" : "预订"}
                </Button>
            </Col>
        </Row>
    )
}

export const TrainListItem = (props: {
    train: TrainItemVModel
    trainOrder: TrainOrderListModel
}): ReactElement => {
    const { train, trainOrder } = props
    const { seats } = train

    return (
        <Row key={train.id} gutter={[24, 24]} align="top">
            <Col xs={24} sm={24} md={12} lg={12} xl={11}>
                <TrainListSchedule {...train} />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={13}>
                <Space direction="vertical" style={{ width: "100%" }}>
                    {seats &&
                        seats.map((item: TrainSeatItemVModel) => {
                            return (
                                <div key={item.id}>
                                    <TrainListSeatItem
                                        seat={item}
                                        train={train}
                                        trainOrder={trainOrder}
                                    />
                                </div>
                            )
                        })}
                </Space>
            </Col>
        </Row>
    )
}
