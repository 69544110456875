import { DatePicker, Form, Grid, Input, Space, Switch } from "antd"
import dayjs, { Dayjs } from "dayjs"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { RangeValue } from "../../../app/tools"

const mapStateToProps = (state: RootState) => ({
    maxDays: state.applyFormEditor.maxApplyDays,
    intlMaxDays: state.applyFormEditor.maxInternationalApplyDays,
    minLongTermDays: state.applyFormEditor.longTermTravelMinimumDays,
    applyForDeadlineEnable: state.applyFormEditor.applyForDeadlineEnable,
})

type Props = ReturnType<typeof mapStateToProps>

const { Item, useFormInstance, useWatch } = Form
function _ApplyDateRange({
    maxDays,
    intlMaxDays,
    minLongTermDays,
    applyForDeadlineEnable,
}: Props) {
    const { md } = Grid.useBreakpoint()
    const [value, setValue] = useState<RangeValue>()
    const [dates, setDates] = useState<RangeValue>()
    const entity = useAppSelector(state => state.applyFormEditor.applyForm)
    const form = useFormInstance()
    const isIntl = useWatch<boolean | undefined>("international", form)

    useEffect(() => {
        if (entity) {
            //设置默认值
            const { applyStartDate, applyEndDate } = entity
            setValue([dayjs(applyStartDate), dayjs(applyEndDate)])
        } else {
            setValue(null)
        }
    }, [entity])

    useEffect(() => {
        //更新关联字段
        form.resetFields(["applyStartDate", "applyEndDate", "longApplyFor"])
        const start = value?.[0]?.startOf("d").format("x")
        form.setFieldValue("applyStartDate", start && Number.parseInt(start))
        const end = value?.[1]?.endOf("d").format("x")
        form.setFieldValue("applyEndDate", end && Number.parseInt(end))
        if (!isIntl && minLongTermDays > 0) {
            //diff 默认是向下取整，1-1是0天（实际应该算作1天）
            form.setFieldValue(
                "longApplyFor",
                (value?.[1]?.diff(value?.[0], "d") ?? 0) >= minLongTermDays - 1
            )
        }
    }, [value, form, isIntl, minLongTermDays])

    const disabledDate = (curr: Dayjs) => {
        // 公司打开超期开关后，申请单创建时允许设置申请单开始时间至今天之前
        if (applyForDeadlineEnable === false && curr.diff(dayjs(), "d") < 0)
            return true
        else {
            if (!dates) return false
            const max = isIntl ? intlMaxDays : maxDays
            if (max <= 0) return false
            const tooLate = dates[0] && curr.diff(dates[0], "d") >= max
            const tooEarly = dates[1] && dates[1].diff(curr, "d") >= max
            return !!tooEarly || !!tooLate
        }
    }

    const onChange = (v: RangeValue) => {
        setValue(v)
        setTimeout(() => {
            //模拟清空输入触发检测
            form.validateFields(["applyStartDate", "applyEndDate"])
        })
    }

    const endDateValidator = async (_: unknown, value: number) => {
        /// 放置于结束日期字段的自定义校验器
        if (!value) return Promise.resolve()
        const startValue = form.getFieldValue("applyStartDate") as number
        if (!startValue) return Promise.resolve()
        const start = dayjs(startValue, "x")
        const end = dayjs(value, "x")
        if (isIntl && intlMaxDays > 0) {
            if (end.diff(start, "d") > intlMaxDays) {
                throw Error(`国际出差不可超过${intlMaxDays}天`)
            }
        } else if (maxDays > 0) {
            if (end.diff(start, "d") > maxDays) {
                throw Error(`出差日期不可超过${maxDays}天`)
            }
        }
        return Promise.resolve()
    }

    return (
        <>
            <Item<Date[]> label="出差日期" required>
                {/* not form item */}
                <Space>
                    <DatePicker.RangePicker
                        disabled={[!!entity, false]}
                        allowClear={true}
                        placement={!md ? 'bottomRight' : 'bottomLeft'}
                        showTime={!md ? { format: 'HH' } : false} // 使用条件渲染来设置时间选择器
                        format="YYYY-MM-DD"
                        value={value}
                        onChange={onChange}
                        disabledDate={disabledDate}
                        onCalendarChange={setDates}
                    />

                    {!isIntl && minLongTermDays > 0 && (
                        <Item<boolean>
                            name="longApplyFor"
                            initialValue={
                                entity === undefined ? false : undefined
                            }
                            valuePropName="checked"
                            noStyle>
                            <Switch
                                disabled
                                unCheckedChildren="短差"
                                checkedChildren="长差"
                            />
                        </Item>
                    )}
                </Space>
                {!entity && (
                    <Item<number>
                        name="applyStartDate"
                        hidden
                        noStyle
                        rules={[
                            { required: true, message: "请选择出差开始日期" },
                        ]}>
                        <Input />
                    </Item>
                )}
                <Item<number>
                    name="applyEndDate"
                    hidden
                    noStyle
                    dependencies={["applyStartDate"]}
                    rules={[
                        { required: true, message: "请选择出差结束日期" },
                        { validator: endDateValidator },
                    ]}>
                    <Input />
                </Item>
            </Item>
        </>
    )
}

export default connect(mapStateToProps)(_ApplyDateRange)
