import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { Staff } from "../models"
import { accessTokenSelector } from "../user/loginSlice"
import { currentStaffSelector } from "../user/userSlice"
import { queryStaffByNameAPI } from "./staffAPI"

interface StaffState {
    loading: boolean
    staffList?: Array<Staff>
}

const initialState: StaffState = {
    loading: false
}

export const searchStaffByName = createAsyncThunk<Array<Staff>, string>('staff/searchByName', async (name: string, { getState, rejectWithValue }) => {
    if (name === '') return rejectWithValue(null) //name is empty
    const state = getState() as RootState
    const token = accessTokenSelector(state)
    const staff = currentStaffSelector(state)
    if (!token || !staff) return rejectWithValue(null) //not login
    try {
        return await queryStaffByNameAPI(token, staff.companyId, name)
    } catch {
        return rejectWithValue(null) // not found
    }
})

const slice = createSlice({
    name: 'staff',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(searchStaffByName.pending, () => ({ loading: true }))
            .addCase(searchStaffByName.fulfilled, (_, { payload }) => ({ loading: false, staffList: payload }))
            .addCase(searchStaffByName.rejected, () => initialState)
    }
})

export default slice.reducer
