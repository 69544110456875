import { ReactElement, useState } from "react"
import { List, Typography, Radio, Checkbox, Card, Tooltip } from "antd"
import { Traveller } from "../../../models"
import { idNumberMarked } from "../../../applyForm/viewer/TraverllerEditor"
import {
    CardPassportTypeList,
    IdCardType,
    idCardTypeList,
} from "../../../traveller/Editor"
import { OrderListTrainPassengerModel } from "../../../order/service/orderListModel"
import { TravellerSelected } from "../../airplane/payConfirm/BookSelectPassenger"
import { isPassportDisabled } from "../../../applyForm/viewer/TraverllerIdCard"

const { Title } = Typography

//出行人样式组件
export interface Props {
    traveller: Traveller[]
    onChange?: (ids: OrderListTrainPassengerModel[]) => void
}
export const TrainSelectPassenger = (params: Props): ReactElement => {
    const { traveller, onChange } = params
    //默认出行人全部选中
    const [selected, setSelected] = useState<TravellerSelected[]>(
        traveller.map(i => {
            return {
                travellerId: String(i.travellerId),
                selected: false,
                selectedIdIdx: undefined,
            }
        })
    )

    function convertBookingApiValues(
        t: TravellerSelected
    ): OrderListTrainPassengerModel {
        const tr = traveller.find(
            traveller => String(traveller.travellerId) === t.travellerId
        )
        //获取选中的idlist
        const trId = tr?.idList?.[t.selectedIdIdx ?? 0]
        const selectedArrayItem = {
            cardType: idCardTypeList().find(
                it => it.idCardType === trId?.idType
            )?.oldIdCardType,
            passengerName: trId?.name,
            cardNum: trId?.idNumber,
            gender: tr?.gender,
            cardTypeName: idCardTypeList().find(
                it => it.idCardType === trId?.idType
            )?.displayName,
        }
        console.log(selectedArrayItem, 'selectedArrayItem')

        return selectedArrayItem
    }

    const onIdCardChanged = () => {
        const ret = selected
            .filter(it => it.selected)
            .map(convertBookingApiValues)
        onChange?.(ret)
    }

    // 多选框
    function onTravellerClick(traveller: Traveller) {
        const reviseSelected = selected.map(j => {
            //修改选择的selected
            if (j.travellerId === traveller.travellerId) {
                j.selected = !j.selected
                if (!j.selected) {
                    delete j.selectedIdIdx
                } else {
                    const idlist = traveller.idList ?? []
                    if (isPassportDisabled(idlist[0])) {
                        delete j.selected
                    } else {
                        j.selectedIdIdx = 0
                    }
                }
            }
            return j
        })
        setSelected(reviseSelected)
        onIdCardChanged()
    }

    // 单选框
    function onIdCardClick(travellerId: string, idx2: number) {
        const reviseSelectedIdx = selected.map(i => {
            if (i.travellerId === travellerId) {
                i.selected = true
                i.selectedIdIdx = idx2
            }
            return i
        })
        setSelected(reviseSelectedIdx)
        onIdCardChanged()
    }

    return (
        <Card
            size="small"
            bordered={false}
            styles={{ body: {padding: "0px 8px 0px 8px"} }}>
            <List
                key="Passenger"
                dataSource={traveller}
                renderItem={traveller => {
                    //根据travellerId 获取选中数组
                    const travellerIdCard = selected.find(
                        s => s.travellerId === traveller.travellerId
                    )
                    if (!traveller.idList) return
                    //根据选中的下标获取  idCard idName
                    const idCard = travellerIdCard
                        ? traveller.idList[travellerIdCard?.selectedIdIdx ?? -1]
                        : undefined
                    const idName = idCard
                        ? `${idCard.name} - ${idNumberMarked(idCard.idNumber)}`
                        : traveller.name
                    return (
                        <List.Item key={traveller.idNumber}>
                            <List.Item.Meta
                                avatar={
                                    <Checkbox
                                        checked={travellerIdCard?.selected}
                                        onClick={() =>
                                            onTravellerClick(traveller)
                                        }
                                    />
                                }
                                title={
                                    <Typography.Text
                                        style={{ padding: 0, margin: 0 }}>
                                        <Title level={5}>{idName}</Title>
                                    </Typography.Text>
                                }
                                description={
                                    <Radio.Group
                                        optionType="button"
                                        value={
                                            idCard ? idCard.idNumber : undefined
                                        }>
                                        {traveller.idList.map((i, idx) => {
                                            return (
                                                <Tooltip
                                                    title={
                                                        isPassportDisabled(i) &&
                                                        "护照已过期"
                                                    }
                                                    key={idx}>
                                                    <Radio.Button
                                                        value={i.idNumber}
                                                        key={i.idType + idx}
                                                        disabled={isPassportDisabled(
                                                            i
                                                        )}
                                                        onClick={() =>
                                                            onIdCardClick(
                                                                String(
                                                                    traveller.travellerId
                                                                ),
                                                                idx
                                                            )
                                                        }>
                                                        {i.idType ===
                                                            IdCardType.PASSPORT &&
                                                            CardPassportTypeList().find(
                                                                it =>
                                                                    it.passportType ===
                                                                    i.passportType
                                                            )?.displayName}
                                                        {
                                                            idCardTypeList().find(
                                                                it =>
                                                                    it.idCardType ===
                                                                    i.idType
                                                            )?.displayName
                                                        }
                                                    </Radio.Button>
                                                </Tooltip>
                                            )
                                        })}
                                    </Radio.Group>
                                }
                            />
                        </List.Item>
                    )
                }}></List>
        </Card>
    )
}
