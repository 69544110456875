import { Button, Flex, Form, Modal, Space, Typography, Drawer, Grid, Tooltip, Table, TableColumnsType } from 'antd'
import React, { useEffect, useState } from 'react'
import ReimburseSelector from './ReimburseSelector'
import { Accounts, findByReimburseList, findStaffId, setAccountsState } from '../listSlice'
import { connect } from 'react-redux'
import { TitleAccountsType, lastFails, listAccountsType } from '../../book/const'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { orange } from '@ant-design/colors'
import IconFont from '../../../components/IconFont'
import Editor from '../../account/Editor'
import { entryReset, resetCreateState, resetDetailsState } from '../../account/accountSlice'
import { volcano } from '@ant-design/colors'
import AccountsList from '../../account/AccountsList'
import dayjs from 'dayjs'
import AccountEditButton from './components/AccountEditButton'

const { Text } = Typography


type ExpenseClaimProps = {
    value?: Accounts[]
    onChange?: (v?: Accounts[]) => void
}

const AddAccountsList = ({ value, onChange }: ExpenseClaimProps) => {
    const getAccounts = useAppSelector(state => state.account.getAccounts)
    const [open, setOpen] = useState(false)
    //  个性化布局
    const { lg } = Grid.useBreakpoint()
    const dispatch = useAppDispatch()
    const [modalVisiable, setModalVisiable] = useState(false)
    const [outValue, setOutValue] = useState<number>()
    const [mode, setMode] = useState<"create" | "interlink">('create')
    const createAccount = useAppSelector(state => state.account.createAccount)
    const AccountsDetails = useAppSelector(state => state.account.AccountsDetails)
    const updateLoading = useAppSelector(state => state.account.updateLoading)
    const createLoading = useAppSelector(state => state.account.createLoading)
    // 员工列表
    const staffIdList = useAppSelector(state => state.reimburseList.staffIdList || state.reimburesEditor.reimburesForm?.staffIdList)
    // 超标原因列表
    const exceedReasonList = useAppSelector(state => state.user.exceedReasonList)
    // 查询申请单关联账目数据
    const ApplyFormAccount = useAppSelector(state => state.applyFormEditor.ApplyFormAccount)
    // 
    const lastFail = useAppSelector(state => state.reimburesEditor.reimburesForm?.extraInfo.validateItem?.lastFails)

    useEffect(() => {
        // 新建账目
        if (createLoading === 'fulfilled' && createAccount) {
            const _value = []
            value && _value.push(...value)
            _value.push(createAccount)
            // 保存账目loading
            dispatch(resetCreateState())
            // 关联账目Loading
            dispatch(setAccountsState())
            onChange?.(_value)
            //初始化加载 关联账目接口
            dispatch(findByReimburseList({ page: 0, size: 10 }))
        }
        // 更新账目
        if (updateLoading === 'fulfilled' && AccountsDetails) {
            const list = [...value ?? []]
            const index = list?.findIndex(item => item.accountsId === AccountsDetails.accountsId)
            if (index !== -1) {
                list.splice(index, 1, AccountsDetails)
            }
            //初始化加载 关联账目接口
            dispatch(findByReimburseList({ page: 0, size: 10 }))
            dispatch(resetDetailsState())
            onChange?.(list)
        }
        if (getAccounts) {
            const list = [...value ?? []]
            const index = list?.findIndex(item => item.accountsId === getAccounts.accountsId)
            if (index !== -1) {
                list.splice(index, 1, getAccounts)
            }
            onChange?.(list)
            dispatch(entryReset())
        }
        // 调用归属人接口
        const staffIds = new Set(value?.map(it => {
            if (it.customerList) {
                return it.customerList.map(i => i.staffId)
            } else {
                return [it.staffId]
            }
        }).flat())

        dispatch(findStaffId(Array.from(staffIds)))

    }, [AccountsDetails, createAccount, createLoading, dispatch, getAccounts, onChange, updateLoading, value])


    const handleClick = (value: 'create' | 'interlink') => {
        setMode(value)
        setModalVisiable(true)
    }

    // 删除
    const onDeleteClick = (t: Accounts) => {
        const _v = value?.filter(it => it.accountsId !== t.accountsId) ?? []
        onChange?.(_v)
    }


    const columns: TableColumnsType<Accounts> = [
        {
            title: "标题",
            dataIndex: "accountsType",
            align: 'center',
            width: 40,
            render: (_, item) => {
                return <Tooltip placement="top" title={TitleAccountsType(item.accountsType)}>
                    <IconFont type={listAccountsType(item.accountsType)} style={{ fontSize: '30px' }} alt='' />
                </Tooltip>
            }
        },
        {
            title: "出发地/目的地",
            align: 'center',
            width: 150,
            render: (_, item) => {
                return <Space direction='horizontal'>
                    {
                        (/^\d+$/.test(item?.accountsStartData)) ? <Text>{dayjs(Number(item?.accountsStartData)).format("YYYY-MM-DD")}</Text> : <Text style={{ width: 80 }} ellipsis={{ tooltip: item?.accountsStartData }}>{item.accountsStartData}</Text>
                    }
                    <IconFont type='icon-jiantou' />
                    {
                        (/^\d+$/.test(item?.accountsEndData)) ? <Text>{dayjs(Number(item?.accountsEndData)).format("YYYY-MM-DD")}</Text> : <Text style={{ width: 80 }} ellipsis={{ tooltip: item.accountsEndData }}>{item.accountsEndData}</Text>
                    }
                </Space>
            }
        },
        {
            title: "消费时间",
            dataIndex: "accountDate",
            align: 'center',
            width: 200,
            // <Text>消费于</Text>：<Text>{item.accountDate}</Text>
        },
        {
            title: "归属人",
            width: 80,
            align: 'center',
            render: (_, item) => {
                if (item.customerList) {
                    return <Text>
                        {
                            item.customerList.map(it => {
                                if (it.exception) {
                                    return it.traveller?.name
                                } else {
                                    return staffIdList?.find(it => it.staffId === item.staffId)?.staffUserName
                                }
                            })
                        }
                    </Text>
                } else {
                    return staffIdList?.find(it => it.staffId === item.staffId)?.staffUserName
                }
            }
        },
        {
            title: "金额",
            align: 'center',
            dataIndex: "accountsSumMoney3",
            width: 100,
        },
        {
            title: "账目类型",
            align: 'center',
            width: 90,
            render: (_, item) => {
                return <Text>{item.corpAccounts ? '对公' : '个人'}</Text>
            }
        },
        {
            title: "超标原因",
            align: 'center',
            width: 150,
            render: (_, item) => {
                return <Text style={{ color: orange[5] }}>{exceedReasonList?.find(it => it.exceedReasonId === item.exceedReasonId)?.content}</Text>
            }
        },
        {
            title: "规则检查",
            width: 250,
            render: (_, item) => {
                return <Text>
                    {lastFail && lastFail?.filter(i => i.refId === item.accountsId).map((it, idx) => {
                        return <Flex key={idx} style={{ color: volcano[5] }}>{lastFails(it)}</Flex>
                    })}
                </Text>
            },
            hidden: !lastFail || lastFail.length === 0
        },
        {
            title: "操作",
            fixed: "right",
            width: "auto",
            render: (_, i) => {
                return (
                    <>
                        {/* 修改 */}
                        < AccountEditButton item={i} />
                        {ApplyFormAccount && ApplyFormAccount?.filter(it => it.oid === i.oid).length > 0 ? "" :
                            <Button danger type="text" size="small" onClick={() => onDeleteClick(i)}>移除</Button>}
                    </>
                )
            },
        },
    ]

    return (
        <>
            {value && value?.length > 0 &&
                <Table
                    rowKey={(record) => record.accountsId ?? ""}
                    dataSource={value}
                    columns={columns}
                    scroll={{ x: "max-content" }}
                />
            }
            <Modal
                title={mode === 'interlink' ? "关联账目" : '创建新账目'}
                open={modalVisiable}
                closable={true}
                centered
                footer={null}
                width={lg ? '80%' : '100%'}
                destroyOnClose
                onCancel={() => setModalVisiable(false)}
            >
                {/* 选择已有账目  */}
                {mode === 'interlink' && <ReimburseSelector mode={mode} accountsOnClick={setModalVisiable} onChange={onChange} value={value} />}
                {mode === 'create' &&
                    <AccountsList mode='reimbures' setOutValue={setOutValue} setModalVisiable={setModalVisiable} setOpen={setOpen} />
                }
            </Modal>
            <Drawer title="创建账目" width={lg ? 640 : "75vw"} onClose={() => setOpen(false)} open={open} destroyOnClose>
                <Editor outValue={outValue} setOpen={setOpen} />
            </Drawer>
            <Space direction='horizontal'>
                <Button type="dashed" onClick={() => handleClick('create')} >创建新账目</Button>
                <Button type="dashed" onClick={() => handleClick('interlink')} >选择已有账目</Button >
            </Space>
        </>
    )
}
const AddAccounts = connect()(AddAccountsList)

export default function ReimburseAccount() {

    return (
        <Form.Item
            name="accounts"
            label="账目"
            rules={[
                { required: true, message: "请添加账目" },
            ]}>
            <AddAccounts />
        </Form.Item>
    )
}
