import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { message, Modal } from "antd"
import { RootState } from "../../app/store"
import { ApplyForm, AsyncActionState, BookingStrictMode, BusinessPurposes, Company, CustomField, DocumentType, PreCheckError, Staff, Traveller } from "../models"
import { findByCompanyIdApi } from "../org/orgApi"
import { findByStaffIdApi } from "../staff/staffAPI"
import { accessTokenSelector } from "../user/loginSlice"
import { currentCompanyIdSelector, currentStaffIdSelector, currentStaffSelector } from "../user/userSlice"
import { findCustomFieldByCompanyIdApi, findByIdApi as findApplyFormByIdApi, patchApplyForm, postApplyForm, updatePreCheckApi, getApplyFormAccount } from "./api"
import { fetchApplyFormDetail, resetState, setUseSubmit } from "./viewerSlice"
import { Accounts } from "../reimburseForm/listSlice"

interface State {
    loading: AsyncActionState
    available: boolean
    error?: string | number
    //通用
    purposes?: BusinessPurposes[]//出差目的
    maxApplyDays: number //最长申请天数
    maxInternationalApplyDays: number //国际差旅最长天数
    longTermTravelMinimumDays: number //长差最短天数
    applyForDeadlineEnable: boolean
    customFields?: CustomField[] //自定义字段
    customFieldsPersonal?: CustomField[] //因私出行自定义字段
    applyId?: string
    //创建
    usePersonalApply: boolean //因私出行
    bookingStrictMode: BookingStrictMode//预订控制模式
    //更新
    applyForm?: ApplyForm
    updated?: boolean
    updatedLoading: AsyncActionState
    updatePreCheckError?: PreCheckError
    preChecked: boolean
    ApplyFormAccount?: Accounts[]
}

const initialState: State = {
    loading: 'idle',
    available: true,
    maxApplyDays: 0,
    maxInternationalApplyDays: 0,
    longTermTravelMinimumDays: 0,
    usePersonalApply: false,
    bookingStrictMode: BookingStrictMode.OFF,
    applyForDeadlineEnable: false,
    updatedLoading: "idle",
    preChecked: false,
}

//加载一些必要的初始化信息
export const initEditor = createAsyncThunk('applyFormEditor/init',
    async (id: string | undefined, { getState, rejectWithValue, dispatch }) => {
        const state = getState() as RootState
        const token = accessTokenSelector(state)
        const companyId = currentCompanyIdSelector(state)
        const staffId = currentStaffIdSelector(state)
        if (!token || !companyId || !staffId) throw rejectWithValue(401)

        try {
            //更新公司配置
            const company = await findByCompanyIdApi(token, companyId)
            //更新出差申请编辑器可用性
            const staff = await findByStaffIdApi(token, staffId)
            dispatch(setAvailable(!staff.authLocked))
            const EmployeeCollection = { company: company, staff: staff }
            dispatch(updateCompanyConfig(EmployeeCollection))
            //获取单据信息
            if (id) {
                const data = await findApplyFormByIdApi(token, id)
                if (data) {
                    dispatch(setApplyForm({ ...data, applyProcesses: undefined } as ApplyForm))
                }
                else throw rejectWithValue("未查询到出差申请")
            }
        } catch (err) {
            throw rejectWithValue(err)
        }

        try {
            //获取自定义字段配置
            const cfs = await findCustomFieldByCompanyIdApi(token, companyId)
            console.log("🚀 ~ cfs:", cfs)
            dispatch(setCustomFields(cfs))
        } catch (_) {
            //do nothing, dont care available.
        }
    })

export const createApplyForm = createAsyncThunk('applyFormEditor/create',
    async (form: ApplyForm, { getState, rejectWithValue }) => {
        const appState = getState() as RootState
        const token = accessTokenSelector(appState)
        const staff = currentStaffSelector(appState)
        if (!token || !staff) throw rejectWithValue(401)
        try {
            const ret = await postApplyForm(token, { ...form, staffId: staff.staffId, companyId: staff.companyId })

            return ret.applyId

        } catch (err) {
            throw rejectWithValue(err)
        }
    })

const applyFormSelector = (state: RootState) => state.applyFormEditor.applyForm

// 更新申请单
export const updateApplyForm = createAsyncThunk('applyFormEditor/update',
    async (values: unknown, { dispatch, getState, rejectWithValue }) => {
        const appState = getState() as RootState
        const token = accessTokenSelector(appState)
        const applyForm = applyFormSelector(appState)
        if (!token) throw rejectWithValue(401)
        if (!applyForm?.applyId) throw rejectWithValue(400)

        try {
            const ret = await patchApplyForm(token, applyForm.applyId, values)
            dispatch(resetState())
            if (ret.approvalState === -1 && applyForm.approvalState === 1) {
                //之前已审批的单据更新后需要提交审批
                //新接口上会重置审批状态
                dispatch(setUseSubmit(true))
            }
        } catch (err) {
            throw rejectWithValue(err)
        }
        // return {}
    })

// 升级申请单中的出行人信息
type PatchTravellerReq = { applyId: string, body: { travellers?: Traveller[] } }
export const updateTravellers = createAsyncThunk('applyFormEditor/traveller/updateIdList',
    async ({ applyId, body }: PatchTravellerReq, { dispatch, getState, rejectWithValue }) => {
        const appState = getState() as RootState
        const token = accessTokenSelector(appState)
        if (!token) throw rejectWithValue(401)
        try {
            await patchApplyForm(token, applyId, body)
            message.success('更新成功')
            dispatch(resetState())/*重置状态*/
            dispatch(fetchApplyFormDetail(applyId)) /* 更新成功后重新查询详情 */
        } catch (err) {
            Modal.error({
                title: "更新失败",
                centered: true,
                content: `请求错误：${err}`,
            })
            throw rejectWithValue(err)
        }
    }
)

// 申请单更新预检接口
export const updatePreCheck = createAsyncThunk('applyFormEditor/traveller/ApplyFormPreCheck',
    async ({ applyId, values }: { applyId: string, values: ApplyForm }, { getState, rejectWithValue }) => {
        const appState = getState() as RootState
        const token = accessTokenSelector(appState)
        if (!token) throw rejectWithValue(401)
        try {
            return await updatePreCheckApi(token, applyId, values)
        } catch (err) {
            throw rejectWithValue(err)
        }
    }
)
// 查询申请单关联账目
export const fetchApplyFormAccount = createAsyncThunk('applyForm/fetchApplyFormAccount',
    async (applyId: string, { getState, rejectWithValue }) => {
        const appState = getState() as RootState
        const token = accessTokenSelector(appState)
        if (!token) throw rejectWithValue(401)
        try {

            const res = await getApplyFormAccount(token, applyId)
            return res
        } catch (err) {
            throw rejectWithValue(err)
        }
    }
)

const slice = createSlice({
    name: 'applyFormEditor',
    initialState,
    reducers: {
        reset: (_) => {
            return { ...initialState }
        },
        updateCompanyConfig: (state, { payload }: PayloadAction<{ company: Company, staff: Staff }>) => {
            const { company, staff } = payload
            const { companyBasicConfig, businessPurposesList } = company
            state.purposes = businessPurposesList
            state.usePersonalApply = companyBasicConfig.usePersonalApply
            state.bookingStrictMode = companyBasicConfig.bookingStrictMode ?? BookingStrictMode.OFF
            state.maxApplyDays = companyBasicConfig.maxApplyDays ?? 0
            state.maxInternationalApplyDays = companyBasicConfig.maxInternationalApplyDays ?? 0
            state.longTermTravelMinimumDays = companyBasicConfig.longTermTravelMinimumDays ?? 0
            state.applyForDeadlineEnable = (company.applyForDeadlineEnable || staff.applyForDeadlineEnable) ?? false
        },

        setCustomFields: (state, { payload }: PayloadAction<CustomField[]>) => {
            state.customFields = payload.filter(cf => cf.documentType === DocumentType.APPLY_TRAVEL)
            state.customFieldsPersonal = payload.filter(cf => cf.documentType === DocumentType.APPLY_PERSONAL_TRAVEL)
        },
        setAvailable: (state, { payload }: PayloadAction<boolean>) => { state.available = payload },
        setApplyForm: (state, { payload }: PayloadAction<ApplyForm>) => {
            state.applyForm = payload
        },
    },
    extraReducers: builder => {
        builder
            .addCase(initEditor.pending, state => { state.loading = "pending"; delete state.error })
            .addCase(initEditor.rejected, (state, { payload }) => {
                const error = payload as string | number | undefined
                return { ...state, loading: "reject", error }
            })
            .addCase(initEditor.fulfilled, state => { state.loading = "fulfilled" })
            // ---
            .addCase(createApplyForm.pending, state => { state.loading = "pending"; delete state.error })
            .addCase(createApplyForm.fulfilled, (state, { payload }) => {
                state.loading = "fulfilled"
                state.applyId = payload
            })
            .addCase(createApplyForm.rejected, (state, { payload }) => {
                state.loading = "reject"
                state.error = payload as (string | number)
            })
            // ---
            .addCase(updateApplyForm.pending, state => { state.loading = "pending"; delete state.error })
            .addCase(updateApplyForm.fulfilled, (state) => {
                state.loading = "fulfilled"
                state.updated = true
            })
            .addCase(updateApplyForm.rejected, (state, { payload }) => {
                state.loading = "reject"
                state.error = payload as (string | number)
            })
            // 升级申请单
            .addCase(updateTravellers.pending, state => { state.updatedLoading = "pending" })
            .addCase(updateTravellers.fulfilled, (state) => { state.updatedLoading = "fulfilled" })
            .addCase(updateTravellers.rejected, (state) => { state.updatedLoading = "reject" })
            // 预检
            .addCase(updatePreCheck.pending, (state) => {
                delete state.updatePreCheckError
                state.preChecked = false
            })
            .addCase(updatePreCheck.fulfilled, (state) => {
                state.preChecked = true
            })
            .addCase(updatePreCheck.rejected, (state, { payload }) => {
                state.updatePreCheckError = payload as PreCheckError
                state.preChecked = true
            })
            // 查询申请单关联账目
            .addCase(fetchApplyFormAccount.pending, state => {
                delete state.ApplyFormAccount
            })
            .addCase(fetchApplyFormAccount.fulfilled, (state, { payload }) => {
                state.ApplyFormAccount = payload
            })
            .addCase(fetchApplyFormAccount.rejected, (state) => {
                delete state.ApplyFormAccount
            })
    },
})

export const { reset, updateCompanyConfig, setAvailable, setCustomFields, setApplyForm } = slice.actions
export default slice.reducer
