import { ReactElement } from "react"
import { Card, Row, Col, Space, Typography } from "antd"

import { TrainOrderListModel } from "../../service/orderListModel"
import {
    OrderListItemHeaderCpn,
    OrderListItemPriceGroupCpn,
} from "../view/OrderListItemCommon"
import { trainOrderStatusToStr } from "../../../book/const"
import { ShowStateOperate } from "../../common/view/OrderStateOperate"
import { useNavigate } from "react-router-dom"

interface Props {
    trainItem: TrainOrderListModel
    changeClick?: () => void
    refundClick?: () => void
}
const { Text } = Typography
export const TrainListCardItem = (props: Props): ReactElement => {
    const { trainItem, changeClick, refundClick } = props

    const {
        customId,
        orderTime,
        fromPlace,
        toPlace,
        seat,
        passenger,
        total,
        orderState,
        packageId,
        train,
    } = trainItem
    // 路由跳转传参
    const navi = useNavigate()
    const keywordClick = (item: TrainOrderListModel) => {
        // 跳转到OrderDetail.tsx
        navi("/order/list/detail", { state: item })
    }

    const _trainChangeClick = () => {
        // 火车改签
        changeClick?.()
    }
    const _trainRefundClick = () => {
        // 火车退票
        refundClick?.()
    }

    return (
        <Card
            type="inner"
            bordered={false}
            title={
                <OrderListItemHeaderCpn
                    publicItem={trainItem}
                    customId={customId}
                    orderTime={orderTime}
                    packageId={packageId}
                />
            }>
            <Row
                className="clickable"
                onClick={() => {
                    keywordClick(trainItem)
                }}>
                <Col flex="2">
                    <Space direction="vertical" size={2}>
                        <Text strong style={{ fontSize: 16 }}>
                            {fromPlace} - {toPlace}
                        </Text>
                        <Text>
                            出发时间：{train?.departureDate?.substring(16, 0)}{" "}
                        </Text>
                        <Text strong>
                            车次：<Text strong>{train?.trainNo}</Text>{" "}
                            <Text strong>{seat}</Text>
                        </Text>
                        <Text>
                            出行人：{passenger && passenger.passengerName}
                        </Text>
                    </Space>
                </Col>
                <Col flex="none">
                    <OrderListItemPriceGroupCpn
                        total={total}
                        status={trainOrderStatusToStr(orderState)}
                    />
                </Col>
            </Row>
            <ShowStateOperate
                orderModel={trainItem}
                trainChangeClick={_trainChangeClick}
                trainRefundClick={_trainRefundClick}
            />
        </Card>
    )
}
