import { ReactElement } from "react"
import { Route, Routes } from "react-router-dom"
import { TrainList } from "./list/TrainList"
import { TrainPayConfirm } from "./payConfirm/TrainPayConfirm"
import { TrainPaySuccess } from "./payConfirm/TrainPaySuccess"

export default function BookingAirplane(): ReactElement {
    return (
        <Routes>
            <Route path="" element={<TrainList />} />
            <Route path="confirm" element={<TrainPayConfirm />} />
            <Route path="success" element={<TrainPaySuccess />} />
        </Routes>
    )
}
