import { Route, Routes } from "react-router-dom"
import Editor from "./Editor"
import List from "./List"
import OperationResult from "./OperationResult"
import Viewer from "./Viewer"

export default function ApplyFormRoutes(): JSX.Element {
    return (
        <Routes>
            <Route path=":documentId" element={<Viewer />} />
            <Route path="result" element={<OperationResult />} />
            <Route path="list" element={<List />} />
            <Route path=":documentId/edit" element={<Editor />} />
            <Route path="create" element={<Editor />} />
        </Routes>
    )
}
