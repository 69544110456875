import { ReactElement, useEffect } from "react"
import { connect } from "react-redux"
import { List } from "antd"
import { AppDispatch, RootState } from "../../../../app/store"
import { AirplaneOrderRefundRulesParams } from "../../service/orderModuleApi"
import { fetchPlaneRefundRules } from "../../service/orderModuleSlice"

const mapStateToProps = (state: RootState) => ({
    planeRefundRules: state.orderModule.planeRefundRules,
    loading: state.orderModule.isLoading,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    // 飞机详情退改规则
    planeRefundRulesInfo: (params: AirplaneOrderRefundRulesParams) => {
        dispatch(fetchPlaneRefundRules(params))
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        params: AirplaneOrderRefundRulesParams
    }

const _planeOrderDetailRulesCpn = (props: Props): ReactElement => {
    const { planeRefundRules, planeRefundRulesInfo, params, loading } = props

    useEffect(() => {
        if (!planeRefundRules)
            // 飞机详情退改规则
            planeRefundRulesInfo(params)
    }, [planeRefundRules, planeRefundRulesInfo, params])
    const data =
        [
            `退票：${planeRefundRules?.refund ?? "--"}`,
            `改签：${planeRefundRules?.change ?? "--"}`,
        ] || []
    return (
        <List
            dataSource={data}
            loading={loading}
            renderItem={item => <List.Item>{item}</List.Item>}
        />
    )
}

export const PlaneDetailRefundRulesCpn = connect(
    mapStateToProps,
    mapDispatchToProps
)(_planeOrderDetailRulesCpn)
