import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { AsyncActionState } from "../models"
import { accessTokenSelector } from "../user/loginSlice"
import { currentCompanyIdSelector } from "../user/userSlice"
import type { BatchOpType } from "./ApplyFormApprovalOpDialog"
import { ApplyFormTodo, fetchPutApprovalApi } from "./approvalApi"

export interface BatchApplyReqParams {
    selected: Array<ApplyFormTodo>
    state: 1 | 2
    content?: string
}

export interface BatchApplyState {
    visible: boolean
    loading: AsyncActionState
    type: BatchOpType
    selectedValues?: Array<ApplyFormTodo>
    errors?: string[]
}

const initialState: BatchApplyState = {
    visible: false,
    loading: "idle",
    type: "accept"
}


export const fetchBatchApplyOps = createAsyncThunk("applyForm/Approval/batchPutApproval",
    async (req: BatchApplyReqParams, { getState, rejectWithValue }) => {
        const appState = getState() as RootState
        const token = accessTokenSelector(appState)
        const companyId = currentCompanyIdSelector(appState)

        if (!token || !companyId) {
            return rejectWithValue("登录会话超时，请重新登录！")
        }


        const errors = []
        for (const item of req.selected) {
            try {
                await fetchPutApprovalApi(token, item.approvalId, { ...req })
            } catch (err) {
                errors.push(item.documentNum + '：' + (err instanceof Error ? '网络异常' : err))
            }
        }

        if (errors.length > 0) return rejectWithValue(errors)
    })


const applyFormApprovalSlice = createSlice({
    name: "applyForm/Approval",
    initialState,
    reducers: {
        showApprovalOpDialog: (state, { payload }: PayloadAction<{ type: BatchOpType, selectedValues: Array<ApplyFormTodo> }>) => {
            state.visible = true
            state.loading = "idle"
            state.type = payload.type
            state.selectedValues = payload.selectedValues
        },
        dismissApprovalOpDialog: (state) => {
            state.visible = false
            state.loading = "idle"
            delete state.selectedValues
            delete state.errors
        },
    },
    extraReducers: builder => {//额外的action increment
        builder
            .addCase(fetchBatchApplyOps.pending, (state) => {
                delete state.errors
                state.loading = "pending"
            })
            .addCase(fetchBatchApplyOps.rejected, (state, { payload }) => {
                state.loading = "reject"
                state.errors = payload as string[]
            })
            .addCase(fetchBatchApplyOps.fulfilled, (state) => {
                state.loading = "fulfilled"
            })


    }
})

export const { showApprovalOpDialog, dismissApprovalOpDialog } = applyFormApprovalSlice.actions
export default applyFormApprovalSlice.reducer
