import { blue } from "@ant-design/colors"
import { ExclamationCircleOutlined, UserOutlined } from "@ant-design/icons"
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    message,
    Modal,
    Row,
    Select,
    Slider,
    Space,
    Typography,
} from "antd"
import { Rule } from "antd/lib/form"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { AppDispatch, RootState } from "../../../../app/store"
import { hotelBooking, HotelBookingFormValues } from "../hotelSlice"
import { SelectPerson } from "./SelectPerson"

const mapStateToProps = (state: RootState) => ({
    hotel: state.bookHotel.hotelDetail,
    checkInDate: state.bookHotel.hotelListQuery?.checkInDate || "",
    checkOutDate: state.bookHotel.hotelListQuery?.checkOutDate || "",
    loading: state.bookHotel.hotelLoading,
    error: state.bookHotel.bookingError,
    bookingPriceError: state.bookHotel.bookingPriceError,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    // 酒店预定下单
    hotelBooking: (v: HotelBookingFormValues) => {
        dispatch(hotelBooking(v))
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

const { Title, Text } = Typography
const { Item } = Form

const _pay = ({
    hotel,
    checkInDate,
    checkOutDate,
    loading,
    error,
    hotelBooking,
    bookingPriceError,
}: Props) => {
    // 获取路由携带参数
    const { id } = useParams<{ id: string }>()
    const [booked, setBooked] = useState(false)
    const [travellerRule, setTravellerRule] = useState<Rule>({})
    const [formValues, setFormValues] = useState<HotelBookingFormValues>()
    // 对话框开关
    const [modalVisible, setModalVisible] = useState(false)
    // 创建 Form 实例，用于管理所有数据状态
    const [form] = Form.useForm()
    // 路由跳转传参
    const navi = useNavigate()

    useEffect(() => {
        // 返回一个符合条件的值
        const room = hotel?.rooms?.find(r => r.products.find(p => p.id === id))
        if (room) {
            setTravellerRule({
                type: "array",
                min: 1,
                max: Number.parseInt(room.capacity),
            })
        } else {
            message.warning("缺少必要参数")
            // 返回上一级
            navi(-1)
        }
    }, [hotel, id, navi])

    useEffect(() => {
        if (!booked && bookingPriceError) {
            if (bookingPriceError?.rc === 1001) {
                Modal.confirm({
                    okText: "继续",
                    content: `${bookingPriceError?.msg}`,
                    onOk: () => {
                        setBooked(true)
                        hotelBooking(formValues as HotelBookingFormValues)
                    },
                    onCancel: () => {
                        navi("/booking/hotel")
                    },
                })
            } else {
                setBooked(true)
            }
            // 关闭对话框
            setModalVisible(false)
        }
    }, [booked, bookingPriceError, formValues, hotelBooking, navi])

    if ((booked && !loading) || error) return <Navigate to="../result" />
    // 返回一个符合条件的值
    const room = hotel?.rooms?.filter(r => r.products.find(p => p.id === id))[0]
    const product = room?.products.filter(p => p.id === id)[0]
    if (!room || !product) return <Navigate to="/booking/hotel" />
    // 天数
    const night = product.productPrices.length
    // 金额
    const unitPrice = product.productPrices
        .map(p => p.price)
        .reduce((p, c) => p + c)
    // 字段值更新时触发回调事件
    const handleValuesChange = (cv: unknown) => {
        const changed = cv as HotelBookingFormValues
        if (changed.roomCount) {
            const min = changed.roomCount
            const max = Number.parseInt(room.capacity) * min
            setTravellerRule({ ...travellerRule, min, max })
        }
    }
    // 点击确定回调
    const handleBooking = () => {
        setBooked(false)
        // 获取一组字段名对应的值，默认返回现存字段值，当调用
        const v = form.getFieldsValue()
        setFormValues(v)
        hotelBooking(v as HotelBookingFormValues)
    }

    return (
        <>
            <Modal
                centered
                title="下单确认"
                open={modalVisible}
                onCancel={() => setModalVisible(false)}
                onOk={handleBooking}
                okButtonProps={{ loading: loading }}>
                您确定要下单吗？
            </Modal>
            <Form
                form={form}
                layout="vertical"
                requiredMark={false}
                onValuesChange={handleValuesChange}
                scrollToFirstError={true}
                onFinish={() => setModalVisible(true)}>
                <Item noStyle name="productId" initialValue={product.id}>
                    <input hidden />
                </Item>
                <Item
                    noStyle
                    name="supplierId"
                    initialValue={product.supplierId}>
                    <input hidden />
                </Item>
                <Row>
                    <Col flex="5 300px">
                        <div className="container">
                            <Title level={3} style={{ color: blue[9] }}>
                                {hotel.hotelName}
                            </Title>
                            <Text>{hotel.address}</Text>
                            <Divider />
                            <Title level={4} style={{ color: blue[9] }}>
                                {room.roomName}
                            </Title>
                            <div>
                                <UserOutlined />
                                &nbsp;<Text>{room.capacity}人</Text>
                                <Divider type="vertical" />
                                <Text>{room.area}&nbsp;m²</Text>
                                <Divider type="vertical" />
                                <Text>{product.bedType}</Text>
                                <Divider type="vertical" />
                                <Text>{product.windowType}</Text>
                                <Divider type="vertical" />
                                <Text>{product.breakfastDesc}</Text>
                                <Divider type="vertical" />
                                <Text>{product.broadNetDesc}</Text>
                            </div>
                        </div>
                        <div className="container">
                            <Row gutter={8}>
                                <Col flex="none">
                                    <Item
                                        label="入住日期"
                                        name="checkInDate"
                                        initialValue={dayjs(checkInDate)}
                                        rules={[{ required: true }]}>
                                        <DatePicker disabled />
                                    </Item>
                                </Col>
                                <Col flex="8px" />
                                <Col flex="none">
                                    <Item
                                        label="离店日期"
                                        name="checkOutDate"
                                        initialValue={dayjs(checkOutDate)}
                                        rules={[{ required: true }]}>
                                        <DatePicker disabled />
                                    </Item>
                                </Col>
                                <Col flex="auto" />
                                <Col flex="266px">
                                    <Item
                                        label="房间数"
                                        name="roomCount"
                                        initialValue={1}
                                        rules={[{ required: true }]}>
                                        <Slider
                                            min={1}
                                            max={8}
                                            marks={{
                                                1: 1,
                                                2: 2,
                                                3: 3,
                                                4: 4,
                                                5: 5,
                                                6: 6,
                                                7: 7,
                                                8: 8,
                                            }}
                                        />
                                    </Item>
                                </Col>
                            </Row>
                            <Divider />
                            <Title level={4}>住客资料</Title>
                            <Text type="secondary">
                                <ExclamationCircleOutlined />
                                &nbsp;请按实际入住人数选择
                            </Text>
                            <Item<HotelBookingFormValues>
                                label="入住人"
                                labelCol={{ hidden: true }}
                                name="selectedTraveller"
                                initialValue={[]}
                                rules={[travellerRule]}>
                                <SelectPerson />
                            </Item>
                            <Item
                                label="联系电话"
                                name="contact"
                                labelCol={{ span: 24 }}
                                rules={[{ required: true, len: 11 }]}>
                                <Input type="number" />
                            </Item>
                            <Divider />
                            <Title level={4}>到店时间</Title>
                            <Text type="secondary">房间整晚保留</Text>
                            <Item
                                name="arrivalEarlyTime"
                                initialValue={"12:00"}
                                rules={[{ required: true }]}>
                                <Select>
                                    <Select.Option
                                        key="12:00"
                                        value="12:00"
                                        children="12:00"
                                    />
                                    <Select.Option
                                        key="13:00"
                                        value="13:00"
                                        children="13:00"
                                    />
                                    <Select.Option
                                        key="14:00"
                                        value="14:00"
                                        children="14:00"
                                    />
                                    <Select.Option
                                        key="15:00"
                                        value="15:00"
                                        children="15:00"
                                    />
                                    <Select.Option
                                        key="16:00"
                                        value="16:00"
                                        children="16:00"
                                    />
                                    <Select.Option
                                        key="17:00"
                                        value="17:00"
                                        children="17:00"
                                    />
                                    <Select.Option
                                        key="18:00"
                                        value="18:00"
                                        children="18:00"
                                    />
                                    <Select.Option
                                        key="19:00"
                                        value="19:00"
                                        children="19:00"
                                    />
                                    <Select.Option
                                        key="20:00"
                                        value="20:00"
                                        children="20:00"
                                    />
                                    <Select.Option
                                        key="21:00"
                                        value="21:00"
                                        children="21:00"
                                    />
                                    <Select.Option
                                        key="22:00"
                                        value="22:00"
                                        children="22:00"
                                    />
                                    <Select.Option
                                        key="23:00"
                                        value="23:00"
                                        children="23:00"
                                    />
                                    <Select.Option
                                        key="00:00"
                                        value="00:00"
                                        children="00:00"
                                    />
                                </Select>
                            </Item>
                        </div>
                        <div className="container">
                            <Row>
                                <Col flex="auto">
                                    <Item
                                        noStyle
                                        shouldUpdate={(p, c) =>
                                            p["roomCount"] !== c["roomCount"]
                                        }>
                                        {({ getFieldValue }) => {
                                            const count =
                                                getFieldValue("roomCount")
                                            const amount = count * unitPrice
                                            return (
                                                <>
                                                    <Item
                                                        name="amount"
                                                        initialValue={amount}
                                                        noStyle>
                                                        <input hidden />
                                                    </Item>
                                                    <Text
                                                        style={{ fontSize: 20 }}
                                                        strong>
                                                        总计￥
                                                        <Text
                                                            style={{
                                                                color: blue[6],
                                                            }}>
                                                            {amount}
                                                        </Text>
                                                    </Text>
                                                </>
                                            )
                                        }}
                                    </Item>
                                </Col>
                                <Col flex="none">
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        size="large">
                                        下单
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col flex="2 300px">
                        <div className="container">
                            <Space
                                direction="vertical"
                                style={{ width: "100%" }}>
                                <Row>
                                    <Col flex="auto">1间 x {night}晚</Col>
                                    <Col flex="none">￥{unitPrice}</Col>
                                </Row>
                                <Row>
                                    <Col flex="auto">房间数</Col>
                                    <Item
                                        noStyle
                                        shouldUpdate={(p, c) =>
                                            p["roomCount"] !== c["roomCount"]
                                        }>
                                        {({ getFieldValue }) => {
                                            return (
                                                <Col flex="none">
                                                    {getFieldValue("roomCount")}
                                                    &nbsp;间
                                                </Col>
                                            )
                                        }}
                                    </Item>
                                </Row>
                                <Row>
                                    <Col flex="auto" style={{ fontSize: 20 }}>
                                        应付总计
                                    </Col>
                                    <Item
                                        noStyle
                                        shouldUpdate={(p, c) =>
                                            p["roomCount"] !== c["roomCount"]
                                        }>
                                        {({ getFieldValue }) => {
                                            const count =
                                                getFieldValue("roomCount")
                                            return (
                                                <Col
                                                    flex="none"
                                                    className="HotelDetailLowPrice"
                                                    style={{
                                                        fontSize: 20,
                                                        fontWeight: 600,
                                                    }}>
                                                    ￥{count * unitPrice}
                                                </Col>
                                            )
                                        }}
                                    </Item>
                                </Row>
                                <Divider />
                                <Text type="secondary">
                                    {product.cancelType}
                                </Text>
                                <Text type="warning">{product.cancelRule}</Text>
                            </Space>
                        </div>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(_pay)
