import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { AccountPolicyList, AsyncActionState, Company, DataPage, PageRequest, ReimbursePolicy, Staff, TaxRateList, } from "../models"
import { accessTokenSelector } from "../user/loginSlice"
import { deleteAccountApi, fetcSubsidyPolicyApi, fetchReimbursePolicyApi, fetchTaxRateApi, fetchaccountsListApi, postAccount, postcreateAccountsDetails } from "./api"
import { Accounts, CustomerList, FileRepertories } from "../reimburseForm/listSlice"
import { currentCompanyIdSelector, currentStaffIdSelector, currentStaffSelector } from "../user/userSlice"
import { Dayjs } from "dayjs"
import { findByCompanyIdApi } from "../org/orgApi"
import { findByStaffIdApi } from "../staff/staffAPI"
import { fetchLocation } from "../location/slice"


export interface AccountsLists {
    accountsType: number //账目类型
    accountsStartData: string | Dayjs
    accountsEndData: string | Dayjs
    accountsSumMoney: number
    accountsSumMoney2: number
    accountsSumMoney3: number
    preTaxTotal: number //不含税金额
    taxRate: number
    // localMoney: number
    accountsDescription: string  //补助信息
    // accountsDate: number
    accountDate: string
    roadMoney: number
    accountsMoney: number
    accountsKilometres: number
    currency: string   //币种默认CNY
    currency2: string  //报销政策币种默认CNY
    currency3: string  //结算币种默认CNY
    exchangeRate: number  //标准
    exchangeRate2: number //结算
    auditInfo: string
    city?: string
    depthPath: string
    corpAccounts: boolean    //支付方式
    accountPolicyId: string //账目报销政策id
    staffId: string //归属人id
    // financialAdjusted: boolean //财务是否调整了该账目
    // financialMoney: number //财务调整后金额
    // financialTaxMoney: number
    // financialTaxRate: number
    // createdAt: string
    // updatedAt: string
    customerList: CustomerList[]  //消费人
    netPrice: number
    flightTax: number //机票税费: 国内机票是机建费 国际是税
    flightOilTax: number //燃油附加费
    alteration: number
    // refund: number
    service: number //服务费
    otherPrice: number //其他费用
    taxDeduct: boolean ///有订单的账目都计税，对私的选择是否抵扣税费 选择可抵扣，则计算不含税价，否则不计算
    // serviceAccount: boolean //是否是服务费账目，默认false 不是服务费账目, true: 服务费账目
    invoiceType?: string
    invoiceIdV2?: string
    fileRepertories?: FileRepertories[]
    accountsRemarks?: string
    ticket?: string //火车票取号票
    trafficNo?: string
    exceedReasonId?: string //超标原因id
    departDepthPath?: string
    invoiceTypeCode?: string  //是否实名票 0-1
    packageId?: string  //收款公司
    corpAccountsNum?: string
    corpAccountsName?: string
    // corporate: false 
    isCorporate?: boolean//短差对公账目只报销一次 ：本地标识
    companyId?: string
}

export interface CreateAccount {
    files?: FileRepertories[]
    accounts: AccountsLists
}

interface State {
    updateLoading: AsyncActionState
    accountsLoading: AsyncActionState
    createLoading: AsyncActionState
    accountsList?: DataPage<Accounts>
    deleteAccountLoading: boolean
    // currency?: currencyList[]
    reimbursePolicy?: ReimbursePolicy
    accountPolicy?: AccountPolicyList[]
    subsidyPolicy?: AccountPolicyList[]
    subsidyPolicyLoading: AsyncActionState
    reimburseSubsidyPromise: boolean
    taxRateList?: TaxRateList[]
    createAccount?: Accounts
    getAccounts?: Accounts
    AccountsDetails?: Accounts //更新账目
}
const initialState: State = {
    updateLoading: 'idle',
    accountsLoading: 'idle',
    subsidyPolicyLoading: 'idle',
    createLoading: 'idle',
    deleteAccountLoading: false,
    reimburseSubsidyPromise: true,

}

//加载一些必要的初始化信息
export const anEntryinitEditor = createAsyncThunk('anEntryinitEditor/init',
    async (id: string | undefined, { getState, rejectWithValue, dispatch }) => {
        const state = getState() as RootState
        const token = accessTokenSelector(state)
        const companyId = currentCompanyIdSelector(state)
        const staffId = currentStaffIdSelector(state)
        if (!token || !companyId || !staffId) throw rejectWithValue(401)

        try {
            //更新公司配置
            const company = await findByCompanyIdApi(token, companyId)
            //更新出差申请编辑器可用性
            const staff = await findByStaffIdApi(token, staffId)
            // dispatch(setAvailable(!staff.authLocked))
            const EmployeeCollection = { company: company, staff: staff }
            // dispatch(updateCompanyConfig(EmployeeCollection))
            console.log("🚀 ~ EmployeeCollection:", EmployeeCollection)
            //税率
            const taxRate = await fetchTaxRateApi(token)
            dispatch(setTaxRate(taxRate))
            // 调用国内外城市接口
            dispatch(fetchLocation())
            //获取单据信息
            // if (id) {
            //     const data = await findApplyFormByIdApi(token, id)
            //     if (data) {
            //         dispatch(setApplyForm({ ...data, applyProcesses: undefined } as ApplyForm))
            //     }
            //     else throw rejectWithValue("未查询到出差申请")
            // }
        } catch (err) {
            throw rejectWithValue(err)
        }
    })

// 账目列表
export const fetchaccountsList = createAsyncThunk('rest/accounts/accountsDate',
    async (page: PageRequest, { getState, rejectWithValue }): Promise<DataPage<Accounts>> => {
        const state = getState() as RootState
        const token = accessTokenSelector(state)
        if (!token) throw new Error()
        try {
            const res = await fetchaccountsListApi(token, { ...page })
            return res
        } catch (error) {
            throw rejectWithValue(error)
        }
    })

// 删除
export const deleteAccount = createAsyncThunk('rest/account/accountsId',
    async (id: string, { getState, rejectWithValue }): Promise<void> => {
        const state = getState() as RootState
        const token = accessTokenSelector(state)
        if (!token) throw new Error()
        try {
            return await deleteAccountApi(token, id)
        } catch (error) {
            throw rejectWithValue(error)
        }
    })
//保存账目
export const createAccounts = createAsyncThunk('rest/account/create',
    async (form: CreateAccount, { getState, rejectWithValue }): Promise<Accounts> => {
        const appState = getState() as RootState
        const staff = currentStaffSelector(appState)
        const token = accessTokenSelector(appState)
        if (!token || !staff) throw rejectWithValue(401)
        try {

            form.accounts.companyId = staff.companyId
            const res = await postAccount(token, form)
            return res
        } catch (err) {
            throw rejectWithValue(err)
        }
    })
// 保存账目详情
export const createAccountsDetails = createAsyncThunk('rest/AccountsId/create',
    async ({ form, id }: { form: CreateAccount, id: string }, { getState, rejectWithValue }): Promise<Accounts> => {
        const appState = getState() as RootState
        const staff = currentStaffSelector(appState)
        const token = accessTokenSelector(appState)
        if (!token || !staff) throw rejectWithValue(401)
        try {
            // form.accounts.companyId = staff.companyId
            const res = await postcreateAccountsDetails(token, form, id)
            console.log("🚀 ~ res:", res)
            return res

        } catch (err) {
            throw rejectWithValue(err)
        }
    })

// 报销政策
export const fetchReimbursePolicy = createAsyncThunk('rest/account/fetchReimbursePolicy',
    async (_, { getState, rejectWithValue }): Promise<ReimbursePolicy> => {
        const appState = getState() as RootState
        const staff = currentStaffSelector(appState)
        const token = accessTokenSelector(appState)
        if (!token || !staff) throw rejectWithValue(401)
        try {
            const res = await fetchReimbursePolicyApi(token)
            return res
        } catch (err) {
            throw rejectWithValue(err)
        }
    })

// 查询可以补贴报销政策
export const fetchSubsidyPolicy = createAsyncThunk('rest/account/fetcSubsidyPolicyApi',
    async (query: { staffId: string, depthPath: string }, { getState, rejectWithValue }): Promise<AccountPolicyList[]> => {
        const appState = getState() as RootState
        const token = accessTokenSelector(appState)
        if (!token) throw rejectWithValue(401)
        try {
            return await fetcSubsidyPolicyApi(token, query)
        } catch (err) {
            throw rejectWithValue(err)
        }
    })

const slice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        entryReset: (_) => {
            return { ...initialState }
        },
        updateCompanyConfig: (state, { payload }: PayloadAction<{ company: Company, staff: Staff }>) => {
            const { company } = payload
            state.reimburseSubsidyPromise = company.reimburseSubsidyPromise ?? true
        },
        setTaxRate: (state, { payload }: PayloadAction<TaxRateList[]>) => {
            state.taxRateList = payload
        },
        // 重置保存账目详情
        resetDetailsState: (state) => { state.updateLoading = 'idle' },
        // 重置保存账目
        resetCreateState: (state) => { state.createLoading = 'idle' },
        setAccounts: (state, { payload }) => {
            state.getAccounts = payload
        },
    },
    extraReducers: builder => {
        builder
            // 账目列表
            .addCase(fetchaccountsList.pending, state => {
                delete state.accountsList
            })
            .addCase(fetchaccountsList.rejected, state => {
                delete state.accountsList
            })
            .addCase(fetchaccountsList.fulfilled, (state, { payload }) => {
                state.accountsList = payload
            })
            // 删除账目
            .addCase(deleteAccount.pending, state => {
                state.deleteAccountLoading = true
            })
            .addCase(deleteAccount.rejected, state => {
                state.deleteAccountLoading = false
            })
            .addCase(deleteAccount.fulfilled, (state) => {
                state.deleteAccountLoading = false
            })
            //保存账目
            .addCase(createAccounts.pending, state => {
                state.createLoading = 'pending'
            })
            .addCase(createAccounts.rejected, state => {
                state.createLoading = 'reject'
            })
            .addCase(createAccounts.fulfilled, (state, { payload }: PayloadAction<Accounts>) => {
                state.createAccount = payload
                state.createLoading = 'fulfilled'
            })
            // 报销政策
            .addCase(fetchReimbursePolicy.pending, state => {
                delete state.reimbursePolicy
            })
            .addCase(fetchReimbursePolicy.rejected, state => {
                delete state.reimbursePolicy
            })
            .addCase(fetchReimbursePolicy.fulfilled, (state, { payload }: PayloadAction<ReimbursePolicy>) => {
                const { accountPolicyList } = payload
                state.reimbursePolicy = payload
                state.accountPolicy = accountPolicyList.filter(it => it.accountsType === 18 || (it.accountsType >= 1800 && it.accountsType <= 1900))
            })
            // 查询可以补贴报销政策
            .addCase(fetchSubsidyPolicy.pending, state => {
                state.subsidyPolicyLoading = 'pending'
                delete state.reimbursePolicy
            })
            .addCase(fetchSubsidyPolicy.rejected, state => {
                state.subsidyPolicyLoading = 'reject'
                delete state.reimbursePolicy
            })
            .addCase(fetchSubsidyPolicy.fulfilled, (state, { payload }: PayloadAction<AccountPolicyList[]>) => {
                state.subsidyPolicyLoading = 'fulfilled'
                state.subsidyPolicy = payload
            })
            // 更新保存账目详情
            .addCase(createAccountsDetails.pending, state => {
                delete state.AccountsDetails
                state.updateLoading = 'pending'
            })
            .addCase(createAccountsDetails.rejected, state => {
                delete state.AccountsDetails
                state.updateLoading = 'reject'

            })
            .addCase(createAccountsDetails.fulfilled, (state, { payload }) => {
                state.AccountsDetails = payload
                state.updateLoading = 'fulfilled'
            })
    },
})

export const { entryReset, setTaxRate, resetDetailsState, resetCreateState, setAccounts } = slice.actions
export default slice.reducer
