import { ReactElement, useEffect } from "react"
import { connect } from "react-redux"
import { AppDispatch, RootState } from "../../../../../app/store"
import { Select, Form, Button } from "antd"
import {
    filterAirplaneList,
    AirplaneFilterOptionValues,
} from "../../services/bookAirplaneSlice"

const mapStateToProps = (state: RootState) => ({
    planeSize: state.bookAirplane.airplaneFilterOptions.planeSize,
    airlineName: state.bookAirplane.airplaneFilterOptions.airlineName,
    landingPortName: state.bookAirplane.airplaneFilterOptions.landingPortName,
    airplaneRes: state.bookAirplane.airplaneRes,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    filterAirplaneList: (fromProms: AirplaneFilterOptionValues) => {
        dispatch(filterAirplaneList(fromProms))
    },
})

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

function AirplaneListFilters({
    airplaneRes,
    filterAirplaneList,
    airlineName,
    landingPortName,
    planeSize,
}: StateProps): ReactElement {
    // 创建 Form 实例，用于管理所有数据状态。
    const [form] = Form.useForm()
    // 时间段
    const takeOffTime = [
        { value: "one", label: "00:00-04:00" },
        { value: "two", label: "04:00-08:00" },
        { value: "three", label: "08:00-12:00" },
        { value: "four", label: "12:00-16:00" },
        { value: "five", label: "16:00-20:00" },
        { value: "six", label: "20:00-24:00" },
    ]
    // 排序takeOffTime
    const sortTakeOffTime = [
        { value: "early", label: "时间早到晚" },
        { value: "late", label: "时间晚到早" },
    ]
    // 排序priceFare
    const sortpriceFare = [
        { value: "low", label: "价格低到高" },
        { value: "high", label: "价格高到低" },
    ]
    // 获取表单数据
    const onValuesChange = (
        changedValue: AirplaneFilterOptionValues["changedValue"],
        allValues: AirplaneFilterOptionValues
    ) => {
        filterAirplaneList({ ...allValues, changedValue })
    }
    // 清空
    const onReset = () => {
        form.resetFields()
        filterAirplaneList({
            filterPlaneSize: [],
            filterLandingPortName: [],
            filterAirlineName: [],
            filterTakeOffTime: [],
        })
    }
    // 数据发生变化以后清空
    useEffect(() => {
        if (!airplaneRes) {
            form.resetFields()
        }
    }, [airplaneRes, form])
    return (
        <>
            <Form<AirplaneFilterOptionValues>
                form={form}
                onValuesChange={onValuesChange}
                layout="vertical">
                <Form.Item name="filterLandingPortName" label="机场">
                    <Select
                        mode="multiple"
                        allowClear
                        showSearch={false}
                        style={{ width: "100%" }}>
                        {landingPortName.map(item => (
                            <Select.Option key={item.value} value={item.value}>
                                {item.value}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="filterPlaneSize" label="机型">
                    <Select
                        mode="multiple"
                        allowClear
                        showSearch={false}
                        style={{ width: "100%" }}>
                        {planeSize.map(item => (
                            <Select.Option
                                key={item.label + item.value}
                                value={item.value}>
                                {" "}
                                {item.value}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="filterAirlineName" label="航空公司">
                    <Select
                        mode="multiple"
                        allowClear
                        showSearch={false}
                        style={{ width: "100%" }}>
                        {airlineName.map(item => (
                            <Select.Option
                                key={item.label + item.value}
                                value={item.value}>
                                {item.value}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="filterTakeOffTime" label="起飞时间">
                    <Select
                        mode="multiple"
                        allowClear
                        showSearch={false}
                        style={{ width: "100%" }}>
                        {takeOffTime.map(item => (
                            <Select.Option key={item.label} value={item.label}>
                                {item.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="sortTakeOffTime"
                    label="时间排序"
                    initialValue={sortTakeOffTime[0].label}>
                    <Select allowClear style={{ width: "100%" }}>
                        {sortTakeOffTime.map(item => (
                            <Select.Option key={item.label} value={item.value}>
                                {item.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="sortpriceFare"
                    label="价格排序"
                    initialValue={sortpriceFare[0].label}>
                    <Select allowClear style={{ width: "100%" }}>
                        {sortpriceFare.map(item => (
                            <Select.Option
                                key={item.value + item.label}
                                value={item.value}>
                                {item.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button onClick={onReset}>清除</Button>
                </Form.Item>
            </Form>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(AirplaneListFilters)
