import { ReactElement } from "react"
import { Route, Routes } from "react-router-dom"
import List from "./List"
import AccountsList from "./AccountsList"
import AccountsEditor from './Editor'



const AccountsRoutes = (): ReactElement => {
    return (
        <Routes>
            <Route path="list" element={<List />} />
            <Route path=":documentId/edit" element={<AccountsEditor />} />
            <Route path="create" element={<AccountsList />} />
        </Routes>
    )
}
export default AccountsRoutes

