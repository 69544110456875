import { Form, Input } from "antd"
import { useEffect } from "react"
import { ApplyForm } from "../../models"
import ProjectSelector from "./components/ProjectSelector"
import { useAppSelector } from "../../../app/hooks"

const { Item } = Form
export default function TravelProjectItems() {
    const form = Form.useFormInstance()
    const data = useAppSelector(state => state.applyFormProjectSelector.searchList ?? state.applyFormProjectSelector.recommendList,)
    const applyForm = Form.useWatch<ApplyForm | undefined>("applyForm", form)
    const projectName = Form.useWatch<string>("projectName", form)

    useEffect(() => {
        const list = data?.find(it => it.name === projectName)
        if (list) {
            form.setFieldValue("projectId", list.cpId)
            form.setFieldValue("projectName", list.name)
        }
    }, [data, form, projectName])

    return (
        <>
            <Item
                name="projectName"
                rules={[{ required: true }]}
                label="出差项目"
                required>
                <ProjectSelector disabled={applyForm ? true : false} />
            </Item>
            <Item noStyle hidden name="projectId">
                <Input />
            </Item>
        </>
    )
}
