import { ReactElement, useState } from "react"
import { Card, Button, Modal, Row, Col, Typography, Space } from "antd"

import { TrainJourneyInfoCpn } from "../../../order/detail/components/TrainOrderDetailJourneyCpn"
import {
    TrainItemVModel,
    TrainSeatItemVModel,
} from "../services/bookTrainSlice"
import { TrainOrderDetailScheduleCpn } from "../../../order/detail/components/TrainOrderDetailScheduleCpn"
import { runTimeStr } from "../../const"
import { TrainOrderScheduleParams } from "../../../order/service/orderModuleApi"
import { blue } from "@ant-design/colors"
const { Text } = Typography
interface Props {
    seat: TrainSeatItemVModel
    train: TrainItemVModel
}

const TrainConfirmTime = (props: TrainItemVModel): ReactElement => {
    const { trainNo, runTimeSpan, fromStation, toStation, trainDate } = props
    const [showStation, setShowStation] = useState(false)
    const scheduleParam: TrainOrderScheduleParams = {
        fromStation: fromStation,
        toStation: toStation,
        trainNo: trainNo,
        trainDate: trainDate,
    }

    return (
        <Space direction="vertical">
            <Text style={{ color: blue[5] }}>{trainNo}</Text>
            <Button
                size="small"
                shape="round"
                onClick={() => setShowStation(true)}>
                时刻表
            </Button>
            <Modal
                title="时刻表"
                width="60vw"
                open={showStation}
                closable={true}
                footer={null}
                onCancel={() => setShowStation(false)}>
                <TrainOrderDetailScheduleCpn params={scheduleParam} />
            </Modal>
            <Text>{runTimeStr(runTimeSpan)}</Text>
        </Space>
    )
}

export const PayConfirmSider = (props: Props): ReactElement => {
    const { seat, train } = props

    const { seatName } = seat

    return (
        <Card
            bordered={false}
            title={
                <Space direction="horizontal">
                    {seatName}
                    {
                        <Text type="secondary">
                            <Text type="secondary" italic={true}>
                                ¥
                            </Text>
                            {seat.price}
                        </Text>
                    }{" "}
                </Space>
            }>
            <Row style={{ textAlign: "center" }}>
                <Col flex="1">
                    {" "}
                    {
                        <TrainJourneyInfoCpn
                            from={train?.fromCity}
                            date={train?.trainDate}
                            time={train?.fromTime}
                        />
                    }
                </Col>
                <Col flex="auto">
                    <TrainConfirmTime {...train} />
                </Col>
                <Col flex="1">
                    {" "}
                    {
                        <TrainJourneyInfoCpn
                            from={train?.toCity}
                            date={train?.trainDate}
                            time={train?.toTime}
                        />
                    }
                </Col>
            </Row>
        </Card>
    )
}
