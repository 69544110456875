import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import dayjs from 'dayjs'
import { RootState } from "../../app/store"
import { ApplyForm, DataPage, PageRequest } from "../models"
import { accessTokenSelector } from "../user/loginSlice"
import { currentCompanyIdSelector } from "../user/userSlice"
import { ApprovalsStateType, findAllApi } from "./api"

export interface ApplyFormItemVo {
    documentId: string
    documentNum: string
    startDate: string
    endDate: string
    state: string
}


interface ApplyFormListState {
    tabKey: ApprovalsStateType
    loading: boolean
    error?: string | number
    list?: DataPage<ApplyFormItemVo>
}

const initialState: ApplyFormListState = {
    tabKey: "draft",
    loading: false,
}


export const convertState = (state: number): string => {
    switch (state) {
        case -1:
        default:
            return "未提交"
        case 0: return "审批中"
        case 1: return "已通过"
        case 2: return "被驳回"
    }
}

const convertToItemVo = (applyForm: ApplyForm): ApplyFormItemVo => ({
    documentId: applyForm.applyId,
    documentNum: applyForm.txm,
    startDate: dayjs(new Date(applyForm.applyStartDate)).format("YYYY-MM-DD"),
    endDate: dayjs(new Date(applyForm.applyEndDate)).format("YYYY-MM-DD"),
    state: convertState(applyForm.approvalState)
})


const tabKeySelector = (state: RootState) => state.applyFormList.tabKey

// 出差申请列表
export const findAll = createAsyncThunk('applyForm/list/findAll',
    async (page: PageRequest, { getState, rejectWithValue }): Promise<DataPage<ApplyFormItemVo>> => {
        const state = getState() as RootState
        const token = accessTokenSelector(state)
        if (!token) throw rejectWithValue(401)
        const tabKey = tabKeySelector(state)
        const companyId = currentCompanyIdSelector(state)
        try {
            const ret = await findAllApi(token, {
                ...page, state: tabKey, companyId
            })

            const content = ret.content?.map(it => convertToItemVo(it))
            return {
                ...ret,
                content,
            }
        } catch (err) {
            throw rejectWithValue(err as string)
        }
    })

const slice = createSlice({
    name: "applyForm/list",
    initialState,
    reducers: {
        reset: () => initialState,
        setTabKey: (state, { payload }: PayloadAction<ApprovalsStateType>) => {
            state.tabKey = payload
            delete state.error
            delete state.list
        }
    },
    extraReducers: builder => {
        builder
            .addCase(findAll.pending, state => {
                state.loading = true
                delete state.error
                delete state.list
            })
            .addCase(findAll.fulfilled, (state, { payload }) => {
                state.loading = false
                state.list = payload
            })
            .addCase(findAll.rejected, (state, { payload }) => {
                state.loading = false
                state.error = payload ? (payload as string | number) : "请求失败"
            })
        // ---
    },
})

export const { reset, setTabKey } = slice.actions
export default slice.reducer
