import { Select } from "antd"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { RootState } from "../../../../app/store"
import { EnvironmentOutlined } from "@ant-design/icons"
import { HotelCity } from "../../../models"

const mapStateToProps = (state: RootState) => ({
    hotelCity: state.booking.hotelCity,
    locLoading: state.location.loading,
})

type Props = ReturnType<typeof mapStateToProps> & {
    onChange?: (id: string) => void
    isStart?: boolean
    accountsEndData?: string
    accountsStartData?: string
    city?: string
}

const _selector = ({ onChange, hotelCity, locLoading, city, isStart, accountsEndData, accountsStartData }: Props) => {
    //过滤检查的数据
    const [subCity, setSubCity] = useState<HotelCity[]>()
    // 下拉菜单数据
    const [options, setOptions] = useState<JSX.Element[]>()

    useEffect(() => {
        // 如果subCit有数据
        if (subCity) {
            setOptions(
                subCity.map((c, idx) => (
                    <Select.Option value={JSON.stringify(c)} key={c.id + "-" + idx}>
                        {c.name}
                    </Select.Option>
                ))
            )
        } else {
            setOptions(undefined)
        }
    }, [subCity])

    const handleSearch = (v: string) => {
        // 输入的东西不存在或如果是空setSubCity(undefined)
        if (!v || v === "") setSubCity(undefined)
        else
            setSubCity(
                hotelCity?.filter(
                    c =>
                        //过滤检查
                        c.name.includes(v) ||
                        c.enName?.toLocaleLowerCase().includes(v)
                )
            )
    }

    return (
        <Select
            loading={locLoading === "pending"}
            style={{ width: "100%" }}
            disabled={!hotelCity}
            showSearch
            suffixIcon={<EnvironmentOutlined />}
            placeholder="城市"
            defaultValue={city || (!isStart ? accountsEndData : accountsStartData)}
            notFoundContent={null}
            filterOption={false}
            onSearch={handleSearch}
            onSelect={onChange}>
            {options}
            {!options && (
                <Select.OptGroup label="城市">
                    {hotelCity && hotelCity?.length > 0 && hotelCity?.map((k, index) => (
                        <Select.Option
                            value={JSON.stringify(k)}
                            key={"hotelCity" + index + k?.name}
                            children={k?.name}
                        />
                    ))}
                </Select.OptGroup>
            )}
        </Select>
    )
}

export default connect(mapStateToProps)(_selector)
