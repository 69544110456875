import React, { useEffect } from 'react'
import { Select } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { InvoiceType, resetInvoiceType } from '../editorSlice'

type LnvoiceTypeProps = {
    orderType?: string
    onChange?: (id: string) => void
    value?: string
}

export default function LnvoiceType({ orderType, onChange, value }: LnvoiceTypeProps) {
    const dispatch = useAppDispatch()
    const InvoiceTypeData = useAppSelector(state => state.reimburesEditor.InvoiceTypeData)
    const InvoiceTypeLoading = useAppSelector(state => state.reimburesEditor.InvoiceTypeLoading)

    // 改变接口状态 删除数据
    useEffect(() => {
        dispatch(resetInvoiceType())
    }, [dispatch])

    useEffect(() => {
        if (InvoiceTypeLoading !== 'fulfilled') {
            dispatch(InvoiceType(orderType ?? ''))
        }
    }, [InvoiceTypeLoading, dispatch, orderType])

    return (
        <Select
            value={value}
            allowClear
            loading={InvoiceTypeLoading === "pending"}
            disabled={!InvoiceTypeData}
            notFoundContent={null}
            filterOption={false}
            onSelect={onChange}
        >
            <Select.OptGroup label="发票类型">
                {
                    InvoiceTypeData?.map((k) => (
                        <Select.Option
                            value={k.dictName}
                            key={k?.id}
                            children={k?.dictName}
                        />
                    ))
                }
            </Select.OptGroup>
        </Select>
    )
}
