import { Select } from 'antd'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { fetchFlightSeats, fetchTrainSeats } from '../editorSlice'

type TransportationSeatProps = {
    orderType?: string
    onChange?: (v: string) => void
    value?: string
}

export default function TransportationSeat({ orderType, onChange, value }: TransportationSeatProps) {
    const dispatch = useAppDispatch()
    const trainSeats = useAppSelector(state => state.reimburesEditor.trainSeats)
    const flightSeats = useAppSelector(state => state.reimburesEditor.flightSeats)

    useEffect(() => {
        if (orderType === 'train' && !trainSeats) {
            dispatch(fetchTrainSeats())
        } else if (orderType === 'flight' && !flightSeats) {
            dispatch(fetchFlightSeats())
        }
    }, [dispatch, flightSeats, orderType, trainSeats])

    return (
        <Select
            value={value}
            showSearch
            notFoundContent={null}
            filterOption={false}
            onSelect={onChange}
        >
            {
                orderType === 'train' && trainSeats?.map((it, idx) => {
                    return <Select.Option key={it + idx} value={it}>{it}</Select.Option>
                })
            }
            {
                orderType === 'flight' && flightSeats?.map((it, idx) => {
                    return <Select.Option key={it + idx} value={it}>{it}</Select.Option>
                })
            }
            {
                orderType === 'oceanLiner' && (
                    <>
                        <Select.Option value="一等舱">一等舱</Select.Option>
                        <Select.Option value="二等舱">二等舱</Select.Option>
                        <Select.Option value="三等舱">三等舱</Select.Option>
                    </>
                )
            }
        </Select>
    )
}
