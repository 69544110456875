import React from 'react'
import { Affix, Button, DatePicker, Form, Input, InputNumber, Modal, Radio, Select, Space, Switch, Typography } from 'antd'
import LnvoiceType from '../../reimburseForm/editor/LnvoiceType'
import TransportationSeat from '../../reimburseForm/editor/TransportationSeat'
import ExceedReason from '../../reimburseForm/editor/ExceedReason'
import InvoiceTaxRate from '../../reimburseForm/editor/InvoiceTaxRate'
import PayeeFlat from '../../reimburseForm/editor/PayeeFlat'
import locale from "antd/es/date-picker/locale/zh_CN"
import dayjs from 'dayjs'
import StaffUserItems from '../../reimburseForm/editor/StaffUserItems'
import AmountofCosts from '../../reimburseForm/editor/AmountofCosts'
import Attachments from '../../reimburseForm/editor/Attachments'
import AccountPolicy from './AccountPolicy'
import Destination, { Destinationitem } from '../../reimburseForm/editor/Destination'
import { currencyList } from '../../book/const'
import { useAppSelector } from '../../../app/hooks'


type selectedOption = {
    selectedOption?: { value: number; label: string, key?: string, mode: string }
}

export default function


    AccountFormEditor({ selectedOption }: selectedOption) {
    const { Title } = Typography
    const payAccount = useAppSelector(state => state.reimburesEditor.payAccount)
    const [open, setOpen] = React.useState<boolean>(false)

    const { useFormInstance, useWatch } = Form
    const form = useFormInstance()
    const corpAccounts = useWatch('corpAccounts', form)
    const invoiceType = useWatch('invoiceType', form)

    return (
        <>
            <Affix>
                <div style={{ background: "#ffffff" }}>
                    <Form.Item
                        label='原始金额'>
                        <Space.Compact block>
                            {(selectedOption?.value === 10 || selectedOption?.value === 11) && <Button onClick={() => setOpen(true)}>费用详情</Button>}
                            <Form.Item
                                label='标准金额'
                                name='accountsSumMoney'
                                noStyle
                            >
                                <Input prefix="￥" className="readonlyInput"
                                    disabled={(selectedOption?.value !== 10 && selectedOption?.value !== 11 && selectedOption?.value !== 18) ? false : true} />
                            </Form.Item>
                            <Form.Item
                                name='currency'
                                noStyle
                                initialValue={currencyList[0].code}
                                className="readonlyInput"
                            >
                                <Select className="readonlyInput" style={{ width: '30%' }} disabled={selectedOption?.value === 18 ? true : false}>
                                    {/* {
                                    currencyList.map((it, idx) => {
                                        return <Select.Option key={idx} value={it.code}>{it.name + '/' + it.code}</Select.Option>
                                    })
                                } */}
                                </Select>
                            </Form.Item>
                        </Space.Compact>
                    </Form.Item>
                </div>

            </Affix>
            {selectedOption?.value !== 18 && <Form.Item
                hidden
                noStyle
                name="accountsType"
                initialValue={selectedOption?.value}>
                <Input hidden />
            </Form.Item>}
            {selectedOption?.value !== 11 &&
                <Form.Item<boolean>
                    rules={[{ required: true }]}
                    name="international"
                    label="国内/国际"
                    valuePropName="checked"
                    initialValue={false}>
                    <Switch unCheckedChildren="国内" checkedChildren="国际" />
                </Form.Item>
            }
            {
                (selectedOption?.value === 18 || selectedOption?.value === 16) &&
                <Form.Item
                    label={selectedOption?.value === 16 ? '酒店名称' : "差旅补助"}
                    name="accountsDescription"
                    rules={[{ required: true }]}
                    initialValue={selectedOption?.value === 18 ? "差旅补助" : ''}
                >
                    <Input />
                </Form.Item>
            }
            {(selectedOption?.value === 9 || selectedOption?.value === 10 || selectedOption?.value === 11 || selectedOption?.value === 12 || selectedOption?.value === 14 || selectedOption?.value === 19 || selectedOption?.value === 20 || selectedOption?.value === 21 || selectedOption?.value === 22) &&
                <Destination />
            }
            {
                selectedOption?.value === 13 && <>
                    <Form.Item label='出发地' name='accountsStartData' rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label='目的地' name='accountsEndData' rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                </>
            }
            {
                selectedOption?.value === 12 && <>
                    <Form.Item
                        label="里程(KM)"
                        name='accountsKilometres'
                        normalize={value => JSON.parse(value)}
                        rules={[{ required: true }]}
                    >
                        <InputNumber min={0} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        label="路桥费"
                        name='roadMoney'
                        normalize={value => JSON.parse(value)}
                        rules={[{ required: true }]}
                    >
                        <InputNumber min={0} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        label="油费"
                        name='accountsMoney'
                        normalize={value => JSON.parse(value)}
                        rules={[{ required: true }]}
                    >
                        <InputNumber min={0} style={{ width: '100%' }} />
                    </Form.Item>
                </>
            }
            {(selectedOption?.value === 13 || selectedOption?.value === 15 || selectedOption?.value === 16 || selectedOption?.value === 17 || selectedOption?.value === 18) &&
                <Form.Item label='出差城市' name='city' rules={[{ required: true }]}>
                    <Destinationitem />
                </Form.Item>
            }
            {(selectedOption?.value === 10 ||
                selectedOption?.value === 11 ||
                selectedOption?.value === 14 ||
                selectedOption?.value === 9) &&
                <Form.Item
                    label="票号"
                    name="ticket"
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
            }
            {
                (selectedOption?.value === 19 ||
                    selectedOption?.value === 20 ||
                    selectedOption?.value === 21 ||
                    selectedOption?.value === 22) &&
                <Form.Item
                    label="车牌号"
                    name="auditInfo"
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
            }
            {/* 发票类型 */}
            {
                (selectedOption?.value === 10 ||
                    selectedOption?.value === 11 ||
                    selectedOption?.value === 14 ||
                    selectedOption?.value === 13 ||
                    selectedOption?.value === 16) &&
                <Form.Item label="发票类型"
                    name='invoiceType'
                    rules={[{ required: true }]}>
                    <LnvoiceType orderType={selectedOption.key} />
                </Form.Item>
            }
            {/* 归属人  */}
            <StaffUserItems />
            {/* 补助类型 */}
            {selectedOption?.value === 18 &&
                <AccountPolicy isEditor={selectedOption.mode} />
            }
            {/* 起始时间 */}
            {
                (selectedOption?.value === 16 ||
                    selectedOption?.value === 18
                ) && <Form.Item
                    label="开始时间"
                    name="accountsStartData"
                    rules={[{ required: true }]}
                    initialValue={dayjs().startOf('day')}
                >
                    <DatePicker locale={locale} style={{ width: '100%' }} format={"YYYY-MM-DD"} />
                </Form.Item>
            }
            {/* 结束时间 */}
            {
                (selectedOption?.value === 16 ||
                    selectedOption?.value === 18
                ) && <Form.Item
                    label="结束时间"
                    name="accountsEndData"
                    initialValue={dayjs().add(1, 'day').startOf('day')}
                    rules={[{ required: true }]}
                >
                    <DatePicker locale={locale} style={{ width: '100%' }} format={"YYYY-MM-DD"} />
                </Form.Item>
            }
            {
                (selectedOption?.value === 17) &&
                <Form.Item
                    label="消费事由"
                    name="accountsDescription"
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
            }
            {/* 出行方式 */}
            {
                selectedOption?.value === 13 &&
                <Form.Item label="出行方式"
                    name='auditInfo'
                    rules={[{ required: true }]}
                >
                    <Select>
                        <Select.Option value={'出租'}>出租</Select.Option>
                        <Select.Option value={'地铁'}>地铁</Select.Option>
                        <Select.Option value={'公交'}>公交</Select.Option>
                    </Select>
                </Form.Item>
            }
            {(selectedOption?.value === 14 || selectedOption?.value === 9) &&
                <Form.Item
                    label="是否实名票"
                    name="taxDeduct"
                    rules={[{ required: true }]}
                >
                    <Radio.Group>
                        <Radio value={true}>是</Radio>
                        <Radio value={false}>否</Radio>
                    </Radio.Group>
                </Form.Item>}
            {/* 发票税率 */}
            {(selectedOption?.value === 16 || invoiceType === '航空电子发票') &&
                <Form.Item
                    label="发票税率"
                    name='taxRate'
                >
                    <InvoiceTaxRate />
                </Form.Item>
            }
            {
                (selectedOption?.value === 10 ||
                    selectedOption?.value === 11 ||
                    selectedOption?.value === 9
                ) &&
                <Form.Item label="席位"
                    name='auditInfo'
                    rules={[{ required: true }]}>
                    <TransportationSeat orderType={selectedOption.key} />
                </Form.Item>
            }
            {(selectedOption?.value === 10 ||
                selectedOption?.value === 11
            ) && <Form.Item
                label={selectedOption?.value === 10 ? "航班号" : '车次'}
                name="trafficNo"
                rules={[{ required: true }]}
            >
                    <Input />
                </Form.Item>}
            {(selectedOption?.value !== 16 &&
                selectedOption?.value !== 18
            ) && <Form.Item name="accountDate" label="时间"
                rules={[{ required: true }]}>
                    <DatePicker locale={locale} style={{ width: '100%' }} showTime format="YYYY-MM-DD HH:mm:ss" />
                </Form.Item>}
            <Form.Item
                label="备注"
                name="accountsRemarks"

                rules={[{ required: true }]}
            >
                <Input.TextArea />
            </Form.Item>
            <Form.Item label="支付方式"
                name='corpAccounts'
                initialValue={false}
            >
                <Select>
                    <Select.Option value={false}>个人支付</Select.Option>
                    {payAccount && <Select.Option value={true}>公司支付</Select.Option>}
                </Select>
            </Form.Item>
            {/* 收款单位 */}
            {corpAccounts && <PayeeFlat />}
            {/* 超标原因 */}
            {
                (selectedOption?.value === 10 ||
                    selectedOption?.value === 11 ||
                    selectedOption?.value === 16
                ) &&
                <Form.Item
                    label="超标原因"
                    name="exceedReasonId"
                >
                    <ExceedReason orderType={selectedOption?.value ?? 0} />
                </Form.Item>
            }
            <Title level={4}>多币种信息</Title>
            <Modal title='费用详情' open={open} onCancel={() => setOpen(false)} onOk={() => setOpen(false)}>
                {(selectedOption?.value === 10 || selectedOption?.value === 11) && <Form.Item layout='vertical' labelCol={{ span: 8 }} label='票价' name='netPrice' initialValue={0}>
                    <InputNumber min={0} style={{ width: "100%" }} />
                </Form.Item>
                }
                {selectedOption?.value === 10 && <>
                    <Form.Item layout='vertical' labelCol={{ span: 8 }} label='燃油附加费' name='flightOilTax' initialValue={0}>
                        <InputNumber min={0} style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item layout='vertical' labelCol={{ span: 8 }} label='民航发展基金' name='flightTax' initialValue={0}>
                        <InputNumber min={0} style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item layout='vertical' labelCol={{ span: 8 }} label='其他' name='otherPrice' initialValue={0}>
                        <InputNumber min={0} style={{ width: "100%" }} />
                    </Form.Item>
                </>}
                {(selectedOption?.value === 10 || selectedOption?.value === 11) && <Form.Item layout='vertical' labelCol={{ span: 8 }} label='退改签费用' name='alteration' initialValue={0}>
                    <InputNumber min={0} style={{ width: "100%" }} />
                </Form.Item>}
                {selectedOption?.value === 11 && <Form.Item layout='vertical' labelCol={{ span: 8 }} label='服务费' name='service' initialValue={0}>
                    <InputNumber min={0} style={{ width: "100%" }} />
                </Form.Item>}
            </Modal >
            <AmountofCosts selected={selectedOption?.value} />
            <Attachments accountsType={selectedOption?.value} />
        </>
    )
}
