export interface Staff {
    staffId: string
    userId: string
    companyId: string
    staffUserName: string
    gender: number
    customStaffId?: string
    staffUserPhone?: string
    staffUserEmail?: string
    workPlace?: string
    workPlacePath?: string
    reimbursePolicyId?: string
    authHr: boolean
    authCheck: boolean
    authCreator: boolean
    authLocked: boolean
    authBoss: boolean
    authApproval: boolean
    staffType: number
    displayRank: number
    financeFunctions?: string[]
    staffRank?: string
    orgStructureId?: string
    applyForDeadlineEnable: boolean
    reimburseDeadlineEnable: boolean
    staffUserDepartment?: string
    reimburseType?: ReimburseType
}

export interface ReimburseType {
    allowEnt: boolean,
    allowStay: boolean,
    allowAir: boolean,
    allowTrain: boolean,
    allowLocal: boolean,
    allowCoach: boolean,
    allowCar: boolean,
    allowShip: boolean,
    allowSubsidy: boolean,
    allowOther: boolean
}

export interface MaskedStaff {
    staffId: string
    companyId: string
    staffUserName: string
    staffUserDepartment?: string
}

export interface CompanyWithStaff extends Company {
    staff: Staff
}
/**
 * 公司信息
 */
export interface Company {
    companyId: string
    billingInfoList?: BillingInfo[]
    companyName: string
    reimburseDeadlineEnable: boolean
    defaultProjectUsable: boolean
    companyEnable: boolean
    accountMaxAttachments: number  //上传图片数据
    parentCompanyId?: string
    approvalAutoComplete: boolean
    reimburseSubsidyPromise: boolean     //报销补贴承诺
    authReimburseAttachments: boolean    //报销附件
    companyCreator?: string
    companyBasicConfig: CompanyBasicConfig
    sendMsg: boolean
    companyCreateTime: number
    authApplyForAttachments: boolean
    companyDescription?: string
    applyForDeadlineEnable: boolean
    authLongApplyFor: boolean
    companyLevel: number
    parentCompanyIds?: string
    businessPurposesList?: BusinessPurposes[]
    exceedReasonList?: ExceedReasonList[]
    setAccessoryMustExceptSubsidy: boolean
}

// 超标原因列表
export interface ExceedReasonList {
    exceedReasonId: string
    content: string
    enable: boolean
    accountsType: number
}

export interface BusinessPurposes {
    enable: boolean
    purposes: string
    businessPurposesId: string
}


/**
 * 公司开票信息
 */
export interface BillingInfo {
    companyName?: string
    taxplayerId?: string
    address?: string
    telephone?: string
    bankName?: string
    bankAccount?: string
}

export enum BookingStrictMode {
    OFF = 'OFF', APPLY = 'APPLY', POLICY = 'POLICY'
}
/**
 * 公司基础配置
 */
export interface CompanyBasicConfig {
    reimburseSubmit: boolean   //报销提交 （true表示允许报销提交）
    orderSubmitTimeoutDays: number
    enableTravellersFlow: boolean
    doNotPrintDepartment: boolean
    usePersonalApply: boolean
    localReportMode?: string
    maxApplyDays: number
    maxInternationalApplyDays: number
    longTermTravelMinimumDays: number
    workplaceHotelApproval: boolean
    enableReduceSubsidy: boolean
    enableApprovalFirst: boolean
    unionFlowMode?: "OFF" | "ON" | "ON_BUT_VIP"
    lifecycleApplyBind: boolean
    lifecycleApplyOneTime: boolean
    applyTripCityInclusive: boolean
    strictEmployeeDependencySubsidy: boolean
    allowSubmitterSelfApproval: boolean
    allowBatchApproval: boolean
    usePolyService: boolean
    bookingStrictMode?: BookingStrictMode
    useOCRInvoiceService: boolean  //使用OCR发票服务（false表示不使用）
    reimburseTransportationClosedLoop: boolean
    submitEditBudgetAfterApproved: boolean
    allowAllCityToOrder: boolean      //允许使用所有城市预订
    enabledAccountTypes: { REIMBURSE_TRAVEL: Array<number> }  //启用的账户类型
}

// reimburseSubmit: 报销提交是否开启（true表示开启）
// subsidyMode: 补贴模式（BEFORE_TODAY表示在今天之前）
// orderSubmitTimeoutDays: 订单提交超时天数
// enableTravellersFlow: 是否开启旅行者流程
// doNotPrintDepartment: 是否不打印部门信息
// usePersonalApply: 是否使用个人申请
// localReportMode: 本地报告模式（SIGN表示签名）
// maxApplyDays: 最大申请天数
// maxInternationalApplyDays: 最大国际申请天数
// workplaceHotelApproval: 工作地点酒店审批是否开启
// enableReduceSubsidy: 是否开启减少补贴
// enableApprovalFirst: 是否优先审批
// unionFlowMode: 联合流程模式
// lifecycleApplyBind: 生命周期申请绑定是否开启
// lifecycleApplyOneTime: 生命周期申请一次性是否开启
// applyTripCityInclusive: 申请行程城市是否包含在内
// strictEmployeeDependencySubsidy: 严格员工依赖补贴是否开启
// allowSubmitterSelfApproval: 是否允许提交者自我批准
// allowBatchApproval: 是否允许批量批准
// usePolyService: 是否使用多项服务
// bookingStrictMode: 预订严格模式
// useOCRInvoiceService: 是否使用OCR发票服务
// reimburseTransportationClosedLoop: 报销交通闭环是否开启
// submitEditBudgetAfterApproved: 审批后提交编辑预算是否开启
// setAccessoryMustExceptSubsidy: 设置附件必须除补贴外是否开启
// allowWorkplaceHotel: 是否允许工作地点酒店
// allowAllCityToOrder: 是否允许所有城市下订单
// reimburseValidDaysType1800: 报销有效天数类型1800
// reimburseValidDaysType1801: 报销有效天数类型1801
// reimburseValidDaysType1802: 报销有效天数类型1802
// longTermTravelMaximumDays: 长期旅行最大天数
// longTermTravelMinimumDays: 长期旅行最小天数
// travellerTargetAutomaticApproved: 旅行者目标自动批准是否开启
// limitAirplanePriceSize: 限制飞机价格大小
// 这些参数定义了公司的基本配置，涵盖了报销、补贴、审批、预订等方面的设置。

export interface Project {
    code: string
    cpId: string
    label?: string
    companyId?: string
    cusId?: string
    enable?: boolean
    name: string
    printName?: string
    startTime?: Date
    endTime?: Date
    processType?: number
    flowId?: string
}
