import { LoadingOutlined } from "@ant-design/icons"
import { Select, Typography } from "antd"
import { ReactElement, useEffect, useState } from "react"
import { connect } from "react-redux"
import { useDebounce } from "react-use"
import { AppDispatch, RootState } from "../../app/store"
import { Staff } from "../models"
import { searchStaffByName } from "./staffSlice"

const mapStateToProps = (state: RootState) => ({
    loading: state.staff.loading,
    staffList: state.staff.staffList,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    dispatchSearchStaff: (name: string) => {
        dispatch(searchStaffByName(name))
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        onChange?: (v?: Staff) => void
    }

const { Option } = Select

function staffDataToOptions(data?: Array<Staff>): Array<ReactElement> {
    return data
        ? data.map(staff => (
              <Option key={staff.staffId} value={JSON.stringify(staff)}>
                  {staff.staffUserName}&nbsp;
                  <Typography.Text type="secondary">
                      {staff.staffUserDepartment}
                  </Typography.Text>
              </Option>
          ))
        : []
}

const _selector = ({
    staffList,
    loading,
    dispatchSearchStaff,
    onChange,
}: Props): ReactElement => {
    const [options, setOptions] = useState<Array<ReactElement>>()
    const [search, setSearch] = useState<string>()
    const [debouncedSearch, setDebouncedSearch] = useState<string>()

    useDebounce(
        () => {
            setDebouncedSearch(search)
        },
        300,
        [search]
    )

    useEffect(() => {
        setOptions(staffDataToOptions(staffList))
    }, [staffList])

    useEffect(() => {
        if (debouncedSearch) {
            dispatchSearchStaff(debouncedSearch)
        } else {
            setOptions([])
        }
    }, [debouncedSearch, dispatchSearchStaff])

    function handleSelect(v?: string) {
        setOptions([])
        onChange?.(v && JSON.parse(v))
    }

    return (
        <Select
            showSearch
            loading={loading}
            suffixIcon={loading && <LoadingOutlined spin={true} />}
            onSearch={setSearch}
            notFoundContent={null}
            filterOption={false}
            onSelect={handleSelect}
            onClear={() => handleSelect(undefined)}
            allowClear={!loading}
            placeholder="输入姓名查询同事">
            {options}
        </Select>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(_selector)
