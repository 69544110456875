import { ReactElement, useEffect, useState } from "react"
import { connect } from "react-redux"
import { Row, Col, Collapse, List, Spin, Empty, Alert } from "antd"
import { PageHeader } from "@ant-design/pro-layout"
/* 组件*/
import TopSearch from "./components/TopSearch"
import {
    SearchAirplaneCabinListParams,
    SearchAirplaneListParams,
    SearchChangeAirplaneListParams,
} from "../services/bookAirplaneApi"
import {
    AirplaneCabinVModel,
    AirplaneFlightVModel,
    deleteAirplaneResInfo,
    searchFlightCabinList,
    searchAirplaneList,
    searchChangeCabinList,
    searchChangeAirplaneList,
    setplaneOrderModel,
} from "../services/bookAirplaneSlice"
import { AppDispatch, RootState } from "../../../../app/store"
import { AirplaneListFlightItem } from "./components/AirplaneListFlightItem"
import { AirplaneListCabinItem } from "./components/AirplaneListCabinItem"
import { useLocation, Navigate } from "react-router-dom"
import { PlaneOrderListModel } from "../../../order/service/orderListModel"
import { Items } from "../../../applyForm/viewer/ApplyProcessTimeline"

const mapStateToProps = (state: RootState) => ({
    airplaneList: state.bookAirplane.airplaneRes,
    listLoading: state.bookAirplane.listLoading,
    isLoading: state.bookAirplane.isLoading,
    cabinList: state.bookAirplane.cabinList,
    noSelectApplyfor: !state.booking.applyForm,
    applyFor: state.booking.applyForm,
    tipMessage: state.location.tipMessage,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    //  查询航班列表
    findAirplaneList: (params: SearchAirplaneListParams) => {
        dispatch(searchAirplaneList(params))
    },
    // 查询飞机航班舱位列表
    findAirplaneCabinList: (params: SearchAirplaneCabinListParams) => {
        dispatch(searchFlightCabinList(params))
    },
    // 飞机改签航班查询
    findChangeAirplane: (params: SearchChangeAirplaneListParams) => {
        dispatch(searchChangeAirplaneList(params))
    },
    // 查询改签飞机舱位列表
    findChangeAirplaneCabin: (params: SearchAirplaneCabinListParams) => {
        dispatch(searchChangeCabinList(params))
    },
    deleteAirplaneRes: () => {
        // 清除飞机资源请求的状态
        dispatch(deleteAirplaneResInfo())
    },
    setplaneOrderModel: (planeOrderModel: PlaneOrderListModel) => {
        dispatch(setplaneOrderModel(planeOrderModel))
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

const _airplaneList = (props: Props): ReactElement => {
    const {
        airplaneList,
        findAirplaneList,
        cabinList,
        findAirplaneCabinList,
        findChangeAirplane,
        findChangeAirplaneCabin,
        deleteAirplaneRes,
        setplaneOrderModel,
        applyFor,
        noSelectApplyfor,
        tipMessage,
    } = props
    const { listLoading, isLoading } = props
    const location = useLocation()
    const [mode, setMode] = useState<"book" | "change">("book")
    // 订单页面飞机改签(RefundTicket.tsx)通过useNavigate传递过来的值useLocation接收
    const planeOrderModel = location.state as PlaneOrderListModel
    // 提示信息开关
    const [visible, setVisible] = useState(true)
    const [items, setItems] = useState<Items[]>()
    useEffect(() => {
        // 只要是订单页面飞机改签点击过来就把setMode('book')改为setMode('change')
        if (planeOrderModel) {
            // 改签
            setplaneOrderModel(planeOrderModel)
            setMode("change")
            deleteAirplaneRes()
        }
    }, [planeOrderModel, deleteAirplaneRes, setplaneOrderModel])

    useEffect(() => {
        const _items: Items[] = []
        airplaneList?.map(
            (flightModel: AirplaneFlightVModel, index: number) => {
                return _items.push({
                    key: `${flightModel.airline}-${index}`,
                    label: <AirplaneListFlightItem flightModel={flightModel} />,
                    showArrow: false,
                    children: (
                        <List
                            dataSource={cabinList}
                            loading={isLoading}
                            renderItem={(item: AirplaneCabinVModel) => (
                                <AirplaneListCabinItem
                                    cabin={item}
                                    flight={flightModel}
                                    mode={mode}
                                    applyId={applyFor?.applyId}
                                />
                            )}></List>
                    ),
                })
            }
        )
        setItems(_items)
    }, [airplaneList, applyFor?.applyId, cabinList, isLoading, mode])

    /**
     * 顶部搜索
     * 子组件TopSearch.tsx 传值过来，SearchAirplaneListParams。父组件中给搜索条件申请单id赋值
     * @param param 搜索条件
     */
    const handleSearch = (param: SearchAirplaneListParams) => {
        param.applyForId = applyFor?.applyId || ""
        if (mode === "book") {
            // 预订航班列表
            findAirplaneList(param)
        } else {
            // 改签航班列表
            const cParam: SearchChangeAirplaneListParams = {
                fromCity: planeOrderModel.flight?.takeOffCity,
                toCity: planeOrderModel.flight?.toCity,
                fromDepthPath: planeOrderModel.flight?.fromDepthPath,
                toDepthPath: planeOrderModel.flight?.toDepthPath,
                applyForId: planeOrderModel.applyId || "",
                flightDate: param.flightDate,
                orderNumber: planeOrderModel.customId || "",
                ticketNum: planeOrderModel.ticketNum || "",
                supplierId: planeOrderModel.cabin?.supplierId || "",
            }
            //改签航班查询
            findChangeAirplane(cParam)
        }
    }

    const handleCabin = (indexStr: string | string[]) => {
        // 目前Collapse 只允许展开一个，所以indexStr一定是string
        const index = parseInt(
            /[^-]+-(\d+)/.exec(indexStr as string)?.[1] || "NaN"
        )
        const tmpList = airplaneList ? airplaneList : []
        if (tmpList.length === 0) return
        if (isNaN(index)) {
            return
        }
        const element = tmpList[index]
        if (mode === "book") {
            // 预订舱位查询
            const params: SearchAirplaneCabinListParams = {
                fromDepthPath: element.fromDepthPath || "",
                toDepthPath: element.toDepthPath || "",
                flightDate: element.flightDate || "",
                flightNum: element.flightNum || "",
                shareFlightNum: element.shareFlightNum,
                applyForId: applyFor?.applyId || "",
                shareFlag: element.shareFlag,
            }

            findAirplaneCabinList(params) //机票仓位查询
        } else {
            // 改签舱位查询
            const cParams: SearchAirplaneCabinListParams = {
                fromCity: planeOrderModel.flight?.takeOffCity,
                toCity: planeOrderModel.flight?.toCity,
                fromDepthPath: element.fromDepthPath,
                toDepthPath: element.toDepthPath,
                flightDate: element.flightDate || "",
                flightNum: element.flightNum || "",
                applyForId: planeOrderModel.applyId || "",
                orderNumber: planeOrderModel.customId,
                ticketNum: planeOrderModel.ticketNum,
                supplierId: planeOrderModel.cabin?.supplierId,
            }
            findChangeAirplaneCabin(cParams) // 改签舱位查询
        }
    }

    if (noSelectApplyfor && !planeOrderModel) {
        return (
            <Navigate
                to="/booking/setup"
                state={{ referrer: location.pathname }}
            />
        )
    }

    return (
        <>
            <PageHeader ghost={false}>
                <TopSearch
                    onSearch={handleSearch}
                    mode={mode}
                    city={
                        planeOrderModel && {
                            from: planeOrderModel.fromPlace,
                            to: planeOrderModel.toPlace,
                        }
                    }
                    loading={listLoading}
                />
            </PageHeader>
            {tipMessage?.orderFunctionPage
                ? visible && (
                    <Alert
                        banner
                        message={
                            <>
                                {" "}
                                <div>{tipMessage?.orderFunctionPage}</div>
                                <div>{tipMessage?.inlandOFNotice}</div>
                            </>
                        }
                        closable
                        afterClose={() => setVisible(false)}
                    />
                )
                : null}
            <Row>
                <Col xs={24} sm={24} lg={24} xl={24}>
                    <div className="container">
                        <Spin spinning={listLoading}>
                            {airplaneList && airplaneList.length > 0 ? (
                                <Collapse
                                    bordered={false}
                                    accordion
                                    onChange={handleCabin}
                                    items={items}
                                    style={{
                                        backgroundColor: "white",
                                        width: "100%",
                                    }}></Collapse>
                            ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )}
                        </Spin>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export const AirplaneList = connect(
    mapStateToProps,
    mapDispatchToProps
)(_airplaneList)
