import { Form, Input } from "antd"
import { useEffect, useState } from "react"
import { useAppSelector } from "../../../app/hooks"
import { DocumentType, Project } from "../../models"
import ProjectSelector from "./ProjectSelector"

const { Item } = Form

export default function ProjectItems() {
    const form = Form.useFormInstance()
    const visiable = useAppSelector(
        state => state.applyFormEditor.applyForm === undefined
    )
    const [required, setRequired] = useState(false)
    const [project, setProject] = useState<Project>()

    const docType = Form.useWatch<DocumentType>("documentType", form)

    useEffect(() => {
        setRequired(docType === DocumentType.APPLY_TRAVEL)
    }, [docType])

    useEffect(() => {
        if (docType !== DocumentType.APPLY_TRAVEL) {
            setProject(undefined)
        }
        form.resetFields(["projectId", "projectName", "projectNum"])
        if (project) {
            form.setFieldValue("projectId", project.cpId)
            form.setFieldValue("projectName", project.name)
            form.setFieldValue("projectNum", project.code)
        }
    }, [docType, project, form])
    if (!visiable) return <></>
    return (
        <>
            <Item<Project>
                rules={[{ required }]}
                label="出差项目"
                hidden={docType !== DocumentType.APPLY_TRAVEL}
                required>
                <ProjectSelector onChange={setProject} value={project} />
                <Item
                    noStyle
                    hidden
                    name="projectId"
                    label="出差项目"
                    rules={[{ required }]}>
                    <Input />
                </Item>
            </Item>
            <Item noStyle hidden name="projectName" rules={[{ required }]}>
                <Input />
            </Item>
            <Item noStyle hidden name="projectNum" rules={[{ required }]}>
                <Input />
            </Item>
        </>
    )
}
