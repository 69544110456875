import { Select, Grid } from "antd"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectCompany } from "./userSlice"

export default function GlobalCompanySelector() {
    const dispatch = useAppDispatch()
    const location = useLocation()
    const [selectAvailable, setSelectAvailable] = useState(true)
    const currCompany = useAppSelector(state => state.user.currentCompany)
    const availableCompany = useAppSelector(
        state => state.user.availableCompany
    )
    // Hook 个性化布局
    const { sm } = Grid.useBreakpoint()

    useEffect(() => {
        const whitelist = ["/"]
        const path = location.pathname
        // 仅在确保切换公司后，相关业务支持调整或者重置方能加入切换可用路由白名单
        if (whitelist.find(it => it === path)) {
            setSelectAvailable(true)
        } else {
            setSelectAvailable(false)
        }
    }, [location])

    const onSelect = (v: string) => {
        const selected = availableCompany?.find(it => it.companyId === v)
        if (selected) {
            dispatch(selectCompany(selected))
        }
    }

    return (
        <Select
            style={{ maxWidth: sm ? "" : "180px" }}
            value={currCompany?.companyId}
            variant='borderless'
            onSelect={onSelect}
            disabled={!selectAvailable}>
            {availableCompany?.map(it => (
                <Select.Option
                    key={"global_company_" + it.companyId}
                    value={it.companyId}>
                    {it.companyName}
                </Select.Option>
            ))}
        </Select>
    )
}
