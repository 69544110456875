import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { AsyncActionState, CustomFieldInstance, DataPage, MaskedStaff, PageRequest, Traveller } from "../models"
import { accessTokenSelector } from "../user/loginSlice"
import { ApprovalsStateType, findAllApi, findByReimburseIdApi } from "./api"
import { convertState } from "../applyForm/listSlice"
import { currentCompanyIdSelector } from "../user/userSlice"
import { getStaffByIds } from "../staff/staffAPI"
import { StaffUserList } from "./editorSlice"
import { UploadFile } from "antd"
import { PaymentList } from "../models/reimburesForm"

export interface ReimburseForm {
    approvalState: number
    reimburseId: string
    reimburseNum: string
    reimburseName: string
    projectName: string
    reimburseMoney: number
    applyId: string
    userId: string
    companyId: string
    staffId: string
    actionType: string
    businessPurposesId: string
    projectId: string
    createdAt: string
    updatedAt: string
    documentType: string
    reimburseReportName?: string
    reimburseCreateDate: number
    hasMoreInvoice: boolean
    paymentDetailList: PaymentList[]
    fmisReimburseState: boolean
    reimburseMoneyPersonal: number
    reimburseMoneyCorp: number
    emailSended?: boolean
    documentId?: string
    customFieldInstances?: Array<CustomFieldInstance>
}
export interface ReimburseList {
    accounts: Accounts[]
    billState?: string
    fileRepertories?: FileRepertories[]
}

// 上传图片
export interface FileRepertories extends UploadFile {
    id?: string
    category?: string
    companyId?: string
    staffId?: string
    refId?: string
    fileName?: string
    fileType?: string
    url?: string
    createdAt?: string
    delFlag?: boolean
    updatedAt?: string
    isLocalFile?: boolean
    sizeDisPlay?: string
    size?: number
}




export interface Accounts {
    accountsId?: string
    userId?: string
    reimburseId?: string
    accountsType: number //账目类型
    accountsStartData: string
    accountsEndData: string
    accountsSumMoney: number
    accountsSumMoney2: number
    accountsSumMoney3: number
    preTaxTotal: number //不含税金额
    taxRate: number
    localMoney?: number
    accountsDescription?: string //差旅补助
    accountsDate?: number
    accountDate: string
    roadMoney: number
    accountsMoney: number
    accountsKilometres: number
    currency: string   //币种默认CNY
    currency2: string  //报销政策币种默认CNY
    currency3: string  //结算币种默认CNY
    exchangeRate: number  //标准
    exchangeRate2: number //结算
    auditInfo: string
    city?: string
    depthPath: string
    corpAccounts: boolean    //支付方式
    accountPolicyId?: string //账目报销政策id
    companyId?: string
    staffId: string //归属人id
    financialAdjusted: boolean //财务是否调整了该账目
    financialMoney: number //财务调整后金额
    financialTaxMoney?: number
    financialTaxRate?: number
    createdAt?: string
    updatedAt?: string
    customerList: CustomerList[]  //消费人
    netPrice: number
    flightTax: number //机票税费: 国内机票是机建费 国际是税
    flightOilTax: number //燃油费
    alteration: number
    refund?: number
    service: number //服务费
    otherPrice: number //其他费用
    taxDeduct: boolean ///有订单的账目都计税，对私的选择是否抵扣税费 选择可抵扣，则计算不含税价，否则不计算
    serviceAccount?: boolean //是否是服务费账目，默认false 不是服务费账目, true: 服务费账目
    invoiceType?: string
    fileRepertories?: FileRepertories[]
    accountsRemarks?: string
    ticket?: string //火车票取号票
    trafficNo?: string
    corpAccountsNum?: string
    exceedReasonId?: string //超标原因id
    departDepthPath?: string
    packageId?: string  //收款公司
    corpAccountsName?: string
    costDesc?: string
    oid?: string
}

// 客户列表
export interface CustomerList {
    staffId: string
    exception: boolean  //例外消费（外部人员报销）
    traveller?: Traveller
}
// 补贴承诺
export interface SubsidyPromises {
    id: string
    reimburseId: string
    startDate: string  //开始时间
    endDate: string    //结束日期
    offerCar: boolean  //车辆
    offerMeal: boolean //用餐
    offerAccommodation: boolean //住宿
}
// 银行卡列表
export interface BankCardList {
    bankName?: string
    number: string
    type: number
}
interface ReimburseListState {
    accountsLoading: AsyncActionState
    tabKey: ApprovalsStateType
    loading: boolean
    error?: string | number
    list?: DataPage<ReimburseItemVo>
    isDeletState: AsyncActionState
    staffIdLoading: AsyncActionState
    staffIdList?: StaffUserList[]
    accountsData?: DataPage<Accounts>
    staffUserlist?: MaskedStaff[]
    getApplyForm?: Traveller[]
}

const initialState: ReimburseListState = {
    tabKey: "draft",
    loading: false,
    isDeletState: 'idle',
    accountsLoading: 'idle',
    staffIdLoading: "idle",
}


export interface ReimburseItemVo {
    reimburseNum: string
    reimburseName: string
    projectName: string
    reimburseMoney: number
    state: string
    documentId: string
    reimburseId: string
}

const convertToItemVo = (reimburse: ReimburseForm): ReimburseItemVo => ({
    documentId: reimburse.applyId,
    reimburseNum: reimburse.reimburseNum,
    reimburseName: reimburse.reimburseName,
    projectName: reimburse.projectName,
    reimburseMoney: reimburse.reimburseMoney,
    state: convertState(reimburse.approvalState),
    reimburseId: reimburse.reimburseId
})


const tabKeySelector = (state: RootState) => state.reimburseList.tabKey

// 报销单列表
export const findreimburseAll = createAsyncThunk('reimburse/list/findreimburseAll',
    async (page: PageRequest, { getState, rejectWithValue }): Promise<DataPage<ReimburseItemVo>> => {
        const state = getState() as RootState
        const token = accessTokenSelector(state)
        if (!token) throw rejectWithValue(401)
        const tabKey = tabKeySelector(state)
        try {
            const ret = await findAllApi(token, {
                ...page, state: tabKey
            })
            const content = ret.content?.map(it => convertToItemVo(it))
            return {
                ...ret,
                content,
            }
        } catch (err) {
            throw rejectWithValue(err as string)
        }
    })

// 关联账目接口
export const findByReimburseList = createAsyncThunk('reimbures/findByReimburseList',
    async (page: PageRequest, { getState, rejectWithValue }): Promise<DataPage<Accounts>> => {
        const appState = getState() as RootState
        const token = accessTokenSelector(appState)

        if (!token) {
            throw rejectWithValue(401)
        }
        try {
            return await findByReimburseIdApi(token, { ...page })
        } catch (error) {
            throw rejectWithValue(error)
        }
    }
)

// 费用归属人id
export const findStaffId = createAsyncThunk('reimbures/findReimburse/StaffId',
    async (id: string[], { getState, rejectWithValue }): Promise<StaffUserList[]> => {
        const state = getState() as RootState
        const token = accessTokenSelector(state)
        const curCompanyId = currentCompanyIdSelector(state)
        if (!token) throw rejectWithValue(401)
        if (!token || !curCompanyId) {
            throw rejectWithValue(401)
        }
        try {
            const res = await getStaffByIds(token, curCompanyId, id)
            return res

        } catch (err) {
            throw rejectWithValue(err as string)
        }
    })

const slice = createSlice({
    name: "reimburse/list",
    initialState,
    reducers: {
        resetListState: () => initialState,
        setTabKey: (state, { payload }: PayloadAction<ApprovalsStateType>) => {
            state.tabKey = payload
            delete state.error
            delete state.list
        },
        setStaffIdLoading: (state) => { state.staffIdLoading = 'idle' },
        // setStaffList: (state, { payload }: PayloadAction<StaffUserList[]>) => { state.staffIdList = payload },
        setApplyForms: (state, { payload }: PayloadAction<Traveller[] | undefined>) => {
            state.getApplyForm = payload
        },
        setAccountsState: (state) => { state.accountsLoading = 'idle' },
    },
    extraReducers: builder => {
        builder
            // 报销单列表
            .addCase(findreimburseAll.pending, state => {
                state.loading = true
                delete state.error
                delete state.list
            })
            .addCase(findreimburseAll.fulfilled, (state, { payload }) => {
                state.loading = false
                state.list = payload
            })
            .addCase(findreimburseAll.rejected, (state, { payload }) => {
                state.loading = false
                state.error = payload ? (payload as string | number) : "请求失败"
            })
            // 关联账目
            .addCase(findByReimburseList.pending, state => {
                state.accountsLoading = 'pending'
            })
            .addCase(findByReimburseList.fulfilled, (state, { payload }) => {
                state.accountsLoading = 'fulfilled'
                state.accountsData = payload
            })
            .addCase(findByReimburseList.rejected, (state) => {
                state.accountsLoading = 'reject'
            })
            // 归属人
            .addCase(findStaffId.pending, state => {
                delete state.staffIdList
                state.staffIdLoading = 'pending'
            })
            .addCase(findStaffId.fulfilled, (state, { payload }) => {
                state.staffIdLoading = 'fulfilled'
                state.staffIdList = payload
            })
            .addCase(findStaffId.rejected, (state) => {
                state.staffIdLoading = 'reject'
            })
    },
})

export const { resetListState, setTabKey, setStaffIdLoading, setApplyForms, setAccountsState } = slice.actions
export default slice.reducer
