import {
    DeleteOutlined,
    EditOutlined,
    ToTopOutlined,
    UndoOutlined,
    DownloadOutlined,
} from "@ant-design/icons"
import {
    Affix,
    Button,
    Descriptions,
    Divider,
    ResultProps,
    Skeleton,
    Space,
    Tag,
    Typography,
} from "antd"
import { PageHeader } from "@ant-design/pro-layout"
import dayjs from "dayjs"
import { useCallback, useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { AppDispatch, RootState } from "../../app/store"
import StateBadge from "../../components/document/ApprovalStateBadge"
import { Traveller } from "../models"
import { reset as resetListState } from "./listSlice"
import ApplyProcessTimeline from "./viewer/ApplyProcessTimeline"
import CreateFlowInstModal from "./viewer/CreateFlowInstModal"
import DelApplyFormModal from "./viewer/DelApplyFormModal"
import DelApprovalModal from "./viewer/DelApprovalModal"
import HistoryTimeline from "./viewer/HistoryTimeline"
import PutApprovalModal from "./viewer/PutApprovalModal"
import TravellerInfo from "./viewer/TravellerInfo"
import {
    fetchApplyFormDetail,
    fetchDownloadPDF,
    resetState as resetViewerState,
} from "./viewerSlice"

const { Text } = Typography

const mapStateToProps = (state: RootState) => ({
    loading: state.applyFormViewer.pageLoading,
    error: state.applyFormViewer.error,
    viewerMode: state.applyFormViewer.viewerMode,
    detail: state.applyFormViewer.detail,
    staffList: state.applyFormViewer.staffList,
    useSubmit: state.applyFormViewer.useSubmit,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    dispatchFetchDetail: (id: string) => dispatch(fetchApplyFormDetail(id)),
    resetList: () => {
        dispatch(resetListState())
    },
    resetViewer: () => {
        dispatch(resetViewerState())
    },
    dispatchDownload: async (id: string) => {
        await dispatch(fetchDownloadPDF(id))
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

const _ApplyFormViewer = ({
    loading,
    error,
    detail,
    useSubmit,
    viewerMode,
    staffList,
    dispatchFetchDetail,
    resetViewer,
    resetList,
    dispatchDownload,
}: Props): JSX.Element => {
    console.log("🚀 ~ viewerMode:", viewerMode)

    const { documentId } = useParams()
    const navi = useNavigate()
    const [extraBtn, setExtraBtn] = useState<JSX.Element[]>()
    const [lastOp, setLastOp] = useState<
        "submit" | "rollback" | "edit" | "delete" | "accept" | "reject"
    >()
    const [downloading, setDownloading] = useState(false)

    const location = useLocation()
    //卸载时清空数据
    useEffect(
        () => () => {
            resetViewer()
        },
        [resetViewer]
    )

    //信息加载
    useEffect(() => {
        if (error) return
        if (!detail || documentId !== detail?.applyId) {
            dispatchFetchDetail(documentId || "404")
            setLastOp(undefined)
        }
        if (useSubmit) {
            dispatchFetchDetail(documentId || "404")
            //更新结束跳转回详情
            setLastOp("submit")
        }
    }, [detail, error, documentId, useSubmit, dispatchFetchDetail])

    //信息加载错误
    useEffect(() => {
        if (location.pathname === "applyForm/result") return
        if (error) {
            const props: ResultProps = {}
            if (error === 404) {
                props.status = "404"
                props.title = "您访问的申请单不存在"
                props.subTitle = "404 not found"
            } else if (error === 403) {
                props.status = "403"
                props.title = "您无法访问此申请单"
                props.subTitle = "403 forbidden"
            } else if (error === 500) {
                props.status = "500"
                props.title = "服务器内部错误"
                props.subTitle = "500 internal server error"
            } else {
                props.status = "error"
                props.title = "请求错误！"
                props.subTitle = error
            }
            resetViewer()
            navi("/applyForm/result", {
                state: props,
                replace: true,
            })
        }
    }, [error, resetViewer, navi, location.pathname])

    //申请单相关操作完成
    const onOpsFinished = () => {
        resetViewer()
        resetList()
        setLastOp(undefined)
    }

    const download = useCallback(async () => {
        setDownloading(true)
        await dispatchDownload(documentId ?? "")
        setDownloading(false)
    }, [dispatchDownload, documentId, setDownloading])

    useEffect(() => {
        //更新操作区
        const state = detail?.approvalState
        if (state === undefined) return
        const btn = new Array<JSX.Element>()
        switch (viewerMode) {
            case "owner": //提交人操作区
                if (state !== 0)
                    btn.push(
                        <Link key="btnEdit" to="./edit">
                            <Button icon={<EditOutlined />}>修改</Button>
                        </Link>
                    )
                if ([-1, 2].indexOf(state) >= 0)
                    btn.push(
                        <Button
                            key="btnSubmit"
                            icon={<ToTopOutlined />}
                            onClick={() => {
                                setLastOp("submit")
                            }}>
                            提交
                        </Button>
                    )
                if ([0, 2].indexOf(state) >= 0)
                    btn.push(
                        <Button
                            key="btnRollback"
                            icon={<UndoOutlined />}
                            danger
                            onClick={() => {
                                setLastOp("rollback")
                            }}>
                            撤回
                        </Button>
                    )
                if ([-1, 2].indexOf(state) >= 0 && !detail?.approvalDateTime)
                    btn.push(
                        <Button
                            key="btnDelete"
                            icon={<DeleteOutlined />}
                            danger
                            onClick={() => setLastOp("delete")}>
                            删除
                        </Button>
                    )
                btn.push(
                    <Button
                        key="btnDownload"
                        onClick={download}
                        loading={downloading}
                        icon={<DownloadOutlined />}>
                        下载
                    </Button>
                )
                break
            case "approver":
                btn.push(
                    <Button
                        key="accept"
                        type="primary"
                        ghost
                        onClick={() => setLastOp("accept")}>
                        同意
                    </Button>
                )
                btn.push(
                    <Button
                        key="reject"
                        danger
                        onClick={() => setLastOp("reject")}>
                        驳回
                    </Button>
                )
                break
        }
        setExtraBtn(btn)
    }, [detail, viewerMode, lastOp, downloading, download, setExtraBtn])

    return (
        <div>
            <Affix key="PageHeader" offsetTop={0}>
                <PageHeader
                    ghost={false}
                    title="申请单详情"
                    tags={<StateBadge state={detail?.approvalState} />}
                    extra={extraBtn}
                />
            </Affix>
            <div key="container" className="container">
                {/* Modals */}
                <CreateFlowInstModal
                    visiable={lastOp === "submit"}
                    onCancel={() => {
                        setLastOp(undefined)
                    }}
                    onFinished={onOpsFinished}
                />
                <DelApplyFormModal
                    visiable={lastOp === "delete"}
                    onCancel={() => setLastOp(undefined)}
                    onFinished={onOpsFinished}
                />
                <DelApprovalModal
                    visiable={lastOp === "rollback"}
                    onCancel={() => setLastOp(undefined)}
                    onFinished={onOpsFinished}
                />
                <PutApprovalModal
                    visiable={
                        ["accept", "reject"].indexOf(lastOp as string) >= 0
                    }
                    mode={lastOp}
                    onCancel={() => {
                        setLastOp(undefined)
                    }}
                    onFinished={onOpsFinished}
                />
                {/* Detail */}
                <Skeleton loading={loading} active={loading}>
                    <Descriptions
                        title="单据信息"
                        items={[
                            {
                                key: "申请人",
                                label: "申请人",
                                children: (
                                    <Text>
                                        {
                                            staffList?.find(
                                                s =>
                                                    s.staffId ===
                                                    detail?.staffId
                                            )?.staffUserName
                                        }
                                    </Text>
                                ),
                            },
                            {
                                key: "单号",
                                label: "单号",
                                children: <Text>{detail?.txm}</Text>,
                            },
                            {
                                key: "项目",
                                label: "项目",
                                children: (
                                    <Text>
                                        {detail?.projectName || "未选择"}
                                    </Text>
                                ),
                            },
                            {
                                key: "预算",
                                label: "预算",
                                children: <Text>{detail?.applyMoney}</Text>,
                            },
                            {
                                key: "国内国际",
                                label: "国内国际",
                                children: (
                                    <Text>
                                        {detail?.international
                                            ? "国际出差"
                                            : "国内出差"}
                                    </Text>
                                ),
                            },
                        ]}
                    />
                    <Divider />
                    <Descriptions
                        title="地点日期"
                        items={[
                            {
                                key: "开始日期",
                                label: "开始日期",
                                children: (
                                    <Text>
                                        {dayjs(
                                            new Date(
                                                detail?.applyStartDate || 0
                                            )
                                        ).format("YYYY-MM-DD")}
                                    </Text>
                                ),
                            },
                            {
                                key: "结束日期",
                                label: "结束日期",
                                children: (
                                    <Text>
                                        {dayjs(
                                            new Date(detail?.applyEndDate || 0)
                                        ).format("YYYY-MM-DD")}
                                    </Text>
                                ),
                            },
                            {
                                key: "申请日期",
                                label: "申请日期",
                                children: (
                                    <Text>
                                        {dayjs(
                                            new Date(detail?.applyDate || 0)
                                        ).format("YYYY-MM-DD")}
                                    </Text>
                                ),
                            },
                            {
                                key: "长/短差",
                                label: "长/短差",
                                children: (
                                    <Text>
                                        {detail?.longApplyFor ? "长差" : "短差"}
                                    </Text>
                                ),
                            },
                            {
                                key: "出差城市",
                                label: "出差城市",
                                children: <Space size={[0, 'middle']} wrap>
                                    {detail?.destinations?.map((it, idx) => (
                                        <Tag key={it.path + idx}>{it.name}</Tag>
                                    ))
                                    }
                                </Space>
                            },
                        ]}
                    />
                    <Divider />
                    <Descriptions
                        title="备注信息"
                        items={[{ children: detail?.description }]}
                    />
                    {detail?.customFieldInstances && (
                        <>
                            <Divider />
                            <Descriptions
                                title="其他信息"
                                items={(() => {
                                    return detail?.customFieldInstances?.map(
                                        it => ({
                                            key: `${it.fieldId}`,
                                            label: `${it.fieldName}`,
                                            children: (
                                                <Text>
                                                    {it.fieldValues?.join("、")}
                                                </Text>
                                            ),
                                        })
                                    )
                                })()}
                            />
                        </>
                    )}
                    <Divider />
                    <Descriptions
                        title="出行人"
                        items={(() => {
                            return detail?.travellers?.map((t: Traveller) => ({
                                key: `${t.travellerId}`,
                                children: (
                                    <TravellerInfo
                                        traveller={t}
                                        viewerMode={viewerMode}
                                        clickable={true}
                                    />
                                ),
                            }))
                        })()}
                    />
                    {detail?.applyProcesses && (
                        <>
                            <Divider />
                            <Descriptions
                                title="流程信息"
                                items={[
                                    {
                                        children: (
                                            <ApplyProcessTimeline
                                                list={
                                                    detail?.applyProcesses || []
                                                }
                                            />
                                        ),
                                    },
                                ]}
                            />
                        </>
                    )}
                    {detail?.documentChangeHistories && (
                        <>
                            <Divider />
                            <Descriptions
                                title="更改历史"
                                items={[
                                    {
                                        children: (
                                            <HistoryTimeline
                                                histList={
                                                    detail?.documentChangeHistories ||
                                                    []
                                                }
                                            />
                                        ),
                                    },
                                ]}
                            />
                        </>
                    )}
                </Skeleton>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(_ApplyFormViewer)
