import { Badge } from "antd"

export default function StateBadge({
    state,
}: {
    state: number | undefined
}): JSX.Element {
    switch (state) {
        case 0:
            return <Badge status="processing" text="审批中" />
        case 1:
            return <Badge status="success" text="已审批" />
        case 2:
            return <Badge status="error" text="已驳回" />
        case -1:
            return <Badge status="default" text="未提交" />
        default:
            return <></>
    }
}
