import { ReactElement, useEffect, useState } from "react"
import {
    BrowserRouter as Router,
    Outlet,
    Route,
    Routes,
} from "react-router-dom"
import { ConfigProvider, Grid, Layout } from "antd"
import zhCN from "antd/es/locale/zh_CN"
import { HeaderBar, LeftSideMenu, MobileMenu } from "./components"
import "dayjs/locale/zh-cn"
import "antd/dist/reset.css"
import "./App.css"
import Login from "./features/user/Login"
import PageNotFound from "./components/PageNotFound"
import BookingApplyFormSelector from "./features/book/ApplyFormSelector"
import HomePage from "./features/homePage/HomePage"
import TravellerRoutes from "./features/traveller/Routes"
import OrderListRoutes from "./features/order/OrderListRoutes"
import BookingAirplaneRoutes from "./features/book/airplane/BookingAirplaneRoutes"
import BookingHotelRoutes from "./features/book/hotel/BookingHotelRoutes"
import BookingTrainRoutes from "./features/book/train/BookingTrainRoutes"
import ApprovalRoutes from "./features/approval/Routes"
import ApplyFormRoutes from "./features/applyForm/Routes"
import ReimburseRoutes from './features/reimburseForm/Routes'
import AccountsRoutes from "./features/account/Routes"

const { Sider, Content, Header } = Layout
const { useBreakpoint } = Grid

const AuthorizedApp = (): ReactElement => {
    const screens = useBreakpoint()
    const [collapesd, setColllapsed] = useState(false)
    const [layoutMarginLeft, setLayoutMarginLeft] = useState(
        screens.lg ? 200 : 0
    )

    useEffect(() => {
        if (!screens.lg) {
            setLayoutMarginLeft(0)
        } else if (collapesd) {
            setLayoutMarginLeft(80)
        } else {
            setLayoutMarginLeft(200)
        }
    }, [setLayoutMarginLeft, screens, collapesd])

    return (
        <Layout hasSider={screens.lg}>
            <MobileMenu />
            <Sider
                hidden={!screens.lg}
                collapsed={collapesd}
                collapsible={true}
                theme="light"
                onCollapse={setColllapsed}
                style={{
                    overflow: "auto",
                    height: "100vh",
                    position: "fixed",
                    left: 0,
                    top: 0,
                    bottom: 0,
                }}>
                <LeftSideMenu />
            </Sider>
            <Layout
                style={{ marginLeft: layoutMarginLeft, minHeight: "100vh" }}>
                <Header className="header">
                    <HeaderBar />
                </Header>
                <Content>
                    <Outlet />
                </Content>
            </Layout>
        </Layout>
    )
}

const App = (): ReactElement => (
    <ConfigProvider locale={zhCN}>
        <Router>
            <Routes>
                <Route path="login/*" element={<Login />} />
                <Route path="/" element={<AuthorizedApp />}>
                    <Route path="/" element={<HomePage />} />
                    <Route
                        path="my/traveller/*"
                        element={<TravellerRoutes />}
                    />
                    <Route path="applyForm/*" element={<ApplyFormRoutes />} />
                    <Route
                        path="booking/setup"
                        element={<BookingApplyFormSelector />}
                    />
                    <Route
                        path="booking/airplane/*"
                        element={<BookingAirplaneRoutes />}
                    />
                    <Route
                        path="booking/hotel/*"
                        element={<BookingHotelRoutes />}
                    />
                    <Route
                        path="booking/train/*"
                        element={<BookingTrainRoutes />}
                    />
                    <Route path="order/list/*" element={<OrderListRoutes />} />
                    <Route path="accountsForm/*" element={<AccountsRoutes />} />
                    <Route path="reimburseForm/*" element={<ReimburseRoutes />} />
                    <Route path="my/todo/*" element={<ApprovalRoutes />} />
                    <Route path="*" element={<PageNotFound />} />
                </Route>
            </Routes>
        </Router>
    </ConfigProvider>
)

export default App
