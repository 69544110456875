import React, { useEffect, useState } from 'react'
import { Form, Input, Select } from 'antd'
import { useAppSelector } from '../../../app/hooks'
import { AccountsCity, MLocation } from '../../models'
import { EnvironmentOutlined } from '@ant-design/icons'


type Props = {
    onChange?: (value?: AccountsCity) => void
    value?: AccountsCity
    isStart?: boolean
}

export function Destinationitem({ onChange, value, isStart }: Props) {
    const form = Form.useFormInstance()
    // 是否国内国际
    const international = Form.useWatch<boolean>("international", form)
    // 国外城市国家  
    const foreigner = useAppSelector(state => state.location.foreigner)
    const foreignCity = useAppSelector(state => state.location.foreignCity)
    const [overseasHotCity, setOverseasHotCity] = useState<MLocation[]>()
    //   国内城市
    const city = useAppSelector(state => state.location.cityChina)
    const [hotCity, setHotCity] = useState<MLocation[]>()
    const loading = useAppSelector(state => state.location.loading)
    // 下拉菜单数据
    const [options, setOptions] = useState<JSX.Element[]>()
    const [subCity, setSubCity] = useState<MLocation[]>()


    // 过滤数据
    useEffect(() => {
        // 获取国内数据
        if (city && !hotCity) {
            const _hotCity = city.filter(it => ['国内一线', '国内二线'].includes(it.category))
            setHotCity(_hotCity)
        }
        // 国外城市
        if (foreigner && foreignCity && !overseasHotCity) {
            const _overseasHotCity = foreignCity.filter(it => ['国际一线', '国际二线'].includes(it.category))
            // 将国外城市与国家合并
            setOverseasHotCity([..._overseasHotCity, ...foreigner])
        }
    }, [city, foreignCity, foreigner, hotCity, loading, overseasHotCity])

    useEffect(() => {
        if (subCity) {
            setOptions(
                subCity.map((c, idx) => (
                    <Select.Option
                        value={JSON.stringify(c)}
                        key={c.id + "-" + idx}>
                        {c.name}
                    </Select.Option>
                ))
            )
        } else {
            setOptions(undefined)
        }
    }, [subCity])

    // 点击国内或国外城市
    const handleSearch = (v: string) => {
        if (international) {
            if (!v || v === "") {
                setSubCity(undefined)
            } else {
                setSubCity(overseasHotCity?.filter(
                    it =>
                        it.name.includes(v) ||
                        it.enName === v ||
                        it.shortName === v
                ))
            }
        } else {
            if (!v || v === "") {
                setSubCity(undefined)
            } else {
                setSubCity(hotCity?.filter(
                    it =>
                        it.name.includes(v) ||
                        it.enName === v ||
                        it.shortName === v
                ))
            }
        }
    }

    const onSelect = (v: string) => {
        const values = JSON.parse(v)
        const _value = {
            depthPath: values.depthPath,
            name: values.name,
        }
        onChange?.(_value)
    }

    // 点击x清除数据
    const onClear = () => {
        setSubCity(undefined)
        setOptions(undefined)
    }

    return (
        <>
            <Select
                value={value?.name}
                loading={loading === "pending"}
                style={{ width: "100%" }}
                allowClear
                onClear={onClear}
                disabled={loading !== 'fulfilled'}
                showSearch
                suffixIcon={<EnvironmentOutlined />}
                placeholder={international ? isStart ? "请选择出发国外城市" : "请选择到达国外城市" : isStart ? "请选择出发国内城市" : "请选择到达国内城市"}
                notFoundContent={null}
                filterOption={false}
                onSearch={handleSearch}
                onSelect={onSelect}>
                {options}
                {!options && (
                    <>
                        {
                            !international && <Select.OptGroup label="城市">
                                {hotCity?.map((k, index) => (
                                    <Select.Option
                                        value={JSON.stringify(k)}
                                        key={"airportCity" + k.id + index}
                                        children={k?.name}
                                    />
                                ))}
                            </Select.OptGroup>
                        }
                        {
                            international && <Select.OptGroup label="国际城市/国家">
                                {overseasHotCity?.map((k, index) => (
                                    <Select.Option
                                        value={JSON.stringify(k)}
                                        key={"airportCity" + k.id + index}
                                        children={k?.name}
                                    />
                                ))}
                            </Select.OptGroup>
                        }
                    </>
                )}
            </Select>
        </>
    )
}

export default function Destination() {
    const form = Form.useFormInstance()
    const [departures, setDepartures] = useState<AccountsCity>()
    const [destination, setDestination] = useState<AccountsCity>()
    const accountsStartData = Form.useWatch<string>("accountsStartData", form)
    const departDepthPath = Form.useWatch<string>("departDepthPath", form)
    const accountsEndData = Form.useWatch<string>("accountsEndData", form)
    const depthPath = Form.useWatch<string>("depthPath", form)

    useEffect(() => {
        if (departures) {
            form.setFieldValue("accountsStartData", departures.name)
            form.setFieldValue("departDepthPath", departures.depthPath)
        } else {
            if (accountsStartData && departDepthPath) {
                setDepartures({
                    depthPath: departDepthPath,
                    name: accountsStartData,
                })
            }
        }
        if (destination) {
            form.setFieldValue("accountsEndData", destination?.name)
            form.setFieldValue("depthPath", destination?.depthPath)
        } else {//回显数据
            if (accountsEndData && depthPath) {
                setDestination({
                    depthPath: depthPath,
                    name: accountsEndData,
                })
            }
        }
    }, [accountsEndData, accountsStartData, departDepthPath, departures, depthPath, destination, form])

    return (
        <>
            <Form.Item label='出发地' required>
                <Destinationitem isStart={true} onChange={setDepartures} value={departures} />
                <Form.Item noStyle hidden name='accountsStartData' rules={[{ required: true, message: "请选择出发地！" }]} ><Input /></Form.Item>
                <Form.Item noStyle hidden name='departDepthPath' ><Input /></Form.Item>
            </Form.Item>
            <Form.Item label='目的地' required>
                <Destinationitem isStart={false} onChange={setDestination} value={destination} />
                <Form.Item noStyle hidden name='accountsEndData' rules={[{ required: true, message: "请选择目的地！" }]}><Input /></Form.Item>
                <Form.Item noStyle hidden name='depthPath' ><Input /></Form.Item>
            </Form.Item>
        </>
    )
}
