import { Drawer, Grid, message } from "antd"
import { Accounts } from "../reimburseForm/listSlice"
import TravelEditor from "../reimburseForm/viewer/TravelEditor"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { resetDetailsState } from "./accountSlice"

const { useBreakpoint } = Grid

type Props = {
    item: Accounts
    isDrawerVisible?: boolean
    setIsDrawerVisible?: (v: boolean) => void
    onFinished?: () => void
}

export const Viewer = ({ item, isDrawerVisible, setIsDrawerVisible, onFinished, }: Props) => {

    //  个性化布局
    const screens = useBreakpoint()
    const [posted, setPosted] = useState<boolean>(false)
    // 账目详情页接口loading
    const updateLoading = useAppSelector(state => state.account.updateLoading)
    const dispatch = useAppDispatch()


    useEffect(() => {
        if (updateLoading === 'idle' && !posted) setPosted(true)
        else if (updateLoading === 'fulfilled' && posted) {
            message.success("账目保存成功！")
            setIsDrawerVisible?.(false)
            onFinished?.()
            dispatch(resetDetailsState())
        }
    }, [updateLoading, dispatch, onFinished, posted, setIsDrawerVisible])

    return (
        <>
            <Drawer
                title='账目详情'
                width={screens.md ? 640 : "100vw"}
                style={{ backgroundColor: "#fafafa" }}
                onClose={() => {
                    setIsDrawerVisible?.(false)
                }}
                open={isDrawerVisible}
                destroyOnClose>
                <TravelEditor accounts={item} />
            </Drawer>
        </>
    )
}

