import { message, Modal, ResultProps, Typography } from "antd"
import { useEffect } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import { AppDispatch, RootState } from "../../../app/store"
import { deleteApplyForm, resetOpsModal } from "../viewerSlice"

const mapStateToProps = (state: RootState) => ({
    state: state.applyFormViewer.operationState,
    error: state.applyFormViewer.operationError,
    id: state.applyFormViewer.detail?.applyId,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    dispatchReset: () => {
        dispatch(resetOpsModal())
    },
    dispatchDelete: (id: string) => {
        dispatch(deleteApplyForm(id))
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        visiable: boolean
        onFinished?: () => void
        onCancel?: () => void
    }

const _DelApplyForm = ({
    id,
    visiable,
    state,
    onCancel,
    onFinished,
    dispatchReset,
    dispatchDelete,
}: Props) => {
    const navi = useNavigate()
    const _onCancel = () => {
        dispatchReset()
        onCancel?.()
    }

    const _onOk = () => {
        if (!id) {
            message.warning("缺少必要参数")
            onCancel?.()
            return
        }
        dispatchDelete(id)
    }

    useEffect(() => {
        if (visiable && state === "fulfilled") {
            onFinished?.()
            navi("../result", {
                replace: true,
                state: {
                    title: "申请单已经删除",
                    status: "success",
                } as ResultProps,
            })
        }
    }, [visiable, state, navi, onFinished])

    return (
        <Modal
            okText="删除"
            okType="danger"
            open={visiable}
            onCancel={_onCancel}
            onOk={_onOk}
            okButtonProps={{ loading: state === "pending" }}
            closable={state !== "pending"}>
            <Typography.Text>确定要删除这个申请单吗？</Typography.Text>
        </Modal>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(_DelApplyForm)
