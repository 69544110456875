import { ReactElement, useState } from "react"
import {
    Row,
    Col,
    Button,
    Modal,
    Space,
    Typography,
    Divider,
    Image,
} from "antd"
import {
    AirplaneCabinVModel,
    AirplaneFlightVModel,
    setAirplaneParameters,
} from "../../services/bookAirplaneSlice"
import { useNavigate } from "react-router-dom"
import { PlaneDetailRefundRulesCpn } from "../../../../order/detail/components/PlaneOrderDetailRulesCpn"
import { blue } from "@ant-design/colors"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"

export interface AirplaneParameters {
    cabin: AirplaneCabinVModel
    flight: AirplaneFlightVModel
    mode?: "book" | "change"
    passengerCount?: number
    applyId?: string
}

export const AirplaneListCabinItem = (
    props: AirplaneParameters
): ReactElement => {
    const dispatch = useAppDispatch()
    const { Text } = Typography
    const { cabin, flight, applyId } = props
    const {
        supplierName,
        packageId,
        cabinGrade,
        discount,
        finallyPrice,
        seating,
        specialFlag,
        serviceAmount,
    } = cabin
    const [showRules, setShowRules] = useState(false)
    const supplierIcon = useAppSelector(state => state.supplier.supplierIcon)
    // 跳转传参
    const navi = useNavigate()
    // 查询退改规则入参
    const params = {
        flightDate: flight?.flightDate,
        supplierId: cabin?.supplierId,
        airline: flight?.airline,
        productType: cabin?.productType,
        initTicketPrice: cabin?.initTicketPrice,
        flightNum: flight?.flightNum,
        cabin: cabin?.cabin,
        takeOffPort: flight?.takeOffPort,
        landingPort: flight?.landingPort,
        applyForId: applyId,
    }

    const bookClick = () => {
        // 预定参数
        dispatch(setAirplaneParameters(props))
        // 点击跳转AirplaneAddPassenger.tex
        navi("/booking/airplane/addPassenger/")
    }

    return (
        <>
            <Row wrap={true} align="middle">
                <Col flex="1">
                    <Space direction="vertical">
                        <Image
                            src={
                                supplierIcon?.find(
                                    it => it.supplier === packageId
                                )?.icon
                            }
                            width={35}
                            height={35}
                            placeholder={true}
                            preview={false}
                        />
                        <Text>{supplierName}</Text>
                    </Space>
                </Col>
                <Col flex="2">
                    <Space direction="vertical">
                        <Button
                            type="link"
                            block
                            style={{ padding: 0 }}
                            onClick={() => setShowRules(true)}>
                            {" "}
                            退改签详情&nbsp;/&nbsp;行李额说明&nbsp;&gt;{" "}
                        </Button>
                        <Text>
                            {cabinGrade}&nbsp;
                            {`${parseInt(discount || "") / 10}折`}
                        </Text>
                    </Space>
                    <Modal
                        title="退改规则"
                        open={showRules}
                        footer={null}
                        onCancel={() => setShowRules(false)}>
                        {showRules && (
                            <PlaneDetailRefundRulesCpn params={params} />
                        )}
                    </Modal>
                </Col>
                <Col flex="2 150px">
                    <Space direction="vertical">
                        <Text>
                            <Text type="secondary" style={{ fontSize: 18 }}>
                                <Text
                                    italic
                                    style={{ color: blue[5], fontSize: 18 }}>
                                    ¥{finallyPrice}
                                </Text>
                                <Text>+</Text>
                                <Text
                                    italic
                                    style={{ color: blue[5], fontSize: 14 }}>
                                    ¥{serviceAmount}
                                </Text>
                            </Text>
                        </Text>
                        {specialFlag && <div>{specialFlag}</div>}
                    </Space>
                </Col>
                <Col flex="1">
                    <Space direction="vertical" align="center">
                        <Button type="primary" onClick={bookClick}>
                            预订
                        </Button>
                        <Text>{seating !== ">9" && `余${seating}张`}</Text>
                    </Space>
                </Col>
            </Row>
            <Divider style={{ margin: 0 }} />
        </>
    )
}
