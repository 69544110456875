import React, { useState } from "react"
import { Accounts } from "../listSlice"
import { TitleAccountsType, lastFails, listAccountsType } from "../../book/const"
import { Drawer, Flex, Grid, Space, Table, TableColumnsType, Tooltip, Typography } from "antd"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import TravelViewer from "./TravelViewer"
import { orange, volcano } from "@ant-design/colors"
import dayjs from "dayjs"
import IconFont from '../../../components/IconFont'
import { fetchOrderInfo, setOrderInfo } from "../viewerSlice"

const { Text } = Typography
type AccountsViewerProps = {
    accounts?: Accounts[]
    clickable?: boolean
}

export const isAccountsData = (
    accountsEndData: string,
    accountsStartData: string
) => {
    if (!/^\d+$/.test(accountsEndData) && !/^\d+$/.test(accountsStartData)) {
        return (
            <div>
                <span>{accountsEndData}</span>--<span>{accountsStartData}</span>
            </div>
        )
    }
}

export default function TravelAccounts({ accounts, clickable }: AccountsViewerProps) {
    const dispatch = useAppDispatch()
    const updatePreCheckError = useAppSelector(state => state.reimburesEditor.updatePreCheckError)
    const orderInfoLoading = useAppSelector(state => state.reimburseFormViewer.orderInfoLoading)
    const [data, setData] = useState<Accounts>()
    // // Drawer开关
    const [visiable, setVisiable] = useState(false)
    const { lg } = Grid.useBreakpoint()
    // // 超标原因列表
    const exceedReasonList = useAppSelector(state => state.user.exceedReasonList)
    // // 员工列表
    const staffList = useAppSelector(state => state.reimburseFormViewer.staffList)

    const isexceedReasonId = (exceedReasonId?: string) => {
        return exceedReasonList?.find(it => it.exceedReasonId === exceedReasonId)?.content
    }

    const columns: TableColumnsType<Accounts> = [
        {
            title: "标题",
            dataIndex: "accountsType",
            align: 'center',
            width: 40,
            render: (_, item) => {
                return <Tooltip placement="top" title={TitleAccountsType(item.accountsType)}>
                    <IconFont type={listAccountsType(item.accountsType)} style={{ fontSize: '30px' }} alt='' />
                </Tooltip>
            }
        },
        {
            title: "出发地/目的地",
            align: 'center',
            width: 150,
            render: (_, item) => {
                return <Space direction='horizontal'>
                    {
                        (/^\d+$/.test(item?.accountsStartData)) ? <Text>{dayjs(Number(item?.accountsStartData)).format("YYYY-MM-DD")}</Text> : <Text style={{ width: 80 }} ellipsis={{ tooltip: item?.accountsStartData }}>{item.accountsStartData}</Text>
                    }
                    <IconFont type='icon-jiantou' />
                    {
                        (/^\d+$/.test(item?.accountsEndData)) ? <Text>{dayjs(Number(item?.accountsEndData)).format("YYYY-MM-DD")}</Text> : <Text style={{ width: 80 }} ellipsis={{ tooltip: item.accountsEndData }}>{item.accountsEndData}</Text>
                    }
                </Space>
            }
        },
        {
            title: "消费时间",
            dataIndex: "accountDate",
            align: 'center',
            width: 150,
        },
        {
            title: "归属人",
            width: 80,
            align: 'center',
            render: (_, item) => {
                return <Text>
                    {
                        item.customerList.map(it => {
                            if (it.exception) {
                                return it.traveller?.name
                            } else {
                                return staffList?.find(i => i.staffId === it.staffId)?.staffUserName ?? ''
                            }
                        })
                    }
                </Text>
            }
        },
        {
            title: "金额",
            align: 'center',
            dataIndex: "accountsSumMoney3",
            width: 100,
        },
        {
            title: "账目类型",
            align: 'center',
            width: 90,
            render: (_, item) => {
                return <Text>{item.corpAccounts ? '对公' : '个人'}</Text>
            }
        },
        {
            title: "超标原因",
            align: 'center',
            width: 180,
            render: (_, item) => {
                return <Text style={{ color: orange[5] }}>{isexceedReasonId(item?.exceedReasonId)}</Text>
            }
        },
        {
            title: "规则检查",
            width: 250,
            hidden: !updatePreCheckError || updatePreCheckError.length === 0,
            render: (_, item) => {
                return <Text>
                    {updatePreCheckError && updatePreCheckError?.filter(i => i.refId === item.accountsId).map((it, idx) => {
                        return <Flex key={idx} style={{ color: volcano[5] }}>{lastFails(it)}</Flex>
                    })}
                </Text>
            }
        },
    ]

    return (
        <>
            <Table
                rowKey={(record) => record.accountsId ?? ""}
                dataSource={accounts}
                columns={columns}
                scroll={{ x: "max-content" }}
                onRow={(r: Accounts) => {
                    return {
                        className: "clickable",
                        onClick: (e) => {
                            if (!clickable) {
                                e?.preventDefault()
                                return
                            }
                            setData(r)
                            if (r.oid && orderInfoLoading !== 'fulfilled') {
                                dispatch(fetchOrderInfo(r.oid))
                            }
                            if (!visiable) setVisiable(true)
                        },
                    }
                }}
            />
            <Drawer
                title="账单详情"
                width={lg ? 640 : "75vw"}
                style={{ backgroundColor: "#fafafa" }}
                open={visiable}
                onClose={() => {
                    setVisiable(false)
                    dispatch(setOrderInfo())
                }}
                destroyOnClose>
                <TravelViewer
                    accounts={data}
                    exceedReason={isexceedReasonId(data?.exceedReasonId)}
                    staffUserName={
                        data?.customerList.map(it => {
                            if (it.exception) {
                                return it.traveller?.name ?? ''
                            } else {
                                return staffList?.find(t => t.staffId === it.staffId)?.staffUserName ?? ''
                            }
                        })
                    }
                />
            </Drawer>
        </>
    )
}
