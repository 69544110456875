import { MLocation, TipMessages } from "../models"
import { getKeyValueByKey } from "../user/userAPI"

export const getLocation = async (): Promise<Array<MLocation>> => {
    const locationConfig = await getKeyValueByKey("config_location")
    const url = JSON.parse(locationConfig.value)["url"]
    const res = await fetch(url, {
        mode: "cors",///跨域请求
        cache: 'default'//保障不会每次重新加载
    })
    if (!res.ok) return Promise.reject(res.status)
    const location = await res.json() as Array<MLocation>
    return location
}

// 提示信息
export const getTipMessage = async (): Promise<TipMessages> => {
    const tipMessage = await getKeyValueByKey("orderPlatformNotice")
    const url = JSON.parse(tipMessage.value)
    return url
}
