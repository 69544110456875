import { ReactElement } from "react"
import { Card, Row, Col, Space, Typography } from "antd"

import {
    OrderListItemHeaderCpn,
    OrderListItemPriceGroupCpn,
} from "../view/OrderListItemCommon"
import { PlaneOrderListModel } from "../../service/orderListModel"
import { airplaneOrderStatusToStr } from "../../../book/const"
import { ShowStateOperate } from "../../common/view/OrderStateOperate"
import { useNavigate } from "react-router-dom"

interface Props {
    planeItem: PlaneOrderListModel
    changeClick?: () => void
    refundClick?: () => void
}
const { Text, Paragraph } = Typography
export const AirplaneListCardItem = (props: Props): ReactElement => {
    const { planeItem, changeClick, refundClick } = props

    const {
        customId,
        orderTime,
        total,
        fromPlace,
        toPlace,
        orderState,
        userName,
        flight,
        depatureTime,
        packageId,
    } = planeItem
    // 路由跳转传参
    const navi = useNavigate()
    const keywordClick = () => {
        // 跳转到OrderDetail.tsx
        navi("/order/list/detail", { state: planeItem })
    }

    const _airplaneChangeClick = () => {
        // 飞机改签
        changeClick?.()
    }
    const _airplaneRefundClick = () => {
        // 飞机退票
        refundClick?.()
    }

    return (
        <Card
            type="inner"
            bordered={false}
            title={
                <OrderListItemHeaderCpn
                    publicItem={planeItem}
                    customId={customId}
                    orderTime={orderTime}
                    packageId={packageId}
                />
            }>
            <Row className="clickable" onClick={keywordClick}>
                <Col flex="2">
                    <Space direction="vertical" size={2}>
                        <Space direction="horizontal">
                            <Paragraph
                                ellipsis={{ rows: 2, tooltip: fromPlace }}
                                style={{
                                    maxWidth: 50,
                                    fontSize: 16,
                                    marginBottom: 0,
                                }}
                                strong>
                                {fromPlace}
                            </Paragraph>
                            --
                            <Paragraph
                                ellipsis={{ rows: 2, tooltip: toPlace }}
                                style={{
                                    maxWidth: 50,
                                    fontSize: 16,
                                    marginBottom: 0,
                                }}
                                strong>
                                {toPlace}
                            </Paragraph>
                        </Space>
                        <Text>出发时间：{depatureTime}</Text>
                        <Text>
                            {flight && flight.airlineName}
                            {flight && flight.flightNum}
                        </Text>
                        <Text>出行人：{userName}</Text>
                    </Space>
                </Col>
                <Col flex="none">
                    <OrderListItemPriceGroupCpn
                        total={total}
                        status={airplaneOrderStatusToStr(orderState)}
                    />
                </Col>
            </Row>
            <ShowStateOperate
                orderModel={planeItem}
                airplaneRefundClick={_airplaneRefundClick}
                airplaneChangeClick={_airplaneChangeClick}
            />
        </Card>
    )
}
