import { Select } from 'antd'
import React from 'react'
import { BankCard } from '../../../models/reimburesForm'
import { useAppSelector } from '../../../../app/hooks'

type Props = {
    value?: BankCard
    onChange?: (v: BankCard) => void
    selectValue?: string
}

export default function BankCardSelect({ onChange, value, selectValue }: Props) {
    // 归属人
    const staffIdList = useAppSelector(state => state.reimburseList.staffIdList || state.reimburesEditor.reimburesForm?.staffIdList)

    const onSelect = (v: string) => {
        const values = JSON.parse(v)
        const _value = {
            type: values.type,
            number: values.number,
            bankName: values.bankName
        }
        onChange?.(_value)
    }

    return (
        <Select value={value?.bankName} key="noBankCardSelection" onSelect={onSelect}>
            {staffIdList?.filter(it => it.staffId === selectValue).map(i => {
                if (!i.bankCardList?.[0].bankName) return null
                return i.bankCardList.map(it =>
                    <Select.Option key={it.number} value={JSON.stringify(it)}>
                        {it.bankName}
                    </Select.Option>
                )
            })}
        </Select>
    )
}
