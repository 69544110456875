import { Form, Input, Select } from "antd"
import { useEffect } from "react"
import { useAppSelector } from "../../../app/hooks"
import { BusinessPurposes } from "../../models"

const purposeToOption = (p: BusinessPurposes, idx: number) => (
    <Select.Option key={`purpose-${idx}`} value={p.purposes}>
        {p.purposes}
    </Select.Option>
)

const { Item } = Form
export default function PurposesSelector() {
    const visiable = useAppSelector(
        state => state.applyFormEditor.applyForm === undefined
    )
    const list = useAppSelector(state => state.applyFormEditor.purposes)
    const options = list?.filter(p => p.enable).map(purposeToOption)
    const form = Form.useFormInstance()
    const actionType = Form.useWatch<string | undefined>("actionType", form)
    useEffect(() => {
        const purpose = list?.find(p => p.purposes === actionType)
        if (purpose) {
            form.setFieldValue("businessPurposesId", purpose.businessPurposesId)
        } else {
            form.resetFields(["businessPurposesId"])
        }
    })
    if (!visiable) return <></>
    return (
        <>
            <Item
                hidden={(list?.length ?? 0) <= 0}
                label="出差目的"
                name="actionType"
                rules={[{ required: (list?.length ?? 0) > 0 }]}>
                <Select>{options}</Select>
            </Item>
            <Item noStyle hidden name="businessPurposesId">
                <Input />
            </Item>
        </>
    )
}
