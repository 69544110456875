import { Form, Switch } from "antd"
import { useAppSelector } from "../../../app/hooks"

export default function InternationalSwitch() {
    const visiable = useAppSelector(
        state => state.applyFormEditor.applyForm === undefined
    )
    const intlMaxDays = useAppSelector(
        state => state.applyFormEditor.maxInternationalApplyDays
    )

    if (!visiable || intlMaxDays <= 0) return <></>
    return (
        <Form.Item<boolean>
            rules={[{ required: true }]}
            name="international"
            label="国内/国际出差"
            valuePropName="checked"
            initialValue={false}>
            <Switch unCheckedChildren="国内" checkedChildren="国际" />
        </Form.Item>
    )
}
