import { Button, Result } from "antd"
import { ResultStatusType } from "antd/lib/result"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { RootState } from "../../../../app/store"

const mapStateToProps = (state: RootState) => ({
    error: state.bookHotel.bookingError,
})

type Props = ReturnType<typeof mapStateToProps>

const _result = ({ error }: Props): JSX.Element => {
    const success = !error
    const status: ResultStatusType = success
        ? "success"
        : error === 500
        ? "500"
        : "error"
    const title = success
        ? "酒店预订成功！"
        : error === 500
        ? "500"
        : "酒店预订失败！"
    const subTitle = success
        ? "酒店预订成功，稍后您会接收到预订成功短信，请您留意。"
        : error === 500
        ? "服务器内部错误，请您再次尝试。"
        : error
    const buttonText = success ? "再订酒店" : "再次尝试"
    const result = { title, subTitle, status }
    return (
        <>
            <Result
                {...result}
                extra={
                    <Link to="./" replace={true}>
                        <Button type="primary">{buttonText}</Button>
                    </Link>
                }
            />
        </>
    )
}

export default connect(mapStateToProps)(_result)
