import { Button, Table, Tag } from "antd"
import { PageHeader } from "@ant-design/pro-layout"
import { ColumnsType } from "antd/lib/table"
import { ReactElement, useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { AppDispatch, RootState } from "../../app/store"
import { PageRequest } from "../models"
import DelTravellerButton from "./DelTravellerButton"
import { findList, TravellerViewModel } from "./slice"
import { CardPassportTypeList, IdCardType, idCardTypeList } from "./Editor"
import { TravellerViewerButton } from "./TravellerViewerButton"
import { orange } from "@ant-design/colors"

const mapStateToProps = (state: RootState) => ({
    loading: state.traveller.isLoadingList,
    data: state.traveller.travellers,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    findList: (page: PageRequest) => {
        dispatch(findList(page))
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        mode?: "list" | "selector"
        onSelect?: (value: TravellerViewModel) => void
    }

const _travellerList = ({
    loading,
    data,
    mode = "list",
    findList,
    onSelect,
}: Props): ReactElement => {
    const [columns, setColumns] = useState<ColumnsType<TravellerViewModel>>()
    const defaultPageSize = 10
    const navi = useNavigate()

    useEffect(() => {
        switch (mode) {
            case "list":
                setColumns([
                    {
                        title: "姓名",
                        dataIndex: "name",
                    },
                    {
                        title: "类型",
                        dataIndex: "sourceStr",
                    },
                    {
                        title: "生日",
                        dataIndex: "birthDay",
                    },
                    {
                        title: "已有证件",
                        dataIndex: "idTypeStr",
                        render: (_, i) => {
                            return i.idList?.length ? (
                                i.idList?.map((item, index) => (
                                    <Tag key={item.idNumber + index}>
                                        {item.idType === IdCardType.PASSPORT &&
                                            CardPassportTypeList().find(
                                                it =>
                                                    it.passportType ===
                                                    item?.passportType
                                            )?.displayName}
                                        {
                                            idCardTypeList().find(
                                                it =>
                                                    it.idCardType ===
                                                    item.idType
                                            )?.displayName
                                        }
                                    </Tag>
                                ))
                            ) : (
                                <div style={{ color: orange[3] }}>
                                    请更新出行人
                                </div>
                            )
                        },
                    },
                    {
                        title: "手机",
                        dataIndex: "phone",
                    },
                    {
                        title: "邮箱",
                        dataIndex: "email",
                    },
                    {
                        title: "操作",
                        fixed: "right",
                        render: (_, r) => {
                            return (
                                <>
                                    <DelTravellerButton
                                        id={r.travellerId || ""}
                                        onFinished={() => {
                                            findList({ size: defaultPageSize })
                                        }}
                                    />
                                    <TravellerViewerButton
                                        item={r || ""}
                                        onFinished={() => {
                                            findList({ size: defaultPageSize })
                                        }}
                                    />
                                </>
                            )
                        },
                    },
                ])
                break
            case "selector":
                setColumns([
                    {
                        title: "姓名",
                        dataIndex: "name",
                        sortOrder: "ascend",
                    },
                    {
                        title: "类型",
                        dataIndex: "sourceStr",
                    },
                    {
                        title: "证件类型",
                        dataIndex: "idList",
                        render: (_, i) => {
                            return i.idList ? (
                                i.idList?.map((item, index) => (
                                    <Tag key={item.idNumber + index}>
                                        {item.idType === IdCardType.PASSPORT &&
                                            CardPassportTypeList().find(
                                                it =>
                                                    it.passportType ===
                                                    item?.passportType
                                            )?.displayName}
                                        {
                                            idCardTypeList().find(
                                                it =>
                                                    it.idCardType ===
                                                    item.idType
                                            )?.displayName
                                        }
                                    </Tag>
                                ))
                            ) : (
                                <div style={{ color: orange[3] }}>
                                    请更新出行人
                                </div>
                            )
                        },
                    },
                    {
                        title: "操作",
                        fixed: "right",
                        width: "auto",
                        render: (_, i) => {
                            return !i.idList ? (
                                <Button
                                    key="choose"
                                    type="link"
                                    size="small"
                                    href="/my/traveller">
                                    升级
                                </Button>
                            ) : (
                                <Button
                                    key="upgrade"
                                    type="link"
                                    size="small"
                                    onClick={() => {
                                        onSelect?.(i)
                                    }}>
                                    选择
                                </Button>
                            )
                        },
                    },
                ])
                break
        }
    }, [mode, setColumns, findList, onSelect, navi])

    useEffect(() => {
        findList({ size: defaultPageSize })
    }, [mode, findList])

    const onPageChange = (page: number, size: number) => {
        findList({ page: page - 1, size })
    }

    return (
        <>
            <PageHeader
                style={{ display: mode === "selector" ? "none" : undefined }}
                ghost={false}
                title={"出行人列表"}
                extra={[
                    <Link key="traveller" to="/my/traveller/new">
                        <Button key="create" type="primary">
                            新建出行人
                        </Button>
                    </Link>,
                ]}
            />
            <div className={mode === "selector" ? undefined : "container"}>
                <Table<TravellerViewModel>
                    size={mode === "selector" ? "small" : "middle"}
                    loading={loading}
                    pagination={
                        data && {
                            total: data.totalElements,
                            current: data.number + 1,
                            pageSize: data.size,
                            defaultPageSize,
                            onChange: onPageChange,
                        }
                    }
                    scroll={{ x: "max-content" }}
                    columns={columns}
                    dataSource={data?.content}
                    rowKey="travellerId"
                />
            </div>
        </>
    )
}
export const TravellerList = connect(
    mapStateToProps,
    mapDispatchToProps
)(_travellerList)
