import { ReactElement, useState, useEffect } from "react"
import { Alert, List } from "antd"
import { PageHeader } from "@ant-design/pro-layout"
import { connect } from "react-redux"
import { useLocation, Navigate } from "react-router-dom"
import TrainTopSearch from "./components/TrainTopSearch"
import { SearchTrainListParams } from "../services/bookTrainApi"
import { AppDispatch, RootState } from "../../../../app/store"
import {
    findTrainList,
    TrainItemVModel,
    deleteTrainListInfo,
} from "../services/bookTrainSlice"
import { TrainListItem } from "./components/TrainListItem"
import { TrainOrderListModel } from "../../../order/service/orderListModel"

const mapStateToProps = (state: RootState) => {
    return {
        trainList: state.bookTrain.trainList,
        listLoading: state.bookTrain.trainListLoading,
        noSelectApplyfor: !state.booking.applyForm,
        applyFor: state.booking.applyForm,
        trainListQueryNotice: state.location.tipMessage?.trainListQueryNotice,
    }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    // 查询火车列表
    findTrainList: (params: SearchTrainListParams) => {
        dispatch(findTrainList(params))
    },
    //清除火车资源请求的状态
    deleteTrainListInfo: () => {
        dispatch(deleteTrainListInfo())
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>
const _trainList = (props: Props): ReactElement => {
    const {
        deleteTrainListInfo,
        findTrainList,
        trainList,
        listLoading,
        noSelectApplyfor,
        applyFor,
        trainListQueryNotice,
    } = props
    const location = useLocation()
    //通过useLocation把TrainTicketOperate.tsx页面的model接收过来（改签）
    const trainOrderModel = location.state as TrainOrderListModel
    // 切换模式change为改签book为预定
    const [mode, setMode] = useState<"book" | "change">("book")
    // 提示信息开关
    const [visible, setVisible] = useState(true)

    useEffect(() => {
        // trainOrderModel有数据就是改签把setMode('book')改为change
        if (trainOrderModel) {
            // 改签
            setMode("change")
            deleteTrainListInfo()
        }
    }, [deleteTrainListInfo, trainOrderModel])

    const handleSearch = (param: SearchTrainListParams) => {
        // 搜索火车城市
        const args: SearchTrainListParams = {
            fromStation:
                mode === "book"
                    ? param.fromStation
                    : trainOrderModel.fromPlace || "",
            toStation:
                mode === "book"
                    ? param.toStation
                    : trainOrderModel.toPlace || "",
            trainDate: param.trainDate,
            applyForId:
                mode === "book"
                    ? applyFor?.applyId || ""
                    : trainOrderModel.applyId || "",
        }
        // 查询火车城市
        findTrainList(args)
    }

    if (noSelectApplyfor && !trainOrderModel) {
        return (
            <Navigate
                to="/booking/setup"
                state={{ referrer: location.pathname }}
            />
        )
    }

    return (
        <>
            <PageHeader ghost={false}>
                <TrainTopSearch
                    onSearch={handleSearch}
                    mode={mode}
                    city={
                        trainOrderModel && {
                            from: trainOrderModel.fromPlace,
                            to: trainOrderModel.toPlace,
                        }
                    }
                    loading={listLoading}
                />
                {trainListQueryNotice
                    ? visible && (
                        <Alert
                            banner
                            message={trainListQueryNotice}
                            closable
                            afterClose={() => setVisible(false)}
                        />
                    )
                    : null}
            </PageHeader>
            <div className="container">
                <List
                    dataSource={trainList}
                    loading={listLoading}
                    renderItem={(item: TrainItemVModel) => (
                        <List.Item key={item.id}>
                            {" "}
                            <TrainListItem
                                train={item}
                                trainOrder={trainOrderModel}
                            />
                        </List.Item>
                    )}
                />
            </div>
        </>
    )
}

export const TrainList = connect(
    mapStateToProps,
    mapDispatchToProps
)(_trainList)
