import { Timeline } from "antd"
import { grey, blue, green, red } from "@ant-design/colors"
import { ClockCircleOutlined } from "@ant-design/icons"
import { ApplyProcess } from "../../models"
import { useEffect, useState } from "react"

type Color = typeof grey

export interface Items {
    key?: string
    color?: string
    dot?: JSX.Element
    children?: JSX.Element
    label?: JSX.Element
    showArrow?: boolean
}

const approvalStateToColor = (state: number): Color => {
    switch (state) {
        case 0:
            return blue
        case 1:
            return green
        case 2:
            return red
        case -1:
        default:
            return grey
    }
}

const ProcessTimeline = ({
    list,
}: {
    list: Array<ApplyProcess>
}): JSX.Element => {
    const [items, setItems] = useState<Items[]>()

    useEffect(() => {
        if (list.length === 0) return
        let _stepping
        const _items: Items[] = []
        //极度依赖入参的顺序，这里假设排序是 stepping, step desc
        const $list = Array.from(list).reverse()
        for (const p of $list) {
            if (_stepping !== p.stepping) {
                _stepping = p.stepping
                _items.push({
                    key: `ap_stepping_"+${_stepping}`,
                    color: `${grey.primary}`,
                    dot: <ClockCircleOutlined />,
                    children: (
                        <>
                            <div>第{_stepping + 1}次提交</div>
                            <div>{p.startTime}</div>
                        </>
                    ),
                })
            }
            _items.push({
                key: `${p.approvalId}`,
                color: `${approvalStateToColor(p.state).primary}`,
                children: (
                    <>
                        <div>{p.realName}</div>
                        <div>{p.endTime}</div>
                        <div>{p.content}</div>
                    </>
                ),
            })
        }
        setItems(_items.reverse())
    }, [list])

    return <Timeline items={items} />
}

export default ProcessTimeline
