import React, { ReactElement } from "react"
import { Card } from "antd"
import { TrainOrderListModel } from "../../service/orderListModel"
import { blue } from "@ant-design/colors"

const TrainOrderDetailPriceCpn = (props: TrainOrderListModel): ReactElement => {
    const { total } = props
    return (
        <Card
            title="价格明细"
            bordered={false}
            style={{ marginBottom: "10px", borderRadius: "10px" }}
            styles={{
                header: {
                    backgroundColor: "rgb(248,248,248)",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                }
            }}>
            <div style={{ display: "inline-block" }}>
                支付金额：
                <p
                    style={{
                        color: blue[6],
                        fontSize: 20,
                        display: "inline-block",
                    }}>
                    ¥{total}
                </p>
            </div>
        </Card>
    )
}
export default TrainOrderDetailPriceCpn
