import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { AsyncActionState, Flow, GetApplyFormByIdReturned, MaskedStaff, PutApprovalBody, } from "../models"
import { getDefaultAuditorByCompanyId, getStaffByIds } from "../staff/staffAPI"
import { accessTokenSelector } from "../user/loginSlice"
import { currentCompanyIdSelector, currentCompanySelector, currentStaffIdSelector } from "../user/userSlice"
import { deleteApplyFromByDocumentId, deleteApprovalByDocumentId, findByIdApi, getFlowByDocumentId, postApplyFromApproval, putApproval, downloadPDF } from "./api"

export type ApplyFormViewerMode = 'owner' | 'approver' | 'guest'

type ApplyFormViewerState = {
    // --- 详情页
    pageLoading: boolean
    longTermFieldVisiable: boolean
    viewerMode: ApplyFormViewerMode
    error?: string | number
    detail?: GetApplyFormByIdReturned
    staffList?: Array<MaskedStaff> //申请单中出现的同事
    // --- 模态框状态
    operationState: AsyncActionState
    operationError?: string | number
    approvalId?: string //审批模式下的ID
    // --- 提交模态框专用
    flow?: Flow
    auditors?: Array<MaskedStaff> //审批人
    isPostApprovalSuccess: boolean
    // --- 更新
    useSubmit: boolean
    isEditMode: boolean
}

const initialState: ApplyFormViewerState = {
    longTermFieldVisiable: true,
    pageLoading: false,
    viewerMode: "guest",
    operationState: "idle",
    isPostApprovalSuccess: false,
    useSubmit: false,
    isEditMode: false,
}

export const fetchApplyFormDetail = createAsyncThunk('applyFormViewer/fetchApplyFormDetail',
    async (id: string, { getState, rejectWithValue, dispatch }): Promise<GetApplyFormByIdReturned> => {
        const appState = getState() as RootState
        const token = accessTokenSelector(appState)

        //覆盖长短差字段显示
        const curCompanyId = currentCompanyIdSelector(appState)
        const curStaffId = currentStaffIdSelector(appState)

        dispatch(changeLongTermFieldVisiable(!!currentCompanySelector(appState)?.authLongApplyFor))

        if (!token || !curCompanyId || !curStaffId) {
            throw rejectWithValue(401)
        }

        try {
            const applyForm = await findByIdApi(token, id)
            const ap = applyForm.applyProcesses?.find(it => it.executable && it.target === curStaffId)

            if (applyForm.staffId === curStaffId)
                dispatch(changeViewMode('owner'))
            else if (ap) {
                dispatch(changeViewMode('approver'))
                dispatch(setApprovalId(ap.approvalId))
            }
            else dispatch(changeViewMode('guest'))

            const staffIds = [applyForm.staffId]
            // applyForm.travellers && applyForm.travellers.map(it => it.bindId).every(id => staffIds.push(id))
            // 获取申请单中所有的staff信息
            const s = await getStaffByIds(token, curCompanyId, staffIds)
            dispatch(setStaffList(s))
            return applyForm
        } catch (err) {
            throw rejectWithValue(err)
        }
    })
// 撤回按钮
export const deleteApproval = createAsyncThunk('applyFormViewer/fetchDeleteApproval',
    async (id: string, { getState, rejectWithValue }): Promise<void> => {
        const appState = getState() as RootState
        const token = accessTokenSelector(appState)

        if (!token) {
            throw rejectWithValue(401)
        }

        try {
            await deleteApprovalByDocumentId(token, id)
            // await fakeDelayApi(2000)
        } catch (err) {
            throw rejectWithValue(err)
        }
    })
// 批准接口
export const submitToApproval = createAsyncThunk('applyFormViewer/fetchPostApplyFormApproval',
    async ({ id, targets }: { id: string, targets?: string[] }, { getState, rejectWithValue }): Promise<void> => {
        const appState = getState() as RootState
        const token = accessTokenSelector(appState)

        if (!token) {
            throw rejectWithValue(401)
        }

        try {
            await postApplyFromApproval(token, id, targets)
        } catch (err) {
            throw rejectWithValue(err)
        }
    })
// 删除
export const deleteApplyForm = createAsyncThunk('applyFormViewer/fetchDeleteApplyForm',
    async (id: string, { getState, rejectWithValue }): Promise<void> => {
        const appState = getState() as RootState
        const token = accessTokenSelector(appState)

        if (!token) {
            throw rejectWithValue(401)
        }

        try {
            // await fakeDelayApi(2000)
            await deleteApplyFromByDocumentId(token, id)
        } catch (err) {
            throw rejectWithValue(err)
        }
    })

export const fetchFlowByDocumentId = createAsyncThunk('applyFormViewer/fetchFlowByDocumentId',
    async (id: string, { getState, rejectWithValue }): Promise<Flow> => {
        const appState = getState() as RootState
        const token = accessTokenSelector(appState)

        if (!token) {
            throw rejectWithValue(401)
        }

        try {
            return await getFlowByDocumentId(token, id)
        } catch (err) {
            if (err === 404) {
                //无配置审批流等同于使用全局审批
                return Promise.resolve({ flowMode: "FREE" } as Flow)
            }
            throw rejectWithValue(err)
        }
    })


const flowSelector = (state: RootState) => state.applyFormViewer.flow

// 审核员接口
export const fetchApplyFormAuditor = createAsyncThunk('applyFormViewer/fetchApplyFormAuditor',
    async (_, { getState, rejectWithValue }): Promise<MaskedStaff[]> => {
        const appState = getState() as RootState
        const token = accessTokenSelector(appState)
        const flow = flowSelector(appState)
        const companyId = currentCompanyIdSelector(appState)

        if (!token || !companyId) {
            throw rejectWithValue(401)
        }

        if ((flow?.applyForAuditor?.length ?? 0) > 0) {
            //翻译id
            return await getStaffByIds(token, companyId, flow?.applyForAuditor ?? [])
        } else {
            //查询全局审批人
            try {
                const ret = await getDefaultAuditorByCompanyId(token, companyId)
                if (!ret) throw rejectWithValue(404)
                return ret
            } catch (err) {
                throw rejectWithValue(err)
            }
        }
    })

// 批准接口
export const fetchPutApproval = createAsyncThunk('applyFormViewer/fetchPutApproval',
    async ({ id, body }: { id: string, body: PutApprovalBody }, { getState, rejectWithValue }) => {
        const appState = getState() as RootState
        const token = accessTokenSelector(appState)
        if (!token) {
            throw rejectWithValue(401)
        }

        try {
            await putApproval(token, id, body)
            // await fakeDelayApi(800)
        } catch (err) {
            throw rejectWithValue(err)
        }
    })
// 下载PDF
export const fetchDownloadPDF = createAsyncThunk('applyFormViewer/downloadPDF',
    async (id: string, { getState, rejectWithValue }) => {
        const appState = getState() as RootState
        const token = accessTokenSelector(appState)
        if (!token) {
            throw rejectWithValue(401)
        }

        try {
            await downloadPDF(token, id)
        } catch (err) {
            throw rejectWithValue(err)
        }
    }
)


const slice = createSlice({
    name: 'applyFormViewer',
    initialState,
    reducers: {
        resetApplyFormViewer: () => {
            return { ...initialState }
        },
        changeViewMode: (state, { payload }: PayloadAction<ApplyFormViewerMode>) => { state.viewerMode = payload },
        changeLongTermFieldVisiable: (state, { payload }: PayloadAction<boolean>) => { state.longTermFieldVisiable = payload },
        setApprovalId: (state, { payload }: PayloadAction<string | undefined>) => { state.approvalId = payload },
        setStaffList: (state, { payload }: PayloadAction<MaskedStaff[]>) => { state.staffList = payload },
        resetOpsModal: (state) => {
            state.operationState = 'idle'
            state.isPostApprovalSuccess = false
            delete state.operationError
            delete state.flow
            delete state.auditors
        },
        setUseSubmit: (state, { payload }: PayloadAction<boolean>) => {
            state.useSubmit = payload
        },
        setOperationError: (state, { payload }: PayloadAction<string>) => {
            state.operationState = 'reject'
            state.operationError = payload
        },
        setIsEditMode: (state, { payload }: PayloadAction<boolean>) => {
            state.isEditMode = payload
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchApplyFormDetail.pending, state => {
            state.pageLoading = true
            delete state.error
        })
            .addCase(fetchApplyFormDetail.fulfilled, (state, { payload }) => {
                console.log("🚀 ~ .addCase ~ payload:", payload)
                state.pageLoading = false
                state.detail = payload
            })
            .addCase(fetchApplyFormDetail.rejected, (state, { payload }) => {
                state.pageLoading = false
                state.error = payload ? payload as string | number : "请求失败"
            })
            //---
            .addCase(deleteApproval.pending, state => {
                state.operationState = 'pending'
                delete state.operationError
            })
            .addCase(deleteApproval.fulfilled, state => { state.operationState = 'fulfilled' })
            .addCase(deleteApproval.rejected, (state, { payload }) => {
                state.operationState = 'reject'
                state.operationError = payload as number
            })
            //---
            .addCase(deleteApplyForm.pending, state => {
                state.operationState = 'pending'
                delete state.operationError
            })
            .addCase(deleteApplyForm.fulfilled, state => { state.operationState = 'fulfilled' })
            .addCase(deleteApplyForm.rejected, (state, { payload }) => {
                state.operationState = 'reject'
                state.operationError = payload as number
            })
            //---
            .addCase(fetchFlowByDocumentId.pending, state => {
                state.operationState = "pending"
                delete state.operationError
                delete state.flow
            })
            .addCase(fetchFlowByDocumentId.rejected, (state, { payload }) => {
                state.operationState = "reject"
                state.operationError = payload as number
            })
            .addCase(fetchFlowByDocumentId.fulfilled, (state, { payload }) => {
                state.operationState = "fulfilled"
                state.flow = payload
            })
            //---
            .addCase(fetchApplyFormAuditor.pending, (state) => {
                state.operationState = "pending"
                delete state.operationError
                delete state.auditors
            })
            .addCase(fetchApplyFormAuditor.fulfilled, (state, { payload }) => {
                state.operationState = "fulfilled"
                state.auditors = payload
            })
            .addCase(fetchApplyFormAuditor.rejected, (state, { payload }) => {
                state.operationState = "reject"
                state.operationError = payload as number
            })
            //---
            .addCase(submitToApproval.pending, state => {
                state.operationState = 'pending'
                delete state.operationError
            })
            .addCase(submitToApproval.fulfilled, state => {
                state.operationState = 'fulfilled'
                state.isPostApprovalSuccess = true
            })
            .addCase(submitToApproval.rejected, (state, { payload }) => {
                state.operationState = 'reject'
                state.operationError = payload as number
            })
            //---
            .addCase(fetchPutApproval.pending, (state) => { state.operationState = "pending" })
            .addCase(fetchPutApproval.fulfilled, (state) => { state.operationState = "fulfilled" })
            .addCase(fetchPutApproval.rejected, (state, { payload }) => {
                state.operationState = 'reject'
                state.operationError = payload as number
            })
    }
})

//不导出的actions
const { setStaffList, changeLongTermFieldVisiable, changeViewMode, setApprovalId } = slice.actions

export const { resetApplyFormViewer: resetState, resetOpsModal, setUseSubmit, setOperationError, setIsEditMode, } = slice.actions
export default slice.reducer
