import { Button, Drawer, Grid } from 'antd'
import React, { useState } from 'react'
import { Accounts } from '../../listSlice'
import TravelEditor from '../../viewer/TravelEditor'

const { useBreakpoint } = Grid
type Props = {
    item: Accounts
}

export default function AccountEditButton({ item }: Props) {
    //  个性化布局
    const screens = useBreakpoint()
    // Drawer开关
    const [visiable, setVisiable] = useState(false)

    return (
        <>
            <Button type="link" size="small" onClick={() => setVisiable(true)}>
                编辑
            </Button>
            <Drawer
                width={screens.lg ? 640 : "75vw"}
                style={{ backgroundColor: "#fafafa" }}
                onClose={() => {
                    setVisiable(false)
                }}
                open={visiable}
                destroyOnClose>

                <TravelEditor accounts={item} setOpen={setVisiable} />
            </Drawer>
        </>
    )
}
