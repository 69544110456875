import { Accounts } from '../listSlice'
import {
    Form,
    Input,
    Grid,
    Typography,
    Divider,
    DatePicker,
    Select,
    Radio,
    Button,
    Row,
    Tooltip,
    Switch,
    InputNumber,
    Affix,
    Space,
    Modal,
} from "antd"
import ExceedReason from '../editor/ExceedReason'
import TransportationSeat from '../editor/TransportationSeat'
import InvoiceTaxRate from '../editor/InvoiceTaxRate'
import LnvoiceType from '../editor/LnvoiceType'
import PayeeFlat from '../editor/PayeeFlat'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { TitleAccountsType, currencyList, listAccountsType, setAccountsType } from '../../book/const'
import IconFont from '../../../components/IconFont'
import locale from "antd/es/date-picker/locale/zh_CN"
import StaffUserItems from '../editor/StaffUserItems'
import AmountofCosts from '../editor/AmountofCosts'
import Attachments from '../editor/Attachments'
import Destination, { Destinationitem } from '../editor/Destination'
import AccountPolicy from '../../account/components/AccountPolicy'
import { AccountsLists } from '../../account/Editor'
import { createAccountsDetails, fetchSubsidyPolicy, setAccounts } from '../../account/accountSlice'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'



const { Title } = Typography

type Props = {
    accounts: Accounts
    setOpen?: (v: boolean) => void
}

const TravelEditor = ({ accounts: it, setOpen }: Props): JSX.Element => {
    const dispatch = useAppDispatch()
    const { lg } = Grid.useBreakpoint()
    const [form] = Form.useForm()
    const [corpAccount, setcorpAccount] = useState<boolean>()
    const payAccount = useAppSelector(state => state.reimburesEditor.payAccount)
    const [open, setOpens] = useState<boolean>(false)

    // 回显数据
    useEffect(() => {
        form.setFieldsValue({
            ...it,
            accountsStartData: /^\d+$/.test(it?.accountsStartData) ? dayjs(Number(it?.accountsStartData)) : it.accountsStartData,
            accountsEndData: /^\d+$/.test(it?.accountsEndData) ? dayjs(Number(it?.accountsEndData)) : it.accountsEndData,
            city: {
                name: it.city,
                depthPath: it.depthPath
            },
            accountDate: dayjs(it.accountDate),
            customerList: it.customerList
        })

        if (it.depthPath && it.customerList[0].staffId) {
            const query = {
                staffId: it.customerList[0].staffId,
                depthPath: it.depthPath
            }
            dispatch(fetchSubsidyPolicy(query))
        }

    }, [dispatch, form, it])

    const onFinish = (values: AccountsLists) => {
        // 得调用/rest/account/v2接口 返回的数据在给onchange
        const account = {
            ...it,
            ...values,
            fileRepertories: values.fileRepertories?.filter(it => !it.originFileObj) ?? [],
            accountDate: dayjs(values.accountDate).format("YYYY-MM-DD HH:mm:ss"),
            accountsEndData: (values.accountsType === 16 || values.accountsType === 18 || (values.accountsType >= 1800 && values.accountsType <= 1900)) ? dayjs(values.accountsEndData).valueOf().toString() : values.accountsEndData,
            accountsStartData: (values.accountsType === 16 || values.accountsType === 18 || (values.accountsType >= 1800 && values.accountsType <= 1900)) ? dayjs(values.accountsStartData).valueOf().toString() : values.accountsStartData,
            city: values.city?.name,
            depthPath: values.depthPath || (values.city?.depthPath ?? ''),
            taxDeduct: (values.accountsType === 9 || values.accountsType === 14) ? values.taxDeduct : true,  //对公计税
        }
        const value = {
            files: values.fileRepertories?.filter(it => it.originFileObj) ?? [],
            accounts: account
        }
        if (value.accounts.reimburseId) {
            dispatch(setAccounts(value.accounts))
            setOpen?.(false)
        } else {//修改账目(账目列表)
            if (it.accountsId) {
                dispatch(createAccountsDetails({ form: value, id: it.accountsId }))
                setOpen?.(false)
            }
        }

    }

    const handleChanges = (_changedValue: AccountsLists, all: AccountsLists) => {
        console.log("🚀 ~ handleChanges ~ all:", all)
        console.log("🚀 ~ handleChanges ~ _changedValues:", _changedValue)
        // 查询可用补贴报销政策
        if (all.city && all.customerList[0].staffId) {
            const query = {
                staffId: all.customerList[0].staffId,
                depthPath: all.city.depthPath
            }
            dispatch(fetchSubsidyPolicy(query))
        }
        if (_changedValue.netPrice || _changedValue.flightOilTax || _changedValue.flightTax || _changedValue.alteration || _changedValue.otherPrice || _changedValue.service) {
            const list = (all?.netPrice ?? 0) + (all.flightOilTax ?? 0) + (all.flightTax ?? 0) +
                (all.alteration ?? 0) + (all.otherPrice ?? 0) + (all.service ?? 0)
            form.setFieldsValue(
                Object.assign(all, {
                    accountsSumMoney: list,
                } as AccountsLists),
            )
        }
        // 支付方式
        if (_changedValue.corpAccounts) {
            setcorpAccount(_changedValue.corpAccounts)
        } else {
            setcorpAccount(_changedValue.corpAccounts)
        }
        if (all.currency === 'CNY') {
            form.setFieldValue("accountsSumMoney2", all.accountsSumMoney ?? '')
            form.setFieldValue("accountsSumMoney3", all.accountsSumMoney ?? "")
        } else {
            form.setFieldValue("accountsSumMoney2", all.accountsSumMoney / 2)
            form.setFieldValue("accountsSumMoney3", all.accountsSumMoney / 2)
            // 在需要处理其他货币类型的逻辑时，可以在这里添加相应的代码
        }
    }

    return (
        <>
            <Form labelCol={{ span: 4 }} form={form} key='flight' layout="horizontal" style={{ width: lg ? "90%" : "100%" }} onFinish={onFinish} onValuesChange={handleChanges}  >
                <Row justify="space-between">
                    <Title level={4}>
                        <Tooltip placement="top" title={TitleAccountsType(it.accountsType)}>
                            <IconFont type={listAccountsType(it.accountsType)} style={{ fontSize: '40px' }} />
                        </Tooltip>
                    </Title>
                    <Button type="primary" htmlType="submit" >保存111</Button>
                </Row>
                <Affix>
                    <Form.Item
                        label='原始金额'>
                        <Space.Compact block>
                            {(it.accountsType === 10 || it.accountsType === 11) && <Button onClick={() => setOpens(true)}>费用详情</Button>}
                            <Form.Item
                                label='标准金额'
                                name='accountsSumMoney'
                                noStyle
                            >
                                <Input className="readonlyInput" disabled={(it.accountsType === 10 || it.accountsType === 11) ? true : false} />
                            </Form.Item>
                            <Form.Item
                                name='currency'
                                noStyle
                                initialValue={currencyList[0].code}
                            >
                                <Select style={{ width: '30%' }} disabled={it.accountsType === 18 ? true : false}>
                                    {/* {
                                    currencyList.map((it, idx) => {
                                        return <Select.Option key={idx} value={it.code}>{it.name + '/' + it.code}</Select.Option>
                                    })
                                } */}
                                </Select>
                            </Form.Item>
                        </Space.Compact>
                    </Form.Item>
                </Affix>
                <Divider />
                {it.accountsType !== 18 && <Form.Item
                    hidden
                    noStyle
                    name="accountsType"
                    initialValue={it.accountsType}>
                    <Input hidden />
                </Form.Item>}
                {it.accountsType !== 11 &&
                    <Form.Item<boolean>
                        rules={[{ required: true }]}
                        name="international"
                        label="国内/国际"
                        valuePropName="checked"
                        initialValue={false}>
                        <Switch unCheckedChildren="国内" checkedChildren="国际" />
                    </Form.Item>
                }
                {
                    (it.accountsType === 18 || it.accountsType === 16) &&
                    <Form.Item
                        label={it.accountsType === 16 ? '酒店名称' : "差旅补助"}
                        name="accountsDescription"
                        rules={[{ required: true }]}
                        initialValue={it.accountsType === 18 ? "差旅补助" : ''}
                    >
                        <Input />
                    </Form.Item>
                }
                {/*出发地 目的地 */}
                {(it.accountsType === 9 || it.accountsType === 10 || it.accountsType === 11 || it.accountsType === 12 || it.accountsType === 14 || it.accountsType === 19 || it.accountsType === 20 || it.accountsType === 21 || it.accountsType === 22) &&
                    <Destination />
                }
                {
                    it.accountsType === 13 && <>
                        <Form.Item label='出发地' name='accountsStartData' rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label='目的地' name='accountsEndData' rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </>
                }
                {
                    it.accountsType === 12 && <>
                        <Form.Item
                            label="里程(KM)"
                            name='accountsKilometres'
                            normalize={value => JSON.parse(value)}
                            rules={[{ required: true }]}
                        >
                            <InputNumber min={0} style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item
                            label="路桥费"
                            name='roadMoney'
                            normalize={value => JSON.parse(value)}
                            rules={[{ required: true }]}
                        >
                            <InputNumber min={0} style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item
                            label="油费"
                            name='accountsMoney'
                            normalize={value => JSON.parse(value)}
                            rules={[{ required: true }]}
                        >
                            <InputNumber min={0} style={{ width: '100%' }} />
                        </Form.Item>
                    </>
                }
                {(it.accountsType === 13 || it.accountsType === 15 || it.accountsType === 16 || it.accountsType === 17 || it.accountsType === 18 || (it.accountsType >= 1800 && it.accountsType <= 1900)) &&
                    <Form.Item label='出差城市' name='city' rules={[{ required: true }]}>
                        <Destinationitem />
                    </Form.Item>
                }
                {(it.accountsType === 10 ||
                    it.accountsType === 11 ||
                    it.accountsType === 14 ||
                    it.accountsType === 9) &&
                    <Form.Item
                        label="票号"
                        name="ticket"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                }
                {/* auditInfo车牌号 */}
                {
                    (it.accountsType === 19 ||
                        it.accountsType === 20 ||
                        it.accountsType === 21 ||
                        it.accountsType === 22) &&
                    <Form.Item
                        label="车牌号"
                        name="auditInfo"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                }
                {/* 发票类型 */}
                {
                    (it.accountsType === 10 ||
                        it.accountsType === 11 ||
                        it.accountsType === 14 ||
                        it.accountsType === 13 ||
                        it.accountsType === 16) &&
                    <Form.Item label="发票类型"
                        name='invoiceType'
                    >
                        <LnvoiceType orderType={setAccountsType.find(item => item.value === Number(it.accountsType))?.key} />
                    </Form.Item>
                }
                {/* 补助类型 */}
                {(it.accountsType === 18 || (it.accountsType >= 1800 && it.accountsType <= 1900)) &&
                    <AccountPolicy />
                }
                {/* 起始时间 */}
                {
                    (it.accountsType === 16 ||
                        it.accountsType === 18 || (it.accountsType >= 1800 && it.accountsType <= 1900)
                    ) && <Form.Item
                        label="开始时间"
                        name="accountsStartData"
                        rules={[{ required: true }]}
                        initialValue={dayjs().startOf('day')}
                    >
                        <DatePicker locale={locale} style={{ width: '100%' }} format={"YYYY-MM-DD"} />
                    </Form.Item>
                }
                {/* 结束时间 */}
                {
                    (it.accountsType === 16 ||
                        it.accountsType === 18 || (it.accountsType >= 1800 && it.accountsType <= 1900)
                    ) && <Form.Item
                        label="结束时间"
                        name="accountsEndData"
                        initialValue={dayjs().add(1, 'day').startOf('day')}
                        rules={[{ required: true }]}
                    >
                        <DatePicker locale={locale} style={{ width: '100%' }} format={"YYYY-MM-DD"} />
                    </Form.Item>
                }
                {
                    (it.accountsType === 17) &&
                    <Form.Item
                        label="消费事由"
                        name="accountsDescription"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                }
                {/* 出行方式 */}
                {
                    it.accountsType === 13 &&
                    <Form.Item label="出行方式"
                        name='auditInfo'
                        rules={[{ required: true }]}
                    >
                        <Select>
                            <Select.Option value={'出租'}>出租</Select.Option>
                            <Select.Option value={'地铁'}>地铁</Select.Option>
                            <Select.Option value={'公交'}>公交</Select.Option>
                        </Select>
                    </Form.Item>
                }
                {/* 是否实名票 */}
                {(it.accountsType === 14 || it.accountsType === 9) &&
                    <Form.Item
                        label="是否实名票"
                        name="taxDeduct"
                        rules={[{ required: true }]}
                    >
                        <Radio.Group>
                            <Radio value={true}>是</Radio>
                            <Radio value={false}>否</Radio>
                        </Radio.Group>
                    </Form.Item>}
                {/* 发票税率 */}
                {(it.accountsType === 16 || it.invoiceType === '航空电子发票') &&
                    <Form.Item
                        label="发票税率"
                        name='taxRate'
                    >
                        <InvoiceTaxRate />
                    </Form.Item>
                }
                {/* InvoiceTaxRate */}
                {/* 席位 */}
                {
                    (it.accountsType === 10 ||
                        it.accountsType === 11 ||
                        it.accountsType === 9
                    ) &&
                    <Form.Item label="席位"
                        name='auditInfo'
                    >
                        <TransportationSeat orderType={setAccountsType.find(item => item.value === Number(it.accountsType))?.key} />
                    </Form.Item>
                }
                {(it.accountsType === 10 ||
                    it.accountsType === 11
                ) && <Form.Item
                    label={it.accountsType === 10 ? "航班号" : '车次'}
                    name="trafficNo"
                    rules={[{ required: true }]}
                >
                        <Input />
                    </Form.Item>}
                {(it.accountsType !== 16 &&
                    it.accountsType !== 18
                ) && <Form.Item name="accountDate" label="时间"
                    rules={[{ required: true }]}>
                        <DatePicker locale={locale} style={{ width: '100%' }} showTime format="YYYY-MM-DD HH:mm:ss" />
                    </Form.Item>}
                {/* 归属人  */}
                <StaffUserItems />
                <Form.Item
                    label="备注"
                    name="accountsRemarks"
                    rules={[{ required: true }]}
                >
                    <Input.TextArea />
                </Form.Item>
                <Form.Item label="支付方式"
                    name='corpAccounts'
                >
                    <Select disabled={(it.accountsType === 18 || (it.accountsType >= 1800 && it.accountsType <= 1900)) ? true : false}>
                        <Select.Option value={false}>个人支付</Select.Option>
                        {payAccount && <Select.Option value={true}>公司支付</Select.Option>}
                    </Select>
                </Form.Item>
                {/* 收款单位 */}
                {(it.corpAccounts || corpAccount) && <PayeeFlat />}
                {/* 超标原因 */}
                {
                    (it.accountsType === 10 ||
                        it.accountsType === 11 ||
                        it.accountsType === 16
                    ) &&
                    <Form.Item
                        label="超标原因"
                        name="exceedReasonId"
                    >
                        <ExceedReason orderType={it.accountsType ?? 0} />
                    </Form.Item>
                }
                <Title level={5}>多币种信息</Title>
                <Modal title='费用详情' open={open} onCancel={() => setOpens(false)} onOk={() => setOpens(false)}>
                    {(it.accountsType === 10 || it.accountsType === 11) && <Form.Item label='票价' name='netPrice' initialValue={0}>
                        <InputNumber min={0} style={{ width: "100%" }} />
                    </Form.Item>
                    }
                    {it.accountsType === 10 && <>
                        <Form.Item label='燃油附加费' name='flightOilTax' initialValue={0}>
                            <InputNumber min={0} style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item label='民航发展基金' name='flightTax' initialValue={0}>
                            <InputNumber min={0} style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item label='其他' name='otherPrice' initialValue={0}>
                            <InputNumber min={0} style={{ width: "100%" }} />
                        </Form.Item>
                    </>}
                    {(it.accountsType === 10 || it.accountsType === 11) && <Form.Item label='退改签费用' name='alteration' initialValue={0}>
                        <InputNumber min={0} style={{ width: "100%" }} />
                    </Form.Item>}
                    {it.accountsType === 11 && <Form.Item label='服务费' name='service' initialValue={0}>
                        <InputNumber min={0} style={{ width: "100%" }} />
                    </Form.Item>}
                </Modal >
                <AmountofCosts selected={it.accountsType} />
                <Attachments accountsType={it.accountsType} />
            </Form >
            <Divider />
        </>
    )
}

export default TravelEditor
