import { blue, orange } from "@ant-design/colors"
import {
    Button,
    Col,
    Image,
    List,
    Row,
    Space,
    Tag,
    Tooltip,
    Typography,
} from "antd"
import { Link } from "react-router-dom"
import IconFont from "../../../../components/IconFont"
import { HotelProduct, HotelRoom } from "../hotelAPI"

const { Text } = Typography

const renderItem = (product: HotelProduct) => {
    return (
        <List.Item key={product.id} style={{ width: "100%" }}>
            <Row style={{ width: "100%" }} gutter={[18, 8]}>
                <Col flex="1 100px">
                    <Row>
                        <Col flex="1 1 100px">
                            <Space align="start">
                                <IconFont type="icon-bed" />
                                <Text>
                                    {product.bedType}
                                    <br />
                                    {product.breakfastDesc}
                                </Text>
                            </Space>
                        </Col>
                        <Col flex="1 1 100px">
                            <Text type="secondary">
                                {product.broadNetDesc}
                                <br />
                                {product.windowType}
                            </Text>
                        </Col>
                        <Col flex="1 1 100px">
                            <Tooltip title={product.cancelRule}>
                                <Text style={{ color: orange[5] }} underline>
                                    {product.cancelType}
                                </Text>
                            </Tooltip>
                        </Col>
                        <Col flex="3 1 100px">
                            <Tag>{product.supplier.name}</Tag>
                        </Col>
                    </Row>
                </Col>
                <Col flex="none">
                    <Text strong>
                        ￥
                        <Text style={{ color: blue[6], fontSize: 24 }}>
                            {product.avgPrice}
                        </Text>
                    </Text>
                    <br />
                    <Link key="hotel" to={`/booking/hotel/pay/${product.id}`}>
                        <Button type="primary">预订</Button>
                    </Link>
                </Col>
            </Row>
        </List.Item>
    )
}

type Props = { item: HotelRoom }
export default function Room({ item }: Props): JSX.Element {
    return (
        <Row style={{ width: "100%" }}>
            <Col flex="none">
                <div style={{ width: 100, marginRight: 18 }}>
                    {item.images.map(
                        (it, idx) =>
                            idx === 0 && (
                                <Image
                                    key={idx}
                                    className="common-img"
                                    width={100}
                                    height={80}
                                    src={it.bigPicUrl || it.smallPicUrl}
                                    placeholder={true}
                                />
                            )
                    )}
                    <Text>{item.roomName}</Text>
                    <br />
                    <Text>{item.area}&nbsp;m²</Text>
                </div>
            </Col>
            <Col flex="auto">
                <List dataSource={item.products} renderItem={renderItem} />
            </Col>
        </Row>
    )
}
