import { ReactElement } from "react"
import { Route, Routes } from "react-router-dom"
import ApplyFormTodoList from "./ApplyFormTodoList"
import ReimburesFormTodoList from "./ReimburesFormTodoList"

export default function ApprovalRoutes(): ReactElement {
    return (
        <Routes>
            <Route path="applyForm" element={<ApplyFormTodoList />} />
            <Route path="resburesForm" element={<ReimburesFormTodoList />} />
        </Routes>
    )
}
