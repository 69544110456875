import {
    Col,
    Divider,
    List,
    message,
    Modal,
    Row,
    Skeleton,
    Space,
    Typography,
} from "antd"
import { ReactElement, useEffect } from "react"
import { connect } from "react-redux"
import { AppDispatch, RootState } from "../../../../app/store"
import { hotelDetail } from "../hotelSlice"
import HotelRoom from "./HotelRoom"
import { blue } from "@ant-design/colors"
import { useNavigate, useParams } from "react-router-dom"
import HotelImages from "./HotelImages"

const mapStateToProps = (state: RootState) => ({
    loading: state.bookHotel.hotelLoading,
    hotel: state.bookHotel.hotelDetail,
    error: state.bookHotel.hotelDetailError,
})
const mapDispatchToProps = (dispatch: AppDispatch) => ({
    //酒店详情
    hotelDetail: (id: string) => {
        dispatch(hotelDetail(id))
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

const { Title, Text, Paragraph } = Typography
function _detail({ loading, hotel, error, hotelDetail }: Props): ReactElement {
    // 获取路由携带参数
    const { id } = useParams<{ id: string }>()
    const navi = useNavigate()
    useEffect(() => {
        // 路由携带id
        if (!id) {
            message.warning("参数错误！")
            // 返回上一级
            navi(-1)
        } else if (!hotel || hotel.id !== id) {
            //酒店详情
            hotelDetail(id)
        } else if (hotel?.rooms?.length === 0) {
            Modal.error({
                content: "抱歉，没有可供使用的预订资源！",
                onOk: () => {
                    navi(-1)
                },
            })
        }
    }, [id, hotel, hotelDetail, navi])

    return (
        <div className="container">
            <Skeleton active={true} loading={loading}>
                <Row style={{ width: "100%" }}>
                    <Col flex="1 500px">
                        <Space direction="vertical">
                            <Title level={3} style={{ color: blue[9] }}>
                                {hotel?.hotelName}
                            </Title>
                            <Text type="secondary">{hotel?.address}</Text>
                            <Paragraph
                                ellipsis={{
                                    expandable: true,
                                    rows: 2,
                                }}>
                                {hotel?.longBrief ||
                                    hotel?.shortBreif ||
                                    "暂无简介"}
                            </Paragraph>
                        </Space>
                    </Col>
                    <Col flex="auto" />
                    <Col flex="none">
                        <Text strong>
                            ￥
                            <Text style={{ color: blue[6], fontSize: 28 }}>
                                {hotel?.lowestPrice}
                            </Text>
                            起
                        </Text>
                    </Col>
                </Row>
                <div style={{ height: 8 }}></div>
                {hotel?.images && (
                    <HotelImages
                        imgs={hotel?.images?.map(
                            i => i.bigPicUrl || i.smallPicUrl
                        )}
                    />
                )}
                <Divider />
                <List
                    loading={loading}
                    dataSource={hotel?.rooms}
                    renderItem={item => (
                        <List.Item>
                            <HotelRoom key={item.id} item={item} />
                        </List.Item>
                    )}
                />
            </Skeleton>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(_detail)
