import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { AsyncActionState, TipMessages, MLocation, } from "../models"
import { getLocation, getTipMessage, } from "./api"


interface State {
    loading: AsyncActionState
    china?: MLocation
    cityChina?: Array<MLocation>
    // intl?: Array<Location>
    country?: Array<MLocation>
    foreigner?: Array<MLocation>
    foreignCity?: Array<MLocation>
    tipMessage?: TipMessages
}

const initialState: State = {
    loading: 'idle',
}

export const locationChinaSelector = (state: RootState) => state.location.china
export const cityChinaSelector = (state: RootState) => state.location.cityChina
// export const locationIntlSelector = (state: RootState) => state.location.intl

export const fetchLocation = createAsyncThunk('location/fetchLocation', async (_, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as RootState
    if (locationChinaSelector(state)) return

    try {
        const allLoc = await getLocation()
        const china = allLoc.find((it: MLocation) => it.depthPath === '0' || it.name === '中国')
        // 国际
        const country = allLoc.map(it => {
            const countryItem = { ...it }
            delete countryItem.child
            return countryItem
        })
        // 外国
        const foreigner = country.filter(it => it.name !== '中国' || it.depthPath !== '0')
        // 国际城市
        const foreign = allLoc.filter(it => it.name !== '中国' || it.depthPath !== '0').map(it => it.child ?? []).flat()

        if (!china) throw Error("no china data!")
        dispatch(setChina(china))
        dispatch(setForeign(foreign))
        const cnCity = Array<MLocation>()
        //不太可能为null
        fillChinaCity(china, cnCity)
        dispatch(setCityChina(cnCity))
        dispatch(setCountry(country))
        dispatch(setforeigner(foreigner))
    } catch (err) {
        rejectWithValue(err)
    }

})
// 提示信息
export const fetchTipMessage = createAsyncThunk('keyValue/fetKeyValue',
    async (_, { rejectWithValue }) => {

        try {
            const tipMessage = await getTipMessage()
            return tipMessage
        } catch (err) {
            rejectWithValue(err)
        }

    })

const fillChinaCity = (loc: MLocation, retList: Array<MLocation>) => {
    if (loc.name === '北京' || loc.name === '天津' || loc.name === '上海' || loc.name === '重庆' || loc.name === '中国澳门' || loc.name === '中国香港' || loc.name === '中国台湾') {//直辖市 特别行政区
        retList.push(loc)
        return
    }

    if (loc.depthPath.split(',').length === 3) {
        retList.push(loc)
        return
    }

    loc.child?.forEach(element => {
        fillChinaCity(element, retList)
    })
}


const slice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        setChina: (state, { payload }: PayloadAction<MLocation>) => { state.china = payload },
        setCityChina: (state, { payload }: PayloadAction<MLocation[]>) => { state.cityChina = payload },
        // setIntl: (state, { payload }: PayloadAction<Location[]>) => { state.intl = payload },
        setCountry: (state, { payload }: PayloadAction<MLocation[]>) => { state.country = payload },
        setforeigner: (state, { payload }: PayloadAction<MLocation[]>) => { state.foreigner = payload },
        setForeign: (state, { payload }) => { state.foreignCity = payload },
        resetloading: (state) => { state.loading = 'idle' },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchLocation.pending, state => { state.loading = 'pending' })
            .addCase(fetchLocation.rejected, state => { state.loading = "reject" })
            .addCase(fetchLocation.fulfilled, state => { state.loading = "fulfilled" })
            // 提示信息
            .addCase(fetchTipMessage.pending, (state) => { delete state.tipMessage })
            .addCase(fetchTipMessage.rejected, (state) => { delete state.tipMessage })
            .addCase(fetchTipMessage.fulfilled, (state, { payload }) => { state.tipMessage = payload })
    }
})

//DO NOT EXPORT
const { setChina, setCityChina, setCountry, setForeign, setforeigner } = slice.actions
export const { resetloading } = slice.actions
export default slice.reducer
