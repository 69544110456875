import { ReactElement } from "react"
import { Row, Col, Button, Typography, Space } from "antd"
import arrowImage from "./../image/arrow-box.png"
import { AirplaneFlightVModel } from "../../services/bookAirplaneSlice"
import { blue, orange } from "@ant-design/colors"
interface Props {
    flightModel: AirplaneFlightVModel
}
const { Text, Paragraph } = Typography
// 飞机航班列表航班信息item
const FlightScheduleItem = (params: {
    time?: string
    airport?: string
    terminal?: string
}): ReactElement => {
    return (
        <Space direction="vertical" size={0}>
            <Text strong style={{ fontSize: 30 }}>
                {params.time && params.time.slice(11, 16)}
            </Text>
            <Paragraph
                ellipsis={{ rows: 2, tooltip: params.airport }}
                style={{ maxWidth: 80, marginBottom: "0" }}>
                {params.airport}&nbsp;{params.terminal}
            </Paragraph>
            <Text>{params.time && params.time.substring(0, 10)}</Text>
        </Space>
    )
}
// 飞机航班列表价格item ,订票按钮事件同item
const FlightPriceItem = (params: {
    priceFare: string
    cabinName: string
    seating: string
}): ReactElement => {
    return (
        <Row justify="space-between" align="middle">
            <Space direction="vertical" size={0}>
                <span style={{ color: blue[5], fontSize: 24 }}>
                    <Text italic style={{ color: blue[5], fontSize: 18 }}>
                        ¥
                    </Text>
                    {parseInt(params.priceFare || "")}
                    <Text type="secondary" style={{ fontSize: 12 }}>
                        起
                    </Text>
                </span>
                <Text>{params.cabinName}</Text>
            </Space>
            <Button type="primary">订票&nbsp;&gt;</Button>
        </Row>
    )
}
// 飞机航班列表item,row
export const AirplaneListFlightItem = ({
    flightModel,
}: Props): ReactElement => {
    //经停站
    const stops =
        parseInt(flightModel.stops || "") > 0
            ? flightModel.stopDesc && flightModel.stopDesc[0]
            : undefined

    return (
        <Row gutter={[24, 5]} justify="space-around" align="middle">
            <Col flex="1 100px">
                <Space direction="vertical" size={0}>
                    <img src={flightModel.flightImg} alt="" />
                    <Text style={{ fontSize: 18 }}>
                        {flightModel.airlineName}
                    </Text>
                    <Text>
                        <Text type="secondary">
                            {flightModel.flightNum}&nbsp;{flightModel.planeType}
                            &nbsp;{flightModel.planeSize}
                        </Text>
                    </Text>
                    <Text style={{ color: orange[5] }}>
                        <span>
                            {flightModel.meal || "无餐饮"}&nbsp;
                            {flightModel.shareFlag === "Y" && "共享"}
                        </span>
                    </Text>
                </Space>
            </Col>
            <Col flex="auto">
                <Space direction="horizontal" size={20}>
                    <FlightScheduleItem
                        time={flightModel.takeOffTime}
                        airport={flightModel.takeOffPortname}
                        terminal={flightModel.takeOffTerminal}
                    />
                    <Space direction="vertical" align="center">
                        <Text type="secondary" style={{ fontSize: 14 }}>
                            {flightModel.flightTime}
                        </Text>
                        <img src={arrowImage} alt="" style={{ maxWidth: 70 }} />
                        {flightModel.stops &&
                            parseInt(flightModel.stops) > 0 && (
                                <div style={{ color: blue[4], fontSize: 12 }}>
                                    经停&bull;{stops?.name}
                                </div>
                            )}
                    </Space>
                    <FlightScheduleItem
                        time={flightModel.landingTime}
                        airport={flightModel.landingPortName}
                        terminal={flightModel.toTerminal}
                    />
                </Space>
            </Col>
            <Col flex="1 40px">
                <FlightPriceItem
                    priceFare={flightModel.priceFare}
                    cabinName={flightModel.cabinName}
                    seating={flightModel.seating}
                />
            </Col>
        </Row>
    )
}
