import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { Company, CompanyWithStaff, ExceedReasonList, Staff } from "../models"
import { findAllCompany } from "../org/orgApi"
import { accessTokenSelector, reset as resetLoginState } from "./loginSlice"



interface UserInfoState {
    availableCompany?: Array<CompanyWithStaff>
    currentCompany?: Company
    currentStaff?: Staff
    redirectTo?: string
    redirectError?: string
    exceedReasonList?: ExceedReasonList[]
    staffList?: Staff
}

const initialState: UserInfoState = {}

export const currentCompanySelector = (state: RootState): Company | undefined => state.user.currentCompany
export const currentCompanyIdSelector = (state: RootState): string | undefined => state.user.currentCompany?.companyId
export const currentStaffSelector = (state: RootState): Staff | undefined => state.user.currentStaff
export const currentStaffIdSelector = (state: RootState): string | undefined => state.user.currentStaff?.staffId
export const currentAllowAllCityToOrder = (state: RootState): boolean | undefined => state.user.currentCompany?.companyBasicConfig.allowAllCityToOrder
export const currentAuthLockedSelector = (state: RootState): boolean | undefined => state.user.currentStaff?.authLocked

//初始化可用公司列表并选中第一项
export const initCompany = createAsyncThunk('user/company/init',
    async (_: void, { getState, rejectWithValue, dispatch }) => {
        const token = accessTokenSelector(getState() as RootState)
        try {
            if (!token) throw Error()
            const list = await findAllCompany(token)
            const avaliable = list.filter(it => !it.staff.authLocked)
            if (avaliable.length === 0) {
                return rejectWithValue("您当前的账号下没有可用的公司账号，也可能是您的公司账号已被您公司的管理员禁用，请与您所在公司的系统管理员联系。")
            } else dispatch(selectCompany(avaliable[0]))
            return avaliable
        } catch (err) {
            dispatch(resetLoginState())
            throw rejectWithValue(null)
        }
    }
)

//刷新可用公司列表，不改变选中项
export const refreshCompany = createAsyncThunk('user/company/refreshAvailable',
    async (_: void, { getState, rejectWithValue, dispatch }) => {
        const state = getState() as RootState
        const token = accessTokenSelector(state)
        try {
            if (!token) throw Error()
            const list = await findAllCompany(token)
            const avaliable = list.filter(it => !it.staff.authLocked)
            if (avaliable.length === 0) {
                return rejectWithValue("您当前的账号下没有可用的公司账号，也可能是您的公司账号已被您公司的管理员禁用，请与您所在公司的系统管理员联系。")
            }

            const currCompanyId = currentCompanyIdSelector(state)
            if (!avaliable.find(it => it.companyId === currCompanyId)) {
                //已选中公司ID不存在重新选中
                dispatch(selectCompany(avaliable[0]))
            }
            return avaliable
        } catch (err) {
            dispatch(resetLoginState())
            throw rejectWithValue(null)
        }
    }
)

//登录后动作，比如申请单审批跳转
export const postLogin = createAsyncThunk('user/app/init',
    async (search: URLSearchParams, { dispatch }) => {

        await dispatch(initCompany())
        if (!search || !search.has("app")) return "/"

        const app = search.get("app")
        switch (app) {
            case "travelApplyApproval":
                //TODO 未来需要兼容多公司账户，需要从APP来源确定，比如申请单，需要申请单对应公司
                return "/applyForm/" + search.get("rel")
            default:
                return "/" // 考虑后续对TP-APP做一个认证
        }
    })

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        reset: () => ({}),
        cleanRedirect: (state) => ({ ...state, redirectTo: undefined, redirectError: undefined }),
        selectCompany: (state, { payload }: PayloadAction<CompanyWithStaff>) => ({
            ...state, currentStaff: payload.staff, currentCompany: { ...payload, staff: undefined } as Company,
            exceedReasonList: payload.exceedReasonList
        }),
        redirectToLogin: (state) => ({ ...state, redirectTo: '/login' }),
    },
    extraReducers: builder => {
        builder
            .addCase(initCompany.pending, () => initialState)
            .addCase(initCompany.fulfilled, (state, { payload }) => ({ ...state, availableCompany: payload }))
            .addCase(initCompany.rejected, (state, { payload }) => {
                if (payload) {
                    return { ...state, redirectTo: '/login/error', redirectError: payload as string }
                }
                return { ...state, redirectTo: '/login' }
            })
            // ---
            .addCase(postLogin.pending, () => initialState)
            .addCase(postLogin.fulfilled, (state, { payload }) => {
                state.redirectTo = payload
            })
            // ---
            .addCase(refreshCompany.fulfilled, (state, { payload }) => ({ ...state, availableCompany: payload }))
            .addCase(refreshCompany.rejected, (state, { payload }) => {
                if (payload) {
                    return { ...state, redirectTo: '/login/error', redirectError: payload as string }
                }
                return { ...state, redirectTo: '/login' }
            })
    }
})

export const { reset, selectCompany, cleanRedirect, redirectToLogin } = userSlice.actions
export default userSlice.reducer
