import { Select, Typography } from "antd"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { EnvironmentOutlined } from "@ant-design/icons"
import { RootState } from "../../../../../app/store"
import { TrainCity } from "../../../../models"

const { Text } = Typography
const mapStateToProps = (state: RootState) => ({
    trainCity: state.booking.trainCity,
    locLoading: state.location.loading,
})

type Props = ReturnType<typeof mapStateToProps> & {
    onChange?: (id: string) => void
    mode?: "book" | "change"
    cityName?: string
    accountsEndData?: string
    accountsStartData?: string
} & {
    isStart: boolean
    isCity?: boolean
}

const _selector = ({
    onChange,
    isStart,
    mode,
    cityName,
    trainCity,
    locLoading,
    isCity,
    accountsEndData,
    accountsStartData
}: Props) => {
    // 过滤数据
    const [subCity, setSubCity] = useState<TrainCity[]>()
    // 下拉菜单数据
    const [options, setOptions] = useState<JSX.Element[]>()

    useEffect(() => {
        // 如果subCity有数据
        if (subCity) {
            setOptions(
                subCity.map((c, idx) => (
                    <Select.Option
                        value={JSON.stringify(c)}
                        key={c.id + "-" + idx}>
                        <Text>&nbsp;{c.name}</Text>
                    </Select.Option>
                ))
            )
        } else {
            setOptions(undefined)
        }
    }, [subCity, mode])

    const handleSearch = (v: string) => {
        //输入的东西不存在或如果是空setSubCity(undefined)
        if (!v || v === "") {
            setSubCity(undefined)
        } else {
            // 过滤检查
            if (!isCity) {
                setSubCity(
                    trainCity?.find(it => it.isCity === false) &&
                    trainCity
                        ?.filter(it => it.isCity === false)
                        ?.filter(
                            c =>
                                c.name.indexOf(v) !== -1 ||
                                c.allName.toLocaleLowerCase().indexOf(v) !==
                                -1
                        )
                )
            } else {
                setSubCity(
                    trainCity?.filter(
                        c =>
                            c.name.indexOf(v) !== -1 ||
                            c.allName.toLocaleLowerCase().indexOf(v) !== -1
                    )
                )
            }
        }
    }

    return (
        <Select
            loading={locLoading === "pending"}
            disabled={!trainCity || mode === "change"}
            showSearch
            defaultValue={cityName || (!isStart ? accountsEndData : accountsStartData)}
            suffixIcon={<EnvironmentOutlined />}
            placeholder={isStart ? "请选择出发城市" : "请选择到达城市"}
            notFoundContent={null}
            filterOption={false}
            onSearch={handleSearch}
            onSelect={onChange}>
            {options}
            {!options && (
                <>
                    {isCity && (
                        <Select.OptGroup label="城市">
                            {trainCity?.find(it => it.isCity === true) &&
                                trainCity
                                    .filter(it => it.isCity === true)
                                    .map((k, index) => (
                                        <Select.Option
                                            value={JSON.stringify(k)}
                                            key={"trainCity" + index + k?.id}
                                            children={k?.name}
                                        />
                                    ))}
                        </Select.OptGroup>
                    )}
                    <Select.OptGroup label="火车站">
                        {trainCity?.find(it => it.isCity === false) &&
                            trainCity
                                .filter(it => it.isCity === false)
                                .map((k, index) => (
                                    <Select.Option
                                        value={JSON.stringify(k)}
                                        key={"trainStation" + index + k?.id}
                                        children={k?.name}
                                    />
                                ))}
                    </Select.OptGroup>
                </>
            )}
        </Select>
    )
}

export const TrainCitySelector = connect(mapStateToProps)(_selector)
