import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AccountFormEditor from './components/AccountFormEditor'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Row, Form, Col, Tooltip, message } from 'antd'
import { CustomerList, FileRepertories } from '../reimburseForm/listSlice'
import { TitleAccountsType, listAccountsType, setAccountsType } from '../book/const'
import IconFont from '../../components/IconFont'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { anEntryinitEditor, createAccounts, entryReset, fetchSubsidyPolicy, resetCreateState } from './accountSlice'
import dayjs, { Dayjs } from "dayjs"
import { AccountsCity } from '../models'

export interface AccountsLists {
    accountsType: number //账目类型
    accountsStartData: string | Dayjs
    accountsEndData: string | Dayjs
    accountsSumMoney: number
    accountsSumMoney2: number
    accountsSumMoney3: number
    preTaxTotal: number //不含税金额
    taxRate: number
    accountsDescription: string  //补助信息
    accountDate: string
    roadMoney: number
    accountsMoney: number
    accountsKilometres: number
    currency: string   //币种默认CNY
    currency2: string  //报销政策币种默认CNY
    currency3: string  //结算币种默认CNY
    exchangeRate: number  //标准
    exchangeRate2: number //结算
    auditInfo: string
    city?: AccountsCity
    depthPath: string
    corpAccounts: boolean    //支付方式
    accountPolicyId: string //账目报销政策id
    staffId: string //归属人id
    customerList: CustomerList[]  //消费人
    netPrice: number  //票价
    flightTax: number //机票税费: 国内机票是机建费 国际是税
    flightOilTax: number //燃油附加费
    alteration: number   //退改签费用
    service: number //服务费
    otherPrice: number //其他费用
    taxDeduct: boolean ///有订单的账目都计税，对私的选择是否抵扣税费 选择可抵扣，则计算不含税价，否则不计算
    invoiceType?: string
    fileRepertories?: FileRepertories[]
    accountsRemarks?: string
    ticket?: string //火车票取号票
    trafficNo?: string
    exceedReasonId?: string //超标原因id
    departDepthPath?: string
    invoiceTypeCode?: string  //是否实名票 0-1
    packageId?: string  //收款公司
    corpAccountsNum?: string
    corpAccountsName?: string
    isCorporate?: boolean//短差对公账目只报销一次 ：本地标识
    agreement?: boolean
    invoiceIdV2?: string
}

interface Props {
    outValue?: number
    setOpen?: (v: boolean) => void
}

export default function AccountsEditor({ outValue, setOpen }: Props) {
    const [initialized, setInitialized] = useState(false)
    const navi = useNavigate()
    const dispatch = useAppDispatch()
    const taxRateList = useAppSelector(state => state.account.taxRateList)
    const createLoading = useAppSelector(state => state.account.createLoading)
    const [posted, setPosted] = useState<boolean>(false)
    const [form] = Form.useForm()
    const { documentId } = useParams()

    // 初始化
    if (!initialized) {
        dispatch(entryReset())
        dispatch(anEntryinitEditor())
        setInitialized(true)
    }

    useEffect(() => {
        if (createLoading === 'idle' && !posted) setPosted(true)
        else if (createLoading === 'fulfilled' && posted) {
            message.success("账目保存成功！")
            if (!outValue) {
                navi("/accountsForm/list", { replace: true })
                dispatch(resetCreateState())
            } else {
                setOpen?.(false)
            }
        }
    }, [createLoading, dispatch, navi, outValue, posted, setOpen])

    const onFinish = (values: AccountsLists) => {
        const taxRate = values.taxRate ? values.taxRate : taxRateList?.find(it => it.type === values.accountsType)?.taxRate ?? 0
        const account = {
            ...values,
            fileRepertories: [],
            accountDate: dayjs(values.accountDate).format("YYYY-MM-DD HH:mm:ss"),
            accountsEndData: (values.accountsType === 16 || values.accountsType === 18 || (values.accountsType >= 1800 && values.accountsType <= 1900)) ? dayjs(values.accountsEndData).valueOf().toString() : values.accountsEndData,
            accountsStartData: (values.accountsType === 16 || values.accountsType === 18 || (values.accountsType >= 1800 && values.accountsType <= 1900)) ? dayjs(values.accountsStartData).valueOf().toString() : values.accountsStartData,
            city: values.city?.name,
            departDepthPath: values.departDepthPath,
            depthPath: values.depthPath || (values.city?.depthPath ?? ''),
            exchangeRate: 1.0,
            exchangeRate2: 1.0, // 汇率2 初始化为1方便使用 结算
            preTaxTotal: Number((values.accountsSumMoney3 / (1 + taxRate)).toFixed(2)), //不含税金额 = 计税金额 /（1 + 税率） accountsSumMoney3  taxRate税率
            taxDeduct: (values.accountsType === 9 || values.accountsType === 14) ? values.taxDeduct : true,  //对公计税
            taxRate: taxRate,//税率
        }
        const value = {
            files: values.fileRepertories,
            accounts: account
        }

        dispatch(createAccounts(value))
    }
    const handleChanges = (_changedValue: AccountsLists, all: AccountsLists) => {
        console.log("🚀 ~ handleChanges ~ _changedValue:", _changedValue)
        console.log("🚀 ~ handleChanges ~ all:", all)
        // 调用补助类型接口  
        if (all.city && all.customerList[0].staffId) {
            const query = {
                staffId: all.customerList[0].staffId,
                depthPath: all.city.depthPath
            }

            dispatch(fetchSubsidyPolicy(query))
        }
        const list = (all?.netPrice ?? 0) + (all.flightOilTax ?? 0) + (all.flightTax ?? 0) +
            (all.alteration ?? 0) + (all.otherPrice ?? 0) + (all.service ?? 0)

        form.setFieldsValue(
            Object.assign(all, {
                accountsSumMoney: list,
            } as AccountsLists),
        )


        if (all.currency === 'CNY') {
            form.setFieldValue("accountsSumMoney2", all.accountsSumMoney ?? '')
            form.setFieldValue("accountsSumMoney3", all.accountsSumMoney ?? "")
        } else {
            // form.setFieldValue("accountsSumMoney2", all.accountsSumMoney / 2)
            // form.setFieldValue("accountsSumMoney3", all.accountsSumMoney / 2)
            // 在需要处理其他货币类型的逻辑时，可以在这里添加相应的代码
        }
    }

    return (
        <><Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onValuesChange={handleChanges}
            labelCol={{ span: 6 }}
        >
            <PageHeader
                ghost={false}
                title={
                    <Tooltip placement='right' title={TitleAccountsType(setAccountsType.find(item => item.value === (Number(documentId) || outValue))?.value)}>
                        <IconFont type={listAccountsType(setAccountsType.find(item => item.value === (Number(documentId) || outValue))?.value)} style={{ fontSize: '40px' }} alt='' />
                    </Tooltip>
                }
                extra={[
                    <Button key="submit" type="primary" htmlType="submit" loading={createLoading === 'pending'}>
                        保存1111222
                    </Button>
                ]}
            />

            {!outValue && <div className="container">
                <div style={{ height: "16px" }}></div>
                <Row justify="center">
                    <Col xs={24} sm={24} md={24} lg={17}>
                        <AccountFormEditor selectedOption={setAccountsType.find(item => item.value === (Number(documentId) || outValue))} />
                    </Col>
                </Row>
            </div >}
            {
                outValue &&
                <AccountFormEditor selectedOption={setAccountsType.find(item => item.value === (Number(documentId) || outValue))} />
            }
        </Form>
        </>
    )
}
