import { ExclamationCircleOutlined } from "@ant-design/icons"
import { ReactElement } from "react"
import { Notes } from "../../../const"
import { Card } from "antd"
import { grey, orange, red } from "@ant-design/colors"
export default function NoteMsg({
    noteList,
}: {
    noteList?: Notes[]
}): ReactElement {
    return (
        <>
            {noteList?.map((item: Notes, index: number) => {
                return (
                    <Card
                        bordered={false}
                        key={index}
                        type="inner"
                        styles={{ body: { padding: 0 } }}>
                        {item.msg ? (
                            <div
                                style={{
                                    textAlign: "justify",
                                    padding: "6px 12px",
                                }}>
                                <span
                                    style={{
                                        color:
                                            item.level === "01"
                                                ? orange[5]
                                                : red[4],
                                    }}>
                                    <ExclamationCircleOutlined rotate={180} />
                                    &nbsp;{item.title}
                                </span>
                                <span style={{ color: grey[5] }}>
                                    {item.msg}
                                </span>
                            </div>
                        ) : null}
                    </Card>
                )
            })}
        </>
    )
}
