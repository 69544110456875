import { LoadingOutlined } from "@ant-design/icons"
import { Select } from "antd"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useDebounce } from "react-use"
import { AppDispatch, RootState } from "../../../app/store"
import { Project } from "../../models"
import {
    fetchRecommendList,
    fetchSearchByKey,
    resetSearch,
} from "./projectSlice"

const mapStateToProps = (state: RootState) => ({
    loading: state.applyFormProjectSelector.loading,
    data:
        state.applyFormProjectSelector.searchList ??
        state.applyFormProjectSelector.recommendList,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    dispatchReset: () => {
        dispatch(resetSearch())
    },
    dispatchRecommend: () => {
        dispatch(fetchRecommendList())
    },
    dispatchSearch: (key: string) => {
        dispatch(fetchSearchByKey(key))
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        value?: Project
        onChange?: (value?: Project) => void
        disabled?: boolean
    }

const _ProjectSelector = ({
    disabled,
    loading,
    data,
    value,
    onChange: outOnChange,
    dispatchRecommend,
    dispatchSearch,
    dispatchReset,
}: Props) => {

    const [search, setSearch] = useState<string>()
    const [debouncedSearch, setDebouncedSearch] = useState<string>()
    useDebounce(
        () => {
            setDebouncedSearch(search)
        },
        300,
        [search]
    )

    useEffect(() => {
        // 初始加载一个列表
        // 然后搜索框加载搜索结果
        dispatchRecommend()
    }, [dispatchRecommend])

    useEffect(() => {
        if (debouncedSearch) {
            dispatchSearch(debouncedSearch)
        } else {
            dispatchReset()
        }
    }, [debouncedSearch, dispatchSearch, dispatchReset])

    const onChange = (value: string) => {
        outOnChange?.(data?.find(p => p.cpId === value))
    }

    return (
        <Select
            disabled={disabled}
            showSearch
            suffixIcon={
                loading === "pending" && <LoadingOutlined spin={true} />
            }
            value={value?.name}
            onChange={onChange}
            filterOption={false}
            loading={loading === "pending"}
            defaultActiveFirstOption={false}
            placeholder="输入项目名称或编号搜索"
            notFoundContent={null}
            onSearch={setSearch}>
            {data?.map(it => (
                <Select.Option key={"cp_" + it.cpId} value={it.cpId}>
                    {it.name}
                </Select.Option>
            ))}
        </Select>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(_ProjectSelector)
