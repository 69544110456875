import { Select } from "antd"
import { useEffect } from "react"
import { EnvironmentOutlined, LoadingOutlined } from "@ant-design/icons"
import { fetchLocation } from "../location/slice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"

type Props = {
    onChange?: (value: string) => void
    value?: string
    disabled?: boolean
}

const NationalitySelector = ({
    onChange: outOnChange,
    value,
    disabled,
}: Props) => {
    const loading = useAppSelector(state => state.traveller.updateState)
    const country = useAppSelector(state => state.location.country)
    const dispatch = useAppDispatch()

    useEffect(() => {
        // 没有数据的时候调用接口
        if (!country) {
            // 查询国家列表
            dispatch(fetchLocation())
        }
    }, [country, dispatch])

    return (
        <Select
            loading={loading === "pending"}
            disabled={disabled}
            showSearch
            placeholder="请输入国籍"
            suffixIcon={
                loading === "pending" ? (
                    <LoadingOutlined spin={true} />
                ) : (
                    <EnvironmentOutlined />
                )
            }
            onSelect={outOnChange} //被选中时调用，参数为选中项的
            value={value}
            filterOption={(input, option) => {
                return (
                    (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                    (option?.shortName ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                    (option?.enName ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                )
            }}
            options={country?.map(i => ({
                value: i.name,
                label: i.name,
                shortName: i.shortName,
                enName: i.enName,
            }))}
        />
    )
}

export default NationalitySelector
