import { Button, Result } from "antd"
import { Link } from "react-router-dom"

export default function PageNotFound(): JSX.Element {
    return (
        <Result
            status="404"
            title="404"
            subTitle="您访问的页面不存在"
            extra={
                <Button type="primary" ghost>
                    <Link to="/" replace>
                        返回首页
                    </Link>
                </Button>
            }
        />
    )
}
