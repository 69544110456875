import { Dayjs } from 'dayjs'

export function createFormData(obj: Record<string, string>): FormData {
    const data = new FormData()
    Object.entries(obj).forEach(([k, v]) => { data.append(k, v) })
    return data
}

export const groupBy = <T>(key: keyof T) => function group(array: Array<T>) {
    type TGrouped<T> = { [K in string | number | symbol]: Array<T> }
    return array.reduce((acc: TGrouped<T>, obj: T) => {
        const property = obj[key]
        switch (typeof property) {
            case 'number':
            case 'string':
            case 'symbol':
                acc[property] = acc[property] || []
                acc[property].push(obj)
                break
        }
        return acc
    }, {})
}

export type RangeValue = [Dayjs | null, Dayjs | null] | null
