import { AccessToken, DataPage, PageRequest, Traveller } from "../models"


export const createTravellerApi = async ({ access_token }: AccessToken, t: Traveller): Promise<void> => {
    const res = await fetch(`${window.SSCL_API}/rest/traveller`, {
        method: "POST",
        mode: "cors",///跨域请求
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        },
        body: JSON.stringify(t)
    })
    if (!res.ok) return Promise.reject(res)
    return Promise.resolve()
}


//查询出行人列表
export const findAllApi = async ({ access_token }: AccessToken, query: { companyId?: string } & PageRequest): Promise<DataPage<Traveller>> => {
    const { companyId, page, size, sort } = query
    const search = new URLSearchParams()
    if (companyId) search.set("companyId", companyId)
    if (page) search.set("page", `${page}`)
    if (size) search.set("size", `${size}`)
    search.set("sort", sort ?? "name")

    const res = await fetch(`${window.SSCL_API}/user/traveller?${search.toString()}`, {
        mode: "cors",
        method: "get",
        headers: {
            'Authorization': `Bearer ${access_token}`,
        }
    })
    if (!res.ok) return Promise.reject(res)
    return await res.json()
}


//删除出行人
export const deleteTravellerApi = async ({ access_token }: AccessToken, id: string): Promise<void> => {

    const res = await fetch(`${window.SSCL_API}/rest/traveller/${id}`, {
        method: "DELETE",
        mode: "cors",///跨域请求
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        }
    })
    if (!res.ok) return Promise.reject(res)
    return Promise.resolve()
}


// 更新出行人接口
export const patchTravellerApi = async ({ access_token }: AccessToken, id: string, pathBody: Traveller): Promise<Traveller> => {

    const res = await fetch(`${window.SSCL_API}/user/traveller/${id}`, {
        method: "PATCH",
        mode: "cors",///跨域请求
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        },
        body: JSON.stringify(pathBody)
    })
    if (!res.ok) return Promise.reject(res.status)
    return await res.json()
}
