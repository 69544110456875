import { Col, Result, Row } from "antd"
import { connect } from "react-redux"
import { RootState } from "../../app/store"
import { useAppDispatch } from "../../app/hooks"
import { useEffect } from "react"
import { fetchTipMessage } from "../location/slice"
import { fetchSupplierIcon } from "../supplier/supplierSlice"

const mapStateToProps = (state: RootState) => ({
    username: state.user.currentStaff?.staffUserName || "用户",
    tipMessage: state.location.tipMessage,
    supplierIcon: state.supplier.supplierIcon,
})

type Props = ReturnType<typeof mapStateToProps>

function greeting() {
    const hr = new Date().getHours()
    if (hr >= 6 && hr <= 11) {
        return "上午好"
    } else if (hr === 12) {
        return "中午好"
    } else if (hr >= 13 && hr <= 18) {
        return "下午好"
    } else {
        return "晚上好"
    }
}

const _homePage = ({
    username,
    tipMessage,
    supplierIcon,
}: Props): JSX.Element => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (!tipMessage && !supplierIcon) {
            dispatch(fetchTipMessage())
            dispatch(fetchSupplierIcon())
        }
    }, [dispatch, supplierIcon, tipMessage])

    return (
        <div className="container" style={{ height: "97%" }}>
            <Row
                align="middle"
                style={{ width: "100%", height: "100%", background: "#fff" }}>
                <Col span={24}>
                    <Result
                        icon={
                            <img
                                src="/homePage.png"
                                width="35%"
                                alt="一张画着飞机和电脑屏幕与智能手机的图"
                            />
                        }
                        title={`${greeting()}，${username}`}
                        subTitle="欢迎使用差旅系统"
                    />
                </Col>
            </Row>
        </div>
    )
}

export default connect(mapStateToProps)(_homePage)
