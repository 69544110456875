import { SearchOutlined } from "@ant-design/icons"
import { Button, Col, DatePicker, Form, Grid, Row, Select } from "antd"
import dayjs, { Dayjs } from "dayjs"
import { connect } from "react-redux"
import { RootState } from "../../../../app/store"
import { RangeValue } from "../../../../app/tools"
import { HotelKeyword } from "../hotelAPI"
import HotelCitySelector from "./HotelCitySelector"
import HotelKeywordSearch from "./HotelKeywordSearch"
import HotelInputGroup, { PriceRange } from "./HotelInputGroup"
import { HotelCity } from "../../../models"

export interface HotelListQueryArgs {
    city: HotelCity
    dateRange: RangeValue
    keyword?: HotelKeyword
    priceRange?: PriceRange
    stars?: string
}

type Props = {
    loading?: boolean
    onSearch?: (values: unknown) => void
}

const mapStateToProps = (state: RootState) => ({
    applyFor: state.booking.applyForm,
})

const { Item } = Form

type StateProps = ReturnType<typeof mapStateToProps> & Props
function _search({ loading = false, onSearch, applyFor }: StateProps) {
    //  个性化布局
    const { lg, md } = Grid.useBreakpoint()
    const onFinish = (values: unknown) => {
        //把from表单输入值传给酒店列表查询HotelList.tsx
        onSearch?.(values)
    }
    // 把已过时间改为禁用
    function disabledDate(current: Dayjs) {
        const start = dayjs()
            .startOf("day")
            .isAfter(dayjs(applyFor?.applyStartDate))
            ? dayjs().startOf("day")
            : dayjs(applyFor?.applyStartDate)
        return (
            current &&
            (current < start || current > dayjs(applyFor?.applyEndDate))
        )
    }
    // 星级
    const hotelStars = [
        { label: "一星级", value: "1" },
        { label: "二星级", value: "2" },
        { label: "三星级", value: "3" },
        { label: "四星级", value: "4" },
        { label: "五星级", value: "5" },
    ]
    return (
        <Form layout="inline" onFinish={onFinish}>
            <Row style={{ width: lg ? "75%" : "100%" }} gutter={[0, 16]}>
                <Col flex="1 300px">
                    <Item
                        labelCol={{ hidden: true }}
                        name="city"
                        label="城市"
                        normalize={value => JSON.parse(value)}
                        rules={[{ required: true }]}>
                        <HotelCitySelector />
                    </Item>
                </Col>

                <Col flex="1 300px">
                    <Item<HotelListQueryArgs>
                        name="dateRange"
                        labelCol={{ hidden: true }}
                        label="入住与离店日期"
                        rules={[
                            { required: true },
                            {
                                validator: async (_, val: RangeValue) => {
                                    const from = val?.[0]
                                    const to = val?.[1]
                                    if (!from || !to) return
                                    if (to.diff(from) <= 0)
                                        throw Error("日期选择至少大于一天")
                                },
                            },
                        ]}>
                        <DatePicker.RangePicker
                            style={{ width: "100%" }}
                            placeholder={["入住日期", "离店日期"]}
                            showTime={!md ? { format: 'HH' } : false}
                            format="YYYY-MM-DD"
                            disabledDate={disabledDate}
                        />
                    </Item>
                </Col>

                <Col flex="1 300px">
                    <Item name="keyword">
                        <HotelKeywordSearch />
                    </Item>
                </Col>

                <Col flex="1 300px">
                    <Item name="stars">
                        <Select allowClear placeholder="星级">
                            {hotelStars.map(item => (
                                <Select.Option
                                    key={item.label}
                                    value={item.value}>
                                    {" "}
                                    {item.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Item>
                </Col>

                <Col flex="1 400px">
                    <Item<HotelListQueryArgs>
                        name="priceRange"
                        rules={[
                            {
                                validator: async (_, value) => {
                                    if (!value) return
                                    const { minPrice, maxPrice } =
                                        value as PriceRange
                                    if (minPrice && minPrice < 0)
                                        return Promise.reject(
                                            new Error(
                                                "价格搜索的最小值不能小于0"
                                            )
                                        )
                                    if (
                                        (maxPrice && maxPrice < 0) ||
                                        Number(maxPrice) <= Number(minPrice)
                                    )
                                        return Promise.reject(
                                            new Error(
                                                "价格搜索的最大值不能小于最小值"
                                            )
                                        )
                                },
                            },
                        ]}>
                        <HotelInputGroup />
                    </Item>
                </Col>

                <Col flex="none">
                    <Item>
                        <Button
                            loading={loading}
                            type="primary"
                            htmlType="submit"
                            icon={<SearchOutlined />}>
                            搜索
                        </Button>
                    </Item>
                </Col>
            </Row>
        </Form>
    )
}

export default connect(mapStateToProps)(_search)
