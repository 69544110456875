import { ReactElement } from "react"
import { Row, Col, Typography, Space, Image } from "antd"
import { OrderListModel } from "../../service/orderListModel"
import { useAppSelector } from "../../../../app/hooks"
import { blue } from "@ant-design/colors"
import { Link } from "react-router-dom"
// 订单列表 统一的item 头部
const { Text } = Typography
export const OrderListItemHeaderCpn = (props: OrderListModel): ReactElement => {
    const supplierIcon = useAppSelector(state => state.supplier.supplierIcon)
    const { customId, orderTime, packageId, publicItem } = props

    return (
        <Row>
            <Col flex="none">
                <Image
                    src={
                        supplierIcon?.find(it => it.supplier === packageId)
                            ?.icon
                    }
                    placeholder={true}
                    preview={false}
                    style={{ width: 30, height: 30, marginRight: 20 }}
                />
            </Col>
            <Col flex="auto">
                <Space direction="horizontal" size={2}>
                    订单号：
                    <Link
                        to={{
                            pathname: "/order/list/detail",
                        }}
                        state={publicItem}>
                        <Text className="clickable" style={{ color: blue[5] }}>
                            {customId}
                        </Text>
                    </Link>
                </Space>
            </Col>
            <Col flex="none">
                <span>
                    预订日期：<span>{orderTime}</span>
                </span>
            </Col>
        </Row>
    )
}

// 订单列表 统一的item 价格组合
export const OrderListItemPriceGroupCpn = (props: {
    total?: number
    status?: string
}): ReactElement => {
    const { total, status } = props

    return (
        <Space direction="horizontal">
            <Text strong style={{ color: blue[5], fontSize: 16 }}>
                <Text style={{ color: blue[5] }} italic>
                    ¥
                </Text>
                {total}
            </Text>
            <Text>|</Text>
            <Text>{status}</Text>
        </Space>
    )
}
