import {
    Form,
    Input,
    Card,
    Space,
    Divider,
    Button,
    Row,
    Grid,
    Typography,
    Tabs,
} from "antd"
import type { Traveller } from "../../models"
import {
    CardPassportTypeList,
    IdCardType,
    idCardTypeList,
} from "../../traveller/Editor"
import TraverllerBasicInfo from "./TraverllerBasicInfo"
import { setIsEditMode } from "../viewerSlice"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { isCertificateExpire } from "./TraverllerIdCard"

const { useBreakpoint } = Grid

type Props = {
    traveller: Traveller
    editable: boolean
}

const TravellerViewer = ({ traveller: t, editable }: Props): JSX.Element => {
    const screens = useBreakpoint()
    const { Item } = Form
    const { Title } = Typography

    const detail = useAppSelector(state => state.applyFormViewer.detail)
    const dispatch = useAppDispatch()

    return (
        <Form style={{ width: screens.lg ? "90%" : "100%" }}>
            {
                <Row justify="space-between">
                    <Title level={4}>出行人详情</Title>
                    {detail?.approvalState !== 0 && editable && (
                        <Button onClick={() => dispatch(setIsEditMode(true))}>
                            编辑
                        </Button>
                    )}
                </Row>
            }
            <Divider />
            <TraverllerBasicInfo traveller={t} />
            <Divider style={{ margin: "0 0 24px" }} />
            {(t.idList?.length ?? 0) > 0 && (
                <Tabs
                    items={(() => {
                        return t.idList?.map((t, idx) => ({
                            key: `${t?.idType + idx}`,
                            label: `${
                                CardPassportTypeList().find(
                                    it => it.passportType === t.passportType
                                )?.displayName || ""
                            }${
                                idCardTypeList().find(
                                    it => it.idCardType === t.idType
                                )?.displayName || ""
                            }`,
                            children: (
                                <Space
                                    direction="vertical"
                                    size="middle"
                                    style={{
                                        display: "flex",
                                        marginBottom: 24,
                                    }}
                                    key={t.idNumber + idx}>
                                    <Card>
                                        <Item label="证件号">
                                            <Input
                                                value={t.idNumber}
                                                className="readonlyInput"
                                                disabled
                                            />
                                        </Item>
                                        <Item label="签发国">
                                            <Input
                                                value={t.issueCountry}
                                                className="readonlyInput"
                                                disabled
                                            />
                                        </Item>
                                        <Item
                                            label="有效期:"
                                            validateStatus="error"
                                            help={isCertificateExpire(t)}>
                                            <Input
                                                value={
                                                    t.idType ===
                                                        IdCardType.ID_CARD &&
                                                    t.expiredAt === "3000-01-01"
                                                        ? "长期"
                                                        : t.expiredAt
                                                }
                                                className="readonlyInput"
                                                disabled
                                            />
                                        </Item>
                                    </Card>
                                </Space>
                            ),
                        }))
                    })()}
                />
            )}
        </Form>
    )
}

export default TravellerViewer
