import { Button, message, Modal, Table, Tag, Typography } from "antd"
import { PageHeader } from "@ant-design/pro-layout"
import dayjs from "dayjs"
import { useCallback, useEffect } from "react"
import { connect } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { AppDispatch, RootState } from "../../app/store"
import {
    ApplyForm,
    ApplyFormLifecycle,
    PageRequest,
    Traveller,
} from "../models"
import { findApprovedList, resetPage, selectApplyForm } from "./bookSlice"
import { MinusOutlined } from "@ant-design/icons"

const mapStateToProps = (state: RootState) => ({
    data: state.booking.applyforPage,
    selectedId: state.booking.applyForm?.applyId,
    loading: state.booking.loading,
    companyId: state.user.currentCompany?.companyId,
    lifecycleApplyOneTime:
        state.user.currentCompany?.companyBasicConfig.lifecycleApplyOneTime,
    locLoading: state.location.loading,
})
const mapDispatchToProps = (dispatch: AppDispatch) => ({
    // 获取已审批申请单列表
    findList: (page: PageRequest) => {
        dispatch(findApprovedList(page))
    },
    // 选择申请类型
    selectApplyForm: (v?: ApplyForm) => {
        dispatch(selectApplyForm(v))
    },
    // 重置页面
    resetPage: () => {
        dispatch(resetPage())
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        mode?: string
        onSelect?: (value: ApplyForm) => void
    }

const TravellerTag = (item?: unknown) => {
    if (!item) return <></>
    const list = item as Traveller[]
    return list.map(t => <Tag key={t.travellerId}>{t.name}</Tag>)
}

const { Text } = Typography

const _selector = ({
    mode,
    loading,
    data,
    selectedId,
    findList,
    selectApplyForm,
    resetPage,
    lifecycleApplyOneTime,
    locLoading,
    onSelect,
}: Props) => {
    const navi = useNavigate()
    const { state } = useLocation()
    const { referrer } = state ?? ({} as { referrer: string })

    useEffect(() => {
        //初始化加载
        findList({ page: 0, size: 10 })
        return () => {
            // 卸载
            resetPage()
        }
    }, [findList, resetPage])

    //申请单选择事件
    const handleSelect = useCallback(
        (item: ApplyForm) => {
            if (mode !== "selector") {
                if ((item.travellers ?? []).filter(i => !i.idList).length > 0) {
                    /* idList为空时，说明存在出行人来自于旧版APP */
                    Modal.warning({
                        content:
                            "您好，近期系统根据航信对于实名制与证件的要求进行了更新，您的出行人信息来自旧版App，请您在该申请单中点击标记星号*的出行人补充必要信息完成更新后重新进行预定。",
                        onOk: () => {
                            navi(`/applyForm/${item.applyId}`, {
                                replace: true,
                            })
                        },
                    })
                }
                if (locLoading !== "fulfilled") {
                    selectApplyForm(item)
                } else {
                    selectApplyForm(item)
                }
                if (referrer) {
                    navi(referrer, { replace: true })
                } else {
                    message.success("选择成功，请您在侧边菜单栏选择预订")
                }
            } else {
                onSelect?.(item)
            }
        },
        [mode, locLoading, referrer, navi, selectApplyForm, onSelect]
    )

    //渲染列表操作区
    const renderOpts = (item: ApplyFormLifecycle) => {
        if (mode !== "selector") {
            if (item.applyId === selectedId)
                return <Text type="success">正在使用</Text>
            else if (
                lifecycleApplyOneTime &&
                !item.international &&
                !item.longApplyFor &&
                item.reimbursed
            )
                return (
                    <Text
                        type="secondary"
                        onClick={() =>
                            Modal.warning({
                                content:
                                    "根据公司要求您的申请单已经被报销单关联过,不可以再选择此申请单预定。",
                            })
                        }>
                        不可用
                    </Text>
                )
            else if (dayjs().isBefore(item.applyEndDate))
                return (
                    <Button
                        size="small"
                        type="primary"
                        ghost
                        onClick={() => {
                            handleSelect(item)
                        }}>
                        使用此申请单
                    </Button>
                )
            else return <Text type="secondary">已过期</Text>
        } else {
            return (
                <Button
                    size="small"
                    type="primary"
                    ghost
                    onClick={() => {
                        handleSelect(item)
                    }}>
                    使用此申请单
                </Button>
            )
        }
    }

    return (
        <>
            <PageHeader
                title="选择预订申请单"
                ghost={false}
                style={{ display: mode === "selector" ? "none" : undefined }}
            />
            <div className={mode ? undefined : "container"}>
                <Table
                    size={mode === "selector" ? "small" : "middle"}
                    loading={loading}
                    scroll={{ x: "max-content" }}
                    columns={[

                        {
                            title: "开始时间/结束时间",
                            width: 220,
                            render: (_, record) => {
                                return <div>{dayjs(record.applyStartDate).format("YYYY-MM-DD")}<MinusOutlined />{dayjs(record.applyEndDate).format("YYYY-MM-DD")}</div>
                            }
                        },
                        {
                            title: "申请单号",
                            dataIndex: "txm",
                            width: 150,
                            render: item => <Text code>{item}</Text>,
                        },
                        {
                            title: "出差类型",
                            width: 200,
                            dataIndex: "actionType",
                        },
                        {
                            title: "出行人",
                            dataIndex: "travellers",
                            width: 400,
                            render: TravellerTag,
                        },
                        {
                            title: "操作",
                            render: renderOpts,
                            width: 100,
                            fixed: "right",
                        },
                    ]}
                    showHeader={false}
                    pagination={
                        data && {
                            hideOnSinglePage: true,
                            current: data.number + 1,
                            total: data.totalElements,
                            onChange: (page, size) => {
                                findList({
                                    page: page - 1,
                                    size: size || 10,
                                })
                            },
                        }
                    }
                    dataSource={data?.content}
                    rowKey="applyId"
                />
            </div >
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(_selector)
