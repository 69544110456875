import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../../app/store"
import { CommonLegacyResult } from "../../../models"
import { accessTokenSelector } from '../../../user/loginSlice'
import {
    BookAirplaneFlightValidateParams,
    bookFlightValidateApi,
    SearchAirplaneCabinListParams,
    searchAirplaneListApi,
    SearchAirplaneListParams,
    searchFlightCabinListApi,
    bookFlightCreateOrderApi,
    SearchChangeAirplaneListParams,
    searchChangeAirplaneListApi,
    searchChangeFlightCabinListApi,
    ChangeAirplaneFlightValidateParams,
    changeFlightCreateOrderApi,
    ChangeAirplaneFlightCreateOrderParams,
    searchChangeFlightVildateApi,
    RefundOrderFlightValidateParams,
    refundFlightValidateApi,
    RefundAirplaneOrderParams,
    refundFlightOrderApi,
    BookAirplaneFlightCreateOrderParams,
} from "./bookAirplaneApi"
import { PlaneOrderListModel } from "../../../order/service/orderListModel"
import { AirplaneParameters } from "../list/components/AirplaneListCabinItem"

// 飞机场
export interface FlightAirportVModel {
    airport?: string             // HIA
    airportCh?: string           // lianshui
    airportEname?: string        // Lianshui Airport
    airportName?: string         // 涟水机场
    cityCode?: string            // HIA
    cityName?: string            // 淮安
    country?: string             // 中国
    createDate?: string          // 2021-11-10 09:58:44
    depthName?: string           // 中国,江苏,淮安
    ename?: string               // huaian
    id?: string                  // 181
    inland?: string              // 1
    locationId?: string          // 4a93c9e8731e47f08d3d28b6bcfb2ec1
    province?: string            // 江苏
    remarks?: string             // ctrip-2020041401
    shortName?: string           // ls
    updateDate?: string          // 2021-11-10 09:58:44
}

// 飞机列表航班
export interface AirplaneFlightVModel {
    toDepthPath?: string
    fromDepthPath?: string
    flightDate?: string         // 起飞日期  yyyy-MM-dd
    airline?: string            // 航空公司，如:CZ
    airlineName?: string        // 航空公司名 如:南方航空
    flightImg?: string          //航司图片
    flightShorterForm?: string  //航司简介
    flightNum?: string          // 航班号  如:CZ3987
    shareFlag?: string          // 是否共享航班  值为Y或N
    shareFlightNum?: string     // 共享航班号 如:CA1234
    takeOffCity?: string        // 出发城市三字码  如:SZX
    takeOffCityName?: string    //出发城市名称 如:深圳
    toCity?: string             // 到达城市三字码  如:CSX
    toCityName?: string         // 到达城市名称 如:长沙
    takeOffPort?: string        // 起飞机场三字码  如:SZX
    takeOffPortname?: string    // 起飞机场名称 如:宝安机
    landingPort?: string        // 降落机场三字码 如:CSX
    landingPortName?: string    // 降落机场名称 如:黄花机
    takeOffTime?: string        // 起飞时间  如: "2018-08-1
    landingTime?: string        // 降落时间  如: "2018-08-1
    planeType?: string          // 机型  如:321
    stops?: string              // 中停数
    stopDesc?: Array<{ name: string }>           // 中停信息 [{"name":"", "sta
    priceFare: string          // 最低票面价 从舱位中统计出的最低
    adultAirportPrice: string  // 成人机建税
    adultFuelPrice: string     // 成人燃油税
    takeOffTerminal?: string    // fromTerminal 出发航
    toTerminal?: string         // 到达航站楼
    meal?: string               // 餐食
    planeSize?: string          // 机型大小  大 / 中 / 小
    shareFlightName?: string    // 共享航空公司名
    flightTime?: string         //飞行时间  2时23分
    seating: string            //座位数  ">9" 或者 "2"之类的具体数据
    cabinName: string          //仓位
    id?: string
}

// 飞机舱位
export interface AirplaneCabinVModel {
    babyBackPolicy?: string
    babyChangePolicy?: string
    babySignPolicy?: string
    backPolicy?: string
    baseCabin?: string
    cabin?: string
    cabinGrade?: string
    changePolicy?: string
    childBackPolicy?: string
    childChangePolicy?: string
    childSignPolicy?: string
    discount?: string
    finallyPrice: string
    flightDate?: string
    flightNum?: string
    id?: string
    initTicketPrice?: string
    packageId?: string
    patFlag?: string
    protocolFlag?: string
    protocolTicketPrice?: string
    seating?: string
    serviceAmount: string
    signPolicy?: string
    specialFlag?: string
    standardPrice?: string
    supplierId?: string
    supplierName?: string
    productType?: string
}

interface BookAirplaneRootState {
    listLoading: boolean
    isLoading: boolean
    bookLoading: boolean
    airplaneRes?: AirplaneFlightVModel[]
    cabinList?: Array<AirplaneCabinVModel>
    validateRes?: CommonLegacyResult<{ resultFlag: string, resultPrice?: string }> // 下单校验结果
    orderRes?: CommonLegacyResult<unknown>
    refundLoading: boolean
    changeRefundValidateRes?: CommonLegacyResult<{ flag: string, fee?: string }> // 退票校验和改签校验 返回结果一致
    airplaneFilterOptions: AirplaneFilterOptions
    airplaneListByQueried: AirplaneFlightVModel[]
    planeOrderModel?: PlaneOrderListModel
    airplaneParameters?: AirplaneParameters
}

interface OptValue<T extends AirplaneFlightVModel[keyof AirplaneFlightVModel]> {
    value: T
    label: string
}

export interface AirplaneFilterOptions {
    planeSize: Array<OptValue<AirplaneFlightVModel['planeSize']>>
    landingPortName: Array<OptValue<AirplaneFlightVModel['landingPortName']>>
    airlineName: Array<OptValue<AirplaneFlightVModel['airlineName']>>
    takeOffTime: Array<OptValue<AirplaneFlightVModel['takeOffTime']>>
    sortTakeOffTime?: string
    sortpriceFare?: string
}

export interface AirplaneFilterOptionValues {
    filterPlaneSize: Array<AirplaneFlightVModel['planeSize']>
    filterLandingPortName: Array<AirplaneFlightVModel['landingPortName']>
    filterAirlineName: Array<AirplaneFlightVModel['airlineName']>
    filterTakeOffTime: Array<string>
    sortTakeOffTime?: string
    sortpriceFare?: string
    changedValue?: Pick<AirplaneFilterOptions, 'sortTakeOffTime'> & Pick<AirplaneFilterOptions, 'sortpriceFare'>
}


const initialState: BookAirplaneRootState = {
    isLoading: false,
    listLoading: false,
    bookLoading: false,
    refundLoading: false,
    airplaneListByQueried: [],
    airplaneFilterOptions: {
        planeSize: [],
        landingPortName: [],
        airlineName: [],
        takeOffTime: []
    },
}

// 查询飞机航班列表
export const searchAirplaneList = createAsyncThunk('/book/airplane/list',
    async (params: SearchAirplaneListParams, { getState, rejectWithValue, dispatch }) => {
        const token = accessTokenSelector(getState() as RootState)
        if (!token) return rejectWithValue(null)
        try {
            const flightList = await searchAirplaneListApi(token, params)
            dispatch(fillFilterOption(flightList))
            return flightList
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

// 查询飞机航班舱位列表
export const searchFlightCabinList = createAsyncThunk('/book/airplane/cabin/list',
    async (params: SearchAirplaneCabinListParams, { getState, rejectWithValue }) => {
        const token = accessTokenSelector(getState() as RootState)
        if (!token) return rejectWithValue(null)
        try {
            const cabinList = await searchFlightCabinListApi(token, params)
            return cabinList
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

// 飞机价格校验
export const airplaneCabinValidate = createAsyncThunk('/book/airplane/cabin/validate',
    async (params: BookAirplaneFlightValidateParams, { getState, rejectWithValue }) => {
        const token = accessTokenSelector(getState() as RootState)
        if (!token) return rejectWithValue(null)
        try {
            const data = await bookFlightValidateApi(token, params)

            return data
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

// 创建飞机订单
export const airplaneCreateOrder = createAsyncThunk('/book/airplane/create/order',
    async (params: BookAirplaneFlightCreateOrderParams, { getState, rejectWithValue }) => {
        const token = accessTokenSelector(getState() as RootState)
        if (!token) return rejectWithValue(null)
        try {
            const data = await bookFlightCreateOrderApi(token, params)

            return data
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

//--------------飞机改签航班查询
export const searchChangeAirplaneList = createAsyncThunk('/change/airplane/list',
    async (params: SearchChangeAirplaneListParams, { getState, rejectWithValue }) => {
        const token = accessTokenSelector(getState() as RootState)
        if (!token) return rejectWithValue(null)
        try {
            const data = await searchChangeAirplaneListApi(token, params)

            return data
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)
//--------------查询改签飞机舱位列表
export const searchChangeCabinList = createAsyncThunk('/change/airplane/cabin/list',
    async (params: SearchAirplaneCabinListParams, { getState, rejectWithValue }) => {
        const token = accessTokenSelector(getState() as RootState)
        if (!token) return rejectWithValue(null)
        try {
            const cabinList = await searchChangeFlightCabinListApi(token, params)
            return cabinList
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

//-------------- 改签校验
export const airplaneChangeValidate = createAsyncThunk('/change/airplane/validate',
    async (params: ChangeAirplaneFlightValidateParams, { getState, rejectWithValue }) => {
        const token = accessTokenSelector(getState() as RootState)
        if (!token) return rejectWithValue(null)
        try {
            const data = await searchChangeFlightVildateApi(token, params)

            return data
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

//-------------- 改签
export const changeFlightCreateOrder = createAsyncThunk('/change/airplane/create/order',
    async (params: ChangeAirplaneFlightCreateOrderParams, { getState, rejectWithValue }) => {
        const token = accessTokenSelector(getState() as RootState)
        if (!token) return rejectWithValue(null)
        try {
            const data = await changeFlightCreateOrderApi(token, params)

            return data
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

//-------------- 退票校验
export const airplaneRefundValidate = createAsyncThunk('/refund/airplane/validate',
    async (params: RefundOrderFlightValidateParams, { getState, rejectWithValue }) => {
        const token = accessTokenSelector(getState() as RootState)
        if (!token) return rejectWithValue(null)
        try {
            const data = await refundFlightValidateApi(token, params)

            return data
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

//-------------- 退票
export const airplaneRefundOrder = createAsyncThunk('/refund/airplane/order',
    async (params: RefundAirplaneOrderParams, { getState, rejectWithValue }) => {
        const token = accessTokenSelector(getState() as RootState)
        if (!token) return rejectWithValue(null)
        try {
            const data = await refundFlightOrderApi(token, params)

            return data
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

const bookAirplaneInfoSlice = createSlice({
    name: "bookAirplane",
    initialState,
    reducers: {
        deleteOrderResInfo: (state) => { delete state.orderRes },
        deleteValidateResInfo: (state) => { delete state.validateRes },
        deleteAirplaneResInfo: (state) => { delete state.airplaneRes },
        deleteChangeRefundValidateResInfo: (state) => { delete state.changeRefundValidateRes },
        fillFilterOption: (state, { payload }: PayloadAction<AirplaneFlightVModel[]>) => {
            // planeSize 机型
            const filterPlaneSize = Array.from(new Set(payload.map((item) => item.planeSize)))
            state.airplaneFilterOptions.planeSize = filterPlaneSize.map((value) => ({ value, label: 'planeSize' }))
            // landingPortName  起飞机
            const filterLandingPortName = Array.from(new Set(payload.map((item) => item.landingPortName)))
            state.airplaneFilterOptions.landingPortName = filterLandingPortName.map((value) => ({ value, label: 'landingPortName' }))
            // airlineName 航空公司
            const filterAirAirlineName = Array.from(new Set(payload.map((item) => item.airlineName)))
            state.airplaneFilterOptions.airlineName = filterAirAirlineName.map((value) => ({ value, label: 'airlineName' }))
        },
        filterAirplaneList: (state, { payload }: PayloadAction<AirplaneFilterOptionValues>) => {
            const { filterPlaneSize, filterLandingPortName, filterAirlineName, filterTakeOffTime, sortpriceFare, sortTakeOffTime, changedValue } = payload
            const filteredList = state.airplaneListByQueried.filter(item => {
                if (filterPlaneSize?.length > 0)
                    return filterPlaneSize.includes(item.planeSize)
                else return true
            })
                .filter(item => {
                    if (filterLandingPortName?.length > 0)
                        return filterLandingPortName.includes(item.landingPortName)
                    else return true
                })
                .filter(item => {
                    if (filterAirlineName?.length > 0)
                        return filterAirlineName.includes(item.airlineName)
                    else return true
                })
                // 时间
                .filter(item => {
                    if (filterTakeOffTime?.length > 0) {
                        const LocaleDateString = new Date().toLocaleDateString()
                        const curTime = new Date(`${LocaleDateString} ${(item.takeOffTime ?? '').slice(11, 16)}`).getTime()
                        return filterTakeOffTime.some((v) => {
                            const [start, end] = v.split("-")
                            const startTimes = new Date(`${LocaleDateString} ${start}`).getTime()
                            const endTimes = new Date(`${LocaleDateString} ${end}`).getTime()
                            return curTime >= startTimes && curTime <= endTimes
                        })
                    } else return true
                })
            state.airplaneRes = filteredList
            // 金额排序   changedValue监听属性sortpriceFare
            if (changedValue?.sortpriceFare) {
                filteredList.sort((a, b) => {
                    if (sortpriceFare === 'low')
                        return Number(a.priceFare) - Number(b.priceFare)
                    else return Number(b.priceFare) - Number(a.priceFare)
                })
            }
            if (changedValue?.sortTakeOffTime) {
                filteredList.sort((a, b) => {
                    if (sortTakeOffTime === 'early')
                        return new Date(a.takeOffTime ?? '').getTime() - new Date(b.takeOffTime ?? '').getTime()
                    else
                        return new Date(b.takeOffTime ?? '').getTime() - new Date(a.takeOffTime ?? '').getTime()
                })
            }

        },
        setplaneOrderModel: (state, { payload }: PayloadAction<PlaneOrderListModel>) => {
            state.planeOrderModel = payload
        },
        setAirplaneParameters: (state, { payload }: PayloadAction<AirplaneParameters>) => {
            state.airplaneParameters = payload
        }
    },
    extraReducers: (builder) => {
        builder
            // 查询飞机列表
            .addCase(searchAirplaneList.pending, (state) => {
                delete state.airplaneRes
                state.listLoading = true
            })
            .addCase(searchAirplaneList.rejected, (state) => {
                delete state.airplaneRes
                state.listLoading = false
            })
            .addCase(searchAirplaneList.fulfilled, (state, { payload }) => {
                state.airplaneRes = payload as AirplaneFlightVModel[]
                state.listLoading = false
                state.airplaneListByQueried = state.airplaneRes
            })

            // 查询飞机舱位列表
            .addCase(searchFlightCabinList.pending, (state) => {
                delete state.cabinList
                state.isLoading = true
            })
            .addCase(searchFlightCabinList.rejected, (state) => {
                delete state.cabinList
                state.isLoading = false
            })
            .addCase(searchFlightCabinList.fulfilled, (state, { payload }) => {
                state.cabinList = payload as Array<AirplaneCabinVModel>
                state.isLoading = false
            })

            // 飞机舱位验价
            .addCase(airplaneCabinValidate.pending, (state) => { state.bookLoading = true })
            .addCase(airplaneCabinValidate.rejected, (state) => { state.bookLoading = false })
            .addCase(airplaneCabinValidate.fulfilled, (state, { payload }) => {
                state.validateRes = (payload as CommonLegacyResult<{ resultFlag: string, resultPrice?: string }>)
                if (state.validateRes.rc !== 0) { // 验价失败
                    state.bookLoading = false
                }
            })

            // 飞机下单 支付
            .addCase(airplaneCreateOrder.pending, (state) => {
                state.bookLoading = true
                delete state.validateRes
                delete state.orderRes
            })
            .addCase(airplaneCreateOrder.rejected, (state) => { state.bookLoading = false })
            .addCase(airplaneCreateOrder.fulfilled, (state, { payload }) => {
                state.bookLoading = false
                state.orderRes = payload
            })

            // 查询改签航班列表
            .addCase(searchChangeAirplaneList.pending, (state) => {
                delete state.airplaneRes
                state.listLoading = true
            })
            .addCase(searchChangeAirplaneList.rejected, (state) => {
                delete state.airplaneRes
                state.listLoading = false
            })
            .addCase(searchChangeAirplaneList.fulfilled, (state, { payload }) => {
                state.airplaneRes = payload.data
                state.listLoading = false
            })

            // 查询改签飞机舱位列表
            .addCase(searchChangeCabinList.pending, (state) => {
                delete state.cabinList
                state.isLoading = true
            })
            .addCase(searchChangeCabinList.rejected, (state) => {
                delete state.cabinList
                state.isLoading = false
            })
            .addCase(searchChangeCabinList.fulfilled, (state, { payload }) => {
                state.cabinList = payload
                state.isLoading = false
            })

            // 飞机改签校验
            .addCase(airplaneChangeValidate.pending, (state) => { state.bookLoading = true; delete state.changeRefundValidateRes })
            .addCase(airplaneChangeValidate.rejected, (state) => { state.bookLoading = false })
            .addCase(airplaneChangeValidate.fulfilled, (state, { payload }) => {
                state.changeRefundValidateRes = (payload as CommonLegacyResult<{ flag: string, fee?: string }>)
                if (state.changeRefundValidateRes.rc !== 0) { // 验价失败
                    state.bookLoading = false
                }
            })

            // 飞机改签
            .addCase(changeFlightCreateOrder.pending, (state) => {
                state.bookLoading = true
                delete state.validateRes
                delete state.orderRes
            })
            .addCase(changeFlightCreateOrder.rejected, (state) => { state.bookLoading = false })
            .addCase(changeFlightCreateOrder.fulfilled, (state, { payload }) => {
                state.bookLoading = false
                state.orderRes = payload as CommonLegacyResult<unknown>
            })

            // 飞机退票校验
            .addCase(airplaneRefundValidate.pending, (state) => { state.refundLoading = true; delete state.changeRefundValidateRes })
            .addCase(airplaneRefundValidate.rejected, (state) => { state.refundLoading = false })
            .addCase(airplaneRefundValidate.fulfilled, (state, { payload }) => {
                state.changeRefundValidateRes = (payload as CommonLegacyResult<{ flag: string, fee?: string }>)
                state.refundLoading = false
            })

            // 飞机退票
            .addCase(airplaneRefundOrder.pending, (state) => {
                state.bookLoading = true
                delete state.orderRes
            })
            .addCase(airplaneRefundOrder.rejected, (state) => { state.bookLoading = false })
            .addCase(airplaneRefundOrder.fulfilled, (state, { payload }) => {
                state.bookLoading = false
                state.orderRes = payload as CommonLegacyResult<unknown>
            })
    }
})

export const { filterAirplaneList, fillFilterOption, deleteOrderResInfo, deleteValidateResInfo, deleteAirplaneResInfo, deleteChangeRefundValidateResInfo, setplaneOrderModel, setAirplaneParameters } = bookAirplaneInfoSlice.actions
export default bookAirplaneInfoSlice.reducer
