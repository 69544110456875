import { ReactElement } from "react"
import { Route, Routes } from "react-router-dom"
import AirplaneAddPassenger from "./addPassenger/AirplaneAddPassenger"
import { AirplaneList } from "./list/AirplaneList"
import { AirplanePayConfirm } from "./payConfirm/AirplanePayConfirm"
import { AirplanePaySuccess } from "./payConfirm/AirplanePaySuccess"

export default function BookingAirplane(): ReactElement {
    return (
        <Routes>
            <Route path="" element={<AirplaneList />} />
            <Route path="addPassenger/" element={<AirplaneAddPassenger />} />
            <Route path="AirplanePayConfirm" element={<AirplanePayConfirm />} />
            <Route path="paysuccess" element={<AirplanePaySuccess />} />
        </Routes>
    )
}
