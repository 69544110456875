import { ReactElement, useState, useEffect, useRef } from "react"
import { Navigate } from "react-router-dom"
import {
    ExclamationCircleOutlined,
    EyeTwoTone,
    EyeInvisibleOutlined,
} from "@ant-design/icons"
import {
    Button,
    Form,
    Input,
    Modal,
    Row,
    Col,
    message,
    Typography,
    Card,
    Alert,
    InputRef,
    Space,
} from "antd"
import { connect } from "react-redux"
import { AppDispatch, RootState } from "../../../../app/store"
import { BookTrainCreateOrderParams } from "../services/bookTrainApi"
import {
    deleteTrainResInfo,
    fetchBookTrain,
    changeTrain,
    TrainItemVModel,
    TrainSeatItemVModel,
} from "../services/bookTrainSlice"
import { PayConfirmSider } from "./TrainPayConfirmSider"
import { PayConfirmPrice } from "./TrainPayConfirmPrice"
import { TrainSelectPassenger } from "../payConfirm/TrainSelectPassenger"
import NoteMsg from "../../airplane/list/components/NoteMsg"
import { TrainOrderListModel } from "../../../order/service/orderListModel"
import { ShowPassenger } from "../../airplane/payConfirm/ShowPassenger"
import { TrainSelectSeat } from "./TrainSelectSeat"
import { useLocation, useNavigate } from "react-router-dom"
import { Traveller } from "../../../models"
import { orange, blue } from "@ant-design/colors"

const { Text, Title } = Typography
interface ConfirmFormValues {
    applyForId: string
    supplierId: string
    bookerNum: string
    trainId: string
    trainSeatId: string
    passengers: Traveller[]
    chooseSeats: string[]
    bookerMobile: string
    bookerName: string
    train12306Account: {
        account12306: string
        pwd12306: string
        verificationCode?: string
    }
}

const mapStateToProps = (state: RootState) => ({
    loading: state.bookTrain.bookLoading,
    staff: state.user.currentStaff,
    trainRes: state.bookTrain.trainRes,
    applyForm: state.booking.applyForm,
    orderFunctionPage:
        state.location.tipMessage?.orderFunctionPage?.split("："),
    orderPayNotice: state.location.tipMessage?.orderPayNotice,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    // 火车下单
    trainCreateOrder: (params: BookTrainCreateOrderParams) => {
        dispatch(fetchBookTrain(params))
    },
    // 火车改签
    changeTrain: (p: { oid: string; trainId: string; trainSeatId: string }) => {
        dispatch(changeTrain(p))
    },
    //火车  删除state里的数据，清除状态
    deleteTrainRes: () => {
        dispatch(deleteTrainResInfo())
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

const _payConfirm = (props: Props): ReactElement => {
    const {
        loading,
        staff,
        trainRes,
        applyForm,
        orderFunctionPage,
        orderPayNotice,
    } = props
    const { trainCreateOrder, changeTrain, deleteTrainRes } = props
    const navi = useNavigate()
    const location = useLocation()
    // 通过useLocation接收TrainListItem.tsx页面传递的porps
    const { seat, train, trainOrder } = location.state as {
        seat: TrainSeatItemVModel
        train: TrainItemVModel
        trainOrder?: TrainOrderListModel
    }

    const [mode, setMode] = useState<"book" | "change">("book")
    // 创建 Form 实例，用于管理所有数据状态
    const [form] = Form.useForm()
    // 支付改签开关
    const [visible, setVisible] = useState<boolean>(false)
    // 选座服务
    const [needSelectSeat, setNeedSelectSeat] = useState(false)
    // 座椅计数
    const [seatCount, setSeatCount] = useState(0)
    const [codeRequire, setCodeRequire] = useState<boolean>(false)
    // 提示信息开关
    const [tipsvisible, setTipsvisible] = useState(true)
    // 密码输入框dom元素
    const passwordInputRef = useRef<InputRef>(null)
    useEffect(() => {
        // 如果trainOrder有值为改签setMode('book)改change
        if (trainOrder) {
            setMode("change")
        }
    }, [trainOrder])

    useEffect(() => {
        if (!applyForm && mode === "book") {
            <Navigate to=".." />
        }
    }, [applyForm, mode])

    useEffect(() => {
        setNeedSelectSeat(
            seat.seatCode === "O" ||
            seat.seatCode === "9" ||
            seat.seatCode === "P" ||
            seat.seatCode === "M"
        )
    }, [seat.seatCode])

    useEffect(() => {
        if (trainRes && trainRes?.rc === 0) {
            //预订成功
            // 跳转
            const tmpState = { ...trainRes, mode: mode }
            navi("/booking/train/success", { state: tmpState, replace: true })
        } else if (
            trainRes &&
            trainRes.rc === 1 &&
            trainRes.data &&
            (trainRes.data as { code: number; msg: string }).code === -2
        ) {
            setCodeRequire(true)
        } else if (
            trainRes &&
            trainRes.data &&
            (trainRes.data as { code: number; msg: string }).code === -3
        ) {
            Modal.confirm({
                cancelButtonProps: { style: { display: "none" } },
                title: "密码错误",
                okText: "重新输入",
                content: `您的12306账号或者密码不正确请重新输入`,
                onOk: () => {
                    passwordInputRef.current?.focus({
                        cursor: "all",
                    })
                },
            })
            setVisible(false)
        } else if (trainRes) {
            Modal.warning({
                content: `${trainRes?.msg}`,
            })
        }
        return () => {
            // 卸载  删除state里的数据，清除状态
            deleteTrainRes()
        }
    }, [trainRes, navi, deleteTrainRes, mode])

    const confirm = (values: ConfirmFormValues) => {
        // 去支付
        if (
            (!values.passengers || values.passengers.length <= 0) &&
            mode === "book"
        ) {
            return message.warning("请选择至少一位乘车人")
        }
        setVisible(true)
    }
    const handleValuesChange = (cv: unknown, values: ConfirmFormValues) => {
        const changed = cv as ConfirmFormValues
        if (changed.passengers) {
            const max = changed.passengers.length
            setSeatCount(max)
        }
    }

    const handleOk = () => {
        if (mode === "change" && trainOrder) {
            // 改签
            const v = form.getFieldValue("verificationCode")
            const s = form.getFieldValue("chooseSeats")
            const param: {
                oid: string
                trainId: string
                trainSeatId: string
                verificationCode?: string
                chooseSeats?: string[]
            } = {
                oid: trainOrder?.oid || "",
                trainId: train.id,
                trainSeatId: seat.id,
                verificationCode: v,
                chooseSeats: s,
            }
            //火车改签
            changeTrain(param)
        } else {
            // 预订
            const v = form.getFieldsValue() as BookTrainCreateOrderParams
            // 火车下单
            trainCreateOrder(v)
        }
    }

    const handleCancel = () => {
        //关闭对话框开关
        setVisible(false)
    }
    // 提示信息数据
    const trainNoteList = [
        {
            title: orderFunctionPage?.[0] + ":",
            msg: orderFunctionPage?.[1],
            level: "01",
        },
    ]

    return (
        <>
            <Form
                form={form}
                name="basicForm"
                scrollToFirstError={true}
                labelCol={{ span: 3 }}
                onFinish={confirm}
                onValuesChange={handleValuesChange}>
                <Row>
                    <Col flex="2 300px">
                        <div className="container">
                            {orderPayNotice
                                ? tipsvisible && (
                                    <Alert
                                        type="warning"
                                        message={orderPayNotice}
                                        closable
                                        afterClose={() =>
                                            setTipsvisible(false)
                                        }
                                    />
                                )
                                : null}
                            <Card
                                size="small"
                                bordered={false}
                                styles={{ body: { padding: "20px 8px 0px 8px" } }}>
                                <Space align="baseline">
                                    <Title level={5}>乘机人</Title>
                                    <Text
                                        type="secondary"
                                        style={{ fontSize: 16 }}>
                                        只限申请单内人员
                                    </Text>
                                </Space>
                            </Card>
                            <Form.Item
                                hidden
                                noStyle
                                name="applyForId"
                                initialValue={applyForm?.applyId}>
                                <Input hidden />
                            </Form.Item>
                            <Form.Item
                                hidden
                                noStyle
                                name="supplierId"
                                initialValue={seat.supplierId}>
                                <Input hidden />
                            </Form.Item>
                            <Form.Item
                                hidden
                                noStyle
                                name="trainId"
                                initialValue={train.id}>
                                <Input hidden />
                            </Form.Item>
                            <Form.Item
                                hidden
                                noStyle
                                name="trainSeatId"
                                initialValue={seat.id}>
                                <Input hidden />
                            </Form.Item>
                            <Form.Item
                                hidden
                                noStyle
                                name="bookerNum"
                                initialValue={staff?.staffId}>
                                <Input hidden />
                            </Form.Item>

                            {mode === "book" ? (
                                <Form.Item
                                    name="passengers"
                                    rules={[
                                        {
                                            type: "array",
                                            min: 1,
                                            message: "至少选择一位乘车人",
                                        },
                                    ]}>
                                    <TrainSelectPassenger
                                        traveller={applyForm?.travellers || []}
                                    />
                                </Form.Item>
                            ) : (
                                <ShowPassenger
                                    name={
                                        trainOrder?.passenger?.passengerName ||
                                        ""
                                    }
                                />
                            )}
                            {needSelectSeat && (
                                <Form.Item style={{ marginBottom: 0 }}>
                                    <Card
                                        size="small"
                                        bordered={false}
                                        styles={{ body: { padding: "0px 8px 15px 8px" } }}>
                                        <Title level={5}>选座服务</Title>
                                    </Card>
                                    <Form.Item
                                        name="chooseSeats"
                                        normalize={value => value.join("")}>
                                        <TrainSelectSeat
                                            seatCode={seat.seatCode}
                                            seatCount={seatCount}
                                        />
                                    </Form.Item>
                                </Form.Item>
                            )}

                            {mode === "book" && (
                                <Form.Item>
                                    <Card
                                        size="small"
                                        styles={{ body: { padding: "0px 8px 15px 8px" } }}
                                        bordered={false}>
                                        <Title level={5}>12306账号信息</Title>
                                    </Card>
                                    <Form.Item
                                        label="12306账号"
                                        name={[
                                            "train12306Account",
                                            "account12306",
                                        ]}
                                        rules={[{ required: true }]}>
                                        <Input placeholder="12306账号 用户名/邮箱/手机号"></Input>
                                    </Form.Item>
                                    <Form.Item
                                        label="12306密码"
                                        name={["train12306Account", "pwd12306"]}
                                        rules={[{ required: true }]}>
                                        <Input.Password
                                            ref={passwordInputRef}
                                            placeholder="12306账号密码,非本系统密码"
                                            iconRender={visible =>
                                                visible ? (
                                                    <EyeTwoTone />
                                                ) : (
                                                    <EyeInvisibleOutlined />
                                                )
                                            }></Input.Password>
                                    </Form.Item>
                                    <Card
                                        size="small"
                                        styles={{ body: { padding: "0px 8px 0px 8px" } }}
                                        bordered={false}>
                                        <Text
                                            type="secondary"
                                            style={{ fontSize: 12 }}>
                                            <ExclamationCircleOutlined
                                                rotate={180}
                                                style={{ color: orange[5] }}
                                            />
                                            &nbsp;温馨提示：铁路局规定购票必须实名制
                                        </Text>
                                        <div>
                                            点击去支付表示您已经阅读并同意{" "}
                                            <Button
                                                type="link"
                                                target="_blank"
                                                href="https://www.suishenchailv.com/policies/12306-policy/"
                                                style={{
                                                    padding: 0,
                                                    color: blue[4],
                                                }}>
                                                《账户授权免责声明》
                                            </Button>
                                        </div>
                                    </Card>
                                </Form.Item>
                            )}
                            <Card
                                size="small"
                                bordered={false}
                                styles={{ body: { padding: "0px 8px 15px 8px" } }}>
                                <Title level={5}>联系人</Title>
                            </Card>
                            <Form.Item
                                label="姓名"
                                initialValue={staff?.staffUserName || ""}
                                name="bookerName"
                                rules={[{ required: true }]}>
                                <Input disabled />
                            </Form.Item>
                            <Form.Item
                                label="电话号码"
                                name="bookerMobile"
                                initialValue={staff?.staffUserPhone || ""}
                                rules={[{ required: true }]}>
                                <Input
                                    disabled={mode === "change"}
                                    placeholder="请输入联系人电话号码"
                                />
                            </Form.Item>

                            <Button
                                style={{ fontSize: 18, borderRadius: 4 }}
                                htmlType="submit"
                                type="primary"
                                block
                                size="large"
                                onClick={() => {
                                    form.submit()
                                }}>{`${mode === "change" ? "确认改签" : "去支付"
                                    }`}</Button>
                            <Modal
                                title={`${mode === "change" ? "改签确认" : "支付确认"
                                    }`}
                                open={visible}
                                onOk={handleOk}
                                destroyOnClose
                                confirmLoading={loading}
                                onCancel={handleCancel}>
                                <p>{`${mode === "change"
                                    ? "确定要改签吗？"
                                    : "确定要支付吗？"
                                    }`}</p>
                                {codeRequire && (
                                    <div>
                                        <Text>
                                            编辑短信内容666发送到12306,返回的验证码输入下框中
                                        </Text>
                                        <Form.Item
                                            name={
                                                mode === "book"
                                                    ? [
                                                        "train12306Account",
                                                        "verificationCode",
                                                    ]
                                                    : "verificationCode"
                                            }
                                            label="验证码"
                                            rules={[{ required: true }]}
                                            style={{ width: "100%" }}>
                                            <Input placeholder="编辑短信内容666发送到12306,输入返回的验证码" />
                                        </Form.Item>
                                    </div>
                                )}
                            </Modal>
                        </div>
                    </Col>

                    <Col flex="1 200px">
                        <div className="container">
                            <PayConfirmSider seat={seat} train={train} />
                            <Form.Item
                                shouldUpdate={(p, n) =>
                                    p["passengers"] !== n["passengers"]
                                }>
                                {({ getFieldValue }) => {
                                    const passengers = getFieldValue(
                                        "passengers"
                                    ) as Traveller[]
                                    return (
                                        <PayConfirmPrice
                                            seat={seat}
                                            passengerCount={
                                                mode === "book"
                                                    ? passengers &&
                                                    passengers.length
                                                    : 1
                                            }
                                            mode={mode}
                                        />
                                    )
                                }}
                            </Form.Item>
                            <NoteMsg noteList={trainNoteList} />
                        </div>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export const TrainPayConfirm = connect(
    mapStateToProps,
    mapDispatchToProps
)(_payConfirm)
