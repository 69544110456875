import { ReactElement } from "react"
import { Route, Routes } from "react-router-dom"
import HotelList from "./list/HotelList"
import HotelDetail from "./detail/HotelDetail"
import HotelPay from "./pay/HotelPay"
import HotelBookResult from "./pay/HotelBookResult"
import HotelRefund from "./HotelRefund"

export default function BookingHotel(): ReactElement {
    return (
        <Routes>
            <Route path="" element={<HotelList />} />
            <Route path="detail/:id" element={<HotelDetail />} />
            <Route path="pay/:id" element={<HotelPay />} />
            <Route path="result" element={<HotelBookResult />} />
            <Route path="refund/:id" element={<HotelRefund />} />
        </Routes>
    )
}
