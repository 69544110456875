import { Button, Col, Result, Row } from "antd"
import { useLocation, useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../app/hooks"
import { reset as resetLoginState } from "./loginSlice"
import { reset as resetUserState } from "./userSlice"

export default function LoginError() {
    const { state } = useLocation()
    const navi = useNavigate()
    const dispatch = useAppDispatch()
    const onClick = () => {
        dispatch(resetLoginState())
        dispatch(resetUserState())
        navi("/login", { replace: true })
    }
    return (
        <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
            <Col>
                <Result
                    title="登录失败"
                    subTitle={state}
                    status="error"
                    extra={
                        <Button
                            key="backToHome"
                            type="primary"
                            onClick={onClick}>
                            重新登录
                        </Button>
                    }
                />
            </Col>
        </Row>
    )
}
