import { ReactElement } from "react"
import { Card } from "antd"
import {
    OrderListPlanePassengerModel,
    PlaneOrderListModel,
} from "../../service/orderListModel"
import { idCardTypeList } from "../../../traveller/Editor"

const IDTypeCardTitle = (props: OrderListPlanePassengerModel): ReactElement => {
    const { passengerName } = props
    return (
        <div>
            <span>{passengerName}</span>
        </div>
    )
}

const PlaneOrderDetailPassengerCpn = (
    props: PlaneOrderListModel
): ReactElement => {
    const { passenger } = props
    return (
        <Card
            title={<IDTypeCardTitle {...passenger} />}
            styles={{ body: { borderBottom: "none" } }}
            size={"small"}>
            {idCardTypeList().find(
                it => it.oldIdCardType === parseInt(passenger?.idType || "")
            )?.displayName ||
                idCardTypeList().find(it => it.idCardType === passenger?.idType)
                    ?.displayName}
            :{passenger?.idNumber}
        </Card>
    )
}

export default PlaneOrderDetailPassengerCpn
