import { AccessToken, CommonLegacyResult, Company, CompanyWithStaff } from "../models"

export const findAllCompany = async ({ access_token }: AccessToken) => {
    const res = await fetch(`${window.SSCL_API}/rest/company`, {
        mode: "cors",
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    })
    if (!res.ok) return Promise.reject(res)
    const body = await res.json() as CommonLegacyResult<Array<CompanyWithStaff>>
    return body.data ?? Promise.reject()
}

/**
 * @deprecated use findByCompanyId instead
 */
export const findFirst = async ({ access_token }: AccessToken): Promise<CompanyWithStaff> => {
    const res = await fetch(`${window.SSCL_API}/rest/company`, {
        mode: "cors",
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    })
    if (!res.ok) return Promise.reject(res)
    const body = await res.json() as CommonLegacyResult<Array<CompanyWithStaff>>
    const company = body.data && body.data[0]
    if (!company) return Promise.reject()
    return company
}


export async function findByCompanyIdApi({ access_token }: AccessToken, companyId: string): Promise<Company> {
    const res = await fetch(`${window.SSCL_API}/rest/company/${companyId}`, {
        mode: "cors",
        method: 'get',
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    })
    if (!res.ok) {
        return Promise.reject(res.status)
    }
    const json = await res.json()
    if (!json) return Promise.reject("empty body")
    const data = json as CommonLegacyResult<Company>
    return data.data ?? Promise.reject("empty data")
}
