import { ReactElement } from "react"
import { Row, Col, Layout, Divider } from "antd"
import { Content, Header } from "antd/lib/layout/layout"
import { HotelOrderListModel } from "../../service/orderListModel"

const HotelOrderDetailRoomCpn = (props: HotelOrderListModel): ReactElement => {
    const {
        startTime,
        endTime,
        roomCount,
        userName,
        corpAccountsName,
        remark,
        night,
    } = props
    return (
        <div>
            <Layout>
                <Header
                    style={{
                        backgroundColor: "white",
                        padding: "0",
                        fontSize: "16px",
                        fontWeight: "bold",
                    }}>
                    舒适大床房-靖康龙酒店
                </Header>
                <Content style={{ backgroundColor: "white" }}>
                    <Row>
                        <Col flex="1"> 入离时间： </Col>
                        <Col flex="2">
                            {" "}
                            {startTime} - {endTime}{" "}
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col flex="1"> 房间数：</Col>
                        <Col flex="1"> {roomCount} 间</Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col flex="1"> 入住天数：</Col>
                        <Col flex="1"> {night} 晚</Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col flex="1"> 入住人：</Col>
                        <Col flex="1"> {userName}</Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col flex="1"> 其他信息：</Col>
                        <Col flex="1">
                            {corpAccountsName}-{remark}
                        </Col>
                    </Row>
                    <Divider />
                </Content>
            </Layout>
        </div>
    )
}
export default HotelOrderDetailRoomCpn
