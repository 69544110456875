import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { SupplierIcons } from "../models"
import { getSupplierIcon } from "./supplierAPI"

interface State {
    supplierIcon?: SupplierIcons[]
}

const initialState: State = {}

// 供应商图标
export const fetchSupplierIcon = createAsyncThunk(
    "keyValue/supplier-icon",
    async (_, { rejectWithValue }) => {
        try {
            return await getSupplierIcon()
        } catch (err) {
            rejectWithValue(err)
        }
    }
)

const slice = createSlice({
    name: "supplier",
    initialState, //初始值
    reducers: {},
    extraReducers: builder => {
        builder
            // 供应商图标
            .addCase(fetchSupplierIcon.pending, state => {
                delete state.supplierIcon
            })
            .addCase(fetchSupplierIcon.rejected, state => {
                delete state.supplierIcon
            })
            .addCase(fetchSupplierIcon.fulfilled, (state, { payload }) => {
                state.supplierIcon = payload
            })
    },
})

export default slice.reducer
