import { useEffect, useState } from 'react'
import { Col, DatePicker, Grid, List, Row, Skeleton, Space, Tooltip, Typography } from 'antd'
import { TitleAccountsType, listAccountsType } from '../book/const'
import dayjs, { Dayjs } from 'dayjs'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { fetchaccountsList } from './accountSlice'
import { Viewer } from './Viewer'
import { RangeValue } from '../../app/tools'
import IconFont from '../../components/IconFont'
import { Accounts, findStaffId } from '../reimburseForm/listSlice'
import AccountsDeleteButton from './AccountsDeleteButton'
import { PageRequest } from '../models'

const { RangePicker } = DatePicker
const { Text } = Typography

export default function AccountsSelector() {
    const [selectedRowData, setSelectedRowData] = useState<Accounts>() // 存储选中行的数据
    const [isDrawerVisible, setIsDrawerVisible] = useState(false) // 控制 Drawer 的显示
    const [data, setData] = useState<PageRequest>()
    const { md } = Grid.useBreakpoint()
    const [dates, setDates] = useState<RangeValue>()
    const dispatch = useAppDispatch()
    const accountsList = useAppSelector(state => state.account.accountsList)
    const accountsLoading = useAppSelector(state => state.account.accountsLoading)
    const defaultPageSize = 50


    useEffect(() => {
        if (!dates) {
            const param = {
                page: 0,
                size: 50,
                startDate: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
                endDate: dayjs().format("YYYY-MM-DD"),
            }
            setData(param)
        }
    }, [dates])

    // RangePicker选择器选中
    const handleChange = (value: RangeValue) => {
        const start = value?.[0]?.startOf("d").format("YYYY-MM-DD")
        const end = value?.[1]?.endOf("d").format("YYYY-MM-DD")
        if (start && end) {
            const param = {
                page: 0,
                size: 50,
                startDate: start,
                endDate: end,
            }
            setData(param)
            dispatch(fetchaccountsList(param))
        }

    }

    // 设置不可选日期
    const disabledDate = (current: Dayjs) => {
        if (!dates) return false
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 15
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 15
        return !!tooEarly || !!tooLate
    }

    return (
        <>
            <RangePicker
                defaultValue={[dayjs().subtract(15, "day"), dayjs()]}
                showTime={!md ? { format: 'HH' } : false}
                format="YYYY-MM-DD"
                onCalendarChange={val => setDates(val)}
                onChange={handleChange}
                disabledDate={disabledDate} />
            <List itemLayout="horizontal"
                style={{ cursor: "pointer" }}
                size='default'
                dataSource={accountsList?.content}
                pagination={
                    accountsList && {
                        total: accountsList.totalElements,
                        current: accountsList.page + 1,
                        pageSize: accountsList.size,
                        onChange: (page, size) => {
                            // 需要拿到RangePicker的值 startDate endDate
                            dispatch(fetchaccountsList({
                                page: page - 1,
                                size: size || defaultPageSize,
                                startDate: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
                                endDate: dayjs().format("YYYY-MM-DD"),
                            }))
                        },
                    }
                }
                renderItem={(item) => (
                    <List.Item key={item.accountDate} onClick={() => {
                        setSelectedRowData(item) // 设置选中行的数据
                        setIsDrawerVisible(true) // 打开 Drawer
                        // 调用归属人接口
                        if (item.customerList) {
                            item.customerList.map(it => dispatch(findStaffId([it.staffId])))
                        } else {
                            dispatch(findStaffId([item.staffId]))
                        }
                    }}>
                        <Skeleton avatar title={false} loading={accountsLoading === 'pending'} active>
                            <Row wrap={true} align="middle" style={{ width: '100%' }}>
                                <Col flex="0 40px">
                                    <Tooltip placement="top" title={TitleAccountsType(item.accountsType)}>
                                        <IconFont type={listAccountsType(item.accountsType)} style={{ fontSize: '30px' }} alt='' />
                                    </Tooltip>
                                </Col>
                                <Col flex='1'>
                                    <Row wrap={true} align="middle" style={{ width: '100%' }}>
                                        <Col flex="2 180px" style={{ textAlign: 'center', alignItems: 'center' }}>
                                            <Space direction='horizontal'>
                                                {
                                                    (/^\d+$/.test(item?.accountsStartData)) ? <Text>{dayjs(Number(item?.accountsStartData)).format("YYYY-MM-DD")}</Text> : <Text style={{ width: 80 }} ellipsis={{ tooltip: item?.accountsStartData }}>{item.accountsStartData}</Text>
                                                }
                                                <IconFont type='icon-jiantou' />
                                                {
                                                    (/^\d+$/.test(item?.accountsEndData)) ? <Text>{dayjs(Number(item?.accountsEndData)).format("YYYY-MM-DD")}</Text> : <Text style={{ width: 80 }} ellipsis={{ tooltip: item.accountsEndData }}>{item.accountsEndData}</Text>
                                                }
                                            </Space>
                                        </Col>
                                        <Col flex="1 180px" style={{ textAlign: 'center' }}>
                                            {item.auditInfo && <Text style={{ width: 180 }} ellipsis={{ tooltip: item.auditInfo ? item.auditInfo : item.accountsRemarks }}>
                                                {item.auditInfo ? item.auditInfo : item.accountsRemarks}
                                            </Text>}
                                            {
                                                (!item.auditInfo) &&
                                                <Text>{dayjs(item.accountsDate).format("YYYY-MM-DD")}</Text>
                                            }
                                        </Col>
                                        <Col flex="2 240px">
                                            <Row justify='center'>
                                                <Col flex='1' style={{ textAlign: 'center' }}>
                                                    <Text>￥{item.accountsSumMoney3.toFixed(2)}</Text>
                                                </Col>
                                                <Col flex='1' style={{ textAlign: 'center' }}>
                                                    <Text>{item.corpAccounts ? '对公' : '个人'}</Text>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col flex='0 40px' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <AccountsDeleteButton id={item.accountsId || ''} onFinished={() =>
                                        dispatch(fetchaccountsList({
                                            page: data?.page, size: data?.size, startDate: data?.startDate,
                                            endDate: data?.endDate,
                                        }))} />
                                </Col>
                            </Row>

                        </Skeleton >
                    </List.Item >
                )}
            >
            </List >
            {
                // 详情
                selectedRowData && <Viewer item={selectedRowData || ""} isDrawerVisible={isDrawerVisible} setIsDrawerVisible={setIsDrawerVisible} onFinished={() =>
                    dispatch(fetchaccountsList({
                        page: data?.page, size: data?.size, startDate: data?.startDate,
                        endDate: data?.endDate,
                    }))} />
            }
        </>
    )
}
