import { ReactElement, useState, useEffect } from "react"
import { Form, Button, DatePicker, Row, Col, Grid, Affix, Drawer } from "antd"
import dayjs, { Dayjs } from "dayjs"
import "dayjs/locale/zh-cn"
import locale from "antd/es/date-picker/locale/zh_CN"
import { FilterOutlined, SearchOutlined } from "@ant-design/icons"
import { SearchAirplaneListParams } from "../../services/bookAirplaneApi"
import { AirplaneCitySelector } from "./AirplaneCitySelector"
import { RootState } from "../../../../../app/store"
import { connect } from "react-redux"
import AirplaneListFilters from "../components/AirplaneListFilters"
import { AirportCity } from "../../../../models"

interface Props {
    onSearch?: (values: SearchAirplaneListParams) => void
    mode: "book" | "change"
    city?: { from?: string; to?: string }
    loading?: boolean
}

export interface SearchFlightFormValues {
    fromDepthPath: AirportCity
    toDepthPath: AirportCity
    flightDate: Dayjs
}

const mapStateToProps = (state: RootState) => ({
    applyFor: state.booking.applyForm,
    fliterBtnAvailable: state.bookAirplane.airplaneListByQueried?.length > 0,
})

type StateProps = ReturnType<typeof mapStateToProps> & Props
const _topSearch = ({
    onSearch,
    mode,
    city,
    loading,
    applyFor,
    fliterBtnAvailable,
}: StateProps): ReactElement => {
    const [form] = Form.useForm()
    const [disabled, setDisabled] = useState(false)
    // 抽屉开关
    const [open, setOpen] = useState(false)
    const config = {
        rules: [
            {
                type: "object" as const,
                required: true,
                message: "请选择出发时间！",
            },
        ],
    }
    const dateFormat = "YYYY-MM-DD"
    // 改签时，出发地目的地不可点击
    useEffect(() => {
        setDisabled(mode === "book")
    }, [mode])
    // 查询
    const onFinish = (values: SearchFlightFormValues) => {
        const args: SearchAirplaneListParams = {
            fromDepthPath: values.fromDepthPath?.depthPath || "",
            toDepthPath: values.toDepthPath?.depthPath || "",
            flightDate: values.flightDate.format(dateFormat),
            applyForId: "",
        }
        // 搜索框子组件传值到父组件AirplaneList.tsx，此时出发地、目的地、飞行日期都已赋值，申请单id未赋值
        onSearch?.(args)
    }

    /**
     * 预订：作为可选日期的开始时间-可选日期的结束时间。其他时间不可预订，日期控件不可点击
     *      可选日期的开始时间：当前日期在申请单开始日志之后，选当前日期；当前日期在申请单开始日期之前，选申请单开始时间
     *      可选日期的结束时间：是申请单的结束日期
     * 改签：目前改签时日期没控制，同App也没控制
     */
    function disabledDate(current: Dayjs) {
        if (mode === "book") {
            const start = dayjs()
                .startOf("day")
                .isAfter(dayjs(applyFor?.applyStartDate))
                ? dayjs().startOf("day")
                : dayjs(applyFor?.applyStartDate)
            return (
                current &&
                (current < start || current > dayjs(applyFor?.applyEndDate))
            )
        } else {
            return current && current < dayjs().startOf("day")
        }
    }
    const { lg } = Grid.useBreakpoint()

    return (
        <Form layout="inline" form={form} onFinish={onFinish}>
            <Row gutter={[0, 16]} style={{ width: lg ? "75%" : "100%" }}>
                <Col flex="1 200px">
                    <Form.Item
                        name="fromDepthPath"
                        normalize={value => JSON.parse(value)}
                        rules={[
                            { required: disabled, message: "请选择出发地！" },
                        ]}>
                        <AirplaneCitySelector
                            isStart={true}
                            isAirport={true}
                            mode={mode}
                            cityName={city?.from}
                        />
                    </Form.Item>
                </Col>
                <Col flex="1 200px">
                    <Form.Item
                        name="toDepthPath"
                        normalize={value => JSON.parse(value)}
                        rules={[
                            { required: disabled, message: "请选择出发地！" },
                        ]}>
                        <AirplaneCitySelector
                            isStart={false}
                            isAirport={true}
                            mode={mode}
                            cityName={city?.to}
                        />
                    </Form.Item>
                </Col>
                <Col flex="1 200px">
                    <Form.Item
                        name="flightDate"
                        initialValue={
                            dayjs()
                                .startOf("day")
                                .isAfter(dayjs(applyFor?.applyStartDate))
                                ? dayjs()
                                : dayjs(applyFor?.applyStartDate)
                        }
                        {...config}>
                        <DatePicker
                            locale={locale}
                            format={dateFormat}
                            disabledDate={disabledDate}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                </Col>

                <Col flex="none">
                    <Form.Item shouldUpdate>
                        {() => (
                            <Button
                                type="primary"
                                loading={loading}
                                htmlType="submit"
                                icon={<SearchOutlined />}>
                                搜索
                            </Button>
                        )}
                    </Form.Item>
                </Col>
                {fliterBtnAvailable && (
                    <Col flex="none">
                        <Affix offsetTop={10}>
                            <Button
                                icon={<FilterOutlined />}
                                type="default"
                                onClick={() => setOpen(true)}>
                                筛选
                            </Button>
                        </Affix>
                        <Drawer
                            title="筛选条件"
                            placement="right"
                            onClose={() => setOpen(false)}
                            open={open}
                            width="300px">
                            <AirplaneListFilters />
                        </Drawer>
                    </Col>
                )}
            </Row>
        </Form>
    )
}

export default connect(mapStateToProps)(_topSearch)
