import React from "react"
import { useAppSelector } from "../../../app/hooks"
import { Select } from "antd"

type ExceedReasonProps = {
    orderType: number
    onChange?: (v?: string) => void
    value?: string
}

export default function ExceedReason({ orderType, onChange, value }: ExceedReasonProps) {
    // 超标原因
    const exceedReasonList = useAppSelector(state => state.user.exceedReasonList)


    return (
        <Select
            value={value ? exceedReasonList?.find(it => it.exceedReasonId === value)?.content : ''}
            allowClear
            onClear={() => onChange?.()}
            disabled={!exceedReasonList}
            notFoundContent={null}
            filterOption={false}
            onSelect={onChange}
        >
            {
                exceedReasonList
                    ?.filter(it => it.accountsType === orderType)
                    .map((it, idx) => {
                        return (
                            <Select.Option
                                key={it.exceedReasonId + idx}
                                value={it.exceedReasonId}>
                                {it.content}
                            </Select.Option>
                        )
                    })
            }
        </Select >
    )
}
