import { Descriptions, Divider, Space, Typography } from 'antd'
import { Tag } from 'antd/lib'
import dayjs from 'dayjs'
import React from 'react'
import { ApplyForm, MaskedStaff, Traveller } from '../../../models'
import TravellerInfo from '../../../applyForm/viewer/TravellerInfo'
import ApplyProcessTimeline from '../../../applyForm/viewer/ApplyProcessTimeline'
import HistoryTimeline from '../../../applyForm/viewer/HistoryTimeline'
import { ApplyFormViewerMode } from '../../viewerSlice'
import { ReviseReimburseForm } from '../../../models/reimburesForm'


type Props = {
    applyForm?: ApplyForm
    staffList?: MaskedStaff[]
    viewerMode?: ApplyFormViewerMode
    detail?: ReviseReimburseForm
}


export default function ApplyFormViewer({ applyForm, staffList, viewerMode, detail }: Props) {

    const { Text } = Typography

    return (
        <>
            <Descriptions
                title="单据信息"
                items={[
                    {
                        key: "申请人",
                        label: "申请人",
                        children: (
                            <Text>
                                {
                                    staffList?.find(
                                        s =>
                                            s.staffId ===
                                            detail?.staffId
                                    )?.staffUserName
                                }
                            </Text>
                        ),
                    },
                    {
                        key: "单号",
                        label: "单号",
                        children: <Text>{applyForm?.txm}</Text>,
                    },
                    {
                        key: "项目",
                        label: "项目",
                        children: (
                            <Text>
                                {applyForm?.projectName || "未选择"}
                            </Text>
                        ),
                    },
                    {
                        key: "预算",
                        label: "预算",
                        children: <Text>{applyForm?.applyMoney}</Text>,
                    },
                    {
                        key: "国内国际",
                        label: "国内国际",
                        children: (
                            <Text>
                                {applyForm?.international
                                    ? "国际出差"
                                    : "国内出差"}
                            </Text>
                        ),
                    },
                ]}
            />
            <Divider />
            <Descriptions
                title="地点日期"
                items={[
                    {
                        key: "开始日期",
                        label: "开始日期",
                        children: (
                            <Text>
                                {dayjs(
                                    new Date(
                                        applyForm?.applyStartDate || 0
                                    )
                                ).format("YYYY-MM-DD")}
                            </Text>
                        ),
                    },
                    {
                        key: "结束日期",
                        label: "结束日期",
                        children: (
                            <Text>
                                {dayjs(
                                    new Date(applyForm?.applyEndDate || 0)
                                ).format("YYYY-MM-DD")}
                            </Text>
                        ),
                    },
                    {
                        key: "申请日期",
                        label: "申请日期",
                        children: (
                            <Text>
                                {dayjs(
                                    new Date(applyForm?.applyDate || 0)
                                ).format("YYYY-MM-DD")}
                            </Text>
                        ),
                    },
                    {
                        key: "长/短差",
                        label: "长/短差",
                        children: (
                            <Text>
                                {applyForm?.longApplyFor ? "长差" : "短差"}
                            </Text>
                        ),
                    },
                    {
                        key: "出差城市",
                        label: "出差城市",
                        children: (
                            <Space size={[0, "middle"]} wrap>
                                {applyForm?.destinations?.map(
                                    (it, idx) => (
                                        <Tag key={it.path + idx}>
                                            {it.name}
                                        </Tag>
                                    )
                                )}
                            </Space>
                        ),
                    },
                ]}
            />
            <Divider />
            <Descriptions
                title="备注信息"
                items={[{ children: applyForm?.description }]}
            />
            {applyForm?.customFieldInstances && (
                <>
                    <Divider />
                    <Descriptions
                        title="其他信息"
                        items={(() => {
                            return applyForm?.customFieldInstances?.map(
                                it => ({
                                    key: `${it.fieldId}`,
                                    label: `${it.fieldName}`,
                                    children: (
                                        <Text>
                                            {it.fieldValues?.join("、")}
                                        </Text>
                                    ),
                                })
                            )
                        })()}
                    />
                </>
            )}
            <Divider />
            <Descriptions
                title="出行人"
                items={(() => {
                    return applyForm?.travellers?.map((t: Traveller) => ({
                        key: `${t.travellerId}`,
                        children: (
                            <TravellerInfo
                                traveller={t}
                                viewerMode={viewerMode}
                                clickable={false}
                            />
                        ),
                    }))
                })()}
            />
            {applyForm?.applyProcesses && (
                <>
                    <Divider />
                    <Descriptions
                        title="流程信息"
                        items={[
                            {
                                children: (
                                    <ApplyProcessTimeline
                                        list={
                                            applyForm?.applyProcesses || []
                                        }
                                    />
                                ),
                            },
                        ]}
                    />
                </>
            )}
            {applyForm?.documentChangeHistories && (
                <>
                    <Divider />
                    <Descriptions
                        title="更改历史"
                        items={[
                            {
                                children: (
                                    <HistoryTimeline
                                        histList={
                                            applyForm?.documentChangeHistories ||
                                            []
                                        }
                                    />
                                ),
                            },
                        ]}
                    />
                </>
            )}
        </>
    )
}
