import { PageHeader } from "@ant-design/pro-layout"
import { Button, Tabs } from "antd"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import ReimburseSelector from "../reimburseForm/editor/ReimburseSelector"
import { useAppDispatch } from "../../app/hooks"
import { entryReset, fetchaccountsList } from "./accountSlice"
import AccountsSelector from "./AccountSelector"
import { initEditor } from "../applyForm/editorSlice"
import { reset } from "../reimburseForm/editorSlice"


export default function List() {
    const [initialized, setInitialized] = useState(false)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!initialized) {
            dispatch(reset())
            dispatch(entryReset())
            // 初始化数据
            dispatch(initEditor())
            setInitialized(true)
        }
    }, [dispatch, initialized])

    const items = [
        {
            label: "未报销账目", key: "unpaid",
            children: <ReimburseSelector />
        },
        {
            label: '按日期查询',
            key: `${dayjs()}-${dayjs().subtract(15, "day")}`,
            children: <AccountsSelector />,
        }, // 务必填写 key
    ]
    // Tabs切换默认昨天今天日期
    const handleTabChange = (key: string) => {
        if (key !== "unpaid") {
            const [start, end] = key.split("-")
            const param = {
                page: 0,
                size: 50,
                startDate: dayjs(end).format("YYYY-MM-DD"),
                endDate: dayjs(start).format("YYYY-MM-DD"),
            }
            dispatch(fetchaccountsList(param))
        }
    }

    return (
        <>
            <PageHeader
                title="账目"
                ghost={false}
                extra={[
                    <Button key="create" type="primary" ghost>
                        <Link to="../create">创建666</Link>
                    </Button>,
                ]}
            />
            <div className="container">
                <Tabs
                    defaultActiveKey="1"
                    items={items}
                    onChange={handleTabChange}></Tabs>
                {/*加个标识*/}
            </div>
        </>
    )
}
