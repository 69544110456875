
import { AccessToken, AccountPolicyList, CommonLegacyResult, DataPage, PageRequest, ReimbursePolicy, TaxRateList } from "../models"
import { Accounts } from "../reimburseForm/listSlice"
import { CreateAccount } from "./accountSlice"
import { GetProp } from "antd"
import type { UploadProps } from "antd/es/upload"

export type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

export const fetchaccountsListApi = async ({ access_token }: AccessToken, query: { endDate?: string, companyId?: string, startDate?: string } & PageRequest): Promise<DataPage<Accounts>> => {
    const { page, size, endDate, startDate } = query
    const search = new URLSearchParams()
    if (startDate) search.set('startDate', `${startDate}`)
    if (endDate) search.set("endDate", `${endDate}`)
    if (page) search.set("page", `${page}`)
    if (size) search.set("size", `${size}`)

    const res = await fetch(`${window.SSCL_API}/rest/account/accountsDate?${search}`, {
        mode: "cors",
        method: "GET",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        },
    })
    const body = await res.json() as CommonLegacyResult<DataPage<Accounts>>
    if (!body.data) return Promise.reject(null)
    return body.data
}

// 删除账目
export const deleteAccountApi = async ({ access_token }: AccessToken, id: string): Promise<void> => {

    const res = await fetch(`${window.SSCL_API}/rest/account/${id}`, {
        method: "DELETE",
        mode: "cors",///跨域请求
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        }
    })
    if (!res.ok) return Promise.reject(res)
    return Promise.resolve()
}
// 保存账目
export const postAccount = async ({ access_token }: AccessToken, t: CreateAccount): Promise<Accounts> => {
    const { files, accounts } = t
    const formData = new FormData()
    files?.forEach((file) => {
        if (file.originFileObj) {
            formData.append('files', file.originFileObj as FileType)
        }
    })

    formData.append("account", new Blob([JSON.stringify(accounts)], { type: "application/json" }))
    const res = await fetch(`${window.SSCL_API}/rest/account/v2`, {
        method: "POST",
        mode: "cors",
        headers: {
            'Authorization': `Bearer ${access_token}`,
        },
        body: formData
    })

    const body = await res.json() as CommonLegacyResult<Accounts>
    if (!body.data) return Promise.reject(null)
    return body.data
}

// 保存账目详情
export const postcreateAccountsDetails = async ({ access_token }: AccessToken, t: CreateAccount, id: string): Promise<Accounts> => {
    const { files, accounts } = t
    const formData = new FormData()
    files?.forEach((file) => {
        if (file.originFileObj) {
            formData.append('files', file.originFileObj as FileType)
        }
    })
    formData.append("account", new Blob([JSON.stringify(accounts)], { type: "application/json" }))
    const res = await fetch(`${window.SSCL_API}/rest/account/${id}/v2`, {
        method: "POST",
        mode: "cors",
        headers: {
            'Authorization': `Bearer ${access_token}`,
        },
        body: formData
    })

    const body = await res.json() as CommonLegacyResult<Accounts>
    if (!body.data) return Promise.reject(null)
    return body.data
}

//报销政策    rest/reimbursePolicy/listed
export const fetchReimbursePolicyApi = async ({ access_token }: AccessToken,): Promise<ReimbursePolicy> => {
    const res = await fetch(`${window.SSCL_API}/rest/reimbursePolicy/listed`, {
        method: 'GET',
        mode: 'cors',//跨域请求
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        }
    })
    if (!res.ok) return Promise.reject(res.status)
    const body = await res.json() as CommonLegacyResult<ReimbursePolicy>
    if (!body.data) return Promise.reject(null)
    return body.data
}

// 查询可用补贴政策
export const fetcSubsidyPolicyApi = async ({ access_token }: AccessToken, query: { staffId: string, depthPath: string }): Promise<AccountPolicyList[]> => {
    const { staffId, depthPath } = query
    const search = new URLSearchParams()
    if (depthPath) search.set('depthPath', `${depthPath}`)
    if (staffId) search.set("staffId", `${staffId}`)
    const res = await fetch(`${window.SSCL_API}/forms/accountPolicy/subsidyPolicy?${search}`, {
        method: 'GET',
        mode: 'cors',//跨域请求
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        }
    })
    if (!res.ok) return Promise.reject(res.status)
    const body = await res.json() as AccountPolicyList[]
    return body
}

// 税率 
export const fetchTaxRateApi = async ({ access_token }: AccessToken,): Promise<TaxRateList[]> => {
    const res = await fetch(`${window.SSCL_API}/rest/account/type/list/all`, {
        method: 'GET',
        mode: 'cors',//跨域请求
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        }
    })
    if (!res.ok) return Promise.reject(res.status)
    const body = await res.json() as CommonLegacyResult<TaxRateList[]>
    if (!body.data) return Promise.reject(null)
    return body.data
}
