import React, { useEffect, useState } from 'react'
import { Form, Input, Select } from 'antd'
import { useAppSelector } from '../../../app/hooks'
import { connect } from 'react-redux'


const { Item, } = Form

interface PayeeFlatItem {
    packageId: string,
    corpAccountsNum: string,
    corpAccountsName: string,
}

type PayeeFlatProps = {
    value?: PayeeFlatItem,
    onChange?: (v: PayeeFlatItem) => void
}

const PayeeFlatSelector = ({ value, onChange }: PayeeFlatProps) => {

    const payAccount = useAppSelector(state => state.reimburesEditor.payAccount)

    const onSelect = (v: string) => {
        const list = payAccount?.find(it => it.packageId === v)

        const payAccountItem = {
            packageId: list?.packageId ?? '',
            corpAccountsNum: list?.account ?? '',
            corpAccountsName: list?.name ?? '',
        }
        onChange?.(payAccountItem)
    }

    return (
        <Select
            value={value?.corpAccountsName}
            disabled={!payAccount}
            showSearch
            placeholder="请选择单位"
            notFoundContent={null}
            filterOption={false}
            onSelect={onSelect}
        >
            {
                payAccount?.map((it, idx) => {
                    return <Select.Option key={it.companyId + idx} value={it.packageId} >
                        {it.name}
                    </Select.Option>
                })
            }
        </Select>
    )
}


const PayeeFlatSelectorItem = connect()(PayeeFlatSelector)


export default function PayeeFlat() {

    const form = Form.useFormInstance()
    const [project, setProject] = useState<PayeeFlatItem>()

    const packageId = Form.useWatch<string>('packageId', form)
    const corpAccountsNum = Form.useWatch<string>('corpAccountsNum', form)
    const corpAccountsName = Form.useWatch<string>('corpAccountsName', form)


    useEffect(() => {
        if (project) {
            form.setFieldValue("packageId", project.packageId)
            form.setFieldValue("corpAccountsNum", project.corpAccountsNum)
            form.setFieldValue("corpAccountsName", project.corpAccountsNum)
        } else {
            if (packageId && corpAccountsNum && corpAccountsName) {//回显
                setProject({
                    packageId: packageId,
                    corpAccountsNum: corpAccountsNum,
                    corpAccountsName: corpAccountsName
                })
            }
        }

    }, [corpAccountsName, corpAccountsNum, form, packageId, project])


    return (
        <>
            <Item
                required
                label="收款单位">
                <Item
                    noStyle
                    hidden
                    name="packageId"
                    label="出差项目"
                    rules={[{ required: true },]}
                >
                    <Input />
                </Item>
                <PayeeFlatSelectorItem onChange={setProject} value={project} />
            </Item>
            <Item noStyle hidden name="corpAccountsNum" >
                <Input />
            </Item>
            <Item noStyle hidden name="corpAccountsName" >
                <Input />
            </Item>
        </>
    )
}
