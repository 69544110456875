import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"

import { RootState } from "../../../app/store"
import { CommonPageConent, PageRequest } from "../../models"
import { accessTokenSelector } from "../../user/loginSlice"
import {
    TrainStopStationsVModel,
    TrainOrderScheduleParams,
    AirplaneOrderRefundRulesParams
} from "../service/orderModuleApi"
import {
    HotelOrderListModel,
    OrderListModel,
    PlaneOrderListModel,
    TrainOrderListModel,
    FlightChangeReasonVModel
} from "./orderListModel"
import {
    fetchOrderListApi,
    fetchOrderTrainScheduleApi,
    fetchPlaneRefundRulesApi,
    fetchChangeReasonApi,
} from './orderModuleApi'

export interface PlaneRefundRulesVModel {
    flag?: string,
    change?: string,
    refund?: string,
}

export interface OrderTypePageRequest extends PageRequest {
    orderType: string
}

export interface PlaneRefundRulesParamVModel {
    flightDate?: string,
    supplierId?: string,
    airline?: string,
    productType?: string,
    initTicketPrice?: string,
    flightNum?: string,
    cabin?: string,
    takeOffPort?: string,
    landingPort?: string,
    applyForId?: string,
}

interface OrderRootState {
    planeRefundRules?: PlaneRefundRulesVModel
    trainSchedule?: Array<TrainStopStationsVModel>
    reasonList?: FlightChangeReasonVModel[],
    isLoading: boolean,
    data?: CommonPageConent<OrderListModel>,
    tabKey: string,
}

const initialState: OrderRootState = {
    isLoading: false,
    tabKey: '1',
}


// 订单列表
export const fetchOrderList = createAsyncThunk('order/fetchOrderList',
    async (param: OrderTypePageRequest, { getState, rejectWithValue }) => {
        const token = accessTokenSelector(getState() as RootState)
        if (!token) return rejectWithValue(null)
        try {
            const orderList = await fetchOrderListApi(token, param)
            return orderList
        } catch (error) {
            return rejectWithValue(null)
        }
    }
)

// 飞机详情退改规则
export const fetchPlaneRefundRules = createAsyncThunk('/order/plane/detail/rules',
    async (params: AirplaneOrderRefundRulesParams, { getState, rejectWithValue }) => {
        const token = accessTokenSelector(getState() as RootState)
        if (!token) return rejectWithValue(null)
        try {
            const refundRules = await fetchPlaneRefundRulesApi(token, params)
            return refundRules
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

// 火车详情时刻表
export const fetchOrderTrainSchedule = createAsyncThunk('/order/train/detail/schedule',
    async (param: TrainOrderScheduleParams, { getState, rejectWithValue }) => {
        const token = accessTokenSelector(getState() as RootState)
        if (!token) return rejectWithValue(null)
        try {
            const refundRules = await fetchOrderTrainScheduleApi(token, param)
            return refundRules
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

// ---------------------订单操作
export const airplaneChangeReason = createAsyncThunk('order/flight/change',
    async (params: string, { getState, rejectWithValue }) => {
        const token = accessTokenSelector(getState() as RootState)
        if (!token) return rejectWithValue(null)
        try {
            const reasonList = await fetchChangeReasonApi(token, params)
            return reasonList
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

const planeOrderSlice = createSlice({
    name: "planeOrderList",
    initialState,
    reducers: {
        setTabKey: (state, { payload }: PayloadAction<string>) => ({ ...state, tabKey: payload }),
    },
    extraReducers: builder => {
        // 订单列表 
        builder
            .addCase(fetchOrderList.pending, (state) => {
                delete state.data
                state.isLoading = true
            })
            .addCase(fetchOrderList.rejected, (state) => {
                delete state.data
                state.isLoading = false
            })
            .addCase(fetchOrderList.fulfilled, (state, action) => {
                const { payload, meta } = action
                if (meta.arg.orderType === '1') { // 火车
                    state.data = payload as CommonPageConent<TrainOrderListModel>
                } else if (meta.arg.orderType === '2') { // 飞机
                    state.data = payload as CommonPageConent<PlaneOrderListModel>
                } else { // 酒店
                    state.data = payload as CommonPageConent<HotelOrderListModel>
                }
                state.isLoading = false
            })

            // 飞机详情退改规则
            .addCase(fetchPlaneRefundRules.pending, (state) => {
                delete state.planeRefundRules
                state.isLoading = true
            })
            .addCase(fetchPlaneRefundRules.rejected, (state) => {
                state.isLoading = false
            })
            .addCase(fetchPlaneRefundRules.fulfilled, (state, { payload }) => {
                state.planeRefundRules = payload as PlaneRefundRulesVModel
                state.isLoading = false
            })

            // 火车详情站点数据
            .addCase(fetchOrderTrainSchedule.pending, (state) => {
                delete state.planeRefundRules
                state.isLoading = true
            })
            .addCase(fetchOrderTrainSchedule.rejected, (state) => {
                state.isLoading = false
            })
            .addCase(fetchOrderTrainSchedule.fulfilled, (state, { payload }) => {
                state.trainSchedule = payload as Array<TrainStopStationsVModel>
                state.isLoading = false
            })

            // 请求改签原因
            .addCase(airplaneChangeReason.pending, (state) => { state.isLoading = true; delete state.reasonList })
            .addCase(airplaneChangeReason.rejected, (state) => { state.isLoading = false })
            .addCase(airplaneChangeReason.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.reasonList = payload as FlightChangeReasonVModel[]
                state.reasonList.forEach((item, index) => {
                    item.key = index + ''
                })
            })
    }
})

export const { setTabKey } = planeOrderSlice.actions
export default planeOrderSlice.reducer
