import { CommonLegacyResult, CommonPageConent, MaskedStaff, Staff, AccessToken } from "../models"
import { StaffUserList } from "../reimburseForm/editorSlice"

export async function findByStaffIdApi({ access_token }: AccessToken, staffId: string): Promise<Staff> {
    const res = await fetch(`${window.SSCL_API}/rest/staff/${staffId}`, {
        method: 'get',
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
    if (!res.ok) return Promise.reject(res.status)
    return await res.json()
}

export async function queryStaffByNameAPI({ access_token }: AccessToken, companyId: string, name: string): Promise<Array<Staff>> {
    const res = await fetch(`${window.SSCL_API}/rest/company/${companyId}/staff/staffUserName/${name}`, {
        method: 'get',
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
    if (!res.ok) return Promise.reject(res.status)
    const data = await res.json() as CommonLegacyResult<CommonPageConent<Staff>>
    return data.data?.content || []
}

// 归属人
export const getStaffByIds = async ({ access_token }: AccessToken, companyId: string, ids: string[]): Promise<StaffUserList[]> => {
    const res = await fetch(`${window.SSCL_API}/rest/company/${companyId}/staff/batch`, {
        method: 'post',
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ select: ids })
    })
    if (!res.ok) {
        return Promise.reject(res.status)
    }
    const body = await res.json()
    return (body as CommonLegacyResult<StaffUserList[]>)?.data || []
}

export const getDefaultAuditorByCompanyId = async ({ access_token }: AccessToken, companyId: string): Promise<MaskedStaff[] | undefined> => {
    const params = new URLSearchParams()
    params.set('queryType', 'auth')
    params.set('authApproval', 'true')

    const returned = new Array<MaskedStaff>()
    for (let page = 0; ; page++) {
        params.set('page', `${page}`)
        const res = await fetch(`${window.SSCL_API}/rest/company/${companyId}/staff?${params.toString()}`, {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${access_token}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        if (!res.ok) return Promise.reject(res.status)
        const ret = await res.json() as CommonLegacyResult<CommonPageConent<MaskedStaff>>
        const data = ret.data
        const content = data?.content
        if (content) {
            returned.push(...content)
        }
        if (!data || ((data.page + 1) >= data.totalPages)) {
            break
        }
    }
    return returned
}
