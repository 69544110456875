import { ReactElement } from "react"
import { Row, Col, Space, Typography } from "antd"
import { PlaneOrderListModel } from "../service/orderListModel"
import planeImage from "../assets/homePlane.png"

const { Text } = Typography
const ChangeFlightScheduleItem = (params: {
    time?: string
    airport?: string
    city?: string
}): ReactElement => {
    return (
        <Space direction="vertical" size={0}>
            <Text>{params.time && params.time.substring(0, 10)}</Text>
            <Text strong style={{ fontSize: 20 }}>
                {params.city}
            </Text>
            <Text>{params.time && params.time.substring(11, 16)}</Text>
            <Text>{params.airport}</Text>
        </Space>
    )
}

export const ChangeAirplaneJourney = (
    props: PlaneOrderListModel
): ReactElement => {
    const { flight } = props

    return (
        <Row>
            <Col flex="1" style={{ textAlign: "center" }}>
                <ChangeFlightScheduleItem
                    time={flight?.takeOffTime}
                    airport={flight?.takeOffPortname}
                    city={flight?.takeOffCityName}
                />
            </Col>
            <Col flex="1" style={{ textAlign: "center" }}>
                <div>
                    <img
                        src={planeImage}
                        alt=""
                        style={{ width: "40px", height: "30px" }}
                    />
                </div>
                <div
                    style={{
                        width: "100%",
                        height: "2px",
                        background: "#f0f0f0",
                        margin: "4px 0px",
                    }}></div>
                <div>{flight?.flightTime}</div>
            </Col>
            <Col flex="1" style={{ textAlign: "center" }}>
                <ChangeFlightScheduleItem
                    time={flight?.landingTime}
                    airport={flight?.landingPortName}
                    city={flight?.toCityName}
                />
            </Col>
        </Row>
    )
}
