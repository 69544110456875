import React, { useEffect, useState } from "react"
import { PlusOutlined } from "@ant-design/icons"
import { GetProp, Form, Modal, Upload, message } from "antd"
import type { RcFile, UploadProps } from "antd/es/upload"
import type { UploadFile } from "antd/es/upload/interface"
import { connect } from "react-redux"
import { FileRepertories } from "../listSlice"
// import { useAppSelector } from "../../../app/hooks"

type Props = {
    value?: FileRepertories[]
    onChange?: (v: FileRepertories[]) => void
}


export type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

export function AttachmentsItem({ value, onChange }: Props) {
    // 公司配置
    // const accountMaxAttachments = useAppSelector(state => state.user.currentCompany?.accountMaxAttachments)
    // const setAccessoryMustExceptSubsidy = useAppSelector(state => state.user.currentCompany?.setAccessoryMustExceptSubsidy)

    // 使用 useEffect 来处理 value 的变化
    useEffect(() => {
        // 如果 value 有值，将其添加到 fileList 中
        if (value) {
            setFileList(value)
        }
    }, [value]) // 依赖项是 value
    // 这个函数是用于图片实现弹窗预览获取图片预览的编码的
    const getBase64 = (file: RcFile): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result as string)
            reader.onerror = (error) => reject(error)
        })


    const [previewOpen, setPreviewOpen] = useState(false)
    const [previewImage, setPreviewImage] = useState("")
    const [previewTitle, setPreviewTitle] = useState("")
    const [fileList, setFileList] = useState<UploadFile[]>([])

    const handleCancel = () => setPreviewOpen(false)
    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile)
        }
        setPreviewImage(file.url || (file.preview as string))
        setPreviewOpen(true)
        setPreviewTitle(file.name || (file.url ? file.url.substring(file.url.lastIndexOf("/") + 1) : ""))
        if (file.type === 'application/pdf') {
            window.open(file.url, '_blank')
        }
    }

    const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
        // 限制文件数量为20张
        if (newFileList.length >= 20) {
            message.warning("只能上传最多20张图片。")
            return
        }
        // 更新状态以反映新的文件列表
        setFileList(newFileList)
        onChange?.(newFileList)
    }

    return (
        <>
            <Upload
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                beforeUpload={() => false}
                accept=".JPG,.png,.JPEG"
            >
                {
                    // 这里我们限制死了图片个数是8个，也可以通过props传参进行限制
                    fileList.length >= 8 ? null : (
                        <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>上传</div>
                        </div>
                    )
                }
            </Upload>
            {/* 图片预览 */}
            {

            }
            <Modal

                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
            >
                <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
        </>
    )
}

const AttachmentsList = connect()(AttachmentsItem)

type AttachmentsProps = {
    accountsType?: number
}

export default function Attachments({ accountsType }: AttachmentsProps) {

    return (
        <>
            {
                (accountsType !== 18 && !((accountsType ?? 0) >= 1800 && (accountsType ?? 0) <= 1900)) && <Form.Item
                    name="fileRepertories"
                    label="附件"
                >
                    <AttachmentsList />
                </Form.Item>
            }

        </>
    )
}