import { ReactElement, useState } from "react"
import { Button, Row, Col, Space, Modal } from "antd"
import {
    airplaneOrderStatus,
    trainOrderStatus,
    hotelOrderStatus,
} from "../../../book/const"
import { OrderListModel } from "../../service/orderListModel"
import { RootState } from "../../../../app/store"
import { connect } from "react-redux"
import HotelRenewal from "../../../book/hotel/HotelRenewal"

export const OperateParent = (children: ReactElement[]): ReactElement => {
    return (
        <>
            <Row>
                <Col flex="auto"></Col>
                <Col flex="none">
                    <Row>
                        {children &&
                            children.map((child: ReactElement) => {
                                return child
                            })}
                    </Row>
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    loading: state.bookAirplane.refundLoading,
})

const mapDispatchToProps = () => ({})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        orderModel: OrderListModel
        airplaneChangeClick?: () => void
        airplaneRefundClick?: () => void
        trainChangeClick?: () => void
        trainRefundClick?: () => void
        hotelRefundClick?: () => void
    }

const _showStateOperate = (props: Props): ReactElement => {
    const { orderModel, loading } = props

    const {
        airplaneChangeClick,
        airplaneRefundClick,
        trainChangeClick,
        trainRefundClick,
        hotelRefundClick,
    } = props
    const { orderType, orderState, source } = orderModel
    const [isRenewalOpen, setRenewalOpen] = useState(false)
    const _changeClick = (item: OrderListModel) => {
        if (item.orderType === 2) {
            // 飞机改签
            airplaneChangeClick?.()
        } else {
            // 火车改签
            trainChangeClick?.()
        }
    }

    const _refundClick = (item: OrderListModel) => {
        if (item.orderType === 2) {
            // 飞机退票
            airplaneRefundClick?.()
        } else {
            // 火车退票
            trainRefundClick?.()
        }
    }
    const _hotelRefundClick = () => {
        hotelRefundClick?.()
    }
    //同酒店续订
    const _hotelRenewalClick = () => {
        setRenewalOpen(true)
    }

    if (
        (orderType === 1 &&
            orderState === trainOrderStatus.CLTrainOrderStateCreateSuccess) ||
        (orderType === 2 &&
            orderState === airplaneOrderStatus.CLOrderStateCreateSuccess)
    ) {
        // 火车/飞机预订下单成功
        return OperateParent([
            <Space direction="horizontal" key="operate_1">
                {source === 0 && (
                    <Button
                        key="btn_ticket_change"
                        type="primary"
                        ghost
                        onClick={_ => _changeClick(orderModel)}>
                        改签
                    </Button>
                )}
                <Button
                    key="btn_ticket_refund"
                    type="primary"
                    ghost
                    loading={loading}
                    onClick={_ => _refundClick(orderModel)}>
                    退票
                </Button>
            </Space>,
        ])
    } else if (orderType === 3) {
        // 酒店
        if (orderState === hotelOrderStatus.CLHotelOrderStateBookingSuccess) {
            return OperateParent([
                <Space direction="horizontal" key="operate_2">
                    <Button
                        key="btn_ticket_renewal"
                        type="primary"
                        ghost
                        onClick={_ => _hotelRenewalClick()}>
                        同酒店续订
                    </Button>
                    <Button
                        key="btn_ticket_refund"
                        type="primary"
                        ghost
                        onClick={_ => _hotelRefundClick()}>
                        退订
                    </Button>
                    <Modal
                        title="同酒店预定"
                        open={isRenewalOpen}
                        destroyOnClose
                        footer={null}
                        onCancel={() => setRenewalOpen(false)}>
                        <HotelRenewal
                            id={orderModel?.applyId}
                            hotelId={orderModel?.hotel?.id}
                            companyId={orderModel?.companyId}
                            cityId={orderModel?.hotel?.hotelCityId}
                        />
                    </Modal>
                </Space>,
            ])
        } else {
            if (orderState === hotelOrderStatus.CLHotelOrderStateApplySuccess) {
                return <></>
            }
            return OperateParent([
                <Space direction="horizontal" key="operate_2">
                    <Button
                        key="btn_ticket_renewal"
                        type="primary"
                        ghost
                        onClick={_ => _hotelRenewalClick()}>
                        同酒店续订
                    </Button>
                    <Button
                        key="btn_ticket_refund"
                        type="primary"
                        style={{ display: "none" }}
                        ghost
                        onClick={_ => _hotelRefundClick()}>
                        退订
                    </Button>
                    <Modal
                        title="同酒店预定"
                        open={isRenewalOpen}
                        destroyOnClose
                        footer={null}
                        onCancel={() => setRenewalOpen(false)}>
                        <HotelRenewal
                            id={orderModel?.applyId}
                            hotelId={orderModel?.hotel?.id}
                            companyId={orderModel?.companyId}
                            cityId={orderModel?.hotel?.hotelCityId}
                        />
                    </Modal>
                </Space>,
            ])
        }
    } else {
        return <></>
    }
}

export const ShowStateOperate = connect(mapStateToProps, _ => ({}))(
    _showStateOperate
)
