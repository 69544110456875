import { ReactElement, useState, useEffect } from "react"
import { Modal, Tabs, message } from "antd"
import { PageHeader } from "@ant-design/pro-layout"
import { OrderListInfo } from "./list/OrderListInfo"
import {
    HotelOrderListModel,
    PlaneOrderListModel,
    TrainOrderListModel,
} from "./service/orderListModel"
import { RefundOrderFlightValidateParams } from "../book/airplane/services/bookAirplaneApi"
import { AppDispatch, RootState } from "../../app/store"
import {
    deleteChangeRefundValidateResInfo,
    airplaneRefundValidate,
} from "../book/airplane/services/bookAirplaneSlice"
import { connect } from "react-redux"
import { deleteTrainResInfo } from "../book/train/services/bookTrainSlice"
import { useNavigate } from "react-router-dom"
import { setTabKey } from "./service/orderModuleSlice"
const mapStateToProps = (state: RootState) => ({
    res: state.bookAirplane.changeRefundValidateRes,
    loading: state.orderModule.isLoading,
    trainRes: state.bookTrain.trainRes,
    orderTabKey: state.orderModule.tabKey,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    //飞机退票验证
    airplaneRefund: (params: RefundOrderFlightValidateParams) => {
        dispatch(airplaneRefundValidate(params))
    },
    //飞机 删state里的数据，清除状态
    deleteOrderRes: () => {
        dispatch(deleteChangeRefundValidateResInfo())
    },
    //火车 删state里的数据，清除状态
    deleteTrainRes: () => {
        dispatch(deleteTrainResInfo())
    },
    // 设置订单选项卡键
    setTabKey: (key: string) => {
        dispatch(setTabKey(key))
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

const _orderListCpn = (props: Props): ReactElement => {
    const {
        airplaneRefund,
        res,
        deleteOrderRes,
        trainRes,
        deleteTrainRes,
        orderTabKey,
        setTabKey,
    } = props
    // 跳转并传值
    const navi = useNavigate()
    const [item, setItem] = useState<PlaneOrderListModel>()
    // 飞机改签 通过useNavigate携带参数跳转到RefundTicket.tsx页面
    const _airplaneChange = (item: PlaneOrderListModel) => {
        navi("/order/list/detail/operate/1", { state: item })
    }

    // 飞机退票
    const _airplaneRefund = (item: PlaneOrderListModel) => {
        setItem(item)
        const params: RefundOrderFlightValidateParams = {
            applyForId: item.applyId || "",
            ticketNum: item.ticketNum || "",
            orderNumber: item.customId || "",
            supplierId: item.cabin?.supplierId || "",
        }
        // 飞机退票验证
        airplaneRefund(params)
    }

    const _trainChange = (item: TrainOrderListModel) => {
        // 火车改签 通过useNavigate携带参数跳转到TrainTicketOperate.tsx页面
        navi("/order/list/train/operate/1", { state: item })
    }
    const _trainRefund = (item: TrainOrderListModel) => {
        // 火车退票 通过useNavigate携带参数跳转到TrainTicketOperate.tsx页面
        navi("/order/list/train/operate/2", { state: item })
    }

    const _hotelRefund = (item: HotelOrderListModel) => {
        // 酒店退订 通过useNavigate携带参数跳转到HotelRefund.tsx页面
        navi(`/booking/hotel/refund/${item.oid}`)
    }

    useEffect(() => {
        if (res && res.rc === 0) {
            // 飞机改签退票
            // 跳转RefundTicket.tsx
            navi("/order/list/detail/operate/2", { state: item })
        } else if (res && res.rc === 1) {
            Modal.warning({ content: `${res?.msg}` })
        }
        return () => {
            //卸载 飞机 删state里的数据，清除状态
            deleteOrderRes()
        }
    }, [res, navi, item, deleteOrderRes])

    useEffect(() => {
        if (trainRes && trainRes.rc === 0) {
            // 火车改签退票
            message.success(trainRes?.msg)
        } else if (trainRes && trainRes.rc === 1) {
            Modal.warning({ content: `${trainRes?.msg}` })
        }
        return () => {
            // 卸载 火车 删state里的数据，清除状态
            deleteTrainResInfo()
        }
    }, [deleteTrainRes, trainRes])
    //taps切换
    const items = [
        { label: "火车", key: "1" },
        { label: "飞机", key: "2" },
        { label: "酒店", key: "3" },
    ]
    return (
        <>
            <PageHeader title={"订单列表"} ghost={false} />
            <div className="container">
                <Tabs
                    items={items}
                    defaultActiveKey={orderTabKey}
                    onChange={setTabKey}
                />
                {orderTabKey === "1" ? (
                    <OrderListInfo
                        orderType={orderTabKey}
                        trainChangeClick={_trainChange}
                        trainRefundClick={_trainRefund}
                    />
                ) : null}
                {orderTabKey === "2" ? (
                    <OrderListInfo
                        orderType={orderTabKey}
                        airplaneChangeClick={_airplaneChange}
                        airplaneRefundClick={_airplaneRefund}
                    />
                ) : null}
                {orderTabKey === "3" ? (
                    <OrderListInfo
                        orderType={orderTabKey}
                        hotelRefundClick={_hotelRefund}
                    />
                ) : null}
            </div>
        </>
    )
}
export const OrderListCpn = connect(
    mapStateToProps,
    mapDispatchToProps
)(_orderListCpn)
