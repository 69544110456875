import { Select } from "antd"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { EnvironmentOutlined } from "@ant-design/icons"
import { RootState } from "../../../../../app/store"
import { AirportCity } from "../../../../models"

const mapStateToProps = (state: RootState) => ({
    airportCity: state.booking.airportCity,
    locLoading: state.location.loading,
})

type Props = ReturnType<typeof mapStateToProps> & {
    onChange?: (id: string) => void
    mode?: "book" | "change"
    cityName?: string
    accountsEndData?: string
    accountsStartData?: string
} & {
    isStart: boolean
    isAirport?: boolean
}

const _selector = ({
    onChange,
    airportCity,
    isStart,
    mode,
    cityName,
    locLoading,
    isAirport,
    accountsEndData,
    accountsStartData
}: Props) => {
    const [subCity, setSubCity] = useState<AirportCity[]>()
    // 下拉菜单数据
    const [options, setOptions] = useState<JSX.Element[]>()

    useEffect(() => {
        if (subCity) {
            setOptions(
                subCity.map((c, idx) => (
                    <Select.Option
                        value={JSON.stringify(c)}
                        key={c.id + "-" + idx}>
                        {c.name}
                    </Select.Option>
                ))
            )
        } else {
            setOptions(undefined)
        }
    }, [subCity, mode])

    const handleSearch = (v: string) => {
        if (!v || v === "") {
            setSubCity(undefined)
        } else {
            if (isAirport) {
                setSubCity(
                    airportCity?.filter(
                        c =>
                            c.name.indexOf(v) !== -1 ||
                            c.ename.toLocaleLowerCase().indexOf(v) !== -1
                    )
                )
            } else {
                setSubCity(
                    airportCity?.find(it => it.isCity === true) &&
                    airportCity?.filter(it => it.isCity === true)?.filter(
                        c =>
                            c.name.indexOf(v) !== -1 ||
                            c.ename.toLocaleLowerCase().indexOf(v) !== -1
                    )
                )
            }

        }
    }

    return (
        <Select
            loading={locLoading === "pending"}
            style={{ width: "100%" }}
            disabled={!airportCity || mode === "change"}
            showSearch
            defaultValue={cityName || (!isStart ? accountsEndData : accountsStartData)}
            suffixIcon={<EnvironmentOutlined />}
            placeholder={isStart ? "请选择出发城市" : "请选择到达城市"}
            notFoundContent={null}
            filterOption={false}
            onSearch={handleSearch}
            onSelect={onChange}>
            {options}
            {!options && (
                <>
                    <Select.OptGroup label="城市">
                        {airportCity?.find(it => it.isCity === true) &&
                            airportCity
                                ?.filter(it => it.isCity === true)
                                .map((k, index) => (
                                    <Select.Option
                                        value={JSON.stringify(k)}
                                        key={"airportCity" + k.id + index}
                                        children={k?.name}
                                    />
                                ))}
                    </Select.OptGroup>
                    {isAirport && <Select.OptGroup label="机场">
                        {airportCity?.find(it => it.isCity === false) &&
                            airportCity
                                ?.filter(it => it.isCity === false)
                                .map((k, index) => (
                                    <Select.Option
                                        value={JSON.stringify(k)}
                                        key={"airportCity" + k.id + index}
                                        children={k?.name}
                                    />
                                ))}
                    </Select.OptGroup>}
                </>
            )}
        </Select>
    )
}

export const AirplaneCitySelector = connect(mapStateToProps)(_selector)
