import { Col, Row, Spin } from "antd"
import { ReactElement, useEffect, useState } from "react"
import { connect } from "react-redux"
import { Navigate, useNavigate, useSearchParams } from "react-router-dom"
import { useDebounce } from "react-use"
import { AppDispatch, RootState } from "../../app/store"
import {
    FetchTokenReq,
    fetchTokenViaAuthCode,
    jump,
    reset as resetLoginState,
} from "./loginSlice"
import { postLogin, reset as resetUserState } from "./userSlice"

const mapStateToProps = (state: RootState) => ({
    authError: state.login.authError,
    authorized: !!state.login.accessToken,
    redirectTo: state.user.redirectTo,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    reset: () => {
        dispatch(resetLoginState())
        dispatch(resetUserState())
    },
    fetchToken: (req: FetchTokenReq) => {
        dispatch(fetchTokenViaAuthCode(req))
    },
    jumpToOAuth: () => dispatch(jump()),
    dispatchPostLogin: (search: URLSearchParams) => {
        dispatch(postLogin(search))
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

const _login = ({
    fetchToken,
    jumpToOAuth,
    reset,
    dispatchPostLogin,
    authError,
    authorized,
    redirectTo,
}: Props): ReactElement => {
    const [error, setError] = useState<string>()
    const [authReq, setAuthReq] = useState<FetchTokenReq>()
    const [debouncedAuthReq, setDebouncedAuthReq] = useState<FetchTokenReq>()
    useDebounce(
        () => {
            setDebouncedAuthReq(authReq)
        },
        300,
        [authReq]
    )
    const navi = useNavigate()
    const [p] = useSearchParams()

    useEffect(() => {
        if (debouncedAuthReq) {
            fetchToken(debouncedAuthReq)
            setAuthReq(undefined)
        }
    }, [debouncedAuthReq, fetchToken])

    useEffect(() => {
        if (authorized) {
            dispatchPostLogin(p)
        } else if (authError) {
            setError(authError)
        } else {
            //获取token流程
            //code=pOX4z5ddh8nSlKCWy6aMZ&state=20211020151340
            const code = p.get("code")
            const state = p.get("state")
            const err = p.get("error")
            if (err) {
                setError(err)
            } else if (!code || !state) {
                reset()
                jumpToOAuth()
            } else {
                //callback
                setAuthReq({ code, state, uri: "" })
            }
        }
    }, [
        p,
        authorized,
        authError,
        reset,
        navi,
        jumpToOAuth,
        fetchToken,
        dispatchPostLogin,
    ])

    return (
        <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
            <Col>
                <Spin size="large" />
                {error && <Navigate to={"./error"} state={error} replace />}
                {redirectTo && <Navigate to={redirectTo} replace />}
            </Col>
        </Row>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(_login)
