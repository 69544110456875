import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../../app/store"
import { CommonLegacyResult, MLocation } from "../../../models"
import { accessTokenSelector } from "../../../user/loginSlice"
import {
    BookTrainCreateOrderParams,
    fetchBookTrainApi,
    fetchChangeTrainApi,
    fetchRefundTrainApi,
    findTrainListApi,
    SearchTrainListParams
} from "./bookTrainApi"

// 火车列表
export interface TrainItemVModel {
    id: string      //火车id
    fromCity: string     //出发城市名称
    toCity: string       //到达城市名称
    trainDate: string    //发车日期
    trainNo: string      //车次号
    fromTime: string     //出发时刻
    toTime: string       //到达时刻
    fromStation: string  //出发站名称
    toStation: string    //到达站名称
    runTimeSpan: string  //行驶时间(分钟)
    departureDate: string //出发日期
    arriveDate: string   //到达日期
    trainType: string    //车次类型
    trainTypeName: string  //车次类型名称
    saleDateTime: string  //预售时间
    seats: TrainSeatItemVModel[]
}

export interface TrainSeatItemVModel {
    id: string
    seatCode: string           //坐席等级码
    seatName: string           //坐席名称
    seatNum: string            //剩余座位数 “99代表票量充足、0代表暂无余票、其他数字为具体剩余票量”
    price: number              //价格
    upperPrice: number         //上铺价格
    lowerPrice: number         //下铺价格
    middleBerthPrice: number   //中铺价格
    serviceAmount: number      //服务费单价
    supplierId: string         //供应商id
    supplierName: string       //供应商名称
    packageId: string
}
interface BookTrainRootState {
    trainListLoading: boolean
    trainList?: TrainItemVModel[]
    trainRes?: CommonLegacyResult<unknown>
    refundLoading: boolean
    changeLoading: boolean
    bookLoading: boolean
    trainListFilterOpts: TrainListFilterOpts
    trainListByQueried: TrainItemVModel[]
    trainCityList?: MLocation[]
}

interface OptValue<T extends TrainItemVModel[keyof TrainItemVModel]> {
    value: T
    label: string
}

export interface TrainListFilterOpts {
    trainType: Array<OptValue<TrainItemVModel['trainType']>>,
    fromStation: Array<OptValue<TrainItemVModel['fromStation']>>,
    toStation: Array<OptValue<TrainItemVModel['toStation']>>,
    seatCode: Array<OptValue<TrainSeatItemVModel['seatCode']>>,
    fromTime?: Array<OptValue<TrainItemVModel['fromTime']>>,
    sortFromTime?: string,
}

export interface TrainListFilterOptsValues {
    trainTypeNames: Array<TrainItemVModel['trainType']>,
    trainStations: Array<TrainItemVModel['fromStation']>,
    traintoStations: Array<TrainItemVModel['toStation']>,
    trainSeatCodes: Array<TrainSeatItemVModel['seatCode']>,
    trainfromTime: Array<TrainItemVModel['fromTime']>,
    sortFromTime?: string,
    changedValue?: Pick<TrainListFilterOpts, 'sortFromTime'>
}


const initialState: BookTrainRootState = {
    trainListLoading: false,
    refundLoading: false,
    changeLoading: false,
    bookLoading: false,
    trainListByQueried: [],
    trainListFilterOpts: {
        trainType: [],
        fromStation: [],
        toStation: [],
        seatCode: [],
    },
}

// 查询火车列表
export const findTrainList = createAsyncThunk('/book/train/list',
    async (params: SearchTrainListParams, { getState, rejectWithValue, dispatch }) => {
        const token = accessTokenSelector(getState() as RootState)
        if (!token) return rejectWithValue(null)
        try {
            const trainList = await findTrainListApi(token, params)
            dispatch(fillFilterOptions(trainList))
            return trainList
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

// 火车下单
export const fetchBookTrain = createAsyncThunk('/book/train/creat/order',
    async (params: BookTrainCreateOrderParams, { getState, rejectWithValue }) => {
        const token = accessTokenSelector(getState() as RootState)
        if (!token) return rejectWithValue(null)
        try {
            const trainList = await fetchBookTrainApi(token, params)
            return trainList
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

// 火车改签
export const changeTrain = createAsyncThunk('/book/train/change',
    async (params: { oid: string; trainId: string; trainSeatId: string }, { getState, rejectWithValue }) => {
        const token = accessTokenSelector(getState() as RootState)
        if (!token) return rejectWithValue(null)
        try {
            const res = await fetchChangeTrainApi(token, params)
            return res
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

// 火车退票
export const fetchRefundTrain = createAsyncThunk('/book/train/refund',
    async (p: { oid: string }, { getState, rejectWithValue }) => {
        const token = accessTokenSelector(getState() as RootState)
        if (!token) return rejectWithValue(null)
        try {
            const res = await fetchRefundTrainApi(token, p)
            return res
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

const bookTrainInfoSlice = createSlice({
    name: "bookAirplane",
    initialState,
    reducers: {
        deleteTrainListInfo: (state) => { delete state.trainList },
        deleteTrainResInfo: (state) => { delete state.trainRes },
        fillFilterOptions: (state, { payload }: PayloadAction<TrainItemVModel[]>) => {
            // //车次类型名称
            const filtertrainTypeName = Array.from(new Set(payload.map((item) => item.trainTypeName)))
            const filtertrainType = Array.from(new Set(payload.map((item) => item.trainType)))
            state.trainListFilterOpts.trainType = filtertrainTypeName.map((label, i) => ({ label, value: filtertrainType[i] }))
            // // 出发站名称 fromStation
            const filterfromStation = Array.from(new Set(payload.map((item) => item.fromStation)))
            state.trainListFilterOpts.fromStation = filterfromStation.map((value, i) => ({ value, label: filterfromStation[i] }))
            // // 到达站名称 toStation
            const filterToStation = Array.from(new Set(payload.map((item) => item.toStation)))
            state.trainListFilterOpts.toStation = filterToStation.map((value, i) => ({ value, label: filterToStation[i] }))
            // 坐席名称 seatName 坐席等级码 seatCode
            const multipleSeatName = payload.map((value) => value.seats.map((item) => item.seatName))
            const filterSeatName = Array.from(new Set(multipleSeatName.reduce(function (a, b) { return a.concat(b) })))
            const multipleSeatCode = payload.map((value) => value.seats.map((item) => item.seatCode))
            const filterSeatCode = Array.from(new Set(multipleSeatCode.reduce(function (a, b) { return a.concat(b) })))
            state.trainListFilterOpts.seatCode = filterSeatName.map((label, i) => ({ label, value: filterSeatCode[i] }))
        },
        filterTrainList: (state, { payload }: PayloadAction<TrainListFilterOptsValues>) => {
            const { trainSeatCodes, trainStations, traintoStations, trainTypeNames, trainfromTime, sortFromTime, changedValue } = payload
            const filtertrainList = state.trainListByQueried.filter(item => {
                if (trainTypeNames?.length > 0)
                    return trainTypeNames.includes(item.trainType)
                else return true
            }).filter(item => {
                if (traintoStations?.length > 0)
                    return traintoStations.includes(item.toStation)
                else return true
            }).filter(item => {
                if (trainStations?.length > 0)
                    return trainStations.includes(item.fromStation)
                else return true
            }).filter(item => {
                if (trainSeatCodes?.length > 0) {
                    return item.seats.some((it) => {
                        return trainSeatCodes.includes(it.seatCode)
                    })
                }
                else return true
            }).filter(item => {
                if (trainfromTime?.length > 0) {
                    const LocaleDateString = new Date().toLocaleDateString()
                    const curTime = new Date(`${LocaleDateString} ${(item.fromTime ?? '')}`).getTime()
                    return trainfromTime.some(v => {
                        const [start, end] = v.split("-")
                        const startTimes = new Date(`${LocaleDateString} ${start}`).getTime()
                        const endTimes = new Date(`${LocaleDateString} ${end}`).getTime()
                        return curTime >= startTimes && curTime <= endTimes
                    })
                } else return true
            })
            state.trainList = filtertrainList
            if (changedValue?.sortFromTime) {
                if (sortFromTime === 'theearliest') {
                    filtertrainList.sort((a, b) => {
                        const LocaleDateString = new Date().toLocaleDateString()
                        return new Date(`${LocaleDateString} ${a.fromTime}`).getTime() - new Date(`${LocaleDateString} ${b.fromTime}`).getTime()
                    })
                } else if (sortFromTime === 'latest') {
                    filtertrainList.sort((a, b) => {
                        const LocaleDateString = new Date().toLocaleDateString()
                        return new Date(`${LocaleDateString} ${b.fromTime}`).getTime() - new Date(`${LocaleDateString} ${a.fromTime}`).getTime()

                    })
                } else if (sortFromTime === 'minimum') {
                    filtertrainList.sort((a, b) => {
                        return Number(a.runTimeSpan) - Number(b.runTimeSpan)
                    })
                }
            }
        },
        setTrainCityList: (state, { payload }: PayloadAction<MLocation[]>) => {
            state.trainCityList = payload
        },
    },
    extraReducers: (builder) => {
        builder
            // 查询火车列表
            .addCase(findTrainList.pending, (state) => {
                delete state.trainList
                state.trainListLoading = true
            })
            .addCase(findTrainList.rejected, (state) => {
                delete state.trainList
                state.trainListLoading = false
            })
            .addCase(findTrainList.fulfilled, (state, { payload }) => {
                state.trainListLoading = false
                state.trainList = payload as Array<TrainItemVModel>
                state.trainListByQueried = state.trainList
            })

            // 火车下单
            .addCase(fetchBookTrain.pending, (state) => { state.bookLoading = true })
            .addCase(fetchBookTrain.rejected, (state) => { state.bookLoading = false })
            .addCase(fetchBookTrain.fulfilled, (state, { payload }) => {
                state.trainRes = (payload as CommonLegacyResult<unknown>)
                state.bookLoading = false
            })

            // 火车改签
            .addCase(changeTrain.pending, (state) => { state.changeLoading = true })
            .addCase(changeTrain.rejected, (state) => { state.changeLoading = false })
            .addCase(changeTrain.fulfilled, (state, { payload }) => {
                state.trainRes = (payload as CommonLegacyResult<unknown>)
                state.changeLoading = false
            })

            // 火车退票
            .addCase(fetchRefundTrain.pending, (state) => { state.refundLoading = true })
            .addCase(fetchRefundTrain.rejected, (state) => { state.refundLoading = false })
            .addCase(fetchRefundTrain.fulfilled, (state, { payload }) => {
                state.trainRes = (payload as CommonLegacyResult<unknown>)
                state.refundLoading = false
            })
    }
})

export const { deleteTrainListInfo, deleteTrainResInfo, fillFilterOptions, filterTrainList, setTrainCityList } = bookTrainInfoSlice.actions
export default bookTrainInfoSlice.reducer
