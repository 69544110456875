import { ReactElement } from "react"
import { Route, Routes } from "react-router-dom"
import { TravellerEditor } from "./Editor"
import { TravellerList } from "./List"

const Traveller = (): ReactElement => (
    <Routes>
        <Route path="" element={<TravellerList />} />
        <Route path="new" element={<TravellerEditor />} />
    </Routes>
)

export default Traveller
