import { ReactElement, useEffect, useState } from "react"
import { connect } from "react-redux"
import { AppDispatch, RootState } from "../../app/store"
import { Modal, TableColumnsType, Typography } from "antd"
import { Button, Tabs, Table, message } from "antd"
import { PageHeader } from "@ant-design/pro-layout"
import { useNavigate } from "react-router-dom"
// import { chooseApprovalForm } from "../applyfor/detail/applyForDetailSlice"
import type { BatchOpType } from "./ApplyFormApprovalOpDialog"
import { ApplyFormApprovalOpDialog } from "./ApplyFormApprovalOpDialog"
import { ApplyFormTodo } from "./approvalApi"
import {
    fetchApplyFormTodoList,
    FetchApplyFormTodoListParams,
} from "./applyFormTodoListSlice"
import { showApprovalOpDialog } from "./applyFormApprovalSlice"

const columns: TableColumnsType<ApplyFormTodo> = [
    {
        title: "申请单号",
        dataIndex: "documentNum",
        responsive: ["lg"],
        width: 150,
    },
    {
        title: "提交人",
        dataIndex: "senderName",
        width: 100,
    },
    {
        title: "出差类型",
        dataIndex: "actionType",
        width: 200,
    },
    {
        title: "开始时间",
        dataIndex: "applyStartDate",
        width: 120,
    },
    {
        title: "结束时间",
        dataIndex: "applyEndDate",
        width: 120,
    },
    {
        title: "出行人数",
        dataIndex: "travellerCount",
        width: 100,
    },
    {
        title: "申请预算",
        dataIndex: "amount",
        width: 100,
    },
]

const mapStateToProps = (state: RootState) => ({
    loading: state.applyFormTodoList.loading,
    error: state.applyFormTodoList.error,
    approvalApplyInfo: state.applyFormTodoList.todoList,
    multiSelectEnabled: state.applyFormTodoList.multiSelectEnabled,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    dispatchFetchList: (arg: FetchApplyFormTodoListParams) => {
        dispatch(fetchApplyFormTodoList(arg))
    },
    dispatchShowOpDialog: (
        type: BatchOpType,
        selectedValues: Array<ApplyFormTodo>
    ) => {
        dispatch(showApprovalOpDialog({ type, selectedValues }))
    },
})

export type TabType = "todo" | "history"

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        tabKey?: TabType
    }

const _list = ({
    tabKey = "todo",
    loading,
    approvalApplyInfo,
    multiSelectEnabled,
    dispatchFetchList,
    dispatchShowOpDialog,
}: Props): ReactElement => {
    const navi = useNavigate()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>()
    const [selectedRowValues, setSelectedRowValues] = useState<ApplyFormTodo[]>(
        []
    )
    const [isShowBatchOps, setShowBatchOps] = useState(false)
    const [currentTab, setCurrentTab] = useState<TabType>(tabKey)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)

    const onSelectChange = (
        selectedRowKeys: React.Key[],
        selectedRows: ApplyFormTodo[]
    ) => {
        setSelectedRowKeys(selectedRowKeys)
        setSelectedRowValues(selectedRows)
    }

    useEffect(() => {
        //初始化和列表切换刷新
        dispatchFetchList({
            page: currentPage - 1,
            size: pageSize,
            executable: currentTab === "todo",
        })
    }, [dispatchFetchList, currentPage, pageSize, currentTab])

    const onBatchBtnClick = () => {
        if (isShowBatchOps) {
            setSelectedRowKeys(undefined)
            setSelectedRowValues([])
        }
        setShowBatchOps(!isShowBatchOps)
    }

    const onBatchOpClick = (type: BatchOpType) => {
        if (selectedRowValues.length === 0) {
            message.warning("请选择出差申请")
        } else {
            dispatchShowOpDialog(type, selectedRowValues)
            setSelectedRowValues([])
        }
    }

    const onBatchOpFinished = (err?: string[]) => {
        setShowBatchOps(false)
        if (!err) {
            message.success("批量审批完成")
        } else {
            Modal.error({
                title: "请求错误！",
                content: (
                    <Typography.Paragraph>
                        <ul>
                            {err.map((e, i) => (
                                <li key={i}>{e}</li>
                            ))}
                        </ul>
                    </Typography.Paragraph>
                ),
                centered: true,
            })
        }
        dispatchFetchList({
            page: currentPage - 1,
            size: pageSize,
            executable: currentTab === "todo",
        })
    }
    const items = [
        { label: "待办审批", key: "todo" }, // 务必填写 key
        { label: "历史审批", key: "history" },
    ]
    return (
        <div>
            <ApplyFormApprovalOpDialog onFinished={onBatchOpFinished} />

            <PageHeader
                ghost={false}
                title="审批申请单"
                extra={[
                    <Button
                        hidden={!isShowBatchOps}
                        key="accept"
                        type="primary"
                        ghost
                        onClick={() => {
                            onBatchOpClick("accept")
                        }}>
                        同意
                    </Button>,
                    <Button
                        hidden={!isShowBatchOps}
                        key="reject"
                        danger
                        onClick={() => {
                            onBatchOpClick("reject")
                        }}>
                        拒绝
                    </Button>,
                    <Button
                        hidden={!multiSelectEnabled || currentTab !== "todo"}
                        key="batchOperations"
                        onClick={onBatchBtnClick}>
                        {!isShowBatchOps ? "多选" : "取消"}
                    </Button>,
                ]}
            />

            <div className="container">
                <Tabs
                    key="tabs"
                    defaultActiveKey="todo"
                    items={items}
                    onChange={key => {
                        setCurrentTab(key as TabType)
                        setShowBatchOps(false)
                    }}
                />
                <Table<ApplyFormTodo>
                    loading={loading}
                    scroll={{ x: "max-content" }}
                    columns={columns}
                    rowKey="approvalId"
                    sticky={true}
                    pagination={
                        approvalApplyInfo && {
                            total: approvalApplyInfo?.totalElements,
                            pageSize,
                            defaultPageSize: pageSize,
                            current: currentPage,
                            onChange: (p, s) => {
                                setCurrentPage(p)
                                setPageSize(s)
                            },
                        }
                    }
                    rowSelection={
                        isShowBatchOps
                            ? {
                                  ...selectedRowKeys,
                                  preserveSelectedRowKeys: false,
                                  fixed: true,
                                  onChange: onSelectChange,
                              }
                            : undefined
                    }
                    dataSource={approvalApplyInfo?.content}
                    onRow={(record: ApplyFormTodo) => {
                        return {
                            className: "clickable",
                            onClick: () => {
                                if (!isShowBatchOps) {
                                    //避免在多选模式下点击条目时进入详情
                                    navi("/applyForm/" + record.documentId)
                                }
                            },
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(_list)
