import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { createTravellerApi, deleteTravellerApi, findAllApi, patchTravellerApi } from "./api"
import { accessTokenSelector } from "../user/loginSlice"
import { AsyncActionState, DataPage, PageRequest, Traveller } from "../models"
import { currentCompanyIdSelector } from "../user/userSlice"


export interface TravellerViewModel extends Traveller {
    sourceStr: string
    idTypeStr: string
}

interface TravellerListInfoState {
    isLoadingList: boolean
    isDeleting: boolean
    isCreating: boolean
    travellers?: DataPage<TravellerViewModel>
    updateState: AsyncActionState
    updateError?: string | number
}

const initialState: TravellerListInfoState = {
    isDeleting: false,
    isCreating: false,
    isLoadingList: false,
    updateState: 'idle',
}

//创建出行人
export const createTraveller = createAsyncThunk('traveller/create',
    async (t: Traveller, { getState, rejectWithValue }) => {
        const token = accessTokenSelector(getState() as RootState)
        if (!token) return rejectWithValue(undefined)
        try {
            return await createTravellerApi(token, t)
        } catch (_) {
            return rejectWithValue(undefined)
        }
    }
)

//查询出行人列表
export const findList = createAsyncThunk('traveller/fetchList',
    async (page: PageRequest, { getState, rejectWithValue }) => {
        const state = getState() as RootState
        const token = accessTokenSelector(state)
        const companyId = currentCompanyIdSelector(state)
        if (!token) {
            return rejectWithValue(undefined)
        }
        try {
            return await findAllApi(token, { companyId, ...page })
        } catch (_) {
            return rejectWithValue(undefined)
        }
    })

//删除出行人
export const deleteTraveller = createAsyncThunk('traveller/delete',
    async (id: string, { getState, rejectWithValue }) => {
        const token = accessTokenSelector(getState() as RootState)
        if (!token) {
            return rejectWithValue(undefined)
        }
        try {
            await deleteTravellerApi(token, id)
        } catch (_) {
            return rejectWithValue(undefined)
        }
    }
)
// 更新常用出行人
export const updateTraveller = createAsyncThunk('traveller/update',
    async ({ id, travellers }: { id: string, travellers: Traveller }, { getState, rejectWithValue }) => {
        const token = accessTokenSelector(getState() as RootState)
        if (!token) {
            return rejectWithValue(undefined)
        }
        try {
            await patchTravellerApi(token, id, travellers)
        } catch (_) {
            return rejectWithValue(undefined)
        }
    }
)

export const travellerToViewModel = (t: Traveller): TravellerViewModel => ({
    ...t,
    sourceStr: sourceToStr(t.source),
    idTypeStr: idTypeToStr(t.idType),

})

export const sourceToStr = (source: number): string => {
    switch (source) {
        case 0: return "本人"
        case 1: return "同事"
        case 2: return "外部人员"
        default: return "其他"
    }
}

export const idTypeToStr = (idType: number): string => {
    switch (idType) {
        case 0: return "身份证"
        case 1: return "护照"
        case 2: return "港澳通行证"
        case 3: return "台湾通行证"
        default: return "其他"
    }
}




const travellerListInfoSlice = createSlice({
    name: "traveller",//名字
    initialState,//初始值
    reducers: {
        resetUpdateState: (state) => { state.updateState = 'idle' }
    },
    extraReducers: builder => {//额外的action increment
        builder
            .addCase(findList.pending, (state) => { state.isLoadingList = true; delete state.travellers })
            .addCase(findList.rejected, (state) => { state.isLoadingList = false; delete state.travellers })
            .addCase(findList.fulfilled, (state, { payload }) => {
                state.isLoadingList = false
                state.travellers = { ...payload, content: payload.content?.map(travellerToViewModel) }
            })

            .addCase(deleteTraveller.pending, (state) => { state.isDeleting = true })
            .addCase(deleteTraveller.fulfilled, (state) => { state.isDeleting = false })
            .addCase(deleteTraveller.rejected, (state) => { state.isDeleting = false })


            .addCase(createTraveller.pending, (state) => { state.isCreating = true })
            .addCase(createTraveller.fulfilled, (state) => { state.isCreating = false })
            .addCase(createTraveller.rejected, (state) => { state.isCreating = false })

            .addCase(updateTraveller.pending, (state) => {
                state.updateState = 'pending'
                delete state.updateError
            })

            .addCase(updateTraveller.fulfilled, (state) => { state.updateState = 'fulfilled' })
            .addCase(updateTraveller.rejected, (state, { payload }) => {
                state.updateState = 'reject'
                state.updateError = payload as (number | string)
            })

    }
})
export const { resetUpdateState } = travellerListInfoSlice.actions
export default travellerListInfoSlice.reducer
