import { message, Modal, Typography } from "antd"
import { useEffect } from "react"
import { connect } from "react-redux"
import { AppDispatch, RootState } from "../../../app/store"
import { deleteApproval, resetOpsModal } from "../viewerSlice"



const mapStateToProps = (state: RootState) => ({
    state: state.reimburseFormViewer.operationState,
    error: state.reimburseFormViewer.operationError,
    id: state.reimburseFormViewer.detail?.reimburseId,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    dispatchReset: () => {
        dispatch(resetOpsModal())
    },
    // 撤回报销单
    dispatchDeleteApproval: (id: string) => {
        dispatch(deleteApproval(id))
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        visiable: boolean
        onCancel?: () => void
        onFinished?: () => void
    }

const _DelApproval = ({
    id,
    visiable,
    state,
    dispatchReset,
    onCancel,
    dispatchDeleteApproval,
    onFinished,
}: Props) => {
    const _onCancel = () => {
        dispatchReset()
        onCancel?.()
    }

    const onOk = () => {
        if (!id) {
            message.warning("缺少必要参数")
            onCancel?.()
            return
        }
        dispatchDeleteApproval(id)
    }

    useEffect(() => {
        if (visiable && state === "fulfilled") {
            message.success("撤回审批流程成功！")
            dispatchReset()
            onFinished?.()
        }
    })

    return (
        <Modal
            okText="撤回"
            okType="danger"
            open={visiable}
            onCancel={_onCancel}
            onOk={onOk}
            okButtonProps={{ loading: state === "pending" }}
            closable={state !== "pending"}>
            <Typography.Text>确定要撤回审批流程吗？</Typography.Text>
        </Modal>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(_DelApproval)
