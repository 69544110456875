import React, { useEffect, useState } from 'react'
import { Col, Form, Grid, Input, List, Modal, Row } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { CustomerList } from '../listSlice'
import { fetchCustomerList } from '../editorSlice'
import { useDebounce } from 'react-use'
import { idTypeToStr, sourceToStr } from '../../traveller/slice'

type Props = {
    onChange?: (value?: CustomerList[]) => void
    value?: CustomerList[]
}

function StaffUserSelector({ onChange, value }: Props) {

    //  个性化布局
    const { lg } = Grid.useBreakpoint()
    // 登录人数据
    const currentStaff = useAppSelector((state) => state.user.currentStaff)
    const staffIdList = useAppSelector(state => state.reimburseList.staffIdList)

    const [isModalOpen, setIsModalOpen] = useState(false)

    // 报销单详情数据edit
    const travellers = useAppSelector(state => state.reimburesEditor.reimburesForm?.extraInfo.applyForm?.travellers)

    // 创建报销单
    // 得判断是否关联了申请单关联了必须是处行人
    const getApplyForm = useAppSelector(state => state.reimburseList.getApplyForm)
    const dispatch = useAppDispatch()
    // 全公司人员
    const staffUserList = useAppSelector((state) => state.reimburesEditor.staffUserList)
    const [outValue, setOutvalue] = useState<string | undefined>()

    const pageSize = 50
    const [search, setSearch] = useState<string | undefined>()
    const [debouncedSearch, setDebouncedSearch] = useState<string>()
    const [staffUserName, setStaffUserName] = useState<string | undefined>('')

    // 处理防抖
    useDebounce(
        () => {
            setDebouncedSearch(search)
        },
        500, [search])

    useEffect(() => {
        value?.map(it => {
            if (it.exception) {
                return setStaffUserName(it.traveller?.name ?? '')
            } else {
                return setStaffUserName(staffIdList?.find(i => i.staffId === it.staffId)?.staffUserName ?? '')
            }
        })
    }, [staffIdList, value])

    useEffect(() => {
        if (!getApplyForm) {
            if (debouncedSearch) {
                dispatch(fetchCustomerList({ page: 0, size: pageSize, staffUserName: debouncedSearch }))
            } else {
                dispatch(fetchCustomerList({ page: 0, size: pageSize }))
            }
        }
    }, [debouncedSearch, dispatch, getApplyForm, pageSize, value])

    return (<>
        <Input
            value={staffUserName || outValue}
            onClick={() => {
                dispatch(fetchCustomerList({ page: 0, size: pageSize }))
                setIsModalOpen(true)
            }} />
        <Modal footer={null}
            width={lg ? '50%' : '100%'}
            styles={{ body: { overflow: 'auto', height: staffUserList ? '450px' : '300px' } }}
            title={<>
                <div>{!getApplyForm ? "选择归属人" : '出行人'}</div>
                {!getApplyForm && <Input onChange={e => setSearch?.(e.target.value)} placeholder="搜索同事" />}
            </>}
            open={isModalOpen}
            onCancel={() => {
                setSearch(undefined)
                setIsModalOpen(false)
            }} destroyOnClose>
            {
                (!getApplyForm) && <List
                    bordered
                    pagination={
                        staffUserList && {
                            total: staffUserList.totalElements,
                            current: staffUserList.page + 1,
                            pageSize: staffUserList.size,
                            onChange: (page, size) => {
                                dispatch(fetchCustomerList({
                                    page: page - 1,
                                    size: size || pageSize,
                                }))
                            },
                        }
                    }
                    dataSource={staffUserList?.content}
                    renderItem={(k) => (
                        <List.Item
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                const customerList = [{
                                    staffId: k.staffId,//不管选谁 staffid都是自己
                                    exception: false,
                                }]
                                onChange?.(customerList)
                                setIsModalOpen(false)
                                setOutvalue(k.staffUserName)
                            }}>
                            <Row gutter={[24, 24]} style={{ width: '100%' }}>
                                <Col flex='1'>
                                    {k?.staffUserName}
                                </Col>
                                <Col flex='1'>
                                    {k?.staffUserDepartment}
                                </Col>
                            </Row>
                        </List.Item>
                    )}
                />
            }
            {
                (getApplyForm && getApplyForm?.length > 0) && <List
                    bordered
                    dataSource={travellers || getApplyForm}
                    renderItem={(k) => (
                        <List.Item onClick={() => {
                            const customerList = [{
                                staffId: k.source === 1 ? k.bindId : (currentStaff?.staffId ?? ''),
                                exception: k.source === 2 ? true : false,
                                traveller: k.source === 2 ? k : undefined
                            }]
                            onChange?.(customerList)
                            setIsModalOpen(false)
                            setOutvalue(k.name)
                        }}>
                            <Row style={{ width: '100%' }}>
                                <Row style={{ width: '100%' }}>
                                    <Col flex='none'>{k?.name}</Col>
                                    <Col flex='1'>{k.phone}</Col>
                                </Row>
                                <Row style={{ width: '100%' }}>
                                    <Col flex='1'>{sourceToStr(k.source)}  </Col>
                                    <Col flex='1'>{k.gender === 0 ? "男" : "女"}</Col>
                                    <Col flex='1'>{idTypeToStr(k.idType)}</Col>
                                    <Col flex='1'>{k.idNumber}</Col>
                                </Row>
                            </Row>
                        </List.Item>
                    )}
                />
            }
        </Modal>
    </>
    )
}


export default function StaffUserItems() {
    const form = Form.useFormInstance()
    // 登录人
    const currentStaff = useAppSelector((state) => state.user.currentStaff)

    useEffect(() => {
        form.setFieldsValue({
            customerList: [{ staffId: currentStaff?.staffId, exception: false, }],
            staffId: currentStaff?.staffId,
        })

    }, [currentStaff?.staffId, form])

    return (
        <>
            <Form.Item
                label="归属人"
                name="customerList"
                rules={[{ required: true }]}
            >
                <StaffUserSelector />
            </Form.Item>
            <Form.Item name='staffId' hidden><Input /></Form.Item>
        </>
    )
}
