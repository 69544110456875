import { Drawer, Grid, Typography } from "antd"
import { useEffect, useState } from "react"
import type { Traveller } from "../../models"
import { setIsEditMode, type ApplyFormViewerMode } from "../viewerSlice"
import TraverllerEditor, { sourceStr, idNumberMarked } from "./TraverllerEditor"
import { idCardTypeList } from "../../traveller/Editor"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import TravellerViewer from "./TravellerViewer"

const { Paragraph, Text } = Typography
const { useBreakpoint } = Grid

type TraverllerViewerProps = {
    traveller: Traveller
    viewerMode?: ApplyFormViewerMode
    clickable: boolean
}

const TravellerInfo = ({
    traveller: t,
    viewerMode: mode,
    clickable,
}: TraverllerViewerProps): JSX.Element => {
    // Drawer开关
    const [visiable, setVisiable] = useState(false)
    const isEditMode = useAppSelector(state => state.applyFormViewer.isEditMode)
    const dispatch = useAppDispatch()
    const screens = useBreakpoint()

    useEffect(() => {
        if (visiable) {
            dispatch(setIsEditMode(false))
        }
    }, [dispatch, visiable])

    const onClick = (e?: React.MouseEvent) => {
        if (!clickable) {
            e?.preventDefault()
            return
        }
        if (!visiable) setVisiable(true)
    }

    return (
        <Paragraph
            onClick={onClick}
            style={{ cursor: clickable ? "pointer" : "default" }}>
            {
                <Drawer
                    width={screens.lg ? 640 : "75vw"}
                    style={{ backgroundColor: "#fafafa" }}
                    open={visiable}
                    onClose={() => {
                        setVisiable(false)
                    }}
                    destroyOnClose>
                    {isEditMode === false && (
                        <TravellerViewer
                            traveller={t}
                            editable={mode === "owner"}
                        />
                    )}
                    {isEditMode === true && <TraverllerEditor traveller={t} />}
                </Drawer>
            }
            {(t.idList?.length ?? 0) > 0 && (
                <div>
                    <Text underline={clickable} strong>
                        {t.name}
                    </Text>
                    <div>{sourceStr(t.source)}</div>
                    <div>
                        {
                            idCardTypeList().find(
                                it => it.oldIdCardType === t.idType
                            )?.displayName
                        }
                        &nbsp;{idNumberMarked(t.idNumber)}
                    </div>
                </div>
            )}
            {!t.idList && (
                <div>
                    **
                    <Text underline={clickable} strong>
                        {t.name}
                    </Text>
                    <div>{sourceStr(t.source)}</div>
                    <div>
                        {
                            idCardTypeList().find(
                                it => it.oldIdCardType === t.idType
                            )?.displayName
                        }
                        &nbsp;{idNumberMarked(t.idNumber)}
                    </div>
                </div>
            )}
        </Paragraph>
    )
}

export default TravellerInfo
