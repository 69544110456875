import { Route, Routes } from "react-router-dom"
import LoginError from "./LoginError"
import O2Login from "./O2Login"
import SSOLogin from "./SSOLogin"

/* 
    保利SSO登录逻辑：
    SSOLogin -> 重定向到后端 --> 保利OA登录 --> 重定向到后端 --> O2Login

    保利TODO登录逻辑：
    后端地址 ——> 保利OA --> 后端API --> SSOLogin --> O2Login --> ApplyForm
*/

export default function LoginRouter(): JSX.Element {
    return (
        <Routes>
            <Route path="" element={<O2Login />} />
            <Route path="error" element={<LoginError />} />
            <Route path="sso" element={<SSOLogin />} />
        </Routes>
    )
}
