import { AccessToken, ApplyForm, CommonLegacyResult, CommonPageConent, CustomField, DataPage, Flow, GetApplyFormByIdReturned, PageRequest, PutApprovalBody } from "../models"
import { OrderInfoList } from "../models/reimburesForm"
import { Accounts } from "../reimburseForm/listSlice"

export const findByIdApi = async ({ access_token }: AccessToken, id: string): Promise<GetApplyFormByIdReturned> => {
    const res = await fetch(`${window.SSCL_API}/rest/applyfor/${id}`, {
        mode: "cors",///跨域请求
        method: "GET",
        headers: {
            'Authorization': `Bearer ${access_token}`,
        },
    })
    if (!res.ok) {
        return Promise.reject(res.status)
    }
    const body = (await res.json()) as CommonLegacyResult<GetApplyFormByIdReturned>
    if (!body.data) return Promise.reject()
    return body.data
}

export const deleteApprovalByDocumentId = async ({ access_token }: AccessToken, documentId: string): Promise<void> => {
    const res = await fetch(`${window.SSCL_API}/approval/documentId/${documentId}`, {
        mode: "cors",
        method: "DELETE",
        headers: {
            'Authorization': `Bearer ${access_token}`,
        },
    })
    if (!res.ok) {
        return Promise.reject(res.status)
    }
}

export const deleteApplyFromByDocumentId = async ({ access_token }: AccessToken, documentId: string): Promise<void> => {
    const res = await fetch(`${window.SSCL_API}/rest/applyfor/${documentId}`, {
        mode: "cors",
        method: "DELETE",
        headers: {
            'Authorization': `Bearer ${access_token}`,
        },
    })
    if (!res.ok) {
        return Promise.reject(res.status)
    }
}

export const getFlowByDocumentId = async ({ access_token }: AccessToken, documentId: string): Promise<Flow> => {
    const res = await fetch(`${window.SSCL_API}/flow/documentId/${documentId}`, {
        mode: "cors",
        method: "GET",
        headers: {
            'Authorization': `Bearer ${access_token}`,
        },
    })
    if (!res.ok) {
        return Promise.reject(res.status)
    }
    const body = (await res.json()) as CommonLegacyResult<Flow>
    if (!body.data) return Promise.reject()
    return body.data
}

export const postApplyFromApproval = async ({ access_token }: AccessToken, documentId: string, targetList?: string[]): Promise<void> => {
    const res = await fetch(`${window.SSCL_API}/approval`, {
        mode: "cors",
        method: "POST",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json",
        },
        body: JSON.stringify({ documentId, targetList })
    })
    if (!res.ok) {
        return Promise.reject(res.status)
    }
}

export const putApproval = async ({ access_token }: AccessToken, approvalId: string, body: PutApprovalBody): Promise<void> => {
    console.log("🚀 ~ putApproval ~ body:", body)
    console.log("🚀 ~ putApproval ~ approvalId:", approvalId)
    const res = await fetch(`${window.SSCL_API}/approval/${approvalId}`, {
        mode: "cors",
        method: "PUT",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json",
        },
        body: JSON.stringify(body),
    })
    if (!res.ok) {
        return Promise.reject(res.status)
    }
}

export type ApprovalsStateType = 'approved' | "draft" | "submitted"
//获取已审批申请单列表
export const findWithLifeCycleApi = async ({ access_token }: AccessToken, query: { state?: ApprovalsStateType, companyId?: string } & PageRequest): Promise<DataPage<ApplyForm>> => {
    const search = new URLSearchParams()
    const { state, companyId, page, size } = query
    switch (state) {
        case 'approved': {
            search.append("approvalState", "1")
            break
        }
        case 'submitted': {
            search.append("approvalState", "0")
            search.append("approvalState", "1")
            search.append("approvalState", "2")
            break
        }
        case 'draft': {
            search.set("approvalState", "-1")
            break
        }
    }


    search.set('documentType', 'APPLY_TRAVEL')
    if (companyId) {
        search.set("companyId", companyId)
    }
    search.set("page", `${page}`)
    search.set("size", `${size}`)
    search.set("sort", "applyStartDate,desc")

    const res = await fetch(`${window.SSCL_API}/forms/applyForm/lifecycle?${search.toString()}`, {
        mode: "cors",
        method: "GET",
        headers: {
            'Authorization': `Bearer ${access_token}`,
        },
    })
    if (!res.ok) return Promise.reject(res.status)
    return await res.json()
}

//列表查询
export const findAllApi = async ({ access_token }: AccessToken, query: { state?: ApprovalsStateType, companyId?: string } & PageRequest): Promise<DataPage<ApplyForm>> => {
    const search = new URLSearchParams()
    const { state, companyId, page, size } = query
    switch (state) {
        case 'approved': {
            search.append("approvalState", "1")
            break
        }
        case 'submitted': {
            search.append("approvalState", "0")
            search.append("approvalState", "1")
            search.append("approvalState", "2")
            break
        }
        case 'draft': {
            search.set("approvalState", "-1")
            break
        }
    }

    if (companyId) {
        search.set("companyId", companyId)
    }
    search.set("page", `${page}`)
    search.set("size", `${size}`)
    search.set("sort", "createdAt,desc")

    const res = await fetch(`${window.SSCL_API}/forms/applyForm?${search.toString()}`, {
        mode: "cors",
        method: "GET",
        headers: {
            'Authorization': `Bearer ${access_token}`,
        },
    })
    if (!res.ok) return Promise.reject(res.status)
    return await res.json()
}

/**
 * @deprecated since 0.5.1
 */
type FindByApprovalStateSearch = { state: number[] } & PageRequest
/**
 * @deprecated since 0.5.1
 */
export const findByApprovalState = async ({ access_token }: AccessToken, { state, page, size }: FindByApprovalStateSearch): Promise<CommonPageConent<ApplyForm>> => {
    const p = new URLSearchParams()
    p.set("page", page + "")
    p.set("size", size + "")
    const res = await fetch(`${window.SSCL_API}/rest/applyfor/findByApprovalState/${state.join(",")}?${p.toString()}`, {
        mode: "cors",
        method: "GET",
        headers: {
            'Authorization': `Bearer ${access_token}`,
        },
    })
    if (!res.ok) {
        return Promise.reject(res.status)
    }
    const json = await res.json()
    if (!json) return Promise.reject("empty body")
    const data = (json as CommonLegacyResult<CommonPageConent<ApplyForm>>).data
    if (!data) return Promise.reject("empty data")
    return data
}

// 自定义字段配置
export const findCustomFieldByCompanyIdApi = async ({ access_token }: AccessToken, companyId: string): Promise<Array<CustomField>> => {
    const res = await fetch(`${window.SSCL_API}/rest/customField/companyId/${companyId}`, {
        mode: "cors",
        method: "GET",
        headers: {
            'Authorization': `Bearer ${access_token}`,
        },
    })
    if (!res.ok) {
        return Promise.reject(res.status)
    }
    const ret = (await res.json()) as CommonLegacyResult<Array<CustomField>>
    return ret.data ?? Promise.reject("empty data")
}

export const postApplyForm = async ({ access_token }: AccessToken, applyForm: ApplyForm): Promise<ApplyForm> => {
    const res = await fetch(`${window.SSCL_API}/forms/applyForm`, {
        mode: "cors",
        method: "POST",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(applyForm)
    })
    if (!res.ok) {
        return Promise.reject(res.status)
    }
    return await res.json()
}

export const patchApplyForm = async ({ access_token }: AccessToken, id: string, patchBody: unknown): Promise<ApplyForm> => {
    const res = await fetch(`${window.SSCL_API}/forms/applyForm/${id}`, {
        mode: "cors",
        method: "PATCH",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(patchBody)
    })
    if (!res.ok) {
        return Promise.reject(res.status)
    }
    return await res.json()
}

export const downloadPDF = async ({ access_token }: AccessToken, id: string): Promise<void> => {
    const res = await fetch(`${window.SSCL_API}/forms/applyForm/${id}/pdf`, {
        mode: "cors",
        method: "GET",
        headers: {
            'Authorization': `Bearer ${access_token}`,
        },
    })
    if (!res.ok) {
        return Promise.reject(res.status)
    }
    const fileName = res.headers.get("content-disposition")?.split('filename=')?.[1]?.replaceAll("\"", "") ?? `${id}.pdf`
    const blob = await res.blob()
    const a = document.createElement('a')
    a.href = URL.createObjectURL(blob)
    a.download = fileName
    a.click()
}

// 申请单更新预检接口 
export const updatePreCheckApi = async ({ access_token }: AccessToken, applyId: string, values: ApplyForm): Promise<void> => {
    const res = await fetch(`${window.SSCL_API}/forms/applyForm/${applyId}/precheck`, {
        mode: "cors",
        method: "PUT",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json",
        },
        body: JSON.stringify(values)
    })
    if (!res.ok) return Promise.reject(await res.json())
}
// 查询申请单关联账目 
export const getApplyFormAccount = async ({ access_token }: AccessToken, applyId: string): Promise<Accounts[]> => {
    const res = await fetch(`${window.SSCL_API}/forms/applyForm/${applyId}/account`, {
        mode: "cors",
        method: "GET",
        headers: {
            'Authorization': `Bearer ${access_token}`,
        },
    })
    if (!res.ok) {
        return Promise.reject(res.status)
    }
    const body = (await res.json()) as Accounts[]
    return body
}



export const getOrderInfo = async ({ access_token }: AccessToken, oid: string): Promise<OrderInfoList> => {
    const res = await fetch(`${window.SSCL_API}/rest/orderInfo/${oid}`, {
        mode: "cors",
        method: "GET",
        headers: {
            'Authorization': `Bearer ${access_token}`,
        },
    })
    if (!res.ok) {
        return Promise.reject(res.status)
    }
    const body = (await res.json()) as CommonLegacyResult<OrderInfoList>
    if (!body.data) return Promise.reject()
    return body.data
}