import { Checkbox, Col, DatePicker, Row, Typography } from "antd"
import { useState } from "react"
import dayjs, { Dayjs } from "dayjs"

type Props = {
    onChange?: (value: string) => void
    value?: string
    idType?: string
}

const { Text } = Typography

const LongTermDatePicker = ({ onChange, idType }: Props) => {
    const [checked, setChecked] = useState<boolean>(false)
    const [date, setDate] = useState<Dayjs | null>()

    // 时间发生变化触发
    const handleChange = (v: Dayjs | null | boolean) => {
        if (v === true) {
            setChecked(v)
            onChange?.("3000-01-01")
            setDate(null)
        } else if (v === false) {
            setChecked(v)
            onChange?.("")
        } else if (v === null) {
            onChange?.("")
        } else if (typeof v === "object") {
            setDate(v)
            onChange?.((v as Dayjs).format("YYYY-MM-DD"))
        }
    }
    // 设置不可选择的日期
    const disabledDate = (current: Dayjs) => {
        return current && current < dayjs().subtract(1, "days")
    }

    return (
        <Row align="middle">
            <Col flex="1">
                <DatePicker
                    disabledDate={disabledDate}
                    value={date}
                    onChange={handleChange}
                    disabled={idType === "ID_CARD" ? checked : false}
                    allowClear={false}
                    format="YYYY-MM-DD"
                    style={{ width: idType === "ID_CARD" ? "95%" : "100%" }}
                />
            </Col>
            <Col flex="none">
                <Text
                    style={{
                        display: idType === "ID_CARD" ? "block" : "none",
                    }}>
                    <Checkbox onChange={e => handleChange(e.target.checked)}>
                        <Text>长期</Text>
                    </Checkbox>
                </Text>
            </Col>
        </Row>
    )
}

export default LongTermDatePicker
