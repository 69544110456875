import { CommonLegacyResult, AccessToken } from '../../../models'
import { AirplaneCabinVModel, AirplaneFlightVModel } from './bookAirplaneSlice'
import { TravellerInPay } from '../payConfirm/BookSelectPassenger'

export interface SearchAirplaneListParams {
    fromCity?: string // 机场、城市三字码都可
    toCity?: string
    fromDepthPath?: string //出发
    toDepthPath?: string   //到达
    flightDate: string
    applyForId: string
}

export interface SearchChangeAirplaneListParams extends SearchAirplaneListParams {
    orderNumber: string
    ticketNum: string
    supplierId: string
}

export interface SearchAirplaneCabinListParams {
    toCity?: string
    fromCity?: string
    fromDepthPath?: string //从深度路径
    toDepthPath?: string   //到深度路径
    flightDate: string
    applyForId: string
    flightNum: string

    // 预订用
    shareFlag?: string
    shareFlightNum?: string

    // 改签用
    orderNumber?: string
    ticketNum?: string
    supplierId?: string
}

export interface BookAirplaneFlightValidateParams {
    fromDepthPath?: string
    toDepthPath?: string
    fromCity?: string
    toCity?: string
    flightDate?: string
    applyId?: string
    flightNum?: string
    cabinId?: string
    supplierId?: string
}

export interface ChangeAirplaneFlightValidateParams extends BookAirplaneFlightValidateParams {
    applyForId: string
    orderNumber: string
    ticketNum: string
}

export interface CreateOrderBookFlights {
    toCity?: string
    fromCity?: string
    fromDepthPath?: string
    toDepthPath?: string
    flightDate: string
    flightNum: string
    cabinId: string
}

export interface BookAirplaneFlightCreateOrderParams {
    applyId: string
    supplierId: string
    paymentFlag: string
    bookerNum: string
    bookerName: string
    bookerMobile: string
    bookerEmail: string
    orderFlights: Array<CreateOrderBookFlights>
    passengers: Array<TravellerInPay>
}

export interface ChangeAirplaneFlightCreateOrderParams extends CreateOrderBookFlights {
    applyForId: string
    supplierId: string
    orderNumber: string
    ticketNum: string
    refundOrChangeReasonNumber?: string
    refundOrChangeReasonExplain?: string
}

export interface RefundOrderFlightValidateParams {
    applyForId: string
    ticketNum: string
    orderNumber: string
    supplierId: string
}

export interface RefundAirplaneOrderParams {
    applyForId: string
    supplierId: string
    orderNumber: string
    ticketNum: string
    refundOrChangeReasonNumber?: string
    refundOrChangeReasonExplain?: string
    freewillFlag: string
}

export interface CreateFlightOrderSuccessResultVModel {
    createResult?: CreateFlightOrderSuccessVModel
}

interface CreateFlightOrderSuccessVModel {
    code: number
    orderAmount: number
    orderID: string
}

// 查询飞机列表
export const searchAirplaneListApi = async ({ access_token }: AccessToken, t: SearchAirplaneListParams): Promise<AirplaneFlightVModel[]> => {
    const res = await fetch(`${window.SSCL_PLATFORM_API}/platform/open/v1/flight`, {
        method: "POST",
        mode: "cors",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        },
        body: JSON.stringify(t)
    })

    if (!res.ok) return Promise.reject(res)

    const body = await res.json() as CommonLegacyResult<Array<AirplaneFlightVModel>>

    return body.data || []
}

// 查询飞机舱位列表
export const searchFlightCabinListApi = async ({ access_token }: AccessToken, t: SearchAirplaneCabinListParams): Promise<Array<AirplaneCabinVModel>> => {
    const res = await fetch(`${window.SSCL_PLATFORM_API}/platform/open/v1/flight/cabins`, {
        method: "POST",
        mode: "cors",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        },
        body: JSON.stringify(t)
    })
    if (!res.ok) return Promise.reject(res)
    const body = await res.json() as CommonLegacyResult<Array<AirplaneCabinVModel>>
    return body.data || []
}

// 航班价格验证
export const bookFlightValidateApi = async (accToken: AccessToken, t: BookAirplaneFlightValidateParams): Promise<CommonLegacyResult<{ resultFlag: string }>> => {
    const { access_token } = accToken
    const res = await fetch(`${window.SSCL_PLATFORM_API}/platform/open/v1/flightOrder/cabin/validate`, {
        method: "POST",
        mode: "cors",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        },
        body: JSON.stringify(t)
    })
    if (!res.ok) return Promise.reject(res)

    const body = await res.json() as CommonLegacyResult<{ resultFlag: string }>

    return body
}

// --机票提交订单接口
export const bookFlightCreateOrderApi = async ({ access_token }: AccessToken, t: BookAirplaneFlightCreateOrderParams): Promise<CommonLegacyResult<unknown>> => {
    const res = await fetch(`${window.SSCL_PLATFORM_API}/platform/open/v1/flightOrder/order/create`, {
        method: "POST",
        mode: "cors",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        },
        body: JSON.stringify(t)
    })
    if (!res.ok) return Promise.reject(res)

    const body = await res.json() as CommonLegacyResult<unknown>

    return body
}

//--------------飞机改签航班查询
export const searchChangeAirplaneListApi = async ({ access_token }: AccessToken, t: SearchChangeAirplaneListParams): Promise<CommonLegacyResult<AirplaneFlightVModel[]>> => {
    const res = await fetch(`${window.SSCL_PLATFORM_API}/platform/open/v1/flight/ticket/changes/flightChgSearch`, {
        method: "POST",
        mode: "cors",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        },
        body: JSON.stringify(t)
    })

    if (!res.ok) return Promise.reject(res)

    const body = await res.json() as CommonLegacyResult<Array<AirplaneFlightVModel>>

    return body
}

//-------------- 改签飞机舱位查询
export const searchChangeFlightCabinListApi = async ({ access_token }: AccessToken, t: SearchAirplaneCabinListParams): Promise<Array<AirplaneCabinVModel>> => {
    const res = await fetch(`${window.SSCL_PLATFORM_API}/platform/open/v1/flight/ticket/changes/flightCabinChgSearch`, {
        method: "POST",
        mode: "cors",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        },
        body: JSON.stringify(t)
    })
    if (!res.ok) return Promise.reject(res)
    const body = await res.json() as CommonLegacyResult<Array<AirplaneCabinVModel>>
    return body.data || []
}

//-------------- 飞机改签申请验证
export const searchChangeFlightVildateApi = async ({ access_token }: AccessToken, t: ChangeAirplaneFlightValidateParams): Promise<CommonLegacyResult<unknown>> => {
    const res = await fetch(`${window.SSCL_PLATFORM_API}/platform/open/v1/flight/ticket/changes/changeApplyCheck`, {
        method: "POST",
        mode: "cors",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        },
        body: JSON.stringify(t)
    })
    if (!res.ok) return Promise.reject(res)
    const body = await res.json() as CommonLegacyResult<unknown>
    return body
}

// --------------飞机改签申请 
export const changeFlightCreateOrderApi = async ({ access_token }: AccessToken, t: ChangeAirplaneFlightCreateOrderParams): Promise<CommonLegacyResult<unknown>> => {
    const res = await fetch(`${window.SSCL_PLATFORM_API}/platform/open/v1/flight/ticket/changes/changeApply`, {
        method: "POST",
        mode: "cors",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        },
        body: JSON.stringify(t)
    })
    if (!res.ok) return Promise.reject(res)

    const body = await res.json() as CommonLegacyResult<unknown>

    return body
}

//-------------- 退票验证 
export const refundFlightValidateApi = async ({ access_token }: AccessToken, t: RefundOrderFlightValidateParams): Promise<CommonLegacyResult<{ flag: string }>> => {
    const res = await fetch(`${window.SSCL_PLATFORM_API}/platform/open/v1/flight/ticket/refunds/refundCheck`, {
        method: "POST",
        mode: "cors",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        },
        body: JSON.stringify(t)
    })
    if (!res.ok) return Promise.reject(res)
    const body = await res.json() as CommonLegacyResult<{ flag: string }>
    return body
}

//-------------- 飞机退票申请
export const refundFlightOrderApi = async ({ access_token }: AccessToken, t: RefundAirplaneOrderParams): Promise<CommonLegacyResult<unknown>> => {
    const res = await fetch(`${window.SSCL_PLATFORM_API}/platform/open/v1/flight/ticket/refunds/refundApply`, {
        method: "POST",
        mode: "cors",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        },
        body: JSON.stringify(t)
    })
    if (!res.ok) return Promise.reject(res)
    const body = await res.json() as CommonLegacyResult<unknown>
    return body
}
