import { Modal } from "antd"
import { CommonLegacyResult, AccessToken } from "../../../models"
import { TrainItemVModel } from "./bookTrainSlice"

export interface SearchTrainListParams {
    fromStation: string
    toStation: string
    trainDate: string
    applyForId?: string
}

export interface BookTrainCreateOrderParams {
    applyForId: string
    supplierId: string
    bookerNum: string
    bookerName: string
    bookerMobile: string
    trainId: string
    trainSeatId: string
    passengers: unknown[]
    chooseSeats: string
    train12306Account: {
        account12306: string
        pwd12306: string
        verificationCode?: string
    }
}

// 查询火车列表
export async function findTrainListApi({ access_token }: AccessToken, t: SearchTrainListParams): Promise<TrainItemVModel[]> {
    const res = await fetch(`${window.SSCL_PLATFORM_API}/platform/open/v1/train/tickets`, {
        mode: "cors",
        method: "POST",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        },
        body: JSON.stringify(t)
    })
    if (!res.ok) return Promise.reject(res)

    const body = await res.json() as CommonLegacyResult<TrainItemVModel[]>
    if (body.rc !== 0) {
        Modal.warning({ content: `${body.msg}` })
        return Promise.reject(null)
    }
    const trainList = body.data
    if (!trainList || trainList.length === 0) return Promise.reject(null)
    return trainList
}

// 创建火车订单 下单
export const fetchBookTrainApi = async ({ access_token }: AccessToken, t: BookTrainCreateOrderParams): Promise<CommonLegacyResult<unknown>> => {
    const res = await fetch(`${window.SSCL_PLATFORM_API}/platform/open/v1/train/orderApply`, {
        method: "POST",
        mode: "cors",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        },
        body: JSON.stringify(t)
    })
    if (!res.ok) return Promise.reject(res)

    const body = await res.json() as CommonLegacyResult<unknown>

    return body
}

// -------------- 改签  
export const fetchChangeTrainApi = async ({ access_token }: AccessToken, t: { oid: string, trainId: string, trainSeatId: string }): Promise<CommonLegacyResult<unknown>> => {
    const res = await fetch(`${window.SSCL_PLATFORM_API}/platform/open/v1/train/ticketChange`, {
        method: "POST",
        mode: "cors",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        },
        body: JSON.stringify(t)
    })
    if (!res.ok) return Promise.reject(res)

    const body = await res.json() as CommonLegacyResult<unknown>

    return body
}

//-------------- 退票
export const fetchRefundTrainApi = async ({ access_token }: AccessToken, p: { oid: string }): Promise<CommonLegacyResult<unknown>> => {
    const res = await fetch(`${window.SSCL_PLATFORM_API}/platform/open/v1/train/ticketRefund`, {
        method: "POST",
        mode: "cors",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        },
        body: JSON.stringify(p)
    })
    if (!res.ok) return Promise.reject(res)
    const body = await res.json() as CommonLegacyResult<unknown>
    return body
}
