import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../../app/store"
import { AsyncActionState, Project } from "../../models"
import { getRecommendProject, searchProjectByKey } from "../../project/api"
import { accessTokenSelector } from "../../user/loginSlice"
import { currentCompanyIdSelector } from "../../user/userSlice"

interface State {
    loading: AsyncActionState
    recommendList?: Array<Project>
    searchList?: Array<Project>
    error?: string | number
}

const initialState: State = {
    loading: 'idle'
}

export const fetchRecommendList = createAsyncThunk('applyFormEditor/project/fetchRecommendList',
    async (_, { getState, rejectWithValue }): Promise<Project> => {
        const state = getState() as RootState
        const token = accessTokenSelector(state)
        const companyId = currentCompanyIdSelector(state)

        if (!token || !companyId) throw rejectWithValue(401)
        try {
            return await getRecommendProject(token, companyId)
        } catch (err) {
            if (typeof err === "number" || typeof err === "string")
                throw rejectWithValue(err)
            else throw rejectWithValue("unknown err")
        }
    })

export const fetchSearchByKey = createAsyncThunk('applyFormEditor/project/fetchSearchByKey',
    async (key: string, { getState, rejectWithValue }): Promise<Project[]> => {
        const state = getState() as RootState
        const token = accessTokenSelector(state)
        const companyId = currentCompanyIdSelector(state)

        if (!token || !companyId) throw rejectWithValue(401)
        try {
            return await searchProjectByKey(token, { companyId, key, onlyEnabled: true })
        } catch (err) {
            if (typeof err === "number" || typeof err === "string")
                throw rejectWithValue(err)
            else throw rejectWithValue("unknown err")
        }
    })

const slice = createSlice({
    name: 'applyFormEditor/project',
    initialState,
    reducers: {
        resetSearch: (state) => {
            return {
                loading: 'idle',
                recommendList: state.recommendList
            }
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchRecommendList.pending, state => {
                state.loading = "pending"
                delete state.recommendList
                delete state.error
            })
            .addCase(fetchRecommendList.fulfilled, (state, { payload }) => {
                state.loading = "fulfilled"
                state.recommendList = [payload]
            })
            .addCase(fetchRecommendList.rejected, (state, { payload }) => {
                state.loading = "idle"
                state.error = payload as (string | number)
            })
            // ---
            .addCase(fetchSearchByKey.pending, state => {
                state.loading = "pending"
                delete state.searchList
                delete state.error
            })
            .addCase(fetchSearchByKey.fulfilled, (state, { payload }) => {
                state.loading = "fulfilled"
                state.searchList = payload
            })
            .addCase(fetchSearchByKey.rejected, (state, { payload }) => {
                state.loading = "idle"
                state.error = payload as (string | number)
            })
    }
})

export const { resetSearch } = slice.actions
export default slice.reducer
