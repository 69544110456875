import { ReactElement, useState, useEffect } from "react"
import { Navigate } from "react-router-dom"
import {
    Button,
    Modal,
    Form,
    Input,
    message,
    Typography,
    Card,
    Alert,
    Space,
} from "antd"
import { AppDispatch, RootState } from "../../../../app/store"
import {
    deleteChangeRefundValidateResInfo,
    deleteOrderResInfo,
    deleteValidateResInfo,
    airplaneCabinValidate,
    changeFlightCreateOrder,
    airplaneChangeValidate,
    airplaneCreateOrder,
} from "../services/bookAirplaneSlice"
import {
    BookAirplaneFlightCreateOrderParams,
    BookAirplaneFlightValidateParams,
    ChangeAirplaneFlightCreateOrderParams,
    ChangeAirplaneFlightValidateParams,
} from "../services/bookAirplaneApi"
import { connect } from "react-redux"
import { BookSelectPassenger } from "./BookSelectPassenger"
import { ShowPassenger } from "./ShowPassenger"
import { useNavigate } from "react-router-dom"

const mapStateToProps = (state: RootState) => ({
    loading: state.bookAirplane.bookLoading,
    validateRes: state.bookAirplane.validateRes,
    orderRes: state.bookAirplane.orderRes,
    staff: state.user.currentStaff,
    changeValidateRes: state.bookAirplane.changeRefundValidateRes,
    applyFor: state.booking.applyForm,
    planeOrderModel: state.bookAirplane.planeOrderModel,
    mode: state.bookAirplane.airplaneParameters?.mode,
    cabin: state.bookAirplane.airplaneParameters?.cabin,
    flight: state.bookAirplane.airplaneParameters?.flight,
    orderPayNotice: state.location.tipMessage?.orderPayNotice,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    // 飞机价格校验
    flightValidate: (params: BookAirplaneFlightValidateParams) => {
        dispatch(airplaneCabinValidate(params))
    },
    // 创建机票提交订单
    flightCreateOrder: (params: BookAirplaneFlightCreateOrderParams) => {
        dispatch(airplaneCreateOrder(params))
    },
    // 清除订单资源状态
    deleteOrderRes: () => {
        dispatch(deleteOrderResInfo())
    },
    // 清除价格校验结果状态
    deleteValidateRes: () => {
        dispatch(deleteValidateResInfo())
    },
    // 飞机改签校验
    changeFlightValidate: (params: ChangeAirplaneFlightValidateParams) => {
        dispatch(airplaneChangeValidate(params))
    },
    // 飞机改签
    airplaneChangeOrder: (params: ChangeAirplaneFlightCreateOrderParams) => {
        dispatch(changeFlightCreateOrder(params))
    },
    // 清除退票校验和改签校验请求状态
    deleteChangeValiidateRes: () => {
        dispatch(deleteChangeRefundValidateResInfo())
    },
})

export interface ParamData {
    handlePassenger?: (passengerNum: number) => void
}

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    ParamData

const _airplanePayConfirm = (props: Props): ReactElement => {
    const {
        loading,
        flightValidate,
        flightCreateOrder,
        planeOrderModel,
        mode,
        cabin,
        flight,
        orderPayNotice,
        validateRes,
        staff,
        orderRes,
        deleteOrderRes,
        deleteValidateRes,
        changeFlightValidate,
        airplaneChangeOrder,
        changeValidateRes,
        deleteChangeValiidateRes,
        applyFor,
        handlePassenger,
    } = props

    const navi = useNavigate()
    // 创建 Form 实例，用于管理所有数据状态。
    const [form] = Form.useForm()
    const { Text, Title } = Typography
    // 提示信息开关
    const [tipsvisible, setTipsvisible] = useState(true)

    // 支付确认弹框是否显示
    const [visible, setVisible] = useState<boolean>(false)
    // 存储联系人手机、邮箱
    const [values, setValues] = useState<{
        bookerMobile?: string
        bookerEmail?: string
    }>()
    // modal content显示，票价变动需要修改
    const [modalContent, setModalContent] = useState<string>("确定要支付吗？")
    // 1.飞机价格校验
    const params: BookAirplaneFlightValidateParams = {
        fromCity: flight?.takeOffCity,
        toCity: flight?.toCity,
        flightDate: flight?.flightDate,
        flightNum: flight?.flightNum,
        cabinId: cabin?.id,
        supplierId: cabin?.supplierId,
        applyId: applyFor?.applyId,
    }

    // 去支付
    const confirm = (values: BookAirplaneFlightCreateOrderParams) => {
        // 选中的乘机人
        if (
            (!values.passengers || values.passengers.length <= 0) &&
            mode !== "change"
        ) {
            return message.warning("请选择至少一位乘机人")
        }
        setModalContent(
            `${mode === "change" ? "确定要改签吗？" : "确定要支付吗？"}`
        )
        setVisible(true) // 显示支付确认框
        setValues(values)
    }

    useEffect(() => {
        if (!applyFor && mode === "book") {
            <Navigate to=".." />
        }
    }, [applyFor, mode])

    useEffect(() => {
        // 预订
        if (mode !== "change") {
            const orderFlights = [
                {
                    fromDepthPath: flight?.fromDepthPath || "",
                    toDepthPath: flight?.toDepthPath || "",
                    flightDate: flight?.flightDate || "",
                    flightNum: flight?.flightNum || "",
                    cabinId: cabin?.id || "",
                },
            ]
            // 2. 创建订单
            const createParams: BookAirplaneFlightCreateOrderParams = {
                applyId: applyFor?.applyId || "",
                supplierId: cabin?.supplierId || "",
                paymentFlag: "Y",
                bookerNum: staff?.staffId || "",
                bookerName: staff?.staffUserName || "",
                bookerMobile: values?.bookerMobile || "",
                bookerEmail: values?.bookerEmail || "",
                orderFlights: orderFlights || [],
                passengers: form.getFieldValue("passengers"),
            }
            if (validateRes?.rc === 0) {
                //验价成功
                setModalContent("验价成功，支付中。。。")
                // 创建机票提交订单
                flightCreateOrder(createParams)
            } else if (validateRes) {
                //验价失败
                // 关闭支付确认框
                setVisible(false)
                if (validateRes?.data?.resultFlag === "C") {
                    // 提示用户 票价变动
                    Modal.confirm({
                        content: `票价已变为¥${validateRes.data.resultPrice}，是否接受新价格预定？`,
                        onOk: () => {
                            setModalContent("验价成功，支付中。。。")
                            // 创建机票提交订单
                            flightCreateOrder(createParams)
                            setVisible(true)
                        },
                    })
                }
            }
        }

        return () => {
            //即将卸载时执行清除下单校验结果
            deleteValidateRes()
        }
    }, [
        applyFor?.applyId,
        cabin?.id,
        cabin?.supplierId,
        deleteValidateRes,
        flight?.flightDate,
        flight?.flightNum,
        flight?.fromDepthPath,
        flight?.toDepthPath,
        flightCreateOrder,
        form,
        mode,
        staff?.staffId,
        staff?.staffUserName,
        validateRes,
        values?.bookerEmail,
        values?.bookerMobile,
    ])

    useEffect(() => {
        if (mode === "change") {
            // 改签
            const param: ChangeAirplaneFlightCreateOrderParams = {
                applyForId: planeOrderModel?.applyId || "",
                supplierId: cabin?.supplierId || "",
                orderNumber: planeOrderModel?.customId || "",
                ticketNum: planeOrderModel?.ticketNum || "",
                fromCity: flight?.takeOffCity,
                toCity: flight?.toCity,
                fromDepthPath: flight?.fromDepthPath,
                toDepthPath: flight?.toDepthPath,
                flightDate: flight?.flightDate || "",
                flightNum: flight?.flightNum || "",
                cabinId: cabin?.id || "",
                refundOrChangeReasonNumber: planeOrderModel?.reasonCode,
                refundOrChangeReasonExplain: planeOrderModel?.reason,
            }
            if (changeValidateRes && changeValidateRes?.rc === 0) {
                //验价成功
                setModalContent("改签验价成功，改签中。。。")
                // 飞机改签
                airplaneChangeOrder(param)
            } else if (changeValidateRes) {
                Modal.warning({ content: `${changeValidateRes?.msg}` })
                // 关闭支付确认框
                setVisible(false)
                if (changeValidateRes?.data?.flag === "N") {
                    // 提示用户 无法改签
                    Modal.warning({ content: `该机票无法改签` })
                } else {
                    // 关闭支付确认框
                    setVisible(false)
                }
            }
        }
        return () => {
            // 即将卸载清除退票校验或改签校验请求状态
            deleteChangeValiidateRes()
        }
    }, [
        airplaneChangeOrder,
        cabin?.id,
        cabin?.supplierId,
        changeValidateRes,
        deleteChangeValiidateRes,
        flight?.flightDate,
        flight?.flightNum,
        flight?.fromDepthPath,
        flight?.takeOffCity,
        flight?.toCity,
        flight?.toDepthPath,
        mode,
        planeOrderModel?.applyId,
        planeOrderModel?.customId,
        planeOrderModel?.reason,
        planeOrderModel?.reasonCode,
        planeOrderModel?.ticketNum,
    ])

    useEffect(() => {
        if (orderRes && orderRes?.rc === 0) {
            //预订成功
            // 跳转问题
            const tmpState = {
                ...orderRes,
                mode: mode,
            }
            navi("/booking/airplane/paysuccess", {
                state: tmpState,
                replace: true,
            })
        } else if (orderRes) {
            Modal.warning({ content: `${orderRes?.msg}` })
            // 关闭支付确认框
            setVisible(false)
        }
        return () => {
            //卸载清除创建订单资源状态
            deleteOrderRes()
        }
    }, [orderRes, navi, deleteOrderRes, mode])

    const handleOk = () => {
        if (mode === "change" && planeOrderModel) {
            // 改签
            const param: ChangeAirplaneFlightValidateParams = {
                applyForId: planeOrderModel?.applyId || "",
                orderNumber: planeOrderModel.customId || "",
                ticketNum: planeOrderModel.ticketNum || "",
                fromCity: flight?.takeOffCity,
                toCity: flight?.toCity,
                fromDepthPath: flight?.fromDepthPath,
                toDepthPath: flight?.toDepthPath,
                flightDate: flight?.flightDate, // 起飞日期
                flightNum: flight?.flightNum,
                cabinId: cabin?.id,
                supplierId: cabin?.supplierId,
            }
            // 飞机改签校验
            changeFlightValidate(param)
        } else {
            // 预订
            // 航班验证
            flightValidate(params)
        }
    }

    const handleCancel = () => {
        // 关闭支付确认框
        setVisible(false)
    }
    // 字段值更新时触发回调事件
    const handleValuesChange = (
        _cv: unknown,
        values: BookAirplaneFlightCreateOrderParams
    ) => {
        handlePassenger?.(values.passengers?.length ?? 0)
    }

    return (
        <div className="container">
            {orderPayNotice
                ? tipsvisible && (
                    <Alert
                        type="warning"
                        message={orderPayNotice}
                        closable
                        afterClose={() => setTipsvisible(false)}
                    />
                )
                : null}
            <Card
                size="small"
                bordered={false}
                styles={{ body: { padding: "20px 8px 0px 8px" } }}>
                <Space align="baseline">
                    <Title level={5}>乘机人</Title>
                    <Text type="secondary" style={{ fontSize: 16 }}>
                        只限申请单内人员
                    </Text>
                </Space>
            </Card>
            <Form
                form={form}
                scrollToFirstError={true}
                labelCol={{ span: 3 }}
                onFinish={confirm}
                onValuesChange={handleValuesChange}>
                {mode === "book" ? (
                    <Form.Item
                        name="passengers"
                        rules={[
                            {
                                type: "array",
                                min: 1,
                                message: "至少选择一位乘车人",
                            },
                        ]}>
                        <BookSelectPassenger
                            traveller={applyFor?.travellers || []}
                        />
                    </Form.Item>
                ) : (
                    <ShowPassenger
                        name={planeOrderModel?.passenger?.passengerName || ""}
                    />
                )}
                <Card
                    size="small"
                    bordered={false}
                    styles={{ body: { padding:"0px 8px 15px 8px"} }}>
                    <Title level={5}>联系人</Title>
                </Card>
                <Form.Item
                    label="姓名"
                    initialValue={staff?.staffUserName}
                    name="bookerName"
                    rules={[{ required: true }]}>
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    label="电话"
                    name="bookerMobile"
                    initialValue={
                        mode === "change"
                            ? planeOrderModel?.passenger?.passengerMobile
                            : staff?.staffUserPhone
                    }
                    rules={[{ required: true }]}>
                    <Input
                        disabled={mode === "change"}
                        placeholder="请输入联系人电话号码"
                    />
                </Form.Item>

                <Form.Item
                    label="邮箱"
                    name="bookerEmail"
                    initialValue={
                        mode === "change"
                            ? planeOrderModel?.passenger?.passengerEmail
                            : staff?.staffUserEmail
                    }
                    rules={[{ required: true }]}>
                    <Input
                        disabled={mode === "change"}
                        placeholder="请输入联系人电子邮箱"
                    />
                </Form.Item>
            </Form>

            <Button
                htmlType="submit"
                type="primary"
                block
                size="large"
                onClick={() => {
                    form.submit()
                }}>{`${mode === "change" ? "确认改签" : "去支付"}`}</Button>
            <Modal
                title={`${mode === "change" ? "改签确认" : "支付确认"}`}
                open={visible}
                onOk={handleOk}
                destroyOnClose
                confirmLoading={loading}
                onCancel={handleCancel}>
                <p>{modalContent}</p>
            </Modal>
        </div>
    )
}

export const AirplanePayConfirm = connect(
    mapStateToProps,
    mapDispatchToProps
)(_airplanePayConfirm)
