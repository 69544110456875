import { ReactElement } from "react"
import { Route, Routes } from "react-router-dom"
import { OrderListCpn } from "./Order"
import { OrderDetailComponent } from "./detail/OrderDetail"
import { RefundTicket } from "./change/RefundTicket"
import { TicketOperate } from "./change/TrainTicketOperate"

const OrderListRouter = (): ReactElement => {
    return (
        <Routes>
            <Route path="" element={<OrderListCpn />} />
            <Route path="detail" element={<OrderDetailComponent />} />
            <Route path="detail/operate/:id" element={<RefundTicket />} />
            <Route path="train/operate/:id" element={<TicketOperate />} />
        </Routes>
    )
}

export default OrderListRouter
