import { Card, Row, Col, Typography, Space } from "antd"
import { ReactElement } from "react"
import { hotelOrderStatusToStr } from "../../../book/const"

import { HotelOrderListModel } from "../../service/orderListModel"
import {
    OrderListItemHeaderCpn,
    OrderListItemPriceGroupCpn,
} from "../view/OrderListItemCommon"
import { ShowStateOperate } from "../../common/view/OrderStateOperate"
import { useNavigate } from "react-router-dom"

interface Props {
    hotelItem: HotelOrderListModel
    refundClick?: () => void
}
const { Text, Paragraph } = Typography
export const HotelListCardItem = (props: Props): ReactElement => {
    const { hotelItem, refundClick } = props
    const {
        customId,
        orderTime,
        userName,
        total,
        orderState,
        hotelName,
        roomType,
        roomCount,
        night,
        startTime,
        endTime,
        packageId,
    } = hotelItem
    // 路由跳转传参
    const navi = useNavigate()
    const keywordClick = (item: HotelOrderListModel) => {
        // 跳转到OrderDetail.tsx
        navi("/order/list/detail", { state: item })
    }

    const _hotelRefundClick = () => {
        // 酒店退票
        refundClick?.()
    }

    return (
        <Card
            type="inner"
            bordered={false}
            title={
                <OrderListItemHeaderCpn
                    publicItem={hotelItem}
                    customId={customId}
                    orderTime={orderTime}
                    packageId={packageId}
                />
            }>
            <Row
                className="clickable"
                onClick={() => {
                    keywordClick(hotelItem)
                }}>
                <Col flex="2">
                    <Space direction="vertical" size={2}>
                        <Paragraph
                            ellipsis={{ rows: 2, tooltip: hotelName }}
                            style={{
                                maxWidth: 140,
                                fontSize: 16,
                                marginBottom: 0,
                            }}
                            strong>
                            {hotelName}
                        </Paragraph>
                        <Text>
                            入离时间：{startTime}-{endTime}
                        </Text>
                        <Text>入住人：{userName}</Text>
                        <Text>{roomCount + "间" + night + "晚"}</Text>
                        <Text>{roomType}</Text>
                    </Space>
                </Col>
                <Col flex="none">
                    <OrderListItemPriceGroupCpn
                        total={total}
                        status={hotelOrderStatusToStr(orderState)}
                    />
                </Col>
            </Row>
            <ShowStateOperate
                orderModel={hotelItem}
                hotelRefundClick={_hotelRefundClick}
            />
        </Card>
    )
}
