import { DocumentType } from "./"

export interface CustomFieldInstance {
    fieldId?: string,
    fieldName?: string,
    orderNum: number
    fieldValues?: Array<string>,
}


export interface Airplane {
    f: boolean //头等舱
    c: boolean //公务舱
    y: boolean //经济仓

}

export interface Train {
    specialSeat: boolean //商务座/特等座
    firstClassSeat: boolean //一等座
    secondClassSeat: boolean //二等座
    premiumSoftSleeper: boolean //高级软卧
    softSleeper: boolean //软卧/一等卧
    muSleeper: boolean //动卧
    hardSleeper: boolean //二等卧/硬卧
    softSeat: boolean //软座
    hardSeat: boolean //硬座
    noSeat: boolean //无座
    other: boolean
}

export interface TravelDetail {
    airplane: Airplane
    train: Train
    hotel: number
}

export interface Traveller {
    name: string,
    source: number,//来源
    idType: number,
    idNumber: string,
    phone: string,
    email: string,
    birthDay: string,
    gender: number,
    bindId: string,
    sourceRemarks?: string,
    userId: string,
    createTime?: string,
    travellerId?: string,
    country?: string,
    issueCountry?: string,
    expiredAt?: string,
    travelDetail?: TravelDetail,
    companyId?: string
    idList?: IdList[],
}

export interface IdList {
    expiredAt?: string,
    idNumber: string,
    idType: string,
    issueCountry: string,
    name: string
    passportType?: string
}

export interface Destination {
    name: string
    path: string
}

export interface ApplyProcess {
    realName: string//审批人名称
    endTime: string
    startTime: string
    content?: string
    procInsId: string// 流程实例ID
    taskId: string// 任务编号
    archived: boolean//是否归档/历史审批（仅显示）
    state: number
    stepping: number
    step: number
    target: string
    executable: boolean//需要审批
    approvalId: string//审批流id



    documentId?: string
    via?: string
    companyId?: string
    staffId?: string
    reimburseId?: string
}

export interface DocumentChangeHistory {
    time: string
    contentList: Array<string>
}

export interface ApplyForm {
    applyStartDate: number
    applyEndDate: number
    applyDate: number
    businessPurposesId: string
    staffId: string
    companyId: string
    applyId: string
    projectId: string
    projectNum: string
    projectName: string
    actionType: string
    documentType: DocumentType
    description: string
    approvalState: number
    applyMoney: number
    orderArrNum: number
    international?: boolean
    longApplyFor?: boolean
    visaNumber: boolean
    visa: boolean
    approvalDateTime?: Date
    travellers?: Array<Traveller>
    customFieldInstances?: Array<CustomFieldInstance>
    destinations?: Array<Destination>
    documentChangeHistories?: Array<DocumentChangeHistory>
    txm: string//申请单号
    applyProcesses?: Array<ApplyProcess>
}


export interface GetApplyFormByIdReturned extends ApplyForm {
    applyProcesses?: Array<ApplyProcess>
    // travels?: Array<Travel>
}

export interface Flow {
    flowMode: 'FREE' | 'LINE' | 'FREE_LINE'
    applyForAuditor?: Array<string>
    reimburseAuditor?: Array<string>
    adventureAuditor?: AdventureAuditor
}

interface AdventureAuditor {
    internationalAuditor?: Array<string>
    exceedAuditor?: Array<string>
    flightAuditor?: Array<string>
    vipAuditor?: Map<string, Array<string>>
}


export interface PutApprovalBody {
    state: 1 | 2
    content?: string
}

export enum CustomFieldType {
    INPUT = "INPUT",
    RADIO_BOX = "RADIO_BOX",
    CHECK_BOX = "CHECK_BOX",
}

export interface CustomField {
    fieldId: string
    companyId: string
    documentType: DocumentType
    fieldName: string
    fieldType: CustomFieldType
    fieldValues?: string[]
    required: boolean
    orderNum: number
    createdAt: string
    updatedAt: string
    exceedValues?: string[]
    highRiskAreaValues?: string[]
}

export interface ApplyFormLifecycle extends ApplyForm {
    reimbursed: boolean
    documentId: string
    userId: string
}

export interface PreCheckError {
    error?: string
    message?: string
    errors?: PreCheckErrorItem[]
}

export interface PreCheckErrorItem {
    code: string
    refType: string
    type: string
}
