import {
    DeleteOutlined,
    EditOutlined,
    ToTopOutlined,
    UndoOutlined,
} from "@ant-design/icons"
import {
    Affix,
    Button,
    Descriptions,
    Divider,
    ResultProps,
    Skeleton,
    Space,
    Typography,
    Image,
    Radio
} from "antd"
import { PageHeader } from "@ant-design/pro-layout"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { AppDispatch, RootState } from "../../app/store"
import StateBadge from "../../components/document/ApprovalStateBadge"
import { resetListState } from "./listSlice"
import CreateFlowInstModal from "./viewer/CreateFlowInstModal"
import PutApprovalModal from "../applyForm/viewer/PutApprovalModal"
import TravelAccounts from "../reimburseForm/viewer/TravelAccounts"
import { fetchReimburseFormDetail, resetState as resetViewerState, } from "./viewerSlice"
import DelReimburesModal from "./viewer/DelReimburesModal"
import DelApprovalModal from "./viewer/DelApprovalModal"
import { updatePreCheck } from "./editorSlice"
import ApplyFormViewer from "./editor/components/ApplyFormViewer"
import ApplyProcessTimeline from "../applyForm/viewer/ApplyProcessTimeline"
import { idNumberMarked } from "../applyForm/viewer/TraverllerEditor"

const { Text } = Typography

const mapStateToProps = (state: RootState) => ({
    loading: state.reimburseFormViewer.pageLoading,
    error: state.reimburseFormViewer.error,
    detail: state.reimburseFormViewer.detail,
    applyForm: state.reimburseFormViewer.detail?.extraInfo.applyForm,
    viewerMode: state.reimburseFormViewer.viewerMode,
    staffList: state.reimburseFormViewer.staffList,
    useSubmit: state.reimburseFormViewer.useSubmit,
    preChecked: state.reimburesEditor.preChecked
})

export const isPersonalCard = (type: number) => {
    switch (type) {
        case 0:
            return "现金/个人卡"
        case 1:
            return "公务卡"
        case 2:
            return "冲借款"
        default:
            return null
    }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    // 报销单详情
    dispatchFetchDetail: (id: string) => dispatch(fetchReimburseFormDetail(id)),
    resetViewer: () => {
        dispatch(resetViewerState())
    },
    resetList: () => {
        dispatch(resetListState())
    },
    updatePreCheck: (reimburseId: string) => {
        dispatch(updatePreCheck(reimburseId))
    }
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

const _ApplyFormViewer = ({
    loading,
    error,
    detail,
    useSubmit,
    viewerMode,
    staffList,
    applyForm,
    dispatchFetchDetail,
    resetViewer,
    resetList,
    updatePreCheck,
    preChecked
}: Props): JSX.Element => {
    console.log("🚀 ~ detail:", detail)

    const { documentId } = useParams()
    const navi = useNavigate()
    const [extraBtn, setExtraBtn] = useState<JSX.Element[]>()
    const [lastOp, setLastOp] = useState<
        "submit" | "rollback" | "edit" | "delete" | "accept" | "reject"
    >()

    const { pathname } = useLocation()
    //卸载时清空数据
    useEffect(
        () => () => {
            resetViewer()
        },
        [resetViewer]
    )

    //信息加载
    useEffect(() => {
        if (error) return
        if (!detail || documentId !== detail?.reimburseId) {
            dispatchFetchDetail(documentId || "404")
            setLastOp(undefined)
        }
        if (useSubmit) {
            dispatchFetchDetail(documentId || "404")
            //更新结束跳转回详情
            setLastOp("submit")
        }

    }, [detail, dispatchFetchDetail, documentId, error, useSubmit])

    //信息加载错误
    useEffect(() => {
        if (pathname === "reimburseForm/result") return
        if (error) {
            const props: ResultProps = {}
            if (error === 404) {
                props.status = "404"
                props.title = "您访问的报销单不存在"
                props.subTitle = "404 not found"
            } else if (error === 403) {
                props.status = "403"
                props.title = "您无法访问此报销单"
                props.subTitle = "403 forbidden"
            } else if (error === 500) {
                props.status = "500"
                props.title = "服务器内部错误"
                props.subTitle = "500 internal server error"
            } else {
                props.status = "error"
                props.title = "请求错误！"
                props.subTitle = error
            }
            resetViewer()
            navi("/reimburseForm/result", {
                state: props,
                replace: true,
            })
        }
    }, [error, resetViewer, navi, pathname])

    //申请单相关操作完成
    const onOpsFinished = () => {
        resetList()
        resetViewer()
        setLastOp(undefined)
    }

    useEffect(() => {
        //更新操作区
        const state = detail?.approvalState
        if (state === undefined) return
        const btn = new Array<JSX.Element>()
        switch (viewerMode) {
            case "owner": //提交人操作区
                if (state !== 0)
                    btn.push(
                        <Link key="btnEdit" to="./edit">
                            <Button icon={<EditOutlined />}>修改</Button>
                        </Link>
                    )
                if ([-1, 2].indexOf(state) >= 0)
                    btn.push(
                        <Button
                            key="btnSubmit"
                            icon={<ToTopOutlined />}
                            onClick={() => {
                                // 调用报销单规则校验接口
                                if (documentId && preChecked !== 'fulfilled') {
                                    updatePreCheck(documentId)
                                } else {
                                    setLastOp("submit")
                                }
                            }}>
                            提交
                        </Button>
                    )
                if ([0, 2].indexOf(state) >= 0)
                    btn.push(
                        <Button
                            key="btnRollback"
                            icon={<UndoOutlined />}
                            danger
                            onClick={() => {
                                setLastOp("rollback")
                            }}>
                            撤回
                        </Button>
                    )
                if ([-1, 2].indexOf(state) >= 0)
                    btn.push(
                        <Button
                            key="btnDelete"
                            icon={<DeleteOutlined />}
                            danger
                            onClick={() => setLastOp("delete")}>
                            删除
                        </Button>
                    )

                break
            case "approver":
                btn.push(
                    <Button
                        key="accept"
                        type="primary"
                        ghost
                        onClick={() => setLastOp("accept")}>
                        同意
                    </Button>
                )
                btn.push(
                    <Button
                        key="reject"
                        danger
                        onClick={() => setLastOp("reject")}>
                        驳回
                    </Button>
                )
                break
        }
        setExtraBtn(btn)
    }, [detail?.approvalState, documentId, preChecked, updatePreCheck, viewerMode])

    return (
        <div>
            <Affix key="PageHeader" offsetTop={0}>
                <PageHeader
                    ghost={false}
                    title="报销单详情"
                    tags={
                        <StateBadge
                            state={detail?.approvalState}
                        />
                    }
                    subTitle={detail && detail.reimburseNum}
                    extra={extraBtn}
                />
            </Affix>
            <div key="container" className="container">
                {/* Modals */}
                <CreateFlowInstModal
                    visiable={lastOp === "submit"}
                    onCancel={() => {
                        setLastOp(undefined)
                    }}
                    onFinished={onOpsFinished}
                />
                <DelReimburesModal
                    visiable={lastOp === "delete"}
                    onCancel={() => setLastOp(undefined)}
                    onFinished={onOpsFinished} />
                <DelApprovalModal
                    visiable={lastOp === "rollback"}
                    onCancel={() => setLastOp(undefined)}
                    onFinished={onOpsFinished}
                />
                <PutApprovalModal
                    visiable={
                        ["accept", "reject"].indexOf(lastOp as string) >= 0
                    }
                    mode={lastOp}
                    onCancel={() => {
                        setLastOp(undefined)
                    }}
                    onFinished={onOpsFinished}
                />
                {/* Detail */}
                <Skeleton loading={loading} active={loading}>
                    <ApplyFormViewer applyForm={applyForm}
                        staffList={staffList}
                        viewerMode={viewerMode}
                        detail={detail} />
                    <>
                        <Divider />
                        <Descriptions title="账目" />
                        <TravelAccounts accounts={detail?.extraInfo.accounts} clickable={true} />
                        <Divider />
                        {detail?.paymentDetailList && (
                            <Descriptions
                                title="支付列表"
                                items={(() => {
                                    return detail?.paymentDetailList?.map(
                                        (it, idx: number) => ({
                                            key: `${idx}`,
                                            children: (
                                                <Radio.Button value="start">
                                                    <Space direction='horizontal'>
                                                        <Text>￥{it.subtotal}</Text>
                                                        <Text>{staffList?.find(t => t.staffId === it.staffId)?.staffUserName}</Text>
                                                        <Text>
                                                            {isPersonalCard(it.type)}
                                                            {idNumberMarked(it.bankCard?.number)}</Text>
                                                    </Space>
                                                </Radio.Button>
                                            ),
                                        })
                                    )
                                })()}
                            />
                        )}
                    </>
                    {
                        detail?.extraInfo.legacyFiles && detail.extraInfo.legacyFiles.length > 0 && (
                            <Descriptions
                                title="附件"
                                items={[
                                    {
                                        key: "附件",
                                        children: <Space size={[20, 'large']} wrap>
                                            {detail?.extraInfo.legacyFiles.map((it, idx) => (
                                                <Image key={idx} width={90} height={90} src={it.url} />
                                            ))
                                            }
                                        </Space>
                                    },
                                ]}
                            />
                        )
                    }
                    {
                        detail?.extraInfo.approvalProcesses && detail?.extraInfo.approvalProcesses.length > 0 && (
                            <Descriptions
                                title="审批流程"
                                items={[
                                    {
                                        children: (
                                            <ApplyProcessTimeline
                                                list={
                                                    detail?.extraInfo.approvalProcesses || []
                                                }
                                            />
                                        ),
                                    },
                                ]}
                            />
                        )
                    }
                </Skeleton>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(_ApplyFormViewer)
