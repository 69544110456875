import {
    Typography,
    Grid,
    Row,
    Form,
    Button,
    Divider,
    Space,
    message,
    Modal,
} from "antd"
import type { Traveller } from "../../models"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { updateTravellers } from "../editorSlice"
import { Dayjs } from "dayjs"
import { IdCardType, idCardTypeList } from "../../traveller/Editor"
import TraverllerBasicInfo from "./TraverllerBasicInfo"
import { TraverllerIdCard } from "./TraverllerIdCard"
import { resetUpdateState, updateTraveller } from "../../traveller/slice"
import { useEffect } from "react"
import { setIsEditMode } from "../viewerSlice"

export interface TravellerFormValues {
    expiredAt?: string
    source: number
    sourceRemarks?: string
    cnName: string
    enName: string
    gender: number
    birthDay: Dayjs
    phone: string
    email: string
    idType: number
    idNumber: string
    issueCountry: string
    country: string
    passportType?: string
    [index: number]: TravellerFormValues //索引签名
}

export const sourceStr = (source: number): string => {
    switch (source) {
        case 0:
            return "申请人本人"
        case 1:
            return "公司同事"
        case 2:
            return "外部人员"
        default:
            return "未知人员"
    }
}

export const idNumberMarked = (idNumber?: string): string => {
    if (!idNumber) return ""
    const setIdCard = /\w{0,4}$/.exec(idNumber)
    if (setIdCard !== null) {
        return setIdCard[0]
    } else {
        return idNumber
    }
}

const { Title } = Typography
const { useBreakpoint } = Grid

type Props = {
    traveller: Traveller
    onFinished?: () => void
}

const TraverllerEditor = ({ traveller: t, onFinished }: Props): JSX.Element => {
    const detail = useAppSelector(state => state.applyFormViewer.detail)
    const updatedLoading = useAppSelector(
        state => state.applyFormEditor.updatedLoading
    )
    const updateState = useAppSelector(state => state.traveller.updateState)
    const updateError = useAppSelector(state => state.traveller.updateError)
    const dispatch = useAppDispatch()
    // 创建 Form 实例，用于管理所有数据状态。
    const [form] = Form.useForm()
    //  个性化布局
    const screens = useBreakpoint()
    // 常用出行人
    useEffect(() => {
        if (updateState === "fulfilled") {
            message.success("更新成功")
            onFinished?.()
            dispatch(resetUpdateState())
        } else if (updateState === "reject") {
            Modal.error({
                title: "更新失败",
                centered: true,
                content:
                    typeof updateError === "number"
                        ? `请求错误：${updateError}`
                        : updateError,
            })
            dispatch(resetUpdateState())
        }
    })

    const onFinish = (value: TravellerFormValues) => {
        const traveller = {
            ...t,
            country: value.country,
            idList: [
                {
                    expiredAt: value?.expiredAt,
                    idNumber: t.idNumber,
                    idType:
                        idCardTypeList().find(
                            it => it.oldIdCardType === t.idType
                        )?.idCardType || "",
                    issueCountry:
                        value.issueCountry ||
                        t.idList?.find(it => it.idType === "ID_CARD")
                            ?.issueCountry ||
                        "",
                    name: t.name,
                },
            ],
        }
        if (detail?.travellers) {
            // 申请单详情出行人
            if (!t.idList) {
                //升级
                const travelerList = detail?.travellers?.map(it => {
                    return it.travellerId === t.travellerId ? traveller : it
                })
                // 升级申请单中的出行人信息
                if (detail?.applyId && travelerList) {
                    dispatch(
                        updateTravellers({
                            applyId: detail.applyId,
                            body: { travellers: travelerList },
                        })
                    )
                }
            } else {
                //补充
                const travelerList = detail?.travellers?.map(it => {
                    const travellers = { ...it }
                    const idList = travellers.idList?.map((i, index) => {
                        const idListItem = { ...i }
                        if (travellers.travellerId === traveller.travellerId) {
                            idListItem["expiredAt"] =
                                value[index]?.expiredAt ||
                                idListItem["expiredAt"]
                        }
                        if (
                            idListItem.idType === IdCardType.PASSPORT &&
                            travellers.travellerId === traveller.travellerId
                        ) {
                            Object.assign(idListItem, {
                                passportType: value[index].passportType,
                            })
                        }
                        return idListItem
                    })
                    travellers.idList = idList
                    return travellers
                })
                // 补充申请单中的出行人信息
                if (detail?.applyId && travelerList) {
                    dispatch(
                        updateTravellers({
                            applyId: detail.applyId,
                            body: { travellers: travelerList },
                        })
                    )
                }
            }
        } else {
            //常用出行人
            if (!t.idList) {
                if (t.travellerId) {
                    dispatch(
                        updateTraveller({
                            id: t.travellerId,
                            travellers: traveller,
                        })
                    )
                }
            } else {
                const travellers = { ...t }
                const idList = travellers.idList?.map((it, index) => {
                    const idListItem = { ...it }
                    if (travellers.travellerId === traveller.travellerId) {
                        idListItem["expiredAt"] =
                            value[index]?.expiredAt || idListItem["expiredAt"]
                    }
                    if (idListItem.idType === IdCardType.PASSPORT) {
                        Object.assign(idListItem, {
                            passportType: value[index].passportType,
                        })
                    }
                    return idListItem
                })
                travellers.idList = idList
                if (t.travellerId) {
                    dispatch(updateTraveller({ id: t.travellerId, travellers }))
                }
            }
        }
    }

    return (
        <Form
            form={form}
            layout="horizontal"
            onFinish={onFinish}
            style={{ width: screens.lg ? "90%" : "100%" }}>
            <Row justify="space-between">
                <Title level={4}>出行人详情</Title>
                <Space>
                    <Button
                        onClick={() => {
                            dispatch(setIsEditMode(false))
                        }}>
                        取消
                    </Button>
                    <Button
                        type="primary"
                        loading={
                            updatedLoading === "pending" ||
                            updateState === "pending"
                        }
                        htmlType="submit">
                        保存
                    </Button>
                </Space>
            </Row>
            <Divider />
            <TraverllerBasicInfo traveller={t} />
            <Divider style={{ margin: "0 0 24px" }} />
            <TraverllerIdCard traveller={t} />
        </Form>
    )
}

export default TraverllerEditor
