import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    ResultProps,
    Row,
    Select,
    Spin,
    Typography,
} from "antd"
import { PageHeader } from "@ant-design/pro-layout"
import dayjs from "dayjs"
import { useCallback, useEffect, useState } from "react"
import { connect } from "react-redux"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { AppDispatch, RootState } from "../../app/store"
import { ApplyForm, BookingStrictMode, DocumentType } from "../models"
import ApplyDateRange from "./editor/ApplyDateRange"
import CustomFields from "./editor/CustomFields"
import DestSelector from "./editor/DestinationItem"
import InternationalSwitch from "./editor/InternationalSwitch"
import ProjectItems from "./editor/ProjectItems"
import PurposesSelector from "./editor/PurposesSelector"
import TravellerItem from "./editor/TravellerSelector"
import {
    createApplyForm,
    updatePreCheck,
    initEditor,
    reset,
    updateApplyForm,
} from "./editorSlice"

/*
有关出差申请编辑的业务逻辑，参考 https://git.chailv8.net/team/workflows/-/issues/1467
*/

export type Mode = "create" | "edit"

const mapStateToProps = (state: RootState) => ({
    loading: state.applyFormEditor.loading,
    available: state.applyFormEditor.available,
    usePersonalApply: state.applyFormEditor.usePersonalApply,
    createdId: state.applyFormEditor.applyId,
    error: state.applyFormEditor.error,
    mode: (state.applyFormEditor.applyForm ? "edit" : "create") as Mode,
    applyForm: state.applyFormEditor.applyForm,
    updated: state.applyFormEditor.updated,
    updatePreCheckError: state.applyFormEditor.updatePreCheckError,
    preChecked: state.applyFormEditor.preChecked,
    bookingStrictMode: state.applyFormEditor.bookingStrictMode,
})
const mapDispatchToProps = (dispatch: AppDispatch) => ({
    // 初始状态
    resetEditor: () => {
        dispatch(reset())
    },
    //初始化信息
    initEditor: (id?: string) => {
        dispatch(initEditor(id))
    },
    // 创建申请单
    create: (form: ApplyForm) => {
        dispatch(createApplyForm(form))
    },
    // 更新申请单
    update: (values: unknown) => {
        dispatch(updateApplyForm(values))
    },
    // 预检
    updatePreCheck: (applyId: string, values: ApplyForm) => {
        dispatch(updatePreCheck({ applyId, values }))
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

const { Item } = Form
const { Title } = Typography

const _Editor = ({
    loading,
    available,
    usePersonalApply,
    createdId,
    error,
    mode,
    applyForm,
    updated,
    updatePreCheckError,
    bookingStrictMode,
    preChecked,
    resetEditor,
    initEditor,
    create,
    update,
    updatePreCheck,
}: Props) => {
    const [formValues, setFormValues] = useState<ApplyForm>()
    const [initialized, setInitialized] = useState(false)
    const navi = useNavigate()
    const { documentId } = useParams()
    const [form] = Form.useForm<ApplyForm>()

    // 更新表单
    const updateForm = useCallback(
        (values: ApplyForm) => {
            if (
                !applyForm?.travellers?.every(
                    it => it.idList && it.idList.length > 0
                )
            ) {
                Modal.warning({
                    content:
                        "请在详情页面点击标记星号的出行人并完善证件信息后再更新申请单。",
                })
            } else {
                update(values)
            }
        },
        [applyForm?.travellers, update]
    )

    useEffect(() => {
        if (!initialized && loading !== "pending") {
            resetEditor()
            initEditor(documentId)
            setInitialized(true)
        } else if (initialized && loading === "fulfilled") {
            if (mode === "create") {
                if (bookingStrictMode === BookingStrictMode.OFF) {
                    Modal.warning({
                        content: "Web版尚未支持此申请控制模式。",
                        onOk: () => {
                            navi(`/applyForm/list`, { replace: true })
                        },
                    })
                }
            } else {
                if (applyForm && (applyForm.destinations?.length ?? 0) < 1) {
                    Modal.warning({
                        content: "Web版尚未支持此申请控制模式。",
                        onOk: () => {
                            navi(`/applyForm/list`, { replace: true })
                        },
                    })
                }
            }
        }
    }, [
        applyForm,
        bookingStrictMode,
        documentId,
        initEditor,
        initialized,
        loading,
        mode,
        navi,
        resetEditor,
    ])

    useEffect(() => {
        //修改模式初始化
        if (mode === "edit" && applyForm) {
            form.setFieldsValue(applyForm)
        } else {
            form.resetFields()
        }
    }, [mode, applyForm, form])

    useEffect(() => {
        if (createdId) {
            //创建完成
            resetEditor()
            navi("../result", {
                replace: true,
                state: {
                    status: "success",
                    title: "出差申请创建成功！",
                    applyId: createdId,
                } as ResultProps,
            })
        } else if (updated) {
            //更新完成
            Modal.success({
                content: "出差申请更新成功！",
                onOk: () => {
                    resetEditor()
                    navi("../" + applyForm?.applyId, {
                        replace: true,
                    })
                },
            })
        } else if (error) {
            //错误
            resetEditor()
            navi("../result", {
                replace: true,
                state: {
                    status: error === 403 ? 403 : "error",
                    title:
                        error === 403
                            ? "没有创建出差申请的权限"
                            : "请求失败：" + error,
                } as ResultProps,
            })
        }
    }, [createdId, error, updated, applyForm?.applyId, navi, resetEditor])
    // 预检校验判断
    useEffect(() => {
        if (!preChecked) return
        if (!formValues) return
        if (
            updatePreCheckError?.errors?.find(
                it => it.code === "REAPPROVAL_REQUIRED" && it.type === "warning"
            )
        ) {
            Modal.confirm({
                okText: "更新",
                content: `更新申请单后需要重新提交审批,是否更新?`,
                onOk: () => {
                    updateForm(formValues)
                },
            })
        } else {
            updateForm(formValues)
        }
    }, [formValues, navi, preChecked, updateForm, updatePreCheckError?.errors])

    const onFinish = (values: ApplyForm) => {
        if (mode === "create") {
            if (
                !values.travellers?.every(
                    it => it.idList && it.idList?.length > 0
                )
            ) {
                Modal.warning({
                    content:
                        "您当前使用的是旧版出行人，请至常用出行人页面更新证件信息",
                    onOk: () => {
                        navi(`/my/traveller`, { replace: true })
                    },
                })
            } else {
                create(values)
            }
        } else {
            //这种判断方案有个前提就是所有字段都为必填，否则无法区分未修改和置空的情况
            const diff = (Object.keys(values) as (keyof ApplyForm)[])
                .filter(key =>
                    typeof values?.[key] !== "object"
                        ? values?.[key] !== applyForm?.[key]
                        : JSON.stringify(values?.[key]) !==
                        JSON.stringify(applyForm?.[key])
                )
                .reduce((prev, next) => {
                    prev[next] = values?.[next]
                    return prev
                }, Object.create({}))

            if (Object.keys(diff).length <= 0) {
                message.warning("并未修改任何信息，无需保存。")
            } else {
                //做预检校验
                if (applyForm?.applyId) {
                    updatePreCheck(applyForm.applyId, values)
                    setFormValues(values)
                }
            }
        }
    }

    return (
        <Form<ApplyForm> form={form} layout="vertical" onFinish={onFinish}>
            {!available && (
                <Navigate
                    to="../result"
                    replace
                    state={
                        {
                            status: "403",
                            title: "您当前账户无法创建新申请",
                            subTitle:
                                "您公司的管理员将您的在所属公司的账户禁用，请与您公司的系统管理员联系。",
                        } as ResultProps
                    }
                />
            )}
            <PageHeader
                ghost={false}
                title={mode === "create" ? "创建出差申请" : "修改出差申请"}
                subTitle={applyForm && applyForm.txm}
                extra={[
                    <Button
                        key="submit"
                        type="primary"
                        htmlType="submit"
                        loading={initialized && loading === "pending"}>
                        保存
                    </Button>,
                ]}
            />
            <div className="container">
                <div style={{ height: "16px" }}></div>
                <Spin size="large" spinning={loading === "pending"}>
                    <Row justify="center">
                        <Col xs={24} sm={24} md={24} lg={12}>
                            <Title level={4}>基础信息</Title>
                            {mode === "create" && (
                                <Item<DocumentType>
                                    rules={[{ required: true }]}
                                    initialValue={DocumentType.APPLY_TRAVEL}
                                    hidden={!usePersonalApply}
                                    name="documentType"
                                    label="申请类型">
                                    <Select<DocumentType>>
                                        <Select.Option
                                            value={DocumentType.APPLY_TRAVEL}>
                                            因公出行
                                        </Select.Option>
                                        <Select.Option
                                            value={
                                                DocumentType.APPLY_PERSONAL_TRAVEL
                                            }>
                                            因私出行
                                        </Select.Option>
                                    </Select>
                                </Item>
                            )}
                            {mode === "create" && (
                                <Item<number>
                                    name="applyDate"
                                    hidden
                                    noStyle
                                    initialValue={Number.parseInt(
                                        dayjs().format("x")
                                    )}>
                                    <Input />
                                </Item>
                            )}
                            <ProjectItems />
                            <PurposesSelector />
                            <Item
                                name="applyMoney"
                                label="出差预算"
                                rules={[
                                    {
                                        required: true,
                                        type: "number",
                                        message: "请输入出差预算",
                                    },
                                ]}>
                                <InputNumber
                                    min={0}
                                    maxLength={8}
                                    prefix="￥"
                                    style={{ width: "100%" }}
                                />
                            </Item>
                            <Divider />
                            <Title level={4}>出行信息</Title>
                            <InternationalSwitch />
                            <ApplyDateRange />
                            <DestSelector />
                            <TravellerItem />
                            {mode === "create" && (
                                <>
                                    <Divider />
                                    <Title level={4}>其他信息</Title>
                                    <Item<string>
                                        name="description"
                                        label="申请说明"
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                            },
                                        ]}>
                                        <Input.TextArea />
                                    </Item>
                                    <CustomFields />
                                </>
                            )}
                        </Col>
                    </Row>
                </Spin>
            </div>
        </Form>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(_Editor)
