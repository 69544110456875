import { ReactElement, useEffect } from "react"
import { connect } from "react-redux"
import { AppDispatch, RootState } from "../../../../../app/store"
import { Select, Form, Button } from "antd"
import {
    filterTrainList,
    TrainListFilterOptsValues,
} from "../../services/bookTrainSlice"

const mapStateToProps = (state: RootState) => ({
    trainType: state.bookTrain.trainListFilterOpts.trainType,
    fromStation: state.bookTrain.trainListFilterOpts.fromStation,
    toStation: state.bookTrain.trainListFilterOpts.toStation,
    seatCode: state.bookTrain.trainListFilterOpts.seatCode,
    trainList: state.bookTrain.trainList,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    filterTrainList: (fromProms: TrainListFilterOptsValues) => {
        dispatch(filterTrainList(fromProms))
    },
})

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

function TrainListFilters({
    trainList,
    trainType,
    fromStation,
    toStation,
    seatCode,
    filterTrainList,
}: StateProps): ReactElement {
    // 创建 Form 实例，用于管理所有数据状态。
    const [form] = Form.useForm()
    // 时间
    const trainfromTime = [
        { key: "one", label: "00:00-04:00" },
        { key: "two", label: "04:00-08:00" },
        { key: "three", label: "08:00-12:00" },
        { key: "four", label: "12:00-16:00" },
        { key: "five", label: "16:00-20:00" },
        { key: "six", label: "20:00-24:00" },
    ]
    // 自定义sort排序数据
    const sortFromTime = [
        { key: "theearliest", label: "最早出发" },
        { key: "latest", label: "最晚出发" },
        { key: "minimum", label: "耗时最短" },
    ]
    // 获取表单数据
    const onValuesChange = (
        changedValue: TrainListFilterOptsValues["changedValue"],
        allValues: TrainListFilterOptsValues
    ) => {
        filterTrainList({ ...allValues, changedValue })
    }
    // 清空
    const onReset = () => {
        filterTrainList({
            trainTypeNames: [],
            trainStations: [],
            traintoStations: [],
            trainSeatCodes: [],
            trainfromTime: [],
        })
        form.resetFields()
    }
    // 数据发生变化以后清空
    useEffect(() => {
        if (!trainList) {
            form.resetFields()
        }
    }, [form, trainList])
    return (
        <>
            <Form<TrainListFilterOptsValues>
                form={form}
                onValuesChange={onValuesChange}
                layout="vertical">
                <Form.Item name="trainTypeNames" label="车型选择">
                    <Select
                        mode="multiple"
                        allowClear
                        showSearch={false}
                        style={{ width: "100%" }}>
                        {trainType.map(item => (
                            <Select.Option
                                key={item.value + item.label}
                                value={item.value}>
                                {" "}
                                {item.label}{" "}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="trainStations" label="出发站">
                    <Select
                        mode="multiple"
                        allowClear
                        showSearch={false}
                        style={{ width: "100%" }}>
                        {fromStation.map(item => (
                            <Select.Option
                                key={item.value + item.label}
                                value={item.value}>
                                {" "}
                                {item.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="traintoStations" label="到达站">
                    <Select
                        mode="multiple"
                        allowClear
                        showSearch={false}
                        style={{ width: "100%" }}>
                        {toStation.map(item => (
                            <Select.Option
                                key={item.value + item.label}
                                value={item.value}>
                                {" "}
                                {item.label}{" "}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="trainSeatCodes" label="坐席类别">
                    <Select
                        mode="multiple"
                        allowClear
                        showSearch={false}
                        style={{ width: "100%" }}>
                        {seatCode.map(item => (
                            <Select.Option
                                key={item.value + item.label}
                                value={item.value}>
                                {" "}
                                {item.label}{" "}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="trainfromTime" label="时间段">
                    <Select
                        mode="multiple"
                        showSearch={false}
                        allowClear
                        style={{ width: "100%" }}>
                        {trainfromTime.map(item => (
                            <Select.Option
                                key={item.key + item.label}
                                value={item.label}>
                                {" "}
                                {item.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="sortFromTime"
                    label="时间"
                    initialValue={sortFromTime[0].label}>
                    <Select
                        defaultActiveFirstOption={true}
                        allowClear
                        style={{ width: "100%" }}>
                        {sortFromTime.map(item => (
                            <Select.Option
                                key={item.key + item.label}
                                value={item.key}>
                                {item.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="trainfromTime">
                    <Button onClick={onReset}>清除</Button>
                </Form.Item>
            </Form>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(TrainListFilters)
