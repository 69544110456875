import { Input, Form, Space, Grid } from 'antd'


type AmountofCostsProps = {
    selected?: number
}

export default function AmountofCosts({ selected }: AmountofCostsProps) {
    const { md } = Grid.useBreakpoint()

    return (
        <>
            <Form.Item
                label='标准金额'>
                <Space.Compact block>
                    <Form.Item
                        label='标准金额'
                        name='accountsSumMoney2'
                        noStyle
                    >
                        <Input className="readonlyInput" disabled style={{ width: md ? '75%' : '50%' }} />
                    </Form.Item>
                    <Form.Item
                        name='currency2'
                        noStyle
                        initialValue={'CNY'}
                    >
                        <Input type="text" className="readonlyInput" style={{ width: md ? '25%' : '50%' }} disabled />
                    </Form.Item>
                </Space.Compact>
            </Form.Item>
            <Form.Item
                label='结算金额'>
                <Space.Compact block>
                    <Form.Item
                        label='结算金额'
                        name='accountsSumMoney3'
                        noStyle
                    >
                        <Input className="readonlyInput" disabled style={{ width: md ? '75%' : '50%' }} />
                    </Form.Item>
                    <Form.Item
                        name='currency3'
                        noStyle
                        initialValue={'CNY'}
                    >
                        <Input type="text" className="readonlyInput" style={{ width: md ? '25%' : '50%' }} disabled />
                    </Form.Item>
                </Space.Compact>
            </Form.Item>
        </>
    )
}
