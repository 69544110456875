import { useEffect } from "react"
import { connect } from "react-redux"
import { List } from "antd"

import { AppDispatch, RootState } from "../../../app/store"
import {
    fetchOrderList,
    OrderTypePageRequest,
} from "../service/orderModuleSlice"
import { AirplaneListCardItem } from "./airplane/AirplaneListCardItem"
import { TrainListCardItem } from "./train/TrainListCardItem"
import { HotelListCardItem } from "./hotel/HotelListCardItem"
import {
    OrderListModel,
    PlaneOrderListModel,
    HotelOrderListModel,
    TrainOrderListModel,
} from "../service/orderListModel"

const mapStateToProps = (state: RootState) => ({
    data: state.orderModule.data,
    loading: state.orderModule.isLoading,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    //订单列表
    OrderList: (param: OrderTypePageRequest) => {
        dispatch(fetchOrderList(param))
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        orderType: string
        airplaneChangeClick?: (item: PlaneOrderListModel) => void
        airplaneRefundClick?: (item: PlaneOrderListModel) => void
        trainChangeClick?: (item: TrainOrderListModel) => void
        trainRefundClick?: (item: TrainOrderListModel) => void
        hotelRefundClick?: (item: HotelOrderListModel) => void
    }

function OrderListComponent(props: Props) {
    const {
        OrderList,
        orderType,
        loading,
        data,
        airplaneChangeClick,
        airplaneRefundClick,
        trainChangeClick,
        trainRefundClick,
        hotelRefundClick,
    } = props

    // hooks
    useEffect(() => {
        // 订单列表
        OrderList({ orderType: orderType, page: 0, size: 10 })
    }, [OrderList, orderType])

    const _airplaneChange = (item: PlaneOrderListModel) => {
        // 飞机改签
        airplaneChangeClick?.(item)
    }
    const _airplaneRefund = (item: PlaneOrderListModel) => {
        //  飞机退票
        airplaneRefundClick?.(item)
    }

    const _trainChange = (item: TrainOrderListModel) => {
        // 火车改签
        trainChangeClick?.(item)
    }
    const _trainRefund = (item: TrainOrderListModel) => {
        // 火车退票
        trainRefundClick?.(item)
    }

    const _hotelRefund = (item: HotelOrderListModel) => {
        // 酒店退订
        hotelRefundClick?.(item)
    }

    function showItem(item: OrderListModel) {
        switch (item.orderType) {
            case 1: // 火车
                return (
                    <TrainListCardItem
                        trainItem={item as TrainOrderListModel}
                        changeClick={() => _trainChange(item)}
                        refundClick={() => _trainRefund(item)}
                    />
                )
            case 2: // 飞机
                return (
                    <AirplaneListCardItem
                        planeItem={item as PlaneOrderListModel}
                        changeClick={() => _airplaneChange(item)}
                        refundClick={() => _airplaneRefund(item)}
                    />
                )
            case 3: // 酒店
                return (
                    <HotelListCardItem
                        hotelItem={item as HotelOrderListModel}
                        refundClick={() => _hotelRefund(item)}
                    />
                )
            default:
                throw Error("Unkonwn orderType")
        }
    }

    return (
        <List
            key="ddd"
            itemLayout="vertical"
            loading={loading}
            pagination={
                data && {
                    hideOnSinglePage: true,
                    current: data.page + 1,
                    total: data.totalElements,
                    onChange: (page, size) => {
                        OrderList({
                            orderType: orderType,
                            page: page - 1,
                            size: size || 10,
                        })
                    },
                }
            }
            dataSource={data?.content}
            renderItem={(item: OrderListModel) => (
                <List.Item style={{ padding: "0" }}>{showItem(item)}</List.Item>
            )}
        />
    )
}

export const OrderListInfo = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderListComponent)
