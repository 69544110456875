import { PreCheckErrors } from "../reimburseForm/editorSlice"

export enum airplaneOrderStatus {
    CLOrderStateCreating = 3000,            // 出票中
    CLOrderStateCreateSuccess = 3010,       // 已出票
    CLOrderStateCreateFail = 3020,          // 出票失败
    CLOrderStateReturning = 4000,           // 退票中
    CLOrderStateReturnSuccess = 4010,       // 已退票
    CLOrderStateReturnFail = 4020,          // 退票失败
    CLOrderStateChanging = 5000,            // 改签中
    CLOrderStateChageSuccess = 5010,        // 已改签
    CLOrderStateChangeFail = 5020,          // 改签失败
    CLOrderStateComplete = 6000,            // 已完成
}
export enum trainOrderStatus {
    CLTrainOrderStateSavingSeat = 0,            // 占座中
    CLTrainOrderStateSavingSeatFail = 99,       // 占座失败
    CLTrainOrderStateWaitingPay = 1,            // 待支付
    CLTrainOrderStateCancel = 2,                // 已取消
    CLTrainOrderStateCreating = 3,              // 出票中
    CLTrainOrderStateCreateSuccess = 4,         // 已出票
    CLTrainOrderStateCreateFail = 5,            // 出票失败
    CLTrainOrderStateChanging = 6,              // 改签中
    CLTrainOrderStateChageSuccess = 7,          // 已改签
    CLTrainOrderStateReturning = 8,             // 退票中
    CLTrainOrderStateReturnFail = 9,            // 退票失败
    CLTrainOrderStateReturnSuccess = 10,        // 已退票
    CLTrainOrderStateWaitingConfirmChange = 11, // 待确认改签
}

export enum hotelOrderStatus {
    CLHotelOrderStateApplyFail = 0,        // 下单失败         无操作
    CLHotelOrderStateApplySuccess = 1,     // 待确认           预订，取消
    CLHotelOrderStateReserving = 2,        // 预订中           无操作
    CLHotelOrderStateBookingSuccess = 3,   // 预订成功          退订
    CLHotelOrderStateBookingFailure = 4,   // 预订失败，        无操作
    CLHotelOrderStateCheckout = 5,         // 已离店，          无操作
    CLHotelOrderStateOrderCanceling = 6,   // 订单取消处理中，    无操作
    CLHotelOrderStateOrderCanceled = 7,    // 订单已取消，       无操作
    CLHotelOrderStateRefunding = 8,        // 退订处理中，       无操作
    CLHotelOrderStateRefunded = 9,         // 已退订，          无操作
    CLHotelOrderStateNotCheckIn = 10,      // 未入住，          无操作
}

export const listAccountsType = (accountsType?: number): string => {
    switch (accountsType) {
        case 9: return 'icon-ship-b'                //轮船
        case 10: return 'icon-feiji-copy'           //飞机
        case 11: return 'icon-ico_huochezhan'          //火车
        case 12: return 'icon-zijiazuchefeibeifen'  //自驾
        case 13: return 'icon-ditie'                //市内交通
        case 14: return 'icon-zhongxingkechezaixian'//长途
        case 15: return 'icon-xiuxianyule'          //娱乐
        case 16: return 'icon-jiudian2'             //住宿
        case 17: return 'icon-qita1'                //其他
        case 18: return 'icon-chalvbaoxiao60px'     //补助
        case 19: return 'icon-qiche'                //公司派车
        case 20: return 'icon-qiche'                //客户派车
        case 21: return 'icon-qiche'                //合作伙伴派车
        case 22: return 'icon-qiche'                //公司派车(专车)
        case 1810: return 'icon-chalvbaoxiao60px'   //包干补助
        case 1801: return 'icon-chalvbaoxiao60px'   //伙食补助
        case 1802: return 'icon-chalvbaoxiao60px'   //车辆补助
        case 1800: return 'icon-chalvbaoxiao60px'   //车辆补助

        default: return 'icon-weizhi3'
    }
}

export const TitleAccountsType = (accountsType?: number): string => {
    switch (accountsType) {
        case 9: return '轮船'                //轮船
        case 10: return '飞机'               //飞机
        case 11: return '火车'               //火车
        case 12: return '自驾'               //自驾
        case 13: return '市内交通'           //市内交通
        case 14: return '长途'               //长途
        case 15: return '娱乐'               //娱乐
        case 16: return '住宿'               //住宿
        case 17: return '其他'               //其他
        case 18: return '补助'               //补助
        case 19: return '公司派车'           //公司派车
        case 20: return '客户派车'           //客户派车
        case 21: return '合租伙伴派车'       //合作伙伴派车
        case 22: return '公司派车'           //公司派车(专车)
        case 1810: return '包干补助'         //包干补助
        case 1801: return '伙食补助'         //伙食补助
        case 1802: return '车辆补助'         //车辆补助
        case 1800: return '补助'             //车辆补助

        default: return '补助'
    }
}

export const setAccountsType = [
    { icon: "icon-ship-b", mode: "editor", value: 9, label: "轮船", key: "oceanLiner" },
    { icon: "icon-feiji-copy", mode: "editor", value: 10, label: "飞机", key: "flight" },
    { icon: "icon-ico_huochezhan", mode: "editor", value: 11, label: "火车", key: "train" },
    { icon: "icon-zhongxingkechezaixian", mode: "editor", value: 14, label: "长途汽车", key: "longdistancebus" },
    { icon: "icon-qiche", mode: "editor", value: 19, label: "公司派车" },
    { icon: "icon-qiche", mode: "editor", value: 20, label: "客户派车" },
    { icon: "icon-qiche", mode: "editor", value: 21, label: "合作伙伴派车" },
    { icon: "icon-qiche", mode: "editor", value: 22, label: "网约车" },
    { icon: "icon-ditie", mode: "editor", value: 13, label: "市内交通", key: "taxi" },
    { icon: "icon-jiudian2", mode: "editor", value: 16, label: "住宿", key: "hotel" },
    { icon: "icon-chalvbaoxiao60px", mode: "editor", value: 18, label: "补助" },
    { icon: "icon-qita1", mode: "editor", value: 17, label: "其他" },
    { icon: "icon-zijiazuchefeibeifen", mode: "editor", value: 12, label: "自驾" }
]

export interface AccountsType {
    icon: string
    mode: string
    value: number
    label: string
    key?: string
}

export const airplaneOrderStatusToStr = (orderStatus?: number): string => {
    switch (orderStatus) {
        case 3000: return "出票中"
        case 3010: return "已出票"
        case 3020: return "出票失败"
        case 4000: return "退票中"
        case 4010: return "已退票"
        case 4020: return "退票失败"
        case 5000: return "改签中"
        case 5010: return "已改签"
        case 5020: return "改签失败"
        case 6000: return "已完成"

        default: return "状态未知"
    }
}

export const trainOrderStatusToStr = (orderStatus?: number): string => {
    switch (orderStatus) {
        case 0: return "占座中"
        case 99: return "占座失败"
        case 1: return "待支付"
        case 2: return "已取消"
        case 3: return "出票中"
        case 4: return "已出票"
        case 5: return "出票失败"
        case 6: return "改签中"
        case 7: return "已改签"
        case 8: return "退票中"
        case 9: return "退票失败"
        case 10: return "已退票"
        case 11: return "待确认改签"

        default: return "状态未知"
    }
}

export const hotelOrderStatusToStr = (orderStatus?: number): string => {
    switch (orderStatus) {
        case 0: return "下单失败"
        case 1: return "待确认"
        case 2: return "预订中 "
        case 3: return "预订成功"
        case 4: return "预订失败"
        case 5: return "已离店"
        case 6: return "订单取消处理中"
        case 7: return "订单已取消"
        case 8: return "退订处理中"
        case 9: return "已退订"
        case 10: return "未入住"

        default: return "状态未知"
    }
}


export interface Notes {
    title?: string
    level?: string
    msg?: string
}

export const runTimeStr = (runTimeSpan?: string): string => {
    const runTime = Number(runTimeSpan)
    const hours = Math.floor(runTime / 60)
    const minutes = runTime % 60
    return hours + "时" + minutes + "分"
}


export const currencyList = [
    { "name": "人民币", "code": "CNY" },
    { "name": "美元", "code": "USD" },
    { "name": "日元", "code": "JPY" },
    { "name": "欧元", "code": "EUR" },
    { "name": "英镑", "code": "GBP" },
    { "name": "韩元", "code": "KRW" },
    { "name": "港币", "code": "HKD" },
    { "name": "澳大利亚元", "code": "AUD" },
    { "name": "加拿大元", "code": "CAD" },
    { "name": "阿尔及利亚第纳尔", "code": "DZD" },
    { "name": "阿根廷比索", "code": "ARS" },
    { "name": "爱尔兰镑", "code": "IEP" },
    { "name": "埃及镑", "code": "EGP" },
    { "name": "阿联酋迪拉姆", "code": "AED" },
    { "name": "阿曼里亚尔", "code": "OMR" },
    { "name": "奥地利先令", "code": "ATS" },
    { "name": "澳门元", "code": "MOP" },
    { "name": "百慕大元", "code": "BMD" },
    { "name": "巴基斯坦卢比", "code": "PKR" },
    { "name": "巴拉圭瓜拉尼", "code": "PYG" },
    { "name": "巴林第纳尔", "code": "BHD" },
    { "name": "巴拿马巴尔博亚", "code": "PAB" },
    { "name": "保加利亚列弗", "code": "BGN" },
    { "name": "巴西雷亚尔", "code": "BRL" },
    { "name": "比利时法郎", "code": "BEF" },
    { "name": "冰岛克朗", "code": "ISK" },
    { "name": "博茨瓦纳普拉", "code": "BWP" },
    { "name": "波兰兹罗提", "code": "PLN" },
    { "name": "玻利维亚诺", "code": "BOB" },
    { "name": "丹麦克朗", "code": "DKK" },
    { "name": "德国马克", "code": "DEM" },
    { "name": "法国法郎", "code": "FRF" },
    { "name": "菲律宾比索", "code": "PHP" },
    { "name": "芬兰马克", "code": "FIM" },
    { "name": "哥伦比亚比索", "code": "COP" },
    { "name": "古巴比索", "code": "CUP" },
    { "name": "哈萨克坚戈", "code": "KZT" },
    { "name": "荷兰盾", "code": "NLG" },
    { "name": "加纳塞地", "code": "GHC" },
    { "name": "捷克克朗", "code": "CZK" },
    { "name": "津巴布韦元", "code": "ZWD" },
    { "name": "卡塔尔里亚尔", "code": "QAR" },
    { "name": "克罗地亚库纳", "code": "HRK" },
    { "name": "肯尼亚先令", "code": "KES" },
    { "name": "科威特第纳尔", "code": "KWD" },
    { "name": "老挝基普", "code": "LAK" },
    { "name": "拉脱维亚拉图", "code": "LVL" },
    { "name": "黎巴嫩镑", "code": "LBP" },
    { "name": "林吉特", "code": "MYR" },
    { "name": "立陶宛立特", "code": "LTL" },
    { "name": "卢布", "code": "RUB" },
    { "name": "罗马尼亚新列伊", "code": "RON" },
    { "name": "毛里求斯卢比", "code": "MUR" },
    { "name": "蒙古图格里克", "code": "MNT" },
    { "name": "孟加拉塔卡", "code": "BDT" },
    { "name": "缅甸缅元", "code": "BUK" },
    { "name": "秘鲁新索尔", "code": "PEN" },
    { "name": "摩洛哥迪拉姆", "code": "MAD" },
    { "name": "墨西哥比索", "code": "MXN" },
    { "name": "南非兰特", "code": "ZAR" },
    { "name": "挪威克朗", "code": "NOK" },
    { "name": "葡萄牙埃斯库多", "code": "PTE" },
    { "name": "瑞典克朗", "code": "SEK" },
    { "name": "瑞士法郎", "code": "CHF" },
    { "name": "沙特里亚尔", "code": "SAR" },
    { "name": "斯里兰卡卢比", "code": "LKR" },
    { "name": "索马里先令", "code": "SOS" },
    { "name": "泰国铢", "code": "THB" },
    { "name": "坦桑尼亚先令", "code": "TZS" },
    { "name": "土耳其新里拉", "code": "TRY" },
    { "name": "突尼斯第纳尔", "code": "TND" },
    { "name": "危地马拉格查尔", "code": "GTQ" },
    { "name": "委内瑞拉玻利瓦尔", "code": "VEB" },
    { "name": "乌拉圭新比索", "code": "UYU" },
    { "name": "西班牙比塞塔", "code": "ESP" },
    { "name": "希腊德拉克马", "code": "GRD" },
    { "name": "新加坡元", "code": "SGD" },
    { "name": "新台币", "code": "TWD" },
    { "name": "新西兰元", "code": "NZD" },
    { "name": "匈牙利福林", "code": "HUF" },
    { "name": "牙买加元", "code": "JMD" },
    { "name": "义大利里拉", "code": "ITL" },
    { "name": "印度卢比", "code": "INR" },
    { "name": "印尼盾", "code": "IDR" },
    { "name": "以色列谢克尔", "code": "ILS" },
    { "name": "约旦第纳尔", "code": "JOD" },
    { "name": "越南盾", "code": "VND" },
    { "name": "智利比索", "code": "CLP" },
    { "name": "白俄罗斯卢布", "code": "BYR" }
]


export const lastFails = (it: PreCheckErrors) => {
    if (it.failLevel === 'REASON') {
        if (it.ruleName === 'checkTripCityInclusive') {
            return '·该条账⽬城市不在出差申请城市范围内。'
        } else
            return '.超标账目必须填写超标原因。'
    } else if (it.failLevel === 'DENY') {
        if (it.ruleName === 'checkAccountsType') {//如果配置了允许报销的账目类型则报销单只能报销这些类型
            return '·该类型账⽬⽆法报销。'
        } else if (it.ruleName === 'checkTripCityInclusive') { //如果是出差城市版本的申请单，则报销城市比如囊括在出差申请城市中
            return '·该条账⽬城市不在出差申请城市范围内。'
        } else if (it.ruleName === 'checkApprovalFirst') {//检查审批时间是否先于报销费用的最早时间
            return '.审批时间是否先于报销费用的最早时间。'
        } else if (it.ruleName === 'checkAccountDateInclusive') { //检查起止日期是否在申请日期范围内
            return '.起止日期是否在申请日期范围内。'
        } else if (it.ruleName === 'checkFlightApprovedLegacy') {//检查旧版出差申请行程模式时，是否申请了飞机行程用以报销机票费用
            return '·该出差申请未申请⻜机出⾏，不能报销⻜机票。'
        } else if (it.ruleName === 'checkApprovedCustomer') {//检查报销单中的补贴是否满足配置模式的时间要求
            return '.费用归属人是否在出差申请中提交。'
        } else if (it.ruleName === 'checkSubsidyStrictMode') { //检查报销单中的补贴是否满足配置模式的时间要求
            return '·不能提交今天以后的补助。'
        } else if (it.ruleName === 'checkAllowedPurposeIdTypes') { //检查报销单中的账目是否设置了允许的出差目的，如果设置了则需要满足条件才能报销此类型账目
            return '·不能提交subsidyBeforeTimestamp之前的补助。'
        } else if (it.ruleName === 'checkWorkPlaceSubsidy') {//检查报销单中没有提交工作属地所在城市的补贴费用
            return '.您的报销单内同类型补助时间重叠或重复，请检查核对后再保存。'
        } else if (it.ruleName === 'checkWorkplaceHotelReimburse') {//检查加班住宿报销单是否满足条件。出差目的选加班住宿、只能报销归属人属地所在城市的住宿费用
            return '·出差⽬的是加班住宿只允许添加住宿账⽬。'
        } else if (it.ruleName === 'checkInvalidWorkplaceHotelReimburse') {//检查非加班住宿时，报销了费用归属人所在属地的住宿费用
            return '.不能报销费用归属人的工作归属地住宿费。'
        } else if (it.ruleName === 'checkSubsidyDuration') {//检查单条补贴时长检测
            return '·该补助超过31天，不符合公司差旅政策。'
        } else if (it.ruleName === 'checkSubsidyReimburseDelay') {//补贴结束时间不晚于当前时间20天，增加豁免accountPolicyId
            return '·该补助已经超出出差结束后20个⼯作⽇。'
        } else if (it.ruleName === 'checkLongTermOnlyAccountType') {//检查仅长差可以报销的账目类型
            return '·短差申请单不允许添加⻓差相关补助账⽬。'
        } else if (it.ruleName === 'checkInlandOnlyAccountType') {//检查仅国内出差可以报销的账目类型
            return '·国际出差申请单不允许添加此补助类型。'
        } else if (it.ruleName === 'checkShortTermOnlyAccountType') {//检查仅短差可以报销的账目类型
            return '·不符合公司⻓差报销政策。'
        } else if (it.ruleName === 'checkLumpSumSubsidyAuth') {//检查包干补助的费用归属人是否有权限，是否在授权报销时间范围之内，但是不含公司整体判断
            return '·您的补助已超出包⼲住勤补助授权时间。'
        } else if (it.ruleName === 'checkAccountPolicy') {//验证账目是否满足对应accountPolicy的配置需求，差标和include/exclude
            return '.超标账目必须选择超标原因。'
        } else if (it.ruleName === 'checkAccountDateOverlap') {//判断账目的起止时间是否与当前以及已报销账目的同类型同消费归属人账目的时间重叠
            return '·您的报销单内同类型补助时间重叠或重复，请检查核对后再保存。'
        }
    } else if (it.failLevel === 'WARN') {
        if (it.ruleName === 'checkApprovalFirst') {
            return '·该账⽬的消费时间必须要在审批完成之后。'
        } else if (it.ruleName === 'checkAccountDateInclusive') {
            return '·该账⽬的消费时间必须要在出差申请的时间范围内。'
        }
    }
    return null
}