import { Button, Col, Row } from "antd"
import { ReactElement, useCallback } from "react"
import { UserAvatar } from "../features/user/UserAvatar"
import { MenuOutlined } from "@ant-design/icons"
import { useAppDispatch } from "../app/hooks"
import { changeMobileMenuVisible } from "../features/menu/menuSlice"
import GlobalCompanySelector from "../features/user/GlobalCompanySelector"

export const HeaderBar = (): ReactElement => {
    const dispatch = useAppDispatch()
    const onMenuClick = useCallback(() => {
        dispatch(changeMobileMenuVisible(true))
    }, [dispatch])

    return (
        <Row>
            <Col flex="none" lg={0} xl={0} xxl={0}>
                <Button
                    style={{ marginLeft: 10 }}
                    size="large"
                    type="text"
                    onClick={onMenuClick}
                    icon={<MenuOutlined />}
                />
            </Col>
            <Col flex="auto" style={{ textAlign: "right" }}>
                <GlobalCompanySelector />
            </Col>
            <Col flex="none">
                <UserAvatar />
            </Col>
        </Row>
    )
}
