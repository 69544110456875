import { Card, Divider, Form, Input, Select, Space, Tabs } from "antd"
import ExpiresDatePicker from "../../traveller/ExpiresDatePicker"
import { IdList, Traveller } from "../../models"
import {
    CardPassportTypeList,
    IdCardType,
    PassportType,
    idCardTypeList,
} from "../../traveller/Editor"
import CountrySelector from "../../traveller/CountrySelector"
import dayjs from "dayjs"
import { useState } from "react"

type Props = {
    traveller: Traveller
}

// 证件是否过期
export const isCertificateExpire = (t: IdList) => {
    if (t.expiredAt && dayjs(t.expiredAt).isBefore(dayjs())) {
        return "证件已过期!"
    } else if (
        t.expiredAt &&
        dayjs(t.expiredAt).isBefore(dayjs().add(6, "M"))
    ) {
        return `您的${
            idCardTypeList().find(i => i.idCardType === t.idType)?.displayName
        }有效期小于6个月，请及时更换,否则出票可能失败。`
    } else {
        return
    }
}

// 护照是否禁用
export const isPassportDisabled = (it: IdList): boolean => {
    return (
        it.idType === IdCardType.PASSPORT &&
        dayjs(it?.expiredAt).isBefore(dayjs())
    )
}

export const TraverllerIdCard = ({ traveller: item }: Props) => {
    const [disabled] = useState(!item?.idList && item?.idType === 0)
    const { Item } = Form
    const { Option } = Select

    return (
        <>
            {disabled === true && (
                <Card>
                    <Item label="证件类型">
                        <Input
                            value={
                                idCardTypeList().find(
                                    it => it.oldIdCardType === item.idType
                                )?.displayName || ""
                            }
                            className="readonlyInput"
                            disabled
                        />
                    </Item>
                    <Item label="证件号">
                        <Input
                            value={item.idNumber}
                            className="readonlyInput"
                            disabled
                        />
                    </Item>
                    <Item
                        label="签发国"
                        initialValue={disabled ? "中国" : ""}
                        rules={[{ required: disabled ? false : true }]}
                        name="issueCountry">
                        <CountrySelector disabled={disabled} />
                    </Item>
                    <Item
                        label="有效期"
                        rules={[{ required: disabled ? false : true }]}
                        name="expiredAt">
                        <ExpiresDatePicker
                            idType={
                                idCardTypeList().find(
                                    it => it.oldIdCardType === item.idType
                                )?.idCardType
                            }
                        />
                    </Item>
                    <Divider />
                </Card>
            )}
            {disabled === false && (
                <Tabs
                    items={(() => {
                        return item.idList?.map((t, index) => ({
                            key: `${t?.idType + index}`,
                            label: `${
                                CardPassportTypeList().find(
                                    it => it.passportType === t.passportType
                                )?.displayName || ""
                            }${
                                idCardTypeList().find(
                                    it => it.idCardType === t.idType
                                )?.displayName || ""
                            }`,
                            children: (
                                <Space
                                    direction="vertical"
                                    size="middle"
                                    style={{
                                        display: "flex",
                                        marginBottom: 24,
                                    }}
                                    key={item.idType + index}>
                                    <Card>
                                        <Item label="证件类型">
                                            <Input
                                                value={`${
                                                    CardPassportTypeList().find(
                                                        it =>
                                                            it.passportType ===
                                                            t.passportType
                                                    )?.displayName || ""
                                                }${
                                                    idCardTypeList().find(
                                                        it =>
                                                            it.idCardType ===
                                                            t.idType
                                                    )?.displayName
                                                }`}
                                                className="readonlyInput"
                                                disabled
                                            />
                                        </Item>
                                        <Item label="证件号">
                                            <Input
                                                value={t.idNumber}
                                                className="readonlyInput"
                                                disabled
                                            />
                                        </Item>
                                        <Item label="签发国">
                                            <Input
                                                value={t.issueCountry}
                                                className="readonlyInput"
                                                disabled
                                            />
                                        </Item>
                                        {(!t.expiredAt ||
                                            dayjs(t.expiredAt).isBefore(
                                                dayjs().add(6, "M")
                                            )) && (
                                            <Item
                                                rules={[
                                                    {
                                                        required:
                                                            t.idType ===
                                                            "ID_CARD"
                                                                ? false
                                                                : true,
                                                    },
                                                ]}
                                                label="有效期"
                                                name={[index, "expiredAt"]}>
                                                <ExpiresDatePicker
                                                    idType={t.idType}
                                                />
                                            </Item>
                                        )}
                                        {!dayjs(t.expiredAt).isBefore(
                                            dayjs().add(6, "M")
                                        ) && (
                                            <Item
                                                label="有效期"
                                                validateStatus="error"
                                                help={isCertificateExpire(t)}>
                                                <Input
                                                    value={
                                                        t.idType ===
                                                            IdCardType.ID_CARD &&
                                                        t.expiredAt ===
                                                            "3000-01-01"
                                                            ? "长期"
                                                            : t.expiredAt
                                                    }
                                                    className="readonlyInput"
                                                    disabled
                                                />
                                            </Item>
                                        )}
                                        {t.idType === IdCardType.PASSPORT && (
                                            <Item
                                                label="护照类型"
                                                rules={[{ required: true }]}
                                                name={[index, "passportType"]}>
                                                <Select>
                                                    <Option
                                                        value={
                                                            PassportType.PASSPORT_FOR_PUBLIC_AFFAIRS
                                                        }>
                                                        因公
                                                    </Option>
                                                    <Option
                                                        value={
                                                            PassportType.PASSPORT
                                                        }>
                                                        因私
                                                    </Option>
                                                </Select>
                                            </Item>
                                        )}
                                    </Card>
                                </Space>
                            ),
                        }))
                    })()}
                />
            )}
        </>
    )
}
