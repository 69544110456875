import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { findWithLifeCycleApi } from "../applyForm/api"
import { AirportCity, ApplyForm, DataPage, HotelCity, MLocation, PageRequest, TrainCity } from "../models"
import { accessTokenSelector } from "../user/loginSlice"
import { currentCompanyIdSelector, currentAllowAllCityToOrder } from "../user/userSlice"
import { fetchLocation } from "../location/slice"
interface State {
    loading: boolean
    applyforPage?: DataPage<ApplyForm>
    applyForm?: ApplyForm
    trainCity?: TrainCity[]
    airportCity?: AirportCity[]
    hotelCity?: HotelCity[]
}

const initialState: State = {
    loading: false
}

export const bookingApplyForSelector = (state: RootState): ApplyForm | undefined => (state.booking.applyForm)

//获取已审批申请单列表
export const findApprovedList = createAsyncThunk('book/setup/list', async (pageReq: PageRequest, { getState, rejectWithValue }) => {
    const state = getState() as RootState
    const token = accessTokenSelector(state)
    const companyId = currentCompanyIdSelector(state)
    if (!token || !companyId) return rejectWithValue(undefined)
    try {
        return await findWithLifeCycleApi(token, { ...pageReq, state: "approved", companyId })
    } catch {
        return rejectWithValue(undefined)
    }
})

export const selectApplyForm = createAsyncThunk('book/setup/selectApplyForm', async (payload: ApplyForm | undefined, { dispatch, getState }) => {
    dispatch(storeApplyForm(payload))
    await dispatch(fetchLocation())
    const state = getState() as RootState
    const allLoc = state.location.cityChina ?? []
    // 允许使用所有城市预订
    const allowAllCityToOrder = currentAllowAllCityToOrder(state)
    const dests = payload?.destinations
    const usedLoc = []
    if (!allowAllCityToOrder && dests && dests.length > 0) {
        const destsPath = dests.map(it => it.path)
        usedLoc.push(...allLoc.filter(loc => destsPath.find(path => path === loc.depthPath)))
    } else {
        usedLoc.push(...allLoc)
    }
    // 火车
    const trainCity = usedLoc.map(it => getAllCityChild(it, (this_loc) => {
        if (this_loc.trainStation && this_loc.trainStation.length > 0) {
            const ret = [{ name: this_loc.name, allName: this_loc.allName, id: this_loc.id, isCity: true }]
            const tsRet = this_loc.trainStation.map(ts => ({ name: ts.name, allName: ts.allName, id: ts.id, isCity: false }))
            ret.push(...tsRet)
            return ret
        }
        return []
    })).flat(1)
    // 酒店
    const hotelCity = usedLoc.map(it => getAllCityChild(it, (this_loc) => {
        if (this_loc.child && this_loc.child.length > 0) {
            const ret: HotelCity[] = [{ name: this_loc.name, depthPath: this_loc.depthPath, enName: this_loc.enName, id: this_loc.id }]
            const tsRet = this_loc.child.map(ts => ({ name: ts.name, depthPath: ts.depthPath, enName: ts.enName, id: ts.id }))
            ret.push(...tsRet)
            return ret
        }
        return []
    })).flat(1)
    // 飞机
    const airportCity = usedLoc.map(it => getAllCityChild(it, (this_loc) => {
        if (this_loc.airport && this_loc.airport.length > 0) {
            const ret: AirportCity[] = [{ name: this_loc.name, depthPath: this_loc.depthPath, id: this_loc.id, ename: this_loc.enName, isCity: true }]
            const tsRet = this_loc.airport.map(ts => ({ name: ts.name, depthPath: this_loc.depthPath, id: ts.id, ename: ts.allName, isCity: false }))
            ret.push(...tsRet)
            return ret
        }
        return []
    })).flat(1)

    dispatch(setAllLoc({ trainCity, hotelCity, airportCity }))
})

const getAllCityChild = <T>(loc: MLocation, transform: (this_loc: MLocation) => T[]) => {
    const ret = [...transform(loc)]
    if (loc.child && loc.child.length > 0) {
        ret.push(...loc.child.flatMap(it => getAllCityChild(it, transform)))
    }
    return ret
}

const slice = createSlice({
    name: "book",
    initialState,
    reducers: {
        storeApplyForm: (state, { payload }: PayloadAction<ApplyForm | undefined>) => {
            state.applyForm = payload
        },
        resetPage: (state) => ({ ...state, applyforPage: undefined }),
        setAllLoc: (state, { payload }: PayloadAction<{ trainCity: TrainCity[], hotelCity: HotelCity[], airportCity: AirportCity[] }>) => {
            const { trainCity, hotelCity, airportCity } = payload
            // 火车
            state.trainCity = trainCity
            // 酒店
            state.hotelCity = hotelCity
            // 飞机城市
            state.airportCity = airportCity
        }
    },
    extraReducers: builder => {
        builder
            .addCase(findApprovedList.pending, (state) => { state.loading = true })
            .addCase(findApprovedList.fulfilled, (state, { payload }) => { state.loading = false; state.applyforPage = payload })
            .addCase(findApprovedList.rejected, (state) => { state.loading = false })
    }
})

const { storeApplyForm, } = slice.actions
export const { resetPage, setAllLoc } = slice.actions
export default slice.reducer
