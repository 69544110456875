import { Form, Input } from "antd"
import type { Traveller } from "../../models"
import React, { useState } from "react"
import { sourceStr } from "./TraverllerEditor"
import CountrySelector from "../../traveller/CountrySelector"

type Props = {
    traveller: Traveller
}

const TraverllerBasicInfo = ({ traveller: t }: Props): JSX.Element => {
    const { Item } = Form
    const [disabled] = useState(!t?.idList && t?.idType === 0)

    return (
        <>
            <Item label="类型">
                <Input
                    value={sourceStr(t.source)}
                    className="readonlyInput"
                    disabled
                />
            </Item>
            <Item label="姓名">
                <Input value={t.name} className="readonlyInput" disabled />
            </Item>
            <Item label="性别">
                <Input
                    value={t.gender === 0 ? "男" : "女"}
                    className="readonlyInput"
                    disabled
                />
            </Item>
            <Item label="手机">
                <Input value={t.phone} className="readonlyInput" disabled />
            </Item>
            <Item label="邮箱">
                <Input value={t.email} className="readonlyInput" disabled />
            </Item>
            {disabled === true && (
                <Item
                    name="country"
                    label="国籍"
                    initialValue={disabled ? "中国" : ""}
                    rules={[{ required: disabled ? false : true }]}>
                    <CountrySelector disabled={disabled} />
                </Item>
            )}
            {disabled === false && (
                <Item label="国籍">
                    <Input
                        value={t.country}
                        className="readonlyInput"
                        disabled
                    />
                </Item>
            )}
            <Item label="生日">
                <Input value={t.birthDay} className="readonlyInput" disabled />
            </Item>
        </>
    )
}

export default TraverllerBasicInfo
