import { AccessToken, CommonLegacyResult, CommonPageConent, PageRequest } from "../models"


export interface ApplyFormTodo {
    approvalId: string//审批流id
    documentId: string//报销单/申请单id
    companyId: string
    target: string//自己员工id
    state: string//审批状态
    executable: string//true 需要审批
    submitTime: string
    documentNum: string
    actionType: string
    amount: string//金额
    documentType: string
    senderName: string//自己 审批人姓名
    travellerCount?: string//出行人个数
    applyStartDate?: string
    applyEndDate?: string
    international?: string
    reimburseId?: string   //报销单id
}


export type PutApprovalRequestBody = {
    state: 1 | 2
    content?: string
}

export const fetchPutApprovalApi = async ({ access_token }: AccessToken, approvalId: string, body: PutApprovalRequestBody): Promise<void> => {
    console.log("🚀 ~ fetchPutApprovalApi ~ body---------------:", body)
    console.log("🚀 ~ fetchPutApprovalApi ~ approvalId-----------:", approvalId)
    const res = await fetch(`${window.SSCL_API}/approval/${approvalId}`, {
        mode: "cors",///跨域请求
        method: "PUT",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        },
        body: JSON.stringify(body)
    })
    if (!res.ok) {
        const body = (await res.json()) as CommonLegacyResult<void>
        return Promise.reject(body.msg || `请求错误: ${res.status}`)
    }
}


export type GetApprovalTodoListRequestBody = {
    type: 'applyForm' | 'reimburse'
    companyId: string
    executable: boolean
} & PageRequest

export const fetchGetApprovalTodoApi = async<TODO>({ access_token }: AccessToken,
    { type, companyId, executable, page, size }: GetApprovalTodoListRequestBody): Promise<CommonPageConent<TODO> | undefined> => {
    const res = await fetch(`${window.SSCL_API}/approval/todo/${type}?companyId=${companyId}&executable=${executable}&size=${size}&page=${page}`, {
        mode: "cors",///跨域请求
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': "application/json;charset=UTF-8"
        }
    })
    const body = await res.json()
    if (!res.ok) {
        if (res.status === 404) return undefined
        else {
            return Promise.reject(body.msg || `请求错误：${res.status}`)
        }
    }
    //解析数据  
    return (body as CommonLegacyResult<CommonPageConent<TODO>>).data
}

