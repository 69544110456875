import { ReactElement } from "react"
import { Row, Col, Typography } from "antd"
import { TrainOrderListModel } from "../../service/orderListModel"
import { idTypeToStr } from "../../../traveller/slice"

const TrainOrderDetailPassengerCpn = (
    props: TrainOrderListModel
): ReactElement => {
    const { passenger, trainSeat } = props
    const { Text } = Typography
    return (
        <Row>
            <Col flex="1 100px">
                <Text strong style={{ fontSize: 16 }}>
                    {passenger?.passengerName}
                </Text>
                <div>
                    {passenger?.cardTypeName &&
                    passenger?.cardTypeName?.length > 0
                        ? passenger?.cardTypeName
                        : idTypeToStr(passenger?.cardType || 0)}
                    ：{passenger?.cardNum}
                </div>
            </Col>
            <Col flex="none">
                <Text strong style={{ fontSize: 16 }}>
                    {trainSeat?.seatName}
                </Text>
                <div>¥{trainSeat?.price}</div>
            </Col>
        </Row>
    )
}

export default TrainOrderDetailPassengerCpn
