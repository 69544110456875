import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { CommonPageConent, PageRequest } from "../models"
import { accessTokenSelector } from "../user/loginSlice"
import { currentCompanySelector } from "../user/userSlice"
import { ApplyFormTodo, fetchGetApprovalTodoApi } from "./approvalApi"

type ApprovalApplyInfoState = {
    multiSelectEnabled: boolean
    loading: boolean
    error?: string
    todoList?: CommonPageConent<ApplyFormTodo>,
}
const initialState: ApprovalApplyInfoState = {
    loading: false,
    multiSelectEnabled: true,
}

export type FetchApplyFormTodoListParams = {
    executable: boolean
} & PageRequest


export const fetchApplyFormTodoList = createAsyncThunk('applyFormTodo/list', async (p: FetchApplyFormTodoListParams,
    { getState, rejectWithValue, dispatch }) => {
    const appState = getState() as RootState
    const token = accessTokenSelector(appState)
    const company = currentCompanySelector(appState)

    if (!token || !company) return rejectWithValue("登录会话过期")
    dispatch(setMultiSelectEnabled(company.companyBasicConfig.allowBatchApproval === true))
    try {
        return await fetchGetApprovalTodoApi<ApplyFormTodo>(token, { type: 'applyForm', companyId: company.companyId, ...p })
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const fetchReimburesFormTodoList = createAsyncThunk('applyFormTodo/list', async (p: FetchApplyFormTodoListParams,
    { getState, rejectWithValue, dispatch }) => {
    const appState = getState() as RootState
    const token = accessTokenSelector(appState)
    const company = currentCompanySelector(appState)

    if (!token || !company) return rejectWithValue("登录会话过期")
    dispatch(setMultiSelectEnabled(company.companyBasicConfig.allowBatchApproval === true))
    try {
        return await fetchGetApprovalTodoApi<ApplyFormTodo>(token, { type: 'reimburse', companyId: company.companyId, ...p })
    } catch (err) {
        return rejectWithValue(err)
    }
})


const applyFormTodoSlice = createSlice({
    name: "applyFormTodo",
    initialState,
    reducers: {
        resetApplyFormTodo: () => {
            return { ...initialState }
        },
        setMultiSelectEnabled: (state, { payload }: PayloadAction<boolean>) => {
            return { ...state, multiSelectEnabled: payload }
        }
    },
    extraReducers: builder => {
        builder
            // 申请单审批列表
            .addCase(fetchApplyFormTodoList.pending, (state) => {
                delete state.todoList
                state.loading = true
            })
            .addCase(fetchApplyFormTodoList.fulfilled, (state, { payload }) => {
                state.loading = false
                state.todoList = payload
            })
            .addCase(fetchApplyFormTodoList.rejected, (state, { payload }) => {
                state.loading = false
                state.error = payload as string || "未知错误"
            })
    }
})
export const { resetApplyFormTodo, setMultiSelectEnabled } = applyFormTodoSlice.actions
export default applyFormTodoSlice.reducer
