import { Button, Popconfirm } from "antd"
import { useState } from "react"
import { connect } from "react-redux"
import { AppDispatch, RootState } from "../../app/store"
import { deleteTraveller } from "./slice"

const mapStateToProps = (state: RootState) => ({
    loading: state.traveller.isDeleting,
})
const mapDispatchToProps = (dispatch: AppDispatch) => ({
    dispatchDelete: async (id: string) => {
        await dispatch(deleteTraveller(id))
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        id: string
        onFinished?: () => void
    }

const _button = ({ id, loading, dispatchDelete, onFinished }: Props) => {
    const [visible, setVisible] = useState(false)
    const confirm = async () => {
        await dispatchDelete(id)
        setVisible(false)
        onFinished?.()
    }

    return (
        <Popconfirm
            title="要删除这个出行人信息吗？"
            open={visible}
            onConfirm={confirm}
            onCancel={() => setVisible(false)}
            okButtonProps={{ loading: loading }}
            okType="danger"
            okText="删除"
            cancelText="取消">
            <Button
                type="link"
                size="small"
                danger
                onClick={() => setVisible(true)}>
                删除
            </Button>
        </Popconfirm>
    )
}

const DelTravellerButton = connect(mapStateToProps, mapDispatchToProps)(_button)
export default DelTravellerButton
