import { Input, Space } from "antd"
import { ReactElement, useEffect, useState } from "react"

export default function EngNameInput({
    onChange,
}: {
    value?: string
    cardType?: string
    onChange?: (value?: string) => void
}): ReactElement {
    const [values, setValues] = useState<{
        firstName?: string
        lastName?: string
    }>({})

    useEffect(() => {
        const { firstName, lastName } = values
        const name =
            firstName && lastName ? `${firstName}/${lastName}` : undefined
        onChange?.(name)
    }, [values, onChange])

    function changeFirstName(v: string) {
        const v1 = v !== "" ? v : undefined
        setValues({ ...values, firstName: v1 })
    }

    function changeLastName(v: string) {
        const v1 = v !== "" ? v : undefined
        setValues({ ...values, lastName: v1 })
    }

    return (
        <Space.Compact block>
            <Input
                style={{ width: "50%" }}
                value={values.lastName}
                placeholder="LastName"
                onChange={e => {
                    changeLastName(e.target.value)
                }}
            />
            <Input
                style={{ width: "50%" }}
                value={values.firstName}
                placeholder="FirstName"
                onChange={e => {
                    changeFirstName(e.target.value)
                }}
            />
        </Space.Compact>
    )
}
