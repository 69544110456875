import {
    Button,
    Card,
    DatePicker,
    Dropdown,
    Form,
    Grid,
    Input,
    message,
    Radio,
    Row,
    Select,
    Space,
} from "antd"
import { PageHeader } from "@ant-design/pro-layout"
import { Dayjs } from "dayjs"
import { ReactElement, useEffect, useState } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import { AppDispatch, RootState } from "../../app/store"
import { Staff, Traveller } from "../models"
import StaffSelector from "../staff/StaffSelector"
import EngNameInput from "./EngNameInput"
import { createTraveller } from "./slice"
import CountrySelector from "./CountrySelector"
import { PlusOutlined } from "@ant-design/icons"
import ExpiresDatePicker from "./ExpiresDatePicker"

const mapStateToProps = (state: RootState) => ({
    curStaff: state.user.currentStaff,
    loading: state.traveller.isCreating,
    selfInfo: state.user.currentStaff,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    dispatchCreateTraveller: (t: Traveller) => {
        dispatch(createTraveller(t))
    },
})
export enum IdCardType {
    ID_CARD = "ID_CARD",
    PASSPORT = "PASSPORT",
    HK_AND_MACAO_PASSPORT = "HK_AND_MACAO_PASSPORT",
    TW_PASSPORT = "TW_PASSPORT",
}
export enum PassportType {
    PASSPORT_FOR_PUBLIC_AFFAIRS = "PASSPORT_FOR_PUBLIC_AFFAIRS",
    PASSPORT = "PASSPORT",
}

type IdCardTypeAdapter = {
    idCardType: IdCardType
    displayName: string
    oldIdCardType: number
}
type CardPassportType = {
    passportType: PassportType
    displayName: string
}

export const CardPassportTypeList = (): Array<CardPassportType> => {
    return [
        {
            passportType: PassportType.PASSPORT_FOR_PUBLIC_AFFAIRS,
            displayName: "因公",
        },
        {
            passportType: PassportType.PASSPORT,
            displayName: "因私",
        },
    ]
}

export const idCardTypeList = (): Array<IdCardTypeAdapter> => {
    return [
        {
            idCardType: IdCardType.ID_CARD,
            displayName: "身份证",
            oldIdCardType: 0,
        },
        {
            idCardType: IdCardType.PASSPORT,
            displayName: "护照",
            oldIdCardType: 1,
        },
        {
            idCardType: IdCardType.HK_AND_MACAO_PASSPORT,
            displayName: "港澳通行证",
            oldIdCardType: 2,
        },
        {
            idCardType: IdCardType.TW_PASSPORT,
            displayName: "台湾通行证",
            oldIdCardType: 3,
        },
    ]
}

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

type IdCard = {
    expiredAt: string
    idNumber: string
    issueCountry: string
    idType: string
    passportType?: string
}

interface FormValues {
    source: number
    selectStaff?: Staff
    sourceRemarks?: string
    chineseName: string
    englishName: string
    gender: number
    birthDay: Dayjs
    phone: string
    email: string
    idType: number
    idNumber: string
    expiredAt: string
    idList: IdCard[]
    country: string
}

const { Option } = Select

const _editor = ({
    curStaff,
    loading,
    selfInfo,
    dispatchCreateTraveller,
}: Props): ReactElement => {
    const [form] = Form.useForm()
    const [posted, setPosted] = useState<boolean>()
    const navi = useNavigate()
    const [idCardTypeIdcard, setIdCardTypeIdcard] = useState(false)
    //  个性化布局
    const { lg } = Grid.useBreakpoint()

    useEffect(() => {
        form.setFieldsValue({
            idList: [{ idType: "ID_CARD", issueCountry: "中国" }],
        })
        if (loading && !posted) setPosted(true)
        else if (!loading && posted) {
            message.success("出行人保存成功！")
            navi("/my/traveller", { replace: true })
        }
    }, [loading, posted, navi, form])

    // 提交表单且数据验证成功
    const handleFinish = (t: FormValues) => {
        const idList = t.idList.map(k => ({
            ...k,
            expiredAt: k.expiredAt,
            name:
                k.idType === IdCardType.ID_CARD ? t.chineseName : t.englishName,
            idType: k.idType,
            passportType:
                k.idType === IdCardType.PASSPORT ? k.passportType : undefined,
        }))
        const args: Traveller = {
            ...t,
            name: t.chineseName || t.englishName,
            userId: curStaff?.userId || "",
            bindId: t.selectStaff?.staffId || curStaff?.userId || "",
            birthDay: t.birthDay.format("YYYY-MM-DD"),
            companyId: t.selectStaff?.companyId || curStaff?.companyId || "",
            idList: idList,
            idType:
                idCardTypeList().find(it => it.idCardType === idList[0]?.idType)
                    ?.oldIdCardType || 0,
            idNumber: idList[0]?.idNumber,
        }
        dispatchCreateTraveller(args)
    }

    // 字段值更新时触发
    const handleChanges = (changedValues: unknown, allValues: unknown) => {
        const changed = changedValues as FormValues
        const all = allValues as FormValues
        const idCard = all?.idList?.find(it => it.idType === IdCardType.ID_CARD)
        if (idCard?.idType === "ID_CARD") {
            setIdCardTypeIdcard(true)
        } else {
            setIdCardTypeIdcard(false)
        }

        if (changed.source === 0) {
            form.setFieldsValue(
                Object.assign(all, {
                    chineseName: selfInfo?.staffUserName,
                    email: selfInfo?.staffUserEmail,
                    phone: selfInfo?.staffUserPhone,
                    gender: selfInfo?.gender,
                } as FormValues)
            )
        } else if (changed.selectStaff) {
            form.setFieldsValue(
                Object.assign(all, {
                    chineseName: changed.selectStaff.staffUserName,
                    email: changed.selectStaff.staffUserEmail,
                    phone: changed.selectStaff.staffUserPhone,
                    gender: changed.selectStaff.gender,
                } as FormValues)
            )
        }
    }

    return (
        <>
            <PageHeader
                ghost={false}
                title="创建出行人"
                extra={[
                    <Button
                        key="1"
                        type="primary"
                        loading={loading}
                        onClick={() => {
                            form.submit()
                        }}>
                        保存
                    </Button>,
                ]}
            />
            <div className="container">
                <Row justify="center" style={{ width: lg ? "75%" : "100%" }}>
                    <Form
                        form={form}
                        layout="horizontal"
                        onValuesChange={handleChanges}
                        onFinish={handleFinish}>
                        <Form.Item
                            label="出行人类型"
                            name="source"
                            rules={[{ required: true }]}>
                            <Radio.Group name="source">
                                <Radio value={0}>本人</Radio>
                                <Radio value={1}>公司员工</Radio>
                                <Radio value={2}>外部人员</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={(pervious, next) =>
                                pervious["source"] !== next["source"]
                            }>
                            {({ getFieldValue }) => {
                                const source = getFieldValue("source") as number
                                switch (source) {
                                    case 1:
                                        return (
                                            <Form.Item
                                                label="选择同事"
                                                name="selectStaff"
                                                rules={[
                                                    { required: true },
                                                    {
                                                        validator: async (
                                                            _,
                                                            staff: Staff
                                                        ) => {
                                                            if (
                                                                staff?.staffId ===
                                                                selfInfo?.staffId
                                                            ) {
                                                                throw new Error(
                                                                    "不能在公司员工类型下选择自己"
                                                                )
                                                            }
                                                        },
                                                    },
                                                ]}>
                                                <StaffSelector />
                                            </Form.Item>
                                        )
                                    case 2:
                                        return (
                                            <Form.Item
                                                label="外部人员类型"
                                                name="sourceRemarks"
                                                rules={[{ required: true }]}>
                                                <Select>
                                                    <Option value="外包人员">
                                                        外包人员
                                                    </Option>
                                                    <Option value="外聘专家">
                                                        外聘专家
                                                    </Option>
                                                    <Option value="客户">
                                                        客户
                                                    </Option>
                                                    <Option value="亲属">
                                                        亲属
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                        )
                                }
                            }}
                        </Form.Item>
                        <Form.Item
                            required={true}
                            dependencies={["idList"]}
                            label="中文姓名"
                            name="chineseName"
                            rules={[
                                {
                                    validator: async (_, value) => {
                                        const idList =
                                            form.getFieldValue("idList")
                                        if (
                                            idList?.find(
                                                (it: { idType: string }) =>
                                                    it.idType === "ID_CARD"
                                            )
                                        ) {
                                            if (
                                                value === undefined ||
                                                value === null ||
                                                value === ""
                                            ) {
                                                throw new Error(
                                                    "使用身份证时必须填写中文姓名"
                                                )
                                            }
                                        }
                                    },
                                },
                            ]}>
                            <Input placeholder="请输入中文姓名" />
                        </Form.Item>
                        <Form.Item
                            required={true}
                            label="Full Name"
                            name="englishName"
                            rules={[
                                {
                                    validator: async (_, value) => {
                                        const idList =
                                            form.getFieldValue("idList")
                                        if (
                                            idList?.find(
                                                (it: { idType: string }) =>
                                                    it.idType !== "ID_CARD"
                                            )
                                        ) {
                                            if (
                                                value === undefined ||
                                                value === null ||
                                                value === ""
                                            ) {
                                                throw new Error(
                                                    "Please input full name."
                                                )
                                            }
                                        }
                                    },
                                },
                            ]}>
                            <EngNameInput />
                        </Form.Item>
                        <Form.Item
                            label="性别"
                            name="gender"
                            rules={[{ required: true }]}>
                            <Radio.Group>
                                <Radio value={0}>男</Radio>
                                <Radio value={1}>女</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="国籍"
                            name="country"
                            rules={[{ required: true }]}>
                            <CountrySelector />
                        </Form.Item>
                        <Form.Item
                            label="出生日期"
                            name="birthDay"
                            rules={[
                                { required: true, type: "object" as const },
                            ]}>
                            <DatePicker
                                format="YYYY-MM-DD"
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="手机"
                            name="phone"
                            rules={[{ required: true, len: 11 }]}>
                            <Input type="number" placeholder="请输入手机号" />
                        </Form.Item>
                        <Form.Item
                            label="邮箱"
                            name="email"
                            rules={[{ required: true, type: "email" }]}>
                            <Input type="email" placeholder="请输入邮箱" />
                        </Form.Item>

                        <Form.List
                            name="idList"
                            rules={[
                                {
                                    validator: async (_, value) => {
                                        if (
                                            value === undefined ||
                                            value == null ||
                                            !(value instanceof Array) ||
                                            value.length <= 0
                                        ) {
                                            throw new Error(
                                                "必须至少填写一个证件"
                                            )
                                        }
                                    },
                                },
                            ]}>
                            {(fields, { add, remove }, { errors }) => {
                                return (
                                    <>
                                        {fields.map(field => {
                                            const { key, name } = field
                                            // 获取选中的idType
                                            const idType =
                                                form.getFieldValue("idList")[
                                                    field.name
                                                ].idType
                                            return (
                                                <Space
                                                    key={key}
                                                    direction="vertical"
                                                    size="middle"
                                                    style={{
                                                        display: "flex",
                                                        marginBottom: 18,
                                                    }}>
                                                    <Card
                                                        styles={{ body: { padding: 15 } }}
                                                        key={key}
                                                        size="small"
                                                        title={
                                                            idCardTypeList().find(
                                                                it =>
                                                                    it.idCardType ===
                                                                    idType
                                                            )?.displayName
                                                        }
                                                        extra={
                                                            <Button
                                                                onClick={() =>
                                                                    remove(name)
                                                                }>
                                                                删除
                                                            </Button>
                                                        }>
                                                        <Form.Item
                                                            name="idType"
                                                            label="证件类型"
                                                            hidden>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item
                                                            label="证件号码"
                                                            labelCol={{
                                                                span: 6,
                                                            }}
                                                            name={[
                                                                name,
                                                                "idNumber",
                                                            ]}
                                                            rules={[
                                                                {
                                                                    required:
                                                                        true,
                                                                    pattern:
                                                                        idType ===
                                                                            "ID_CARD"
                                                                            ? new RegExp(
                                                                                /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
                                                                                "g"
                                                                            )
                                                                            : undefined,
                                                                    message:
                                                                        "请输入证件号码",
                                                                },
                                                            ]}>
                                                            <Input placeholder="请输入证件号码" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            label="有限期至"
                                                            labelCol={{
                                                                span: 6,
                                                            }}
                                                            rules={[
                                                                {
                                                                    required:
                                                                        idType ===
                                                                            "ID_CARD"
                                                                            ? false
                                                                            : true,
                                                                },
                                                            ]}
                                                            name={[
                                                                name,
                                                                "expiredAt",
                                                            ]}>
                                                            <ExpiresDatePicker
                                                                idType={idType}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            label="签发国"
                                                            labelCol={{
                                                                span: 6,
                                                            }}
                                                            rules={[
                                                                {
                                                                    required:
                                                                        true,
                                                                },
                                                            ]}
                                                            name={[
                                                                name,
                                                                "issueCountry",
                                                            ]}>
                                                            <CountrySelector />
                                                        </Form.Item>
                                                        {idType ===
                                                            "PASSPORT" && (
                                                                <Form.Item
                                                                    label="护照类型"
                                                                    labelCol={{
                                                                        span: 6,
                                                                    }}
                                                                    rules={[
                                                                        {
                                                                            required:
                                                                                true,
                                                                        },
                                                                    ]}
                                                                    name={[
                                                                        name,
                                                                        "passportType",
                                                                    ]}>
                                                                    <Select>
                                                                        <Option
                                                                            value={
                                                                                PassportType.PASSPORT_FOR_PUBLIC_AFFAIRS
                                                                            }>
                                                                            因公
                                                                        </Option>
                                                                        <Option
                                                                            value={
                                                                                PassportType.PASSPORT
                                                                            }>
                                                                            因私
                                                                        </Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            )}
                                                    </Card>
                                                </Space>
                                            )
                                        })}
                                        <Form.Item labelCol={{ offset: 2 }}>
                                            <Dropdown
                                                menu={{
                                                    items: [
                                                        {
                                                            key: "ID_CARD",
                                                            label: (
                                                                <div
                                                                    onClick={() =>
                                                                        idCardTypeIdcard ||
                                                                        add({
                                                                            idType: "ID_CARD",
                                                                        })
                                                                    }>
                                                                    身份证
                                                                </div>
                                                            ),
                                                            disabled:
                                                                idCardTypeIdcard,
                                                        },
                                                        {
                                                            key: "PASSPORT",
                                                            label: (
                                                                <div
                                                                    onClick={() =>
                                                                        add({
                                                                            idType: "PASSPORT",
                                                                        })
                                                                    }>
                                                                    护照
                                                                </div>
                                                            ),
                                                        },
                                                        {
                                                            key: "HK_AND_MACAO_PASSPORT",
                                                            label: (
                                                                <div
                                                                    onClick={() =>
                                                                        add({
                                                                            idType: "HK_AND_MACAO_PASSPORT",
                                                                        })
                                                                    }>
                                                                    港澳通行证
                                                                </div>
                                                            ),
                                                        },
                                                        {
                                                            key: "TW_PASSPORT",
                                                            label: (
                                                                <div
                                                                    onClick={() =>
                                                                        add({
                                                                            idType: "TW_PASSPORT",
                                                                        })
                                                                    }>
                                                                    台湾通行证
                                                                </div>
                                                            ),
                                                        },
                                                    ],
                                                }}
                                                placement="bottomLeft">
                                                <Button
                                                    style={{ width: "100%" }}
                                                    type="dashed"
                                                    block
                                                    icon={<PlusOutlined />}>
                                                    添加证件
                                                </Button>
                                            </Dropdown>
                                            <Form.ErrorList errors={errors} />
                                        </Form.Item>
                                    </>
                                )
                            }}
                        </Form.List>
                    </Form>
                </Row>
            </div>
        </>
    )
}

export const TravellerEditor = connect(
    mapStateToProps,
    mapDispatchToProps
)(_editor)
