import { Form, Input, Select } from "antd"
import { useAppSelector } from "../../../app/hooks"
import {
    CustomField,
    CustomFieldInstance,
    CustomFieldType,
} from "../../models"

const { Item } = Form
const colName = "customFieldInstances"
const mapToCusTomField = (field: CustomField, idx: number) => {
    let _innerComp: JSX.Element
    switch (field.fieldType) {
        case CustomFieldType.RADIO_BOX:
            _innerComp = (
                <Select>
                    {field.fieldValues?.map((v, i) => (
                        <Select.Option key={field.fieldName + i} value={v}>
                            {v}
                        </Select.Option>
                    ))}
                </Select>
            )
            break
        case CustomFieldType.CHECK_BOX:
            _innerComp = (
                <Select mode="multiple">
                    {field.fieldValues?.map((v, i) => (
                        <Select.Option key={field.fieldName + i} value={v}>
                            {v}
                        </Select.Option>
                    ))}
                </Select>
            )
            break
        case CustomFieldType.INPUT:
        default:
            _innerComp = <Input />
            break
    }
    return (
        <Item<CustomFieldInstance>
            key={field.fieldId}
            label={field.fieldName}
            required={field.required}>
            <Item
                noStyle
                hidden
                name={[colName, idx, "fieldId"]}
                initialValue={field.fieldId}>
                <Input />
            </Item>
            <Item
                noStyle
                hidden
                name={[colName, idx, "fieldName"]}
                initialValue={field.fieldName}>
                <Input />
            </Item>
            <Item<number>
                noStyle
                hidden
                name={[colName, idx, "orderNum"]}
                initialValue={field.orderNum}>
                <Input />
            </Item>
            <Item<Array<string>>
                name={[colName, idx, "fieldValues"]}
                rules={[
                    {
                        required: field.required,
                        message: `请输入${field.fieldName}`,
                    },
                ]}
                normalize={v => (v instanceof Array ? v : [v])}
                noStyle>
                {_innerComp}
            </Item>
        </Item>
    )
}

export default function CustomFields() {
    const customFieldsPersonal = useAppSelector(state => state.reimburesEditor.customFieldsPersonal)

    return <>{customFieldsPersonal && customFieldsPersonal?.map(mapToCusTomField)}</>
}
