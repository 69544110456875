import { Button, Col, DatePicker, Form, Grid, Row } from "antd"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import { AppDispatch, RootState } from "../../../app/store"
import { fetchApplyFormDetail } from "../../applyForm/viewerSlice"
import { useEffect } from "react"
import dayjs, { Dayjs } from "dayjs"
import { RangeValue } from "../../../app/tools"
import { SearchOutlined } from "@ant-design/icons"
import { findHotelDetails } from "./hotelSlice"
import { selectApplyForm } from "../bookSlice"
import { ApplyForm } from "../../models"
import { HotelListQueryArgs } from "./list/TopSearch"

const mapStateToProps = (state: RootState) => ({
    applyForm: state.applyFormViewer.detail,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    selectApplyForm: (v: ApplyForm) => {
        dispatch(selectApplyForm(v))
    },
    //查询申请单
    fetchApplyFormDetail: (id: string) => dispatch(fetchApplyFormDetail(id)),
    findHotelDetails: (
        checkInDate: string,
        checkOutDate: string,
        companyId: string,
        cityId: string
    ) =>
        dispatch(
            findHotelDetails({
                checkInDate,
                checkOutDate,
                companyId,
                cityId,
            })
        ),
})
type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        id?: string
        hotelId?: string
        companyId?: string
        cityId?: string
    }

const _renewal = ({
    id,
    hotelId,
    companyId,
    cityId,
    applyForm,
    selectApplyForm,
    fetchApplyFormDetail,
    findHotelDetails,
}: Props) => {
    // 跳转并传值
    const navi = useNavigate()
    const { Item } = Form
    //  个性化布局
    const { md } = Grid.useBreakpoint()
    useEffect(() => {
        // 路由携带id
        if (id) fetchApplyFormDetail(id)
    }, [fetchApplyFormDetail, id])
    // 选择申请表格
    useEffect(() => {
        if (applyForm) selectApplyForm(applyForm)
    }, [applyForm, selectApplyForm])
    // 把已过时间改为禁用
    function disabledDate(current: Dayjs) {
        const start = dayjs()
            .startOf("day")
            .isAfter(dayjs(applyForm?.applyStartDate))
            ? dayjs().startOf("day")
            : dayjs(applyForm?.applyStartDate)
        return (
            current &&
            (current < start || current > dayjs(applyForm?.applyEndDate))
        )
    }
    const onFinish = (fieldsValue: { dateRange: RangeValue }) => {
        const rangeValue = fieldsValue["dateRange"]
        const checkInDate = rangeValue?.[0]?.format("YYYY-MM-DD")
        const checkOutDate = rangeValue?.[1]?.format("YYYY-MM-DD")
        if (cityId && companyId && checkInDate && checkOutDate) {
            findHotelDetails(checkInDate, checkOutDate, companyId, cityId)
        }
        // 跳转HotelDetail.tsx
        navi(`/booking/hotel/detail/${hotelId}`)
    }
    return (
        <>
            <Row justify="center" style={{ height: "80%" }}>
                <Col flex="1 300px">
                    <div>
                        申请单时间:
                        {dayjs(applyForm?.applyStartDate).format("YYYY-MM-DD")}-
                        {dayjs(applyForm?.applyEndDate).format("YYYY-MM-DD")}
                    </div>
                </Col>
                <Col flex="1 300px">
                    <Form onFinish={onFinish}>
                        <Item name="cityId" initialValue={cityId} hidden>
                            <input hidden />
                        </Item>
                        <Item<HotelListQueryArgs>
                            name="dateRange"
                            labelCol={{ hidden: true }}
                            label="入住与离店日期"
                            rules={[
                                { required: true },
                                {
                                    validator: async (_, val: RangeValue) => {
                                        const from = val?.[0]
                                        const to = val?.[1]
                                        if (!from || !to) return
                                        if (to.diff(from) <= 0)
                                            throw Error("日期选择至少大于一天")
                                    },
                                },
                            ]}>
                            <DatePicker.RangePicker
                                style={{ width: "100%" }}
                                placeholder={["入住日期", "离店日期"]}
                                showTime={!md ? { format: 'HH' } : false}
                                format="YYYY-MM-DD"
                                disabledDate={disabledDate}
                            />
                        </Item>

                        <Item shouldUpdate>
                            {() => (
                                <Button
                                    style={{ width: "100%" }}
                                    type="primary"
                                    htmlType="submit"
                                    icon={<SearchOutlined />}>
                                    搜索
                                </Button>
                            )}
                        </Item>
                    </Form>
                </Col>
            </Row>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(_renewal)
