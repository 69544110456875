import { LoadingOutlined } from "@ant-design/icons"
import { Form, Select } from "antd"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useDebounce } from "react-use"
import { AppDispatch, RootState } from "../../../../app/store"
import { groupBy } from "../../../../app/tools"
import { HotelKeyword } from "../hotelAPI"
import { hotelKeywordSearch, resetHotelKeyword } from "../hotelSlice"

const mapStateToProps = (state: RootState) => ({
    keywords: state.bookHotel.keywordList,
    loading: state.bookHotel.keywordLoading,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    // 酒店关键字查询
    keywordSearch: (depthPath: string, keyword: string) => {
        dispatch(hotelKeywordSearch({ depthPath, keyword }))
    },
    // 重置酒店关键字
    resetKeyword: () => {
        dispatch(resetHotelKeyword())
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        cityId?: string
        onChange?: (v?: HotelKeyword) => void
    }

const { OptGroup, Option } = Select
const _search = ({
    keywords,
    loading,
    keywordSearch,
    onChange,
    resetKeyword,
}: Props) => {
    const [search, setSearch] = useState<HotelKeyword>() //查询关键词亦作为默认结果使用
    const [debouncedSearch, setDebouncedSearch] = useState<HotelKeyword>()
    // 处理防抖
    useDebounce(
        () => {
            setDebouncedSearch(search)
        },
        300,
        [search]
    )
    const [hotHotel, setHotHotel] = useState<HotelKeyword[]>([])
    const [hotLoc, setHotLoc] = useState<HotelKeyword[]>([])
    // 用于直接获取 form 中字段对应的值
    const depthPath = Form.useWatch("city")
    // 文本框变化回调
    const handleSearch = (k: string) => {
        // 如果from获取的字段不存在就直接return出去
        if (!depthPath) return
        setSearch(k && k !== "" ? { type: "1", name: k } : undefined)
    }

    useEffect(() => {
        // 如果(防抖)name存在
        if (debouncedSearch?.name) {
            // 酒店关键字查询
            keywordSearch(depthPath.depthPath, debouncedSearch?.name)
        } else {
            // 重置酒店关键字
            resetKeyword()
        }
    }, [debouncedSearch, depthPath, keywordSearch, resetKeyword])

    useEffect(() => {
        if (keywords) {
            const grouped = groupBy<HotelKeyword>("type")(keywords)
            setHotHotel(grouped["1"] || [])
            setHotLoc(grouped["3"] || [])
        }
    }, [keywords])
    // 被选中时调用，参数为选中项的 value
    const handleSelect = (v: string) => {
        // 将JSON格式字符串转换为js对象
        const k = JSON.parse(v) as HotelKeyword
        // 清除内容时回调
        onChange?.(k)
    }

    return (
        <Select
            placeholder="请输入酒店/地点/商圈以搜索"
            style={{ width: "100%" }}
            showSearch
            allowClear={!loading}
            onClear={onChange}
            disabled={!depthPath}
            onSearch={handleSearch}
            onSelect={handleSelect}
            filterOption={false}
            loading={loading}
            suffixIcon={loading && <LoadingOutlined spin={true} />}
            notFoundContent={null}>
            {search && (
                <Option
                    key={"self" + search.name}
                    value={JSON.stringify(search)}
                    children={search.name}
                />
            )}

            {hotLoc.length > 0 && (
                <OptGroup label="地点">
                    {hotLoc.map((k, index) => (
                        <Option
                            value={JSON.stringify(k)}
                            key={"type3" + index + k.name}
                            children={k.name}
                        />
                    ))}
                </OptGroup>
            )}

            {hotHotel.length > 0 && (
                <OptGroup label="酒店">
                    {hotHotel.map((k, index) => (
                        <Option
                            value={JSON.stringify(k)}
                            key={"type1" + index + k.name}
                            children={k.name}
                        />
                    ))}
                </OptGroup>
            )}
        </Select>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(_search)
