import React, { useEffect, useState } from 'react'
import { Button, Flex, Form, Input, InputNumber, Modal, Radio, Select, Space, Typography } from 'antd'
import { Accounts } from '../listSlice'
import { useAppSelector } from '../../../app/hooks'
import { connect } from 'react-redux'
import { CloseOutlined } from '@ant-design/icons'
import { StaffUserList } from '../editorSlice'
import { BankCard, PaymentList } from '../../models/reimburesForm'
import BankCardSelect from './components/BankCardSelect'
import { idNumberMarked } from '../../applyForm/viewer/TraverllerEditor'


export interface Financial {
    type?: number
    staffId: string
    financialAdjusted: boolean
    financialMoney: number
    staffIdList?: StaffUserList[]
    bankCard?: BankCard
    subtotal: number
}

type PaymentDetailProps = {
    value?: Financial[]
    onChange?: (v?: Financial[]) => void
}
const { Text } = Typography

const PaymentDetailItem = ({ value, onChange }: PaymentDetailProps) => {
    const { useFormInstance, useWatch } = Form
    const forms = useFormInstance()
    const [form] = Form.useForm()
    const [datas, setDatas] = useState<Financial[]>()
    // Modal弹框
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectValue, setSelectValue] = useState('')
    const [mode, setMode] = useState<"book" | "change">("book")
    // 归属人
    const staffIdList = useAppSelector(state => state.reimburseList.staffIdList || state.reimburesEditor.reimburesForm?.staffIdList)
    const [isButtonVisible, setIsButtonVisible] = useState(true)
    const reimburseMoney = useWatch<number>('reimburseMoney', forms)
    const accounts = useWatch<Accounts[] | undefined>('accounts', forms)

    useEffect(() => {
        if (value) {
            setIsButtonVisible(false)
        }
        const accountMap = new Map()
        accounts?.forEach(account => {
            if (!account.corpAccounts) {
                // 从 account 中提取必要的信息
                const { accountsSumMoney3, financialAdjusted, financialMoney, customerList } = account
                // 检查 customerList 中的 staffId 是否相同
                const uniqueStaffIds = new Set(customerList.map(customer => customer.staffId))
                // 如果 customerList 中有多个不同的 staffId，则我们需要分别处理
                uniqueStaffIds.forEach(staffId => {
                    const key = `${staffId}-${financialAdjusted}-${financialMoney}`
                    // 如果 Map 中已经存在这个键，则更新 accountsSumMoney3
                    if (accountMap.has(key)) {
                        accountMap.get(key).accountsSumMoney3 += accountsSumMoney3
                    } else {
                        // 否则，创建一个新的条目，并只包含当前 staffId 的相关信息
                        const filteredCustomerList = customerList.filter(customer => customer.staffId === staffId)
                        accountMap.set(key, {
                            ...account,
                            customerList: filteredCustomerList,
                            accountsSumMoney3: accountsSumMoney3 // 确保只累加当前 staffId 的金额
                        })
                    }
                })
            }
        })
        // 将 Map 转换为数组，并过滤掉不必要的属性
        const uniqueAccounts = Array.from(accountMap).map(([_key, value]) => {
            // 返回需要的属性
            return {
                staffId: value.customerList[0].staffId,
                financialAdjusted: value.financialAdjusted,
                financialMoney: value.financialMoney,
                subtotal: value.accountsSumMoney3,
                bankCard: staffIdList?.find(a => a.staffId === value.customerList[0].staffId)?.bankCardList?.[0],
            }
        })

        setDatas([...uniqueAccounts])
    }, [accounts, staffIdList, value])

    // 一键生成支付信息
    const setPaymentInfo = () => {
        if (reimburseMoney === undefined || reimburseMoney <= 0) {
            Modal.info({ title: '不需要填写支付列表' })
        } else {
            onChange?.(datas)
            setIsButtonVisible(false)
        }

    }

    // 单条记录
    const handleClickSingleEntry = () => {
        if (reimburseMoney === undefined || reimburseMoney <= 0) {
            Modal.info({ title: '不需要填写支付列表' })
        } else {
            if (!isModalOpen) {
                form.resetFields()
            }
            setIsModalOpen(true)
            setMode('book')
        }
    }

    // 删除
    const onDeleteClick = (idx: number) => {
        // 创建一个新数组，拷贝除了要删除的对象之外的所有对象
        const updatedArray = [...(value ?? [])]
        updatedArray.splice(idx, 1) // 使用splice删除要删除的对象
        // 调用 onChange 函数来更新数组
        onChange?.(updatedArray.length > 0 ? updatedArray : undefined)
        // 如果更新后的数组为空，则设置按钮可见
        setIsButtonVisible(updatedArray.length === 0)
    }
    // 归属人发生变化
    const handleChanges = (changedValues: Financial) => {
        datas?.filter(it => it.staffId === changedValues.staffId).forEach(i => {
            form.setFieldsValue({
                financialAdjusted: i.financialAdjusted,
                financialMoney: i.financialMoney,
                bankCard: i.bankCard
            })
        })
    }

    const onFinish = (values: Financial) => {

        // 创建一个新的数据对象
        const newData = {
            financialAdjusted: values.financialAdjusted,
            financialMoney: values.financialMoney,
            staffId: values.staffId,
            subtotal: values.subtotal,
            type: values.type,
            bankCard: values.bankCard
        }

        if (mode === 'book') {
            // 如果之前已经保存过数据，则将新的数据追加到之前保存的数据后面
            const updatedData = value ? [...value, newData] : [newData]
            setIsButtonVisible(false)
            // 调用 onChange 回调以更新父组件中的数据，传递整个数组
            onChange?.(updatedData)
            setIsModalOpen(false)
        } else {
            const _datas = datas?.map(item => {
                if (item.staffId === newData.staffId) {
                    return newData // 替换为新的对象
                }
                return item // 保持其他对象不变
            })
            setIsButtonVisible(false)
            // 调用 onChange 回调以更新父组件中的数据，传递整个数组
            onChange?.(_datas)
            setIsModalOpen(false)
        }
    }
    // 判断是否禁用按钮
    const isDisabled = () => {
        // 如果 accounts 存在且不为空，则按钮不应该被禁用
        if (accounts?.length) {
            const totalAmount = Number(value?.map(it => it.subtotal).reduce((total, amount) => total + amount))
            if (totalAmount >= reimburseMoney) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }

    const handleClick = (i: Financial) => {
        setMode('change')
        setIsModalOpen(true)
        datas?.filter(it => it.staffId === i.staffId).forEach(i => {
            form.setFieldsValue({
                staffId: i.staffId,
                financialAdjusted: i.financialAdjusted,
                financialMoney: i.financialMoney,
                bankCard: i.bankCard,
                subtotal: i.subtotal
            })
        })
    }

    return (
        <>
            <Flex vertical={true} gap='middle'> {
                (value?.length ?? 0) > 0 && (
                    value?.map((it, idx) => {
                        return <div key={idx}>
                            <Radio.Group>
                                <Radio.Button value="start" onClick={() => handleClick(it)}>
                                    <Space direction='horizontal'>
                                        <Text>{it.subtotal}</Text>
                                        <Text>{staffIdList?.find(t => t.staffId === it.staffId)?.staffUserName}</Text>
                                        <Text>现金/个人卡:{idNumberMarked(it.bankCard?.number)}</Text>
                                    </Space>
                                </Radio.Button>
                                <Radio.Button value="end" onClick={(e) => {
                                    onDeleteClick?.(idx)
                                    // 阻止冒泡
                                    e.stopPropagation()
                                }}>
                                    <CloseOutlined style={{ fontSize: '18px', color: '#08c' }} />
                                </Radio.Button>
                            </Radio.Group>
                        </div>
                    })
                )
            }
                <Space direction='vertical'>
                    {isButtonVisible && <Button disabled={isDisabled()} onClick={setPaymentInfo}>一键生成支付信息</Button>}
                    {<Button disabled={isDisabled()} type="primary" ghost onClick={handleClickSingleEntry}>手动添加单条记录</Button>}
                </Space >
            </Flex>

            <Modal title={`剩余[${reimburseMoney}]金额`}
                open={isModalOpen}
                destroyOnClose={true}
                footer={null}
                onCancel={() => setIsModalOpen(false)}
                forceRender >
                <Form labelCol={{ span: 4 }} form={form} onFinish={onFinish} onValuesChange={handleChanges}>
                    <Form.Item label='收款人' name='staffId' rules={[{ required: true, }]}>
                        <Select
                            key='staffUserName'
                            allowClear
                            showSearch={false}
                            onChange={(value) => setSelectValue(value)}
                            style={{ width: "100%" }}>
                            {staffIdList?.map((item, idx) => (
                                <Select.Option key={idx} value={item.staffId}>
                                    {item.staffUserName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label='收款方式' name="type" initialValue="0" rules={[{ required: true, }]}>
                        <Select>
                            <Select.Option value="0">现金/个人卡</Select.Option>
                            <Select.Option value="1">公务卡</Select.Option>
                            <Select.Option value="2">冲击卡</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label='银行卡' name='bankCard' rules={[{ required: true }]} >
                        <BankCardSelect selectValue={selectValue} />
                    </Form.Item>
                    <Form.Item name='financialAdjusted' hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item name='financialMoney' hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item label='金额' name='subtotal' rules={[
                        { required: true }, {
                            validator: async (_, vals) => {
                                const staffId = form.getFieldValue("staffId")
                                const num = value?.filter(it => it.staffId !== staffId).map(it => it.subtotal).reduce((total, amount) => total + amount, 0)
                                if (vals > reimburseMoney || (vals + num) > reimburseMoney) {
                                    throw new Error("请不要超出剩余金额")
                                }
                            },
                        },
                    ]}>
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item >
                        <Button style={{ width: "100%" }} type="primary" htmlType="submit">确定添加</Button>
                    </Form.Item>
                </Form>
            </Modal >
        </>
    )
}
const PaymentDetailList = connect()(PaymentDetailItem)

export default function PaymentListItem() {
    const forms = Form.useFormInstance()
    const reimburseMoney = Form.useWatch<number>('reimburseMoney', forms)

    return (
        <Form.Item
            name="paymentDetailList"
            label="支付列表"
            rules={[
                { required: true }, {
                    validator: async (_, value: PaymentList[]) => {
                        if (value?.map(it => it.subtotal).reduce((total, amount) => total + amount) < reimburseMoney) {
                            throw new Error(
                                "支付总额不正确"
                            )
                        }
                    },
                },
            ]}>
            <PaymentDetailList />
        </Form.Item>
    )
}
