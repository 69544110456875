import { Timeline } from "antd"
import { useEffect, useState } from "react"
import { DocumentChangeHistory } from "../../models"
import { Items } from "./ApplyProcessTimeline"

export default function HistoryTimeline({
    histList,
}: {
    histList: Array<DocumentChangeHistory>
}): JSX.Element {
    const [items, setItems] = useState<Items[]>()
    useEffect(() => {
        if (!histList || histList.length === 0) return
        histList.forEach(it => {
            const _items: Items[] = []
            _items.push({
                children: (
                    <div>
                        {it.time}
                        {/* 正常来说contentList不为空，不过测试数据中发现确实有可能有为空的可能 */}
                        {it.contentList?.map((c, ii) => (
                            <div key={"hist_content_" + ii}>{c}</div>
                        ))}
                    </div>
                ),
            })
            setItems(_items)
        })
    }, [histList])

    return <Timeline items={items} />
}
