export interface PageRequest {
    page?: number
    size?: number
    sort?: string
    staffUserName?: string
    startDate?: string
    endDate?: string
}

interface Pageable {
    sort: Sort
    offset: number
    pageNumber: number
    pageSize: number
    unpaged: boolean
    paged: boolean
}

interface Sort {
    empty: boolean
    unsorted: boolean
    sorted: boolean
}

//Fullsize page result from APIs
export interface DataPage<DATA> {
    length: number
    content?: DATA[]
    totalPages: number
    totalElements: number
    last: boolean
    size: number
    number: number
    numberOfElements: number
    first: boolean
    empty: boolean
    sort: Sort
    pageable: Pageable
    page: number
}

export interface CommonLegacyResult<DATA> {
    rc: number
    msg?: string
    data?: DATA
}

export interface CommonPageConent<CONTENT> {
    page: number,
    size: number,
    totalPages: number,
    totalElements: number,
    content?: Array<CONTENT>
}

export type HotelCity = {
    name: string
    depthPath: string
    enName: string
    id: string
}

export type TrainCity = {
    name: string
    allName: string
    id: string
    isCity: boolean
}

export type AirportCity = {
    depthPath: string
    name: string
    ename: string
    id: string
    isCity: boolean
}

export type AccountsCity = {
    depthPath: string
    name: string
}

export type CommonError = 'not_login' | 'token_expired'

export interface APIError {
    error: CommonError
}

export enum DocumentType {
    /**
         * 差旅申请单
         */
    APPLY_TRAVEL = "APPLY_TRAVEL",

    /**
     * 因私差旅申请单
     */
    APPLY_PERSONAL_TRAVEL = "APPLY_PERSONAL_TRAVEL",

    /**
     * 差旅报销单
     */
    REIMBURSE_TRAVEL = "REIMBURSE_TRAVEL",

    /**
     * 通用报销单
     */
    REIMBURSE_GENERAL = "REIMBURSE_GENERAL",
}

export type AsyncActionState = 'idle' | 'pending' | 'fulfilled' | 'reject'

// 汇率
export interface CurrencyList {
    currencyF: string
    currencyF_Name: string
    currencyT: string
    currencyT_Name: string
    currencyFD: string
    exchange: string
    result: string
    updateTime: string
}

export interface ReimbursePolicy {
    accountPolicyList: AccountPolicyList[]
    reimbursePolicyList: ReimbursePolicyList[]
}

export interface AccountPolicyList {
    accountsPolicyId: string
    reimbursePolicyId: string
    accountsType: number
    sort: number
    policyType: number
    displayName: string
    cityPackageId: string
    createdAt: string
    updatedAt: string
    selectable: boolean
    applyState: number
    currency: string
    value: number
    unitType: string
}

interface ReimbursePolicyList {
    reimbursePolicyId: string
    companyId: string
    policyName: string
    createdAt: string
    updatedAt: string
}

// 汇率
export interface TaxRateList {
    id: string//系统id
    name: string//类型名称
    type: number//类型
    namePrint: string//打印名称
    taxRate: number//税率
    serviceTaxRate?: number//服务费税率
}
