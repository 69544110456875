import { ReactElement, useState, useEffect } from "react"
import { Result, Button } from "antd"
import { Link, useLocation } from "react-router-dom"
import { CommonLegacyResult } from "../../../models"

interface ResCommonResult<DATA> extends CommonLegacyResult<DATA> {
    mode?: string
}

export const TrainPaySuccess = (): ReactElement => {
    const location = useLocation()
    const [title, setTitle] = useState("")
    const [subTitle, setSubTitle] = useState("")

    const res = location.state as ResCommonResult<unknown>
    useEffect(() => {
        const tmpRes = res as CommonLegacyResult<unknown>
        if (res.mode === "change") {
            setTitle("改签成功!")
            setSubTitle("点击左边菜单栏可以在订单列表查看详情")
        } else {
            setTitle(`支付成功! ${tmpRes.msg}`)
            setSubTitle("点击左边菜单栏可以在订单列表查看详情")
        }
    }, [res])

    return (
        <Result
            status="success"
            title={title}
            subTitle={subTitle}
            extra={
                <Button type="primary" ghost>
                    <Link to="/" replace>
                        返回首页
                    </Link>
                </Button>
            }></Result>
    )
}
