import { DeleteOutlined } from "@ant-design/icons"
import { Button, Form, Modal, Space, Tabs } from "antd"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useDebounce } from "react-use"
import { AppDispatch, RootState } from "../../../app/store"
import { fetchLocation } from "../../location/slice"
import { ApplyForm, Destination, MLocation } from "../../models"
import DestinationSearch from "./DestinationSearch"

const mapStateToProps = (state: RootState) => ({
    loading: state.location.loading,
    city: state.location.cityChina,
    foreignCity: state.location.foreignCity,
    applyForm: state.applyFormEditor.applyForm,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    dispatchInit: () => {
        dispatch(fetchLocation())
    },
})

type DestTagProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        value?: Destination[]
        onChange?: (v?: Destination[]) => void
    }

const { useFormInstance, useWatch } = Form
const _Destinations = ({
    city,
    loading,
    dispatchInit,
    value,
    onChange,
    foreignCity,
    applyForm,
}: DestTagProps) => {
    const { approvalDateTime, destinations } = applyForm || {}
    // Modal框开关
    const [modalVisiable, setModalVisiable] = useState(false)
    // 热门城市
    const [hotCity, setHotCity] = useState<MLocation[]>()
    // 搜索框
    const [search, setSearch] = useState<string>()
    // 防抖
    const [debouncedSearch, setDebouncedSearch] = useState<string>()
    const [searchResult, setSearchResult] = useState<MLocation[]>()
    // 国外
    const [overseasHotCity, setOverseasHotCity] = useState<MLocation[]>()
    const [overseasSearch, setOverseasSearch] = useState<string>()
    const [overseasDebouncedSearch, setOverseasDebouncedSearch] =
        useState<string>()
    const [overseasSearchResult, setOverseasSearchResult] =
        useState<MLocation[]>()

    // 获取国际国内出差按钮
    const form = useFormInstance()
    const isIntl = useWatch<boolean | undefined>("international", form)

    // 防抖
    useDebounce(
        () => {
            setDebouncedSearch(search)
            setOverseasDebouncedSearch(overseasSearch)
        },
        300,
        [search, overseasSearch]
    )

    useEffect(() => {
        //数据初始化
        if (!city && loading === "idle") {
            dispatchInit()
        } else if (city && !hotCity) {
            //默认加载热门城市
            const _hotCity = city.filter(it =>
                ["国内一线", "国内二线"].includes(it.category)
            )
            setHotCity(_hotCity)
            setSearchResult(_hotCity)
        }
        // 国外数据初始化
        if (!foreignCity && loading === "idle") {
            dispatchInit()
        } else if (foreignCity && !overseasHotCity) {
            //默认加载国外热门城市
            const _hotAbroadCity = foreignCity.filter(it =>
                ["国际一线", "国际二线"].includes(it.category)
            )
            setOverseasHotCity(_hotAbroadCity)
            setOverseasSearchResult(_hotAbroadCity)
        }
    }, [city, dispatchInit, hotCity, foreignCity, loading, overseasHotCity])

    useEffect(() => {
        if (!debouncedSearch || debouncedSearch === "") setSearchResult(hotCity)
        else {
            const subs = city?.filter(
                it =>
                    it.name.includes(debouncedSearch) ||
                    it.enName === debouncedSearch ||
                    it.shortName === debouncedSearch
            )
            setSearchResult(subs)
        }
        // 国外
        if (!overseasDebouncedSearch || overseasDebouncedSearch === "")
            setOverseasSearchResult(overseasHotCity)
        else {
            const subs = foreignCity?.filter(
                it =>
                    it.name.includes(overseasDebouncedSearch) ||
                    it.enName === overseasDebouncedSearch ||
                    it.shortName === overseasDebouncedSearch
            )
            setOverseasSearchResult(subs)
        }
    }, [
        debouncedSearch,
        city,
        hotCity,
        overseasDebouncedSearch,
        foreignCity,
        overseasHotCity,
    ])

    // 添加城市打开Modal框显示页面数据
    const addDestOnClick = () => {
        setModalVisiable(true)
        setSearchResult(hotCity)
        setOverseasSearchResult(overseasHotCity)
    }
    // 点击国内或国外城市
    const destItemOnClick = (v: MLocation) => () => {
        const _value = new Array<Destination>()
        value && _value.push(...value)
        _value.push({ name: v.name, path: v.depthPath })
        onChange?.(_value)
        setSearchResult(undefined)
        // 关闭Modal框
        setModalVisiable(false)
        // 清空国际城市
        setOverseasSearchResult(undefined)
    }

    // 删除
    const destOnClick = (idx: number) => () => {
        const _v = value?.filter((_, vIdx) => vIdx !== idx)
        onChange?.((_v?.length ?? 0) > 0 ? _v : undefined)
    }

    // 定义tabs值
    const items = [
        {
            label: "国内",
            key: "1",
            children: (
                <DestinationSearch
                    search={setSearch}
                    searchResult={searchResult}
                    destItemOnClick={destItemOnClick}
                />
            ),
        },
        {
            label: "国际",
            key: "2",
            children: (
                <DestinationSearch
                    search={setOverseasSearch}
                    searchResult={overseasSearchResult}
                    destItemOnClick={destItemOnClick}
                />
            ),
        },
    ]

    return (
        <>
            <Space direction="horizontal" style={{ flexWrap: "wrap" }}>
                {value?.map((it, idx) => (
                    <Button.Group key={"dest_" + idx}>
                        <Button type="default">{it.name}</Button>
                        {approvalDateTime &&
                            idx <= (destinations?.length ?? 0) - 1 ? null : (
                            <Button
                                type="default"
                                danger
                                onClick={destOnClick(idx)}
                                icon={<DeleteOutlined />}
                            />
                        )}
                    </Button.Group>
                ))}
                <Button
                    key="addDest"
                    loading={loading === "pending"}
                    type="dashed"
                    onClick={addDestOnClick}>
                    添加城市
                </Button>
            </Space>
            <Modal
                destroyOnClose
                centered
                footer={null}
                title="搜索城市"
                open={modalVisiable}
                onCancel={() => setModalVisiable(false)}>
                <Tabs
                    items={items}
                    style={{ display: isIntl ? "block" : "none" }}
                />
                <div style={{ display: isIntl ? "none" : "block" }}>
                    <DestinationSearch
                        search={setSearch}
                        searchResult={searchResult}
                        destItemOnClick={destItemOnClick}
                    />
                </div>
            </Modal>
        </>
    )
}

const Destinations = connect(mapStateToProps, mapDispatchToProps)(_Destinations)

export default function DestinationItem() {
    return (
        <Form.Item<ApplyForm>
            label="出行城市"
            name="destinations"
            rules={[{ required: true, type: "array", min: 2 }]}>
            <Destinations />
        </Form.Item>
    )
}
