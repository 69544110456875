import { ReactElement, useEffect, useState } from "react"
import { Timeline, Row, Col, Typography, Space } from "antd"
import { connect } from "react-redux"
import { AppDispatch, RootState } from "../../../../app/store"
import { fetchOrderTrainSchedule } from "../../service/orderModuleSlice"
import { TrainOrderScheduleParams } from "../../service/orderModuleApi"
import { TrainStopStationsVModel } from "../../service/orderModuleApi"
import { Items } from "../../../applyForm/viewer/ApplyProcessTimeline"

const mapStateToProps = (state: RootState) => ({
    orderTrainSchedule: state.orderModule.trainSchedule,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    // 火车详情时刻表
    trainDetailsSchedule: (param: TrainOrderScheduleParams) => {
        dispatch(fetchOrderTrainSchedule(param))
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        params: TrainOrderScheduleParams
    }

const _trainOrderDetailScheduleCpn = (props: Props): ReactElement => {
    const { orderTrainSchedule, trainDetailsSchedule, params } = props
    const [items, setItems] = useState<Items[]>()
    const { Title } = Typography
    useEffect(() => {
        // 火车详情时刻表
        trainDetailsSchedule(params)
    }, [trainDetailsSchedule, params])

    const stopStationCpn = (
        param: TrainStopStationsVModel,
        color: string
    ): ReactElement => {
        return (
            <Row style={{ color: `${color}`, textAlign: "center" }}>
                <Col flex="1">{param.station}</Col>
                <Col flex="1">{param.arrivalTime}</Col>
                <Col flex="1">{param.departureTime}</Col>
                <Col flex="1">{param.stayTimeSpan}</Col>
            </Row>
        )
    }

    useEffect(() => {
        const _items: Items[] = []
        orderTrainSchedule &&
            orderTrainSchedule.forEach(station => {
                if (
                    station.station === station.fromStation ||
                    station.station === station.toStation
                ) {
                    _items.push({
                        key: `${station.stationNo}`,
                        color: "#1890ff",
                        children: stopStationCpn(station, "#1890ff"),
                    })
                    setItems(_items)
                } else {
                    _items.push({
                        key: `${station.stationNo}`,
                        color: "gray",
                        children: stopStationCpn(station, "#333"),
                    })
                    setItems(_items)
                }
            })
    }, [orderTrainSchedule])

    return (
        <>
            <Space
                style={{
                    display: "flex",
                    justifyContent: "space-around",
                    margin: "0px 5px 15px 30px",
                }}>
                <Title level={5}>站点</Title>
                <Title level={5}>到达</Title>
                <Title level={5}>出发</Title>
                <Title level={5}>停留</Title>
            </Space>
            <Timeline items={items} />
        </>
    )
}

export const TrainOrderDetailScheduleCpn = connect(
    mapStateToProps,
    mapDispatchToProps
)(_trainOrderDetailScheduleCpn)
