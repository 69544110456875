import { ReactElement } from "react"
import { Card, Row, Col, Divider } from "antd"
import { PlaneOrderListModel } from "../../service/orderListModel"
import { red } from "@ant-design/colors"
const PlaneOrderDetailJourneyCpn = (
    props: PlaneOrderListModel
): ReactElement => {
    const { total, netPrice, service, tax, oilTax } = props
    return (
        <div className="orderlist">
            <Card
                title="订单支付明细"
                bordered={false}
                styles={{
                    header: {
                        backgroundColor: "rgb(248,248,248)",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                    }
                }}>
                <div style={{ display: "inline-block" }}>
                    支付金额：
                    <p
                        style={{
                            color: red[6],
                            fontSize: "20px",
                            display: "inline-block",
                        }}>
                        ¥{total}
                    </p>
                </div>
                <Card
                    styles={{ body: { padding: 0, backgroundColor: "rgb(250,250,250)", } }}>
                    <ul style={{ listStyle: "none", paddingLeft: "0px" }}>
                        <li>
                            <Row>
                                <Col span={12}> 乘机人 </Col>
                                <Col span={12} style={{ textAlign: "right" }}>
                                    {" "}
                                    x1人
                                </Col>
                            </Row>
                            <Divider style={{ margin: "12px" }} />
                        </li>
                        <li>
                            <Row>
                                <Col span={12}> 机票价 </Col>
                                <Col span={12} style={{ textAlign: "right" }}>
                                    {" "}
                                    ¥{netPrice} x1人
                                </Col>
                            </Row>
                            <Divider style={{ margin: "12px" }} />
                        </li>
                        <li>
                            <Row>
                                <Col span={12}> 税费 </Col>
                                <Col span={12} style={{ textAlign: "right" }}>
                                    {" "}
                                    ¥{tax} x1人
                                </Col>
                            </Row>
                            <Divider style={{ margin: "12px" }} />
                        </li>
                        <li>
                            <Row>
                                <Col span={12}> 燃油税 </Col>
                                <Col span={12} style={{ textAlign: "right" }}>
                                    {" "}
                                    ¥{oilTax} x1人
                                </Col>
                            </Row>
                            <Divider style={{ margin: "12px" }} />
                        </li>
                        <li>
                            <Row>
                                <Col span={12}> 服务费 </Col>
                                <Col span={12} style={{ textAlign: "right" }}>
                                    {" "}
                                    ¥{service} x1人
                                </Col>
                            </Row>
                        </li>
                    </ul>
                </Card>
            </Card>
        </div>
    )
}
export default PlaneOrderDetailJourneyCpn
