import { Avatar, Dropdown, Space } from "antd"
import { ReactElement, useCallback, useEffect } from "react"
import { connect } from "react-redux"
import { useLocation } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { AppDispatch, RootState } from "../../app/store"
// import { TopHoverMenu } from "../../components"
import { validateToken } from "./loginSlice"
import { cleanRedirect, refreshCompany } from "./userSlice"
import { reset as resetLoginState } from "../../features/user/loginSlice"
import { reset as resetUserState } from "../../features/user/userSlice"
import { useAppDispatch } from "../../app/hooks"

const mapStateToProps = (state: RootState) => ({
    username: state.user.currentStaff?.staffUserName,
    redirectTo: state.user.redirectTo,
    redirectError: state.user.redirectError,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    refreshCompany: () => {
        dispatch(refreshCompany())
    },
    validateToken: () => {
        dispatch(validateToken())
    },
    cleanRedirect: () => {
        dispatch(cleanRedirect())
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

const _userAvatar = ({
    username,
    refreshCompany,
    cleanRedirect,
    validateToken,
    redirectTo,
    redirectError,
}: Props): ReactElement => {
    const navi = useNavigate()
    const location = useLocation()

    useEffect(() => {
        //根目录刷新可用公司列表，非根目录获取token有效期
        if (location.pathname === "/") {
            refreshCompany()
        } else {
            validateToken()
        }
    }, [location.pathname, refreshCompany, validateToken])

    useEffect(() => {
        if (redirectTo) {
            cleanRedirect()
            navi(redirectTo, { replace: true, state: redirectError })
        }
    }, [redirectTo, navi, cleanRedirect, redirectError])

    const dispatch = useAppDispatch()
    const logout = useCallback(() => {
        dispatch(resetUserState())
        dispatch(resetLoginState())
        navi("/login", { replace: true })
    }, [dispatch, navi])

    const items = [
        { key: "/logout", onClick: logout, label: "登出", danger: true },
    ]
    return (
        <Dropdown menu={{ items }} trigger={["click"]}>
            <Space className="clickable" style={{ padding: "0 10px" }}>
                <Avatar>{username ?? ""}</Avatar>
                <div>{(username && username) || "加载中"}</div>
            </Space>
        </Dropdown>
    )
}

export const UserAvatar = connect(
    mapStateToProps,
    mapDispatchToProps
)(_userAvatar)
