import { Form, Input, Select } from "antd"
import { useEffect } from "react"
import { useAppSelector } from "../../../app/hooks"
import { ApplyForm, BusinessPurposes } from "../../models"

const purposeToOption = (p: BusinessPurposes, idx: number) => (
    <Select.Option key={`purpose-${idx}`} value={p.purposes}>
        {p.purposes}
    </Select.Option>
)

const { Item } = Form
export default function TravelPurposesSelector() {

    const list = useAppSelector(state => state.reimburesEditor.purposes)
    const options = list?.filter(p => p.enable).map(purposeToOption)
    const form = Form.useFormInstance()
    const actionType = Form.useWatch<string | undefined>("actionType", form)
    const applyForm = Form.useWatch<ApplyForm | undefined>("applyForm", form)
    useEffect(() => {
        const purpose = list?.find(p => p.purposes === actionType)
        if (purpose) {
            form.setFieldValue("businessPurposesId", purpose.businessPurposesId)
        } else {
            form.resetFields(["businessPurposesId"])
        }
    })

    return (
        <>
            <Item
                hidden={(list?.length ?? 0) <= 0}
                label="出差目的"
                name="actionType"
                rules={[{ required: (list?.length ?? 0) > 0 }]}>
                <Select disabled={applyForm ? true : false}>{options}</Select>
            </Item>
            <Item noStyle hidden name="businessPurposesId">
                <Input />
            </Item>
        </>
    )
}

