import {
    HomeOutlined,
    MoneyCollectOutlined,
    ProfileOutlined,
    SolutionOutlined,
    SwapOutlined,
    TagsOutlined,
    UserOutlined,
} from "@ant-design/icons"
import type { MenuProps } from "antd"
import { Drawer, Menu } from "antd"
import { ReactElement, useCallback } from "react"
import { Link } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { changeMobileMenuVisible } from "../features/menu/menuSlice"

import IconFont from "./IconFont"

type MenuItem = Required<MenuProps>["items"][number]

export const LeftSideMenu = ({
    onMenuClick,
}: {
    onMenuClick?: () => void
}): ReactElement => {
    const items: MenuItem[] = [
        { key: "/", icon: <HomeOutlined />, label: <Link to="/">首页</Link> },
        {
            key: "/applyForm",
            icon: <SolutionOutlined />,
            label: <Link to="/applyForm/list">出差申请</Link>,
        },
        {
            key: "/booking",
            icon: <TagsOutlined />,
            label: "差旅预定",
            children: [
                {
                    key: "/booking/airplane",
                    icon: <IconFont type="icon-airplane" />,
                    label: <Link to="/booking/airplane">飞机</Link>,
                },
                {
                    key: "/booking/hotel",
                    icon: <IconFont type="icon-hotel" />,
                    label: <Link to="/booking/hotel">酒店</Link>,
                },
                {
                    key: "/booking/train",
                    icon: <IconFont type="icon-train" />,
                    label: <Link to="/booking/train">火车</Link>,
                },
                {
                    key: "/booking/setup",
                    icon: <SwapOutlined />,
                    label: <Link to="/booking/setup">选择申请单</Link>,
                },
            ],
        },
        {
            key: "/order/list",
            icon: <ProfileOutlined />,
            label: <Link to="/order/list">订单列表</Link>,
        },
        {
            key: "/accountsForm",
            icon: <MoneyCollectOutlined />,
            label: <Link to="/accountsForm/list">账目</Link>,
        },
        {
            key: "/reimburseForm",
            icon: <MoneyCollectOutlined />,
            label: <Link to="/reimburseForm/list">报销单</Link>,
        },
        {
            key: "/my",
            icon: <UserOutlined />,
            label: "我的",
            children: [
                {
                    key: "/my/traveller",
                    label: <Link to="/my/traveller">常用出行人</Link>,
                },
                {
                    key: "/my/todo/applyForm",
                    label: <Link to="/my/todo/applyForm">审批申请单</Link>,
                },
                {
                    key: "/my/todo/resburesForm",
                    label: <Link to="/my/todo/resburesForm">审批报销单</Link>,
                },
            ],
        },
    ]
    return (
        <Menu
            items={items}
            mode="inline"
            theme="light"
            defaultOpenKeys={["/booking", "/my"]}
            onClick={onMenuClick}
        />
    )
}

export const MobileMenu = (): ReactElement => {
    const visiable = useAppSelector(state => state.layout.isMobileMenuVisible)
    const dispatch = useAppDispatch()
    const closeMenu = useCallback(() => {
        dispatch(changeMobileMenuVisible(false))
    }, [dispatch])

    return (
        <Drawer
            styles={{ body: { padding: 0 } }}
            onClose={closeMenu}
            closeIcon={null}
            open={visiable}
            getContainer={false}
            placement="left"
            width="75vw"
            key="Mobile-Menu">
            <LeftSideMenu onMenuClick={closeMenu} />
        </Drawer>
    )
}
