import { ReactElement, useState, useEffect } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import {
    Layout,
    Card,
    Button,
    Form,
    Radio,
    Input,
    Popconfirm,
    message,
    Typography,
    Descriptions,
    Row,
    Col,
    Alert,
    Modal,
} from "antd"
import { PageHeader } from "@ant-design/pro-layout"
import { PlaneOrderListModel } from "../service/orderListModel"
import { PlaneDetailRefundRulesCpn } from "../detail/components/PlaneOrderDetailRulesCpn"
import { ChangeRefundReasonList } from "./ReasonList"
import { ChangeAirplaneJourney } from "./ChangeAirplaneJourney"
import PlaneOrderDetailPassengerCpn from "../detail/components/PlaneOrderDetailPassengerCpn"
import { AppDispatch, RootState } from "../../../app/store"
import {
    deleteOrderResInfo,
    airplaneRefundOrder,
} from "../../book/airplane/services/bookAirplaneSlice"
import { RefundAirplaneOrderParams } from "../../book/airplane/services/bookAirplaneApi"
import { connect } from "react-redux"
import { orange } from "@ant-design/colors"

const { TextArea } = Input
const { Text } = Typography
interface FormValues {
    dictValue: string
    freewillFlag: string
    refundOrChangeReasonExplain: string
}

const mapStateToProps = (state: RootState) => ({
    loading: state.bookAirplane.bookLoading,
    orderRes: state.bookAirplane.orderRes,
    specialFlightNotice: state.location.tipMessage?.specialFlightNotice,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    // 飞机退票申请
    airplaneRefundOrder: (params: RefundAirplaneOrderParams) => {
        dispatch(airplaneRefundOrder(params))
    },
    // 飞机  删state里的数据，清除状态
    deleteOrderRes: () => {
        dispatch(deleteOrderResInfo())
    },
})

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

const _refundTicket = (props: Props): ReactElement => {
    const {
        airplaneRefundOrder,
        loading,
        orderRes,
        deleteOrderRes,
        specialFlightNotice,
    } = props
    const location = useLocation()
    // 路由跳转传参
    const navi = useNavigate()
    //通过useLocation把Order.tsx订单列表页面的item接收过来
    const model = location.state as PlaneOrderListModel
    // 获取路由携带参数
    const { id } = useParams<{ id: string }>()
    // 创建 Form 实例，用于管理所有数据状态
    const [form] = Form.useForm()
    // 确认框开关
    const [visible, setVisible] = useState<boolean>(false)
    // 提示信息开关
    const [tipsvisible, setTipsvisible] = useState(true)
    const { Title } = Typography
    // 提交表单输入值
    const [formValues, setFormValues] = useState<FormValues>({
        dictValue: "",
        freewillFlag: "",
        refundOrChangeReasonExplain: "",
    })
    const { flight, cabin, applyId } = model
    const rulesParams = {
        flightDate: flight?.flightDate,
        supplierId: cabin?.supplierId,
        airline: flight?.airline,
        productType: cabin?.productType,
        initTicketPrice: cabin?.initTicketPrice,
        flightNum: flight?.flightNum,
        cabin: cabin?.cabin,
        takeOffPort: flight?.takeOffPort,
        landingPort: flight?.landingPort,
        applyForId: applyId,
    }
    //提交表单
    const handleFinish = (values: FormValues) => {
        // 打开确认框
        setVisible(true)
        setFormValues(values)
    }
    const showPopconfirm = () => {
        // 提交表单
        form.submit()
    }

    const handleOk = () => {
        //关闭确认框
        setVisible(false)
        if (id === "1") {
            // 改签
            const paramModel = {
                ...model,
                reason: formValues?.refundOrChangeReasonExplain,
                reasonCode: formValues?.dictValue,
            }
            // 携带参数跳转AirplaneList.tsx
            navi("/booking/airplane", { state: paramModel })
        } else {
            // 退票
            const params: RefundAirplaneOrderParams = {
                applyForId: model.applyId || "",
                supplierId: model.cabin?.supplierId || "",
                orderNumber: model.customId || "",
                ticketNum: model.ticketNum || "",
                freewillFlag: formValues.freewillFlag,
                refundOrChangeReasonNumber: formValues?.dictValue,
                refundOrChangeReasonExplain:
                    formValues?.refundOrChangeReasonExplain,
            }
            //飞机退票申请
            airplaneRefundOrder(params)
        }
    }

    const handleCancel = () => {
        //关闭确认框
        setVisible(false)
    }

    useEffect(() => {
        if (orderRes && orderRes.rc === 0) {
            message.success(orderRes.msg)
            navi("/order/list")
        } else if (orderRes && orderRes.rc === 1) {
            Modal.warning({ content: `${orderRes?.msg}` })
        }
        return () => {
            // 卸载 飞机  删state里的数据，清除状态
            deleteOrderRes()
        }
    }, [orderRes, deleteOrderRes, navi])

    return (
        <Layout>
            <PageHeader
                ghost={false}
                title={`我要${id === "1" ? "改签" : "退票"}`}
                onBack={() => {
                    navi(-1)
                }}></PageHeader>
            {specialFlightNotice
                ? tipsvisible && (
                    <Alert
                        banner
                        message={
                            <Text style={{ color: orange[5] }}>
                                {specialFlightNotice}
                            </Text>
                        }
                        closable
                        afterClose={() => setTipsvisible(false)}
                    />
                )
                : null}

            <div className="container">
                <Card
                    bordered={false}
                    styles={{ body: {  padding: "12px 0px 12px 0px"  } }}>
                    <Title level={5}>行程信息</Title>
                    <Row gutter={[24, 24]}>
                        <Col flex="1 200px">
                            <ChangeAirplaneJourney {...model} />
                        </Col>
                        <Col flex="1" style={{ textAlign: "center" }}>
                            <PlaneOrderDetailPassengerCpn {...model} />
                        </Col>
                    </Row>
                </Card>

                <Form form={form} onFinish={handleFinish}>
                    {id === "2" && (
                        <Card>
                            <Form.Item
                                style={{ marginBottom: "0" }}
                                label="是否自愿退票"
                                rules={[
                                    {
                                        required: true,
                                        type: "string",
                                        message: "请选择是否自愿退票",
                                    },
                                ]}
                                name="freewillFlag">
                                <Radio.Group>
                                    <Radio value={'Y'}>是</Radio>
                                    <Radio value={'N'}>否</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Card>
                    )}
                    <Descriptions
                        layout="horizontal"
                        title={`${id === "1" ? "改签" : "退票"}原因`} items={[{
                            contentStyle: { display: 'flow-root' },
                            children: <Form.Item
                                name="dictValue"
                                rules={[
                                    {
                                        required: true,
                                        message: `请选择${id === "1" ? "改签" : "退票"
                                            }原因`,
                                    },
                                ]}>
                                <ChangeRefundReasonList
                                    orderModel={model}
                                    type={id || ""}
                                />
                            </Form.Item>
                        }]} />
                    <Descriptions layout="horizontal" title="备注说明" items={[{
                        contentStyle: { display: 'flow-root', padding: '0px' },
                        children: <Form.Item
                            name="refundOrChangeReasonExplain"
                            rules={[
                                {
                                    required: true,
                                    message: `请输入${id === "1" ? "改签" : "退票"
                                        }原因说明`,
                                },
                            ]}>
                            <TextArea
                                rows={2}
                                placeholder={`请输入${id === "1" ? "改签" : "退票"
                                    }原因`}
                            />
                        </Form.Item>
                    }]} />
                </Form>
                <Descriptions layout="horizontal" title="退改签规则" items={[{
                    contentStyle: { display: 'flow-root' },
                    children: <PlaneDetailRefundRulesCpn params={rulesParams} />
                }]} />
                <Card bordered={false}>
                    <Popconfirm
                        title="温馨提示:如果您已经值机，可能会导致退改签失败。"
                        open={visible}
                        onConfirm={handleOk}
                        okButtonProps={{ loading: loading }}
                        onCancel={handleCancel}>
                        <Button
                            type="primary"
                            size="large"
                            block
                            onClick={showPopconfirm}>
                            {" "}
                            {`${id === "1" ? "下一步,选择改签日期" : "确定退票"
                                }`}{" "}
                        </Button>
                    </Popconfirm>
                </Card>
            </div>
        </Layout>
    )
}

export const RefundTicket = connect(
    mapStateToProps,
    mapDispatchToProps
)(_refundTicket)
