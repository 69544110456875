import { Button, Drawer, Grid } from "antd"
import { useEffect, useState } from "react"
import { Traveller } from "../models"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import TravellerViewer from "../applyForm/viewer/TravellerViewer"
import { setIsEditMode } from "../applyForm/viewerSlice"
import TraverllerEditor from "../applyForm/viewer/TraverllerEditor"

const { useBreakpoint } = Grid

type Props = {
    item: Traveller
    onFinished: () => void
}

export const TravellerViewerButton = ({ item, onFinished }: Props) => {
    // Drawer开关
    const [visiable, setVisiable] = useState(false)
    //  个性化布局
    const screens = useBreakpoint()
    const isEditMode = useAppSelector(state => state.applyFormViewer.isEditMode)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (visiable) {
            dispatch(setIsEditMode(false))
        }
    }, [dispatch, visiable])

    return (
        <>
            <Button type="link" size="small" onClick={() => setVisiable(true)}>
                详情
            </Button>
            <Drawer
                width={screens.lg ? 640 : "75vw"}
                style={{ backgroundColor: "#fafafa" }}
                onClose={() => {
                    setVisiable(false)
                }}
                open={visiable}
                destroyOnClose>
                {isEditMode === false && (
                    <TravellerViewer traveller={item} editable={true} />
                )}
                {isEditMode === true && (
                    <TraverllerEditor
                        traveller={item}
                        onFinished={onFinished}
                    />
                )}
            </Drawer>
        </>
    )
}
